'use strict';

// Import the Ethers library for some data manipulation tasks.
import { ethers } from 'ethers';

// Import Ethers connection helper functions.
import { ethersService } from '@/services';
import { addError } from '@/components/utility/notifications';

// Accessible status messages for future localization.
const MESSAGES = {
	NO_INFURA: `We were unable to connect to the default Infura provider.`,
	UNKNOWN: `We were unable to connect to your Ethereum provider for an unknown reason.`,
};

// Export the Ethers actions.
export default {
	// Attempt to initialize our Ethereum provider with event handling.
	// Initialization can be passed a callback which is triggered upon success.
	async initialize ({ dispatch, commit }, callback) {
		commit('initializeRequest');
		await ethersService.startProviderWatcher(dispatch, callback);
	},

	// Handle the results of failing to initialize the Ethereum provider.
	async initializeFailure ({ commit }, errorReason) {
		await ethersService.stopProviderWatcher();

		commit('initializeFailure');
		addError(MESSAGES[errorReason]);
	},

	async hasLocalProvider ({ commit }, value) {
		commit('hasLocalProvider', value);
	},

	// Handle the results of a successful Ethereum provider initialization.
	async initializeSuccess ({ commit }, { canSign, provider, callbacks }) {
		await ethersService.stopProviderWatcher();

		let address;
		let balance = ethers.BigNumber.from(0);
		if (canSign) {
			const signer = provider.getSigner();
			address = await signer.getAddress();
			balance = await signer.getBalance();
		}
		const network = await provider.getNetwork();
		const networkId = ethers.utils.hexValue(network.chainId);

		commit('initializeSuccess', {
			canSign,
			provider,
			address,
			balance,
			networkId,
		});

		// If callbacks ere attached to our initialization request, trigger them.
		for (const callback of (callbacks || [])) {
			await callback();
		}
	},

	// Connect the user's current signing address to the application.
	async connectWallet ({ state }, options = undefined) {
		await ethersService.connectLocalEthereumProvider(!!state.address, options);
	},

	// Disconnect the user's current signing address from the application.
	async disconnectWallet ({ commit }) {
		commit('disconnect');
	},

	// Change the currently connected address that we're tracking in VueX state.
	async changeCurrentAddress ({ commit }, currentAddress) {
		commit('addressChanged', currentAddress);
	},

	// Change the Ethereum network that we're tracking in VueX state.
	async changeChainId ({ commit }, chainId) {
		commit('networkChanged', chainId);
	},

	// Change the block number that we're tracking in VueX state.
	async changeBlockNumber ({ commit }, blockNumber) {
		commit('blockNumberChanged', blockNumber);
	},
};
