<template>
  <svg
    fill="none"
    height="16"
    viewbox="0 0 14 16"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.6 0H12.4C13.3 0 14 0.7 14 1.6V16L12.3 14.5 11.3 13.6 10.3 12.6 10.7 14.1H1.6C0.7 14.1 0 13.4 0 12.5V1.6C0 0.7 0.7 0 1.6 0ZM8.7 9.8C9 10.1 9.3 10.4 9.3 10.4 11 10.4 11.7 9.2 11.7 9.2 11.7 6.7 10.6 4.6 10.6 4.6 9.4 3.7 8.3 3.7 8.3 3.7L8.2 3.9C9.6 4.3 10.2 4.9 10.2 4.9 9.4 4.4 8.6 4.2 7.8 4.1 7.2 4 6.7 4.1 6.2 4.1 6.1 4.1 6.1 4.1 6.1 4.1 6.1 4.1 6 4.1 6 4.1 5.8 4.2 5.1 4.3 4.2 4.6 3.9 4.8 3.8 4.9 3.8 4.9 3.8 4.9 4.4 4.2 5.9 3.8L5.8 3.7C5.8 3.7 4.7 3.7 3.5 4.6 3.5 4.6 2.4 6.7 2.4 9.2 2.4 9.2 3 10.4 4.8 10.4 4.8 10.4 5.1 10.1 5.4 9.8 4.3 9.5 4 8.8 4 8.8 4 8.8 4 8.9 4.2 9 4.2 9 4.2 9 4.2 9 4.2 9 4.2 9 4.2 9 4.3 9 4.3 9 4.3 9 4.5 9.2 4.7 9.2 4.9 9.3 5.2 9.4 5.6 9.6 6 9.7 6.6 9.8 7.3 9.8 8.1 9.7 8.5 9.6 8.9 9.5 9.3 9.3 9.6 9.2 9.9 9.1 10.2 8.8 10.2 8.8 9.8 9.5 8.7 9.8ZM4.7 7.6C4.7 7.1 5.1 6.7 5.6 6.7 6 6.7 6.4 7.1 6.4 7.6 6.4 8.1 6 8.5 5.6 8.5 5.1 8.5 4.7 8.1 4.7 7.6ZM7.7 7.6C7.7 7.1 8 6.7 8.5 6.7 8.9 6.7 9.3 7.1 9.3 7.6 9.3 8.1 8.9 8.5 8.5 8.5 8 8.5 7.7 8.1 7.7 7.6Z"
      fill="currentColor"
    />
  </svg>
</template>
