<template>
  <template v-if="!isHasSlot">
    <ListItem
      v-for="(option, index) in options"
      :key="index"
      :is-active="!isMultiple && isActive(option)"
      color="violet"
      class="select-item"
      @click="handleChange(option)"
    >
      <div class="select-item-inner">
        <div
          v-if="!!option?.icon"
          class="select-icon icon"
        >
          <component :is="option.icon" />
        </div>
        <p>{{ option?.label }}</p>
      </div>
      <div
        v-if="isMultiple && isActive(option)"
        class="select-checked icon"
      >
        <CheckIcon />
      </div>
    </ListItem>
  </template>
  <slot
    v-else
    :options="options"
    :handleChange="handleChange"
    :isActive="isActive"
  />
</template>

<script>
import ListItem from '@/components/ui/list/ListItem';
import { CheckIcon } from '@/components/icons';

import SelectButton from './SelectButton.vue';

export default {
	components: {
		ListItem,
		SelectButton,
		CheckIcon,
	},
	props: {
		modelValue: {
			type: [Object, Array],
			default: null,
			required: false,
		},
		options: {
			type: Array,
			required: true,
		},
		isMultiple: {
			type: Boolean,
			required: false,
			default: false,
		},
		isClearable: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	emits: ['update:modelValue', 'singleChange'],
	computed: {
		isHasSlot () {
			return !!this.$slots.default;
		},
	},
	methods: {
		isActive (option) {
			if (!this.isMultiple) {
				return this.modelValue?.value === option?.value;
			}
			return this.modelValue.some(item => item?.value === option?.value);
		},
		handleChange (option) {
			let value = this.isMultiple ? [...(this.modelValue || [])] : (this.modelValue || null);

			if (!this.isMultiple) {
				if (option?.value !== value?.value) {
					value = option;
				} else {
					if (this.isClearable) {
						value = null;
					}
				}

				this.$emit('singleChange', value);
			} else {
				if (!value.find(item => item?.value === option?.value)) {
					value.push(option);
				} else {
					const foundIndex = value.findIndex(item => item?.value === option?.value);

					value.splice(foundIndex, 1);
				}
			}

			this.$emit('update:modelValue', value);
		},
	},
};
</script>

<style scoped lang='scss'>
  .select-item {
    white-space: nowrap;
    justify-content: space-between;

    .select-item-inner {
      display: flex;
      align-items: center;
    }

    .icon {
      display: flex;
    }

    .select-icon {
      margin-right: 10px;
    }

    .select-checked {
      margin-left: 10px;
      pointer-events: none;
    }
  }
</style>
