import { omit } from 'lodash';

import { genUniqueKey } from '@/utility';

export const hasDuplicates = (collection, key) => {
	const array = (collection || []).map(item => item[key]);

	return new Set(array).size !== array.length;
};

export const formatAddressListToArray = stringValue => {
	if (!stringValue) return [];

	return stringValue.split('\n').map((line, index) => ({
		id: genUniqueKey(),
		line,
		numberValue: index + 1,
	}));
};

export const formatAddressListToString = list => {
	if (!Array.isArray(list)) return '';

	return list.join('\n');
};

export const formatNftListToRequest = list => (list || []).map(item => {
	const data = {
		...item,
		description: item?.description || '',
	};

	if ((/new/.test(data?.id))) {
		return omit(data, 'id');
	}

	return data;
});
