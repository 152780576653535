export const COLLECTION_ANNOUNCEMENT_TABS = {
	ALL: 'all',
	AIRDROPS: 'airdrops',
};

export const COLLECTION_ANNOUNCEMENT_TYPES = {
	AD: 'ad',
	AIRDROP: 'airdrop',
};

export const COLLECTION_ANNOUNCEMENT_STATUSES = {
	DRAFT: 'draft',
	PUBLISHED: 'published',
	TRASH: 'trash',
};
