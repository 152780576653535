<script>
import { defineComponent } from 'vue';

import Row from '../Row.vue';

export default defineComponent({
	name: 'BodyRow',
	components: {
		Row,
	},
	render () {
		return <Row class="body__row">{this.$slots.default()}</Row>;
	},
});
</script>

<style lang="scss" scoped>
:not(:last-child).body__row {
  border-bottom: 1px solid $table-border;
}
</style>
