<template>
  <section ref="previewContainer">
    <div class="preview-title-wrapper">
      <h2 class="preview-title">
        Drop page preview:
      </h2>
    </div>
    <Banner
      :title="previewValues.title"
      :username="previewValues.creator"
      :date-value="previewValues.startDate"
      :starts-at="previewValues.startDate"
      :description="previewValues.description"
      :access="previewValues.access"
      :issue-type="previewValues.issue"
      :image-url="previewValues.imageUrl"
      :banner-url="previewValues.bannerUrl"
      :provider="mainState?.provider?.value"
      :max-allocation="previewValues.maxAllocation"
    />
    <List />
  </section>
</template>

<script>
import List from './component/List.vue';
import Banner from './component/Banner.vue';
import { getPreviewValues } from '../../helpers';

export default {
	components: {
		List,
		Banner,
	},
	inject: ['mainState', 'formState'],
	emits: ['onInit'],
	computed: {
		previewValues () {
			return getPreviewValues(this.formState?.formValues);
		},
		previewContainer () {
			return this.$refs?.previewContainer;
		},
	},
	mounted () {
		this.initPreview();
	},
	methods: {
		toPreview () {
			const previewContainer = this.previewContainer;

			if (previewContainer) {
				previewContainer.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
			}
		},
		initPreview () {
			const previewQuery = this.$route.query?.preview;

			if (previewQuery) {
				this.toPreview();

				this.$router.replace({
					path: this.$route.path,
					query: {
						...this.$route.query,
						preview: undefined,
					},
				});
			}

			this.$emit('onInit', this);
		},
	},
};
</script>

<style scoped lang='scss'>
  .preview-title-wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: 1600px;
    padding: 20px;
  }

  .preview-title {
    font-size: 26px;
    font-weight: 700;
  }
</style>
