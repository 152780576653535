import { markRaw } from 'vue';

import store from '@/store';

import EditLinkModal from '../EditLinkModal.vue';

async function openEditLinkModal (value, onSave) {
	await store.dispatch('modal/openModal', {
		content: markRaw(EditLinkModal),
		props: { value, onSave },
		type: 'text-editor-link',
	});
}

export default openEditLinkModal;
