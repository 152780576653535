<template>
  <Tooltip
    v-if="renderContentByData?.icon"
    :value="renderContentByData.text"
    :position="tooltipPosition"
  >
    <component
      :is="renderContentByData.icon"
      :class="classes"
    />
  </Tooltip>
</template>

<script>
'use strict';

import Tooltip from '@/components/ui/Tooltip.vue';
import { ImmutableXIcon, EthereumIcon } from '@/components/icons';

export default {
	components: {
		Tooltip,
	},
	props: {
		provider: {
			type: String,
			required: true,
		},
		isLight: {
			type: Boolean,
			required: false,
		},
		isDropDetails: {
			type: Boolean,
			required: false,
		},
		tooltipPosition: {
			type: String,
			required: false,
		},
	},
	computed: {
		classes () {
			let classes = 'icon ';

			if (this.isLight) classes += 'is-light ';
			if (!this.isDropDetails) classes += 'small ';
			if (this.provider) classes += this.provider;

			return classes;
		},
		getDataByProvider () {
			switch (this.provider) {
				case 'imx':
					return {
						icon: ImmutableXIcon,
						name: 'Immutable X',
					};
				case 'eth':
					return {
						icon: EthereumIcon,
						name: 'Ethereum',
					};
				default:
					return null;
			}
		},
		renderContentByData () {
			const data = this.getDataByProvider;

			return {
				icon: data?.icon,
				text: this.isDropDetails ? `This drop is on the ${data?.name} network.` : `Blockchain: ${data?.name}`,
			};
		},
	},
};
</script>

<style scoped lang='scss'>
.icon {
  width: 18px;
  height: 18px;
  color: rgba(0, 0, 0, .5);

  &.is-light {
    color: $white;
  }

  &.small {
    width: 14px;
    height: 14px;
  }
}
</style>
