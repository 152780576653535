<template>
  <transition-group name="modal-slide">
    <div
      v-for="(item, index) in openedModals"
      :key="index"
      class="wrapper"
    >
      <div
        class="overlay"
        @click.prevent="!item?.disableOverlayClick ? closeModal() : undefined"
      />

      <component
        v-bind="{modalProps: item?.props || {}}"
        :is="item.content"
        v-if="item?.content"
        class="modal"
      />
    </div>
  </transition-group>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import IconButton from '@/components/ui/IconButton';
import { CrossIcon } from '@/components/icons';

export default {
	components: {
		CrossIcon,
		IconButton,
	},
	computed: {
		...mapGetters('modal', ['openedModals']),
	},
	watch: {
		$route (to, from) {
			if ((to.fullPath !== from.fullPath) && this.openedModals.length !== 0) {
				this.closeModal?.();
			}
		},
	},
	methods: {
		...mapActions('modal', ['closeModal']),
	},
};
</script>

<style scoped lang='scss'>
  .wrapper {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
  }

  .modal {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 14px 40px 0 rgba(0, 0, 0, 0.25);
    z-index: 99;
    min-width: 300px;
    max-width: 95vw;
    min-height: 100px;
    max-height: 95vh;
  }

  .modal-slide-enter-active,
  .modal-slide-leave-active {
    transition: all .2s ease-in-out;

    .modal {
      transition: all .2s ease-in-out;
    }
  }

  .modal-slide-enter-from,
  .modal-slide-leave-to {
    opacity: 0;

    .modal {
      transform: translate3d(0, 60px, 0);
      transform-style: preserve-3d;
      opacity: 0;
    }
  }
</style>
