<template>
  <div class="image-drop__container">
    <div
      class="image-drop__dropzone"
      :class="classes"
      v-bind="getRootProps()"
    >
      <div class="image-drop__actions">
        <transition
          v-if="hasImage"
          name="fade"
          mode="out-in"
        >
          <Button
            v-if="hasClearCallback"
            size="32px"
            class="image-drop__close-action"
            :disabled="disabled"
            @click.stop="onClear"
          >
            <CrossIcon size="16px" />
          </Button>
        </transition>
      </div>
      <input
        :name="name"
        v-bind="getInputProps()"
        :disabled="isDisabled"
      >

      <div
        v-if="loading"
        class="image-drop__dropzone__loader"
      >
        <LoaderIcon />
      </div>

      <template v-else>
        <Media
          v-if="hasImage"
          class="image-drop__media"
          :src="imageUrl"
          fit="cover"
        />
        <div
          class="image-drop__dropzone__content"
          :class="{'has-image': hasImage, 'disabled': isDisabled}"
        >
          <div
            v-if="isDragActive"
            class="image-drop__dropzone__content__upload"
          >
            <ArrowDownIcon style="transform: rotate(180deg)" />
            <div>Upload</div>
          </div>
          <slot
            v-else
            name="default"
          />
        </div>
      </template>
    </div>

    <div
      v-if="Boolean(validation)"
      class="image-drop__footer"
      :class="classes"
    >
      <p class="image-drop__validation text-footnote">
        {{ validation }}
      </p>
    </div>
  </div>
</template>

<script>
import LoaderIcon from '@/components/icons/LoaderIcon.vue';
import ArrowDownIcon from '@/components/icons/ArrowDownIcon.vue';
import Button from '@/components/ui/Button.vue';
import CrossIcon from '@/components/icons/CrossIcon.vue';
import Media from '@/components/common/Media.vue';

import {
	useBaseDropzone,
	megabytesToBytes,
	bytesToMegabytes,
	getFormatsString,
	MAX_IMAGE_MB_SIZE,
} from './helpers';

export default {
	name: 'BaseDropzone',

	components: { Button, CrossIcon, LoaderIcon, Media, ArrowDownIcon },

	props: {
		name: {
			type: String,
			required: false,
			default: 'image-dropzone',
		},
		accept: {
			type: Array,
			required: false,
			default: () => [],
		},
		imageUrl: {
			type: String,
			required: false,
			default: null,
		},
		loading: {
			type: Boolean,
			required: false,
			default: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		minFileBytes: {
			type: Number,
			required: false,
			default: 0,
		},
		maxFileBytes: {
			type: Number,
			required: false,
			default: megabytesToBytes(MAX_IMAGE_MB_SIZE),
		},
		clear: {
			type: Function,
			required: false,
			default: null,
		},
	},

	emits: ['drop'],

	setup (props, { emit }) {
		return useBaseDropzone(props, emit);
	},

	computed: {
		isDisabled () {
			return this.disabled || this.loading;
		},
		hasError () {
			return !!this.error && !this.loading;
		},
		hasImage () {
			return !!this.imageUrl && !this.loading;
		},
		hasClearCallback () {
			return !!this.clear;
		},
		classes () {
			let classes = '';

			if (this.isDisabled) {
				classes += 'disabled ';
			}
			if (this.hasImage) {
				classes += 'with-image ';
			}
			if (this.hasError) {
				classes += 'error';
			}
			if (this.isDragActive) {
				classes += 'has-drag';
			}

			return classes;
		},
		formats () {
			return getFormatsString(this.accept);
		},
		fileSize () {
			return `Max file size ${bytesToMegabytes(this.maxFileBytes)}MB`;
		},
		validation () {
			if (this.hasError) {
				return this.error;
			}

			if (this.hasImage) {
				return '';
			}

			return null;
		},
	},

	methods: {
		onClear (event) {
			event.stopPropagation();
			this.clear?.();
		},
	},
};
</script>

<style scoped lang="scss">
.image-drop {
  &__container {
    position: relative;
    display: block;
    width: 100%;

    &:hover {
      .image-drop__close-action {
        opacity: 1;
      }
    }
  }

  &__dropzone {
    position: relative;
    width: 100%;
    height: 136px;
    background-color: $white;
    border: 1px dashed $shade30;
    border-radius: 8px;
    cursor: pointer;
    @include defaultTransition(all);

    &:hover {
      // background-color: $shade10;
      border-color: $violet;
    }

    &:active {
      // background-color: var(--color-shade60);
      border-color: $violet;
    }

    &.disabled {
      background-color: transparent;
      cursor: default;
    }

    &.with-image {
      background-color: transparent;
      border-color: transparent;
    }

    &.has-drag {
      background-color: var(--color-dark-bg2);
      border: 1px solid var(--color-accent2);
    }

    &.error {
      border-color: var(--color-shade60);

      &:hover {
        border-color: var(--color-shade40);
      }

      &:active {
        border-color: var(--color-base1);
      }
    }

    &__loader {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__image {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      z-index: -2;

      :deep(.image-container) {
        border-radius: inherit;
      }

      :deep(img) {
        border-radius: inherit;
      }
    }

    &__content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      @include defaultTransition(all);

      &.disabled {
        cursor: not-allowed;
      }

      &__upload {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & > :first-child {
          margin-bottom: 4px;
        }
      }
    }

    .has-image {
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
      opacity: 0;

      &:not(.disabled):hover {
        opacity: 1;
      }

      &:not(.disabled):active {
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
      }
    }
  }

  &__actions {
    position: absolute;
    top: -8px;
    right: -8px;
    z-index: 2;
  }

  &__media {
    border-radius: 8px;
    overflow: hidden;
  }

  &__close-action {
    width: 32px;
    height: 32px;
    @include flex();
    padding: 0;
    opacity: 0;
    color: $shade60;
    background-color: $shade10;
    @include defaultTransition(background-color, opacity);

    :deep(.global-base-icon-wrapper svg) {
      @include defaultTransition(all);
    }
    @include max-screen-lg() {
      opacity: 1;
    }

    &:disabled {
      background-color: $shade10;
      cursor: not-allowed;
    }

    &:not(:disabled):hover {
      background-color: $red;
      color: $white;
      :deep(.global-base-icon-wrapper svg) {
        color: $white;
      }
    }

    &:not(:disabled):active {
      background-color: #F04661;
      color: $white;
      :deep(.global-base-icon-wrapper svg) {
        color: $white;
      }
    }
  }

  &__footer {
    position: absolute;
    display: flex;
    flex-direction: column;
    margin-top: 4px;

    @include max-screen-sm () {
      width: max-content;
    }

    .image-drop__formats,
    .image-drop__description,
    .image-drop__validation {
      color: var(--color-shade40);
    }

    .image-drop__caption {
      color: var(--color-shade40);
    }

    &.error {
      .image-drop__validation {
        color: var(--color-accent3);
      }
    }
  }
}
</style>
