export default {
	setLoading (state, loading) {
		state.loading = loading;
	},
	setShowLength (state, payload) {
		state.showLength = payload;
	},
	setActiveTab (state, payload) {
		state.type = payload;
	},
	setOffset (state, payload) {
		state.offset = payload;
	},
	setSort (state, payload) {
		state.sort = payload;
	},
	setSearch (state, payload) {
		state.search = payload;
	},
	setStatus (state, payload) {
		state.status = payload;
	},
	setCheckbox (state, report) {
		state.selected[report.id] = !state.selected[report.id];
		if (!state.selected[report.id]) delete state.selected[report.id];
	},
	removeCheckbox (state, report) {
		if (state.selected[report.id]) delete state.selected[report.id];
	},
	setAllChecks (state) {
		Object.keys(state.selected)?.length !== state.list?.length
			? state.list.forEach(report => {
				state.selected[report.id] = true;
			}) : state.selected = {};
	},
	setChecks (state, payload) {
		state.selected = payload;
	},
	setAcceptLoading (state, loading) {
		state.acceptLoading = loading;
	},
};
