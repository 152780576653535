<template>
  <Actions />
  <Table
    :data="list"
    :columns="columns"
    class="nfts-slot"
    :loading="loading"
    :pagination="{
      limit: showLength,
      offset,
      total: totalCount,
    }"
    :with-checkbox="true"
    :checkbox-value="checkboxValue"
    :head-checkbox="headCheckboxValue"
    @offset:change="handleOffsetChange"
    @checked="handleCheck"
    @head-checked="handleHeadCheck"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import Status from '@/components/ui/Status';
import Table from '@/components/ui/table/Table';
import TextLink from '@/components/ui/TextLink';
import PreviewLink from '@/components/ui/PreviewLink';

import { getExactOption } from '@/utility/options';

import { Actions, TableActions } from '../components';
import { formatValuesForTable, reportOptions } from '../helpers';

export default {
	components: {
		Actions,
		Table,
	},
	data () {
		return {
			name: 'nfts',
		};
	},

	computed: {
		...mapState('reports', ['offset', 'showLength', 'totalCount', 'loading', 'list', 'selected']),
		...mapGetters('reports', ['checkboxValue', 'headCheckboxValue']),
		columns () {
			return [
				{ name: 'Date',
					value: (data) => formatValuesForTable.date(data?.createdAt),
				},
				{
					name: 'Name',
					value: (data) => <PreviewLink
						preview={data.previewUrl}
						label={data.objectName || data.objectId}
						withStringFormatting={!data.objectName}
					/>,
				},
				{ name: 'Type', value: (data) => getExactOption(reportOptions, data.type).label },
				{
					name: 'Reported by',
					value: (data) => <TextLink
						content={data?.reporterName || formatValuesForTable.accountId(data?.reporterId)}
						tag={'a'}
						src={`/users?search=${data?.reporterName || data?.reporterId}`}
					/>,
				},
				{
					name: 'Status',
					value: (data) => <Status type={data?.status === 'accepted' ? 'done' : 'pending'}>{data?.status}</Status>,
				},
				{
					name: 'Actions',
					value: (data) => <TableActions item={data} hideEditButton={true} />,
					width: '168px',
				},
			];
		},
	},

	methods: {
		...mapActions('reports', ['changeOffset']),
		...mapMutations('reports', ['setCheckbox', 'setAllChecks']),
		handleOffsetChange (value) {
			this.changeOffset(value);
		},
		handleCheck (report) {
			this.setCheckbox(report);
		},
		handleHeadCheck () {
			this.setAllChecks();
		},
	},
};
</script>

<style scoped lang="scss">
.nfts-slot {
  :deep(.nfts-slot__media) {
    width: 40px;
    height: 40px;
  }
}
</style>
