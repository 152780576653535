<template>
  <div
    class="quill-editor"
    :class="{ 'quill-editor__error': props.error }"
  >
    <QuillEditorToolbar
      ref="editorToolbar"
      :range="selectionRange"
      :format="selectionFormat"
      :mediahub-image-type="mediahubImageType"
      @format-change="handleFormatChange"
      @add-link-click="handleAddLinkClick"
      @unlink-click="handleUnlinkClick"
      @add-file-click="handleAddFileClick"
    />
    <PreviewLinkPopup ref="previewLinkPopupRef" />
    <div class="quill-editor__editor-wrapper">
      <div
        ref="editorContainer"
        @click="handleEditorContainerClick"
      />
      <div
        v-if="loading"
        class="quill-editor__loader"
      >
        <LoaderIcon size="36px" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineExpose, defineProps, onMounted, onBeforeUnmount, ref } from 'vue';
import { useStore } from 'vuex';
import { find, has } from 'lodash';

import { LoaderIcon } from '@/components/icons';

import QuillEditorToolbar from './components/QuillEditorToolbar.vue';
import PreviewLinkPopup from './components/PreviewLinkPopup.vue';
import openEditLinkModal from './components/helpers/openEditLinkModal';

import { mediaHubService } from '@/services/mediahub.service';

import './quill-editor.theme.css';

const props = defineProps({
	modelValue: String,
	placeholder: String,
	mediahubImageType: String,
});

const store = useStore();

const emit = defineEmits(['update:modelValue']);

const { sendFile } = mediaHubService;

const editorContainer = ref(null);
const editorToolbar = ref(null);
const previewLinkPopupRef = ref(null);
let QuillEditor;
let editor;

const selectionRange = ref({});
const selectionFormat = ref({});
const text = ref('');

const loading = ref(false);

async function importQuill () {
	const Quill = await import('quill');
	QuillEditor = Quill.default;
}

function setSelectionRange () {
	selectionRange.value = editor.selection.savedRange;
}

function setSelectionFormat () {
	selectionFormat.value = editor.getFormat(editor.selection.savedRange);
}

function updateModelValue () {
	emit('update:modelValue', editor.root.innerHTML);
}

function setSelection () {
	setSelectionRange();
	setSelectionFormat();
}

async function init () {
	if (!editorContainer.value) return;
	await importQuill();
	editor = new QuillEditor(editorContainer.value, {
		theme: 'snow',
		modules: {
			toolbar: false,
		},
		placeholder: props.placeholder,
	});

	editor.on('text-change', () => {
		setSelection();
		text.value = editor.root.innerHTML;
		updateModelValue();
	});

	editor.on('selection-change', async () => {
		setSelection();
		text.value = editor.root.innerHTML;

		if (previewLinkPopupRef.value.getVisible()) await previewLinkPopupRef.value.hide();

		if (
			!previewLinkPopupRef.value.getVisible()
			&& !find(store.state.modal.openedModals, { type: 'text-editor-link' })
			&& !editor.hasFocus()
		) trim();
	});

	if (props.modelValue) editor.root.innerHTML = props.modelValue;

	updateModelValue();
}

function editLink (range) {
	return ({ text, link }) => {
		editor.deleteText(range.index, range.length);
		editor.insertText(range.index, text, { link });
	};
}

function handleFormatChange (key, value) {
	editor.format(key, value);
	setSelectionFormat();
	text.value = editor.root.innerHTML;
	updateModelValue();
}

function getLinkRange (leaf, offset) {
	return {
		index: selectionRange.value.index - offset,
		length: selectionRange.value.index - offset + leaf.text.length,
	};
}

function handleEditorContainerClick (event) {
	if (selectionFormat.value.link) {
		const [leaf, offset] = editor.getLeaf(selectionRange?.value?.index);
		const linkRange = getLinkRange(leaf, offset);
		previewLinkPopupRef.value.show(
			event,
			{ text: leaf.text, link: selectionFormat.value.link },
			() => openEditLinkModal({ text: leaf.text, link: selectionFormat.value.link }, editLink(linkRange)),
			() => editor.formatText(linkRange.index, linkRange.length, { link: false }),
		);
	}
}

function handleAddLinkClick () {
	openEditLinkModal(
		{
			text: has(selectionRange.value, 'index') && selectionRange.value?.length
				? editor.getText(selectionRange.value.index, selectionRange.value.length).slice(0, selectionRange.value.length)
				: '',
		},
		editLink(has(selectionRange.value, 'index') && has(selectionRange.value, 'length')
			? selectionRange.value
			: { index: 0, length: 0 }),
	);
}

function handleUnlinkClick () {
	const [leaf, offset] = editor.getLeaf(selectionRange?.value?.index);
	const linkRange = getLinkRange(leaf, offset);
	editor.formatText(linkRange.index, linkRange.length, { link: false });
}

async function handleAddFileClick (event, button) {
	loading.value = true;

	try {
		editor.focus();

		const input = document.querySelector(`.quill-editor__toolbar-button.file input[name=${button.key}]`);

		const url = await sendFile(input.files[0], 'announcement/media');

		editor.insertEmbed(selectionRange.value.index, button.key, url);
		updateModelValue();
	} finally {
		loading.value = false;
	}
}

function getText () {
	return editor.getText();
}

function getContents () {
	return editor.getContents();
}

function setModelValue (value) {
	editor.root.innerHTML = value;
}

function trim () {
	editor.setContents(editor.getContents().ops.map((item) => ({ ...item, insert: item.insert.split('\n').map(str => str.trim()).join('\n') })));
}

onMounted(() => {
	init();
});

onBeforeUnmount(() => {
	editor = null;
});

defineExpose({ getContents, getText, setModelValue });
</script>

<style lang="scss">
.quill-editor {
	min-height: 100%;
	position: relative;
	border: 1px solid rgb(113 18 255 / 20%);
	overflow: hidden;

	&__editor-wrapper {
		width: 100%;
		height: calc(100% - 40px);
	}

	.ql-container {
		min-height: 100%;
		box-sizing: border-box;
	}

	.ql-editor {
    background-color: $white;
		min-height: 100%;
  }

  .ql-tooltip {
    display: none;
  }

	&__loader {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		@include flex();
		background-color: rgba(255, 2555, 2555, 0.8);
	}
}
</style>
