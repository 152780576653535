<template>
  <svg
    fill="none"
    height="14"
    viewBox="0 0 16 14"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 1.5C15.4 1.8 14.7 2 14.1 2.1 14.8 1.7 15.3 1 15.5 0.2 14.9 0.6 14.2 0.9 13.4 1 12.8 0.4 12 0 11 0 9.2 0 7.8 1.5 7.8 3.3 7.8 3.5 7.8 3.8 7.9 4 5.1 3.9 2.7 2.6 1.1 0.6 0.8 1.1 0.6 1.7 0.6 2.3 0.6 3.4 1.2 4.4 2.1 5 1.6 5 1.1 4.8 0.6 4.6V4.6C0.6 6.2 1.8 7.5 3.3 7.8 3 7.9 2.7 8 2.4 8 2.2 8 2 7.9 1.8 7.9 2.2 9.2 3.4 10.1 4.8 10.2 3.7 11.1 2.3 11.6 0.8 11.6 0.5 11.6 0.3 11.6 0 11.5 1.5 12.5 3.2 13 5 13 11.1 13 14.4 8 14.4 3.7 14.4 3.5 14.4 3.4 14.4 3.3 15 2.8 15.6 2.2 16 1.6L16 1.5Z"
      fill="currentColor"
    />
  </svg>
</template>
