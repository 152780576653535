<template>
  <ModalContentContainer class="content">
    <ModalHeader class="content__header">
      <ModalTitle
        class="header__title"
        value="Add a token"
      />
    </ModalHeader>
    <ModalBody class="content__body">
      <FormTextField
        placeholder="Enter a token address"
        name="address"
      />
      <div
        v-if="unavailable"
        class="body__error"
      >
        <InfoIcon class="error__icon" />
        <p>{{ errorMessage }}</p>
      </div>
    </ModalBody>
    <ModalFooter class="content__footer">
      <Button
        class="footer__button"
        size="flex"
        @click="onSubmit"
      >
        <SmallLoadingSpinnerIcon v-if="btnLoading" />
        <CheckIcon
          v-if="checkedMark"
          class="button_with-check"
        />
        Add
      </Button>
    </ModalFooter>
  </ModalContentContainer>
</template>

<script>
import { provide, ref } from 'vue';
import { useStore } from 'vuex';
import { useForm } from 'vee-validate';
import {
	ModalContentContainer,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalTitle } from '@/components/common/modal/index';
import Button from '@/components/ui/Button';
import {
	FormTextField,
	ethereumAddress,
	object,
} from '@/components/common/form';
import { SmallLoadingSpinnerIcon, CheckIcon, InfoIcon } from '@/components/icons';
import { addDefault } from '@/components/utility/notifications';

export default {
	components: {
		ModalContentContainer,
		ModalHeader,
		ModalBody,
		ModalFooter,
		ModalTitle,
		Button,
		FormTextField,
		SmallLoadingSpinnerIcon,
		CheckIcon,
		InfoIcon,
	},

	setup (props, { attrs }) {
		const propsModal = attrs.modalProps;
		const store = useStore();
		const btnLoading = ref(false);
		const checkedMark = ref(false);
		const unavailable = ref(false);
		const errorMessage = ref('');

		const validationSchema = object({
			address: ethereumAddress('Please enter the correct address'),
		});

		const { values, handleSubmit, setFieldValue } = useForm({
			initialValues: { address: '' },
			validationSchema,
		});

		const update = async payload => {
			await propsModal(payload);
		};

		const submitFormValues = async (data, values) => {
			if (!data && !values) return;

			const { update } = data;
			const dataset = {
				address: values.address,
			};
			await update(dataset);
		};

		const onSubmit = handleSubmit(async values => {
			if (btnLoading.value === false) {
				btnLoading.value = true;
				try {
					await submitFormValues({ update }, values);
					checkedMark.value = true;
					setTimeout(() => {
						checkedMark.value = false;
						store.dispatch('modal/closeModal');
						addDefault('Added new token');
					}, 1000);
				} catch (e) {
					errorMessage.value = e?.response?.data?.message || e.message;
					unavailable.value = true;
				} finally {
					btnLoading.value = false;
				}
			}
		});

		provide('formState', { setFieldValue, values });

		return {
			onSubmit,
			btnLoading,
			checkedMark,
			unavailable,
			errorMessage,
		};
	},
};
</script>

<style scoped lang="scss">
  .content {
    width: 410px;

    &__body {

      .body {

        &__error {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 10px;
          font-size: 12px;
          line-height: 16px;
          color: $red;

          :deep(path) {
            stroke: $red;
          }

          .error {
            &__icon {
              margin-right: 12px;
            }
          }
        }
      }

    }

    &__footer {
      display: flex;
      justify-content: center;

      .footer {
        &__button {
          .button {
            &_with-check {
              margin-right: 9px;
            }
          }
        }
      }
    }
  }
</style>
