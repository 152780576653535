<template>
  <List
    :list="list"
    :is-loading="isLoading"
  />
</template>

<script>
import List from '../components/list/index.vue';
import useDropsList from '../components/list/useDropsList';

export default {
	components: { List },
	setup () {
		const { isLoading, list } = useDropsList('live');

		return { isLoading, list };
	},
};
</script>
