<template>
  <label
    class="checkbox-wrapper"
    :class="{'is-secondary': isSecondary, 'is-large': isLarge, disabled}"
  >
    <input
      type="checkbox"
      :disabled="disabled"
      v-bind="$attrs"
    >
    <div class="checkbox-box">
      <CheckMarkIcon
        v-if="!isSecondary"
        class="checkbox-mark"
      />
      <CheckMarkSecondaryIcon
        v-else
        class="checkbox-mark"
      />
    </div>
    <div
      v-if="hasContent"
      class="content"
    ><slot /></div>
  </label>
</template>

<script>
import { CheckMarkIcon, CheckMarkSecondaryIcon } from '@/components/icons';

export default {
	components: {
		CheckMarkIcon,
		CheckMarkSecondaryIcon,
	},
	props: {
		isSecondary: {
			type: Boolean,
			required: false,
		},
		isLarge: {
			type: Boolean,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		hasContent () {
			return !!this.$slots.default;
		},
	},
};
</script>

<style scoped lang='scss'>
  .checkbox-wrapper {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &.disabled {
      opacity: .5;
      cursor: default;

      .checkbox-box {
        cursor: default;
      }
    }

    input {
      opacity: 0;
      position: absolute;
      z-index: -1;

      &[type=checkbox]:checked + .checkbox-box {
        background-color: $violet;

        .checkbox-mark {
          opacity: 1;
        }
      }

      &[type=checkbox]:focus + .checkbox-box {
        border-color: transparent;
        box-shadow: 0 0 3px 1px rgba(113, 18, 255, .2);
      }
    }

    .checkbox-box {
      width: 20px;
      height: 20px;
      cursor: pointer;
      transition: border-color 200ms cubic-bezier(.215, .61, .355, 1);
      border: 1px solid rgba(113, 18, 255, .2);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      .checkbox-mark {
        opacity: 0;
        color: $white;
      }

      &:hover {
        border-color: rgba(113, 18, 255, .3);
      }
    }

    &.is-secondary {
      input {
        &[type=checkbox]:checked + .checkbox-box {
          border-color: $violet;
          background-color: transparent;
        }

        &[type=checkbox]:focus + .checkbox-box {
          border-color: $violet;
        }
      }

      .checkbox-box {
        padding: 3px;

        .checkbox-mark {
          color: $violet;
        }

        &:hover {
          border-color: rgba(113, 18, 255, .5);
          background-color: rgba(113, 18, 255, .1);
        }
      }
    }

    .content {
      margin-left: 10px;
    }

    &.is-large {
      .checkbox-box {
        width: 30px;
        height: 30px;
        border-radius: 5px;
      }
    }
  }
</style>
