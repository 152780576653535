<template>
  <HelperText class="error-message">
    <slot />
  </HelperText>
</template>

<script>
import HelperText from '@/components/ui/HelperText';

export default {
	components: { HelperText },
};
</script>

<style scoped lang='scss'>
  .error-message {
    font-size: 12px;
    margin-top: 5px;
    color: $red;
    padding: 0 12px;
    opacity: unset;
  }
</style>
