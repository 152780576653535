<template>
  <section class="actions-wrapper">
    <div class="actions-inner">
      <template v-if="isDraft || mainState.isNew.value">
        <Button
          :disabled="isSubmitLoading"
          size="large"
          variant="outlined"
          @click="$emit('action-click', 'draft')"
        >
          {{ mainState.isNew.value ? 'Save as Draft' : 'Save' }}
        </Button>
        <Button
          :disabled="isSubmitLoading"
          size="large"
          @click="$emit('action-click', 'deploy')"
        >
          🚀 Deploy Drop
        </Button>
      </template>
    </div>
    <LoadingSpinnerIcon
      v-if="isSubmitLoading"
      class="loading-spinner"
    />
  </section>
</template>

<script>
import Button from '@/components/ui/Button';
import LoadingSpinnerIcon from '@/components/icons/LoadingSpinnerIcon';

export default {
	components: {
		Button,
		LoadingSpinnerIcon,
	},
	inject: ['mainState'],
	emits: ['action-click'],
	computed: {
		isSubmitLoading () {
			return this.mainState?.isSubmitLoading.value;
		},
		status () {
			return this.mainState?.status.value;
		},
		isDraft () {
			return this.status === 'draft';
		},
	},
};
</script>

<style scoped lang='scss'>
  .actions-wrapper {
    display: flex;
    align-items: center;

    .actions-inner {
      display: grid;
      align-items: center;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      flex: 1;
    }

    .loading-spinner {
      width: 60px;
      height: 60px;
      margin-left: 10px;
    }
  }
</style>
