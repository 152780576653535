<template>
  <Teleport
    to="body"
    :disabled="!visible"
  >
    <div
      v-if="visible"
      ref="popupRef"
      v-click-outside="{ handler: onClickOutside }"
      class="quill-editor__preview-link-popup"
      :class="popupClass"
      :style="popupStyle"
    >
      <IconButton
        class="quill-editor__preview-link-popup_close-button"
        @click.stop="handleCloseClick"
      >
        <CrossIcon />
      </IconButton>
      <div class="quill-editor__preview-link-popup_text">
        <TextOverflow>
          {{ localValue?.text }}
        </TextOverflow>
      </div>
      <div class="quill-editor__preview-link-popup_link">
        <TextOverflow>
          <TextLink
            color="violet"
            :src="localValue?.link"
            is-regular
          >
            {{ localValue?.link }}
          </TextLink>
        </TextOverflow>
      </div>
      <div class="quill-editor__preview-link-popup_buttons">
        <Button
          class="quill-editor__preview-link-popup_buttons-button"
          is-secondary
          @click.stop="handleEditClick"
        >
          Edit
        </Button>
        <Button
          class="quill-editor__preview-link-popup_buttons-button"
          is-secondary
          @click.stop="handleRemoveClick"
        >
          Remove
        </Button>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { defineExpose, ref, nextTick } from 'vue';

import Button from '@/components/ui/Button.vue';
import TextOverflow from '@/components/ui/TextOverflow.vue';
import TextLink from '@/components/ui/TextLink.vue';
import IconButton from '@/components/ui/IconButton.vue';
import { CrossIcon } from '@/components/icons';

import { vClickOutside } from '@/utility/directives';

const localValue = ref(null);
const onEdit = ref(null);
const onRemove = ref(null);
const toggle = ref(null);

const visible = ref(false);
const clickOutsideEnabled = ref(false);

const windowSize = ref({});
const toggleClientRect = ref({});
const popupClientRect = ref({});

const popupRef = ref(null);

const popupStyle = ref({});
const popupClass = ref({});

const TOGGLE_MARGIN = 9;
const SCREEN_MARGIN = 3;

function show (event, value, onEditFn, onRemoveFn) {
	if (event.target.tagName === 'A') {
		clickOutsideEnabled.value = false;

		localValue.value = value;
		onEdit.value = onEditFn;
		onRemove.value = onRemoveFn;
		toggle.value = event.srcElement;

		window.addEventListener('resize', handleResize);
		window.addEventListener('scroll', handleResize);

		visible.value = true;

		handleResize();

		setTimeout(() => { clickOutsideEnabled.value = true; }, 0);
	}
}

function clearData () {
	localValue.value = null;
	onEdit.value = null;
	onRemove.value = null;
	toggle.value = null;
}

function hide () {
	clearData();
	window.removeEventListener('resize', handleResize);
	window.removeEventListener('scroll', handleResize);
	clickOutsideEnabled.value = false;
	visible.value = false;
}

function onClickOutside () {
	if (clickOutsideEnabled.value) hide();
}

function getVisible () {
	return visible.value;
}

function setWindowSize () {
	windowSize.value = { height: window.innerHeight, width: window.innerWidth };
}

function setToggleClientRect () {
	toggleClientRect.value = toggle.value.getBoundingClientRect();
}

function setPopupClientRect () {
	popupClientRect.value = popupRef.value.getBoundingClientRect();
}

function setPopupPosition () {
	const bottomPlaced = windowSize.value.height - (toggleClientRect.value.bottom + TOGGLE_MARGIN + SCREEN_MARGIN) >= popupClientRect.value.height;
	const topPlaced = (toggleClientRect.value.top + TOGGLE_MARGIN + SCREEN_MARGIN) >= popupClientRect.value.height;

	const leftPlaced = (toggleClientRect.value.left + (toggleClientRect.value.width - popupClientRect.value.width) / 2) > SCREEN_MARGIN;

	popupStyle.value = {
		top: (bottomPlaced && `${toggleClientRect.value.bottom + window.scrollY + TOGGLE_MARGIN}px`)
			|| (topPlaced && `${toggleClientRect.value.top + window.scrollY - popupClientRect.value.height - TOGGLE_MARGIN}px`)
			|| `${TOGGLE_MARGIN}px`,
		left: leftPlaced
			? `${toggleClientRect.value.left + (toggleClientRect.value.width - popupClientRect.value.width) / 2}px`
			: `${SCREEN_MARGIN}px`,
	};
	popupClass.value = { 'quill-editor__preview-link-popup-flipped': !bottomPlaced && topPlaced };
}

async function handleResize () {
	setWindowSize();
	setToggleClientRect();
	await nextTick();
	setPopupClientRect();
	setPopupPosition();
}

function handleEditClick () {
	if (onEdit.value) onEdit.value();
	hide();
}

function handleRemoveClick () {
	if (onRemove.value) onRemove.value();
	hide();
}

function handleCloseClick () {
	hide();
}

defineExpose({ show, hide, getVisible });
</script>

<style lang="scss">
.quill-editor__preview-link-popup {
  position: absolute;
  width: 312px;
  background-color: $shade10;
  border-radius: 4px;
  z-index: 1000;
  padding: 8px 48px 8px 8px;
  row-gap: 4px;

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $shade10;
    border-top: unset;
    bottom: unset;
    top: -10px;
    left: calc(50% - 10px);
  }

  &-flipped {
    &::after {
      border-bottom: unset;
      border-top: 10px solid var(--color-shade60);
      top: unset;
      bottom: -10px;
    }
  }

  &_close-button {
    position: absolute;
    top: 8px;
    right: 0;
    width: 48px;
    height: 48px;
    flex: 0 0 48px;
  }

  &_text {
    padding: 16px 0;
  }

  &_link {
    color: var(--color-accent1);
  }

  &_buttons {
    @include flex();
    column-gap: 16px;
    margin-top: 8px;

    &-button {
      width: 120px;
    }
  }
}
</style>
