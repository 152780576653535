<template>
  <svg
    width="20pt"
    height="20pt"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0756 0.5L19.8119 1.39313V27.3097L20.0756 27.572L32.1514 20.461L20.0756 0.5Z"
      fill="#343434"
    />
    <path
      d="M20.0757 0.5L8 20.461L20.0757 27.5721V14.993V0.5Z"
      fill="#8C8C8C"
    />
    <path
      d="M20.0755 29.8498L19.9269 30.0303V39.2623L20.0755 39.6949L32.1585 22.7423L20.0755 29.8498Z"
      fill="#3C3C3B"
    />
    <path
      d="M20.0757 39.6948V29.8498L8 22.7423L20.0757 39.6948Z"
      fill="#8C8C8C"
    />
    <path
      d="M20.0755 27.5719L32.1511 20.461L20.0755 14.9929V27.5719Z"
      fill="#141414"
    />
    <path
      d="M8 20.461L20.0756 27.5719V14.9929L8 20.461Z"
      fill="#393939"
    />
  </svg>
</template>
