<template>
  <ConfirmationModal
    class="edit-announcement-confirmation-modal"
    title="Save announcement as draft?"
    accept-text="SAVE DRAFT"
    cancel-text="Continue editing"
    @on-accept="onConfirm"
  >
    <template #default>
      <div class="edit-announcement-confirmation-modal__body">
        Not all required fields are filled. Do you want to save announcement as draft?
      </div>
    </template>
  </ConfirmationModal>
</template>

<script setup>
import { computed, useAttrs } from 'vue';

import ConfirmationModal from '@/components/common/modal/ConfirmationModal.vue';

const attrs = useAttrs();

const onConfirm = computed(() => attrs.modalProps?.onConfirm);
</script>

<style lang="scss">
.edit-announcement-confirmation-modal {
  width: 300px;

  &__body {
    padding: 16px;
    text-align: center;
  }
}
</style>
