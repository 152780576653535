<template>
  <ConfirmationModal
    class="delete-modal"
    :title="forModal.title"
    :accept-text="forModal.acceptBtnText"
    :cancel-text="forModal.declineBtnText"
    :accept-button-props="{ color: 'black' }"
    :cancel-button-props="{ color: 'black' }"
    :disable-btns="btnOnLoading"
    @on-accept="deleteToken"
  >
    <div class="delete-modal_without-content" />
  </ConfirmationModal>
</template>

<script>
import { ref, defineComponent } from 'vue';
import { ConfirmationModal } from '@/components/common/modal/index';
import { confirmModalContent } from '@/components/common/modal/data/confirmModalContent';
import { useStore } from 'vuex';

export default defineComponent({
	name: 'DeleteModal',
	components: { ConfirmationModal },
	setup (props, { attrs }) {
		const store = useStore();
		const btnOnLoading = ref(false);
		const propsModal = attrs.modalProps;
		const forModal = confirmModalContent.token;

		const deleteToken = async () => {
			btnOnLoading.value = true;
			propsModal();
			store.dispatch('modal/closeModal');
			btnOnLoading.value = false;
		};

		return {
			forModal,
			deleteToken,
			btnOnLoading,
		};
	},
});
</script>

<style scoped lang="scss">
  .delete-modal {
    width: 300px;

    &_without-content {
      height: 20px;
    }
  }
</style>
