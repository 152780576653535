<template>
  <Content :style="$attrs.modalProps.modalStyles">
    <div class="confirmation-header">
      <Title :value="$attrs.modalProps.title || title" />
    </div>
    <slot />
    <div
      v-if="$attrs.modalProps.contentText"
      class="confirmation-content"
    >
      {{ $attrs.modalProps.contentText }}
    </div>
    <Footer class="confirmation-footer">
      <AcceptCancelButtons
        :accept-text="$attrs.modalProps.acceptText || acceptText"
        :accept-button-props="$attrs.modalProps.acceptButtonProps || acceptButtonProps"
        :cancel-text="$attrs.modalProps.cancelText || cancelText"
        :cancel-button-props="$attrs.modalProps.cancelButtonProps || cancelButtonProps"
        :disabled="$attrs.modalProps.disableBtns || disableBtns"
        @on-accept="handleAccept"
        @on-cancel="handleCancel"
      />
    </Footer>
  </Content>
</template>

<script>
import { mapActions } from 'vuex';

import AcceptCancelButtons from '@/components/ui/AcceptCancelButtons';

import Title from './Title.vue';
import Footer from './Footer.vue';
import Content from './Content.vue';

export default {
	components: {
		Title,
		Footer,
		Content,
		AcceptCancelButtons,
	},
	props: {
		title: {
			type: String,
			required: true,
			default: '',
		},
		acceptText: {
			type: String,
			required: false,
			default: 'Accept',
		},
		cancelText: {
			type: String,
			required: false,
			default: 'Cancel',
		},
		disableBtns: {
			type: Boolean,
			required: false,
			default: false,
		},
		acceptButtonProps: {
			type: Object,
			required: false,
			default: () => {},
		},
		cancelButtonProps: {
			type: Object,
			required: false,
			default: () => {},
		},
	},
	emits: ['onCancel', 'onAccept'],
	methods: {
		...mapActions('modal', ['closeModal']),
		handleCancel () {
			this.closeModal?.();

			this.$emit('onCancel');
		},
		handleAccept () {
			this.$attrs.modalProps.acceptButtonProps?.action?.();
			this.$emit('onAccept', this.closeModal);
		},
	},
};
</script>

<style scoped lang='scss'>
  .confirmation-header {
    padding: 20px 20px 0 20px;
    text-align: center;
  }

  .confirmation-content {
    max-width: 360px;
    padding: 12px 24px 24px;
    text-align: center;
  }

  .confirmation-footer {
    width: 100%;
    border: none;
    padding-top: 0;
  }
</style>
