<template>
  <div class="announcements-tab-component">
    <div
      v-if="!isNotData"
      class="announcements-tab-component__wrapper"
    >
      <!-- place for search -->
      <Button
        v-if="!isMobile"
        :disabled="isDisabled"
        color="shade"
        @click="handleClick"
      >
        {{ trashButtonLabel }}
      </Button>
    </div>
    <AnnouncementsTable
      v-if="!isMobile && !isNotData"
      :columns="currentColumns"
      :cursor="queryParams.cursor"
      :data="currentList"
      :infinite-loading="isInfiniteLoading"
      :loading="isLoadingList"
      :row-click="handleRowClick"
      class="announcements-tab-component__table"
      row-hover
      @infinite="onInfinite"
    />
    <div
      v-if="isMobile && !isDisabled"
      class="announcements-tab-component__mobile-sticky"
    >
      <Button
        class="announcements-tab-component__remove-mobile"
        color="shade"
        @click="handleClick"
      >
        {{ trashButtonLabel }}
      </Button>
    </div>
    <div
      v-if="isNotData"
      class="announcements-tab-component__nothing-found"
    >
      <NoEventsIcon
        class="announcements-tab-component__nothing-found__icon"
        size="64px"
      />
      <p class="announcements-tab-component__nothing-found__text">
        No announcements yet
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, reactive, watch, defineProps, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import Button from '@/components/ui/Button';
import AnnouncementsTable
	from '@/components/pages/announcements_and_airdrops/announcement-table/AnnouncementsTable';

import columns from '@/pages/collections/announcements/components/helpers/columns';
import { announcementsService } from '@/services';
import { NoEventsIcon } from '@/components/icons';
import { useDeviceDetector } from '@/composables/useDeviceDetector.js';
import { COLLECTION_ANNOUNCEMENT_STATUSES, COLLECTION_ANNOUNCEMENT_TYPES } from '@/pages/collections/announcements/data';
import { useAnnouncementRemoveModal } from '@/components/common/modal/announcement-airdrop/actions/openModal';

const props = defineProps({
	status: String,
	identifier: String,
});

const emit = defineEmits(['trash-action', 'create-action', 'start-loading', 'stop-loading']);

const store = useStore();
const router = useRouter();

const { openAnnouncementModal } = useAnnouncementRemoveModal();

const { isMobile, isTablet } = useDeviceDetector();
const isDisabled = computed(() => !store.state?.announcements?.listToTrash?.length);
const currentList = computed(() => store.state?.announcements?.currentList);
const listToTrash = computed(() => store.state?.announcements?.listToTrash);
const isNotData = computed(() => !currentList.value?.length);
const queryParams = reactive({
	type: COLLECTION_ANNOUNCEMENT_TYPES.AD,
	status: null,
	limit: 20,
	cursor: null,
	showScheduled: true,
});

const updateStatus = (status) => {
	queryParams.status = status;
};

const updateCursor = (cursor) => {
	queryParams.cursor = cursor;
};

const isLoadingList = ref(false);
const isInfiniteLoading = ref(false);

const trashButtonLabel = computed(() => props.status === COLLECTION_ANNOUNCEMENT_STATUSES.TRASH ? 'Remove' : 'Move to Trash');

const handleRowClick = (e) => {
	router.push({ name: 'CollectionAnnouncementEdit', params: { ts: e.sortTimestamp } });
};

const fetchAnnouncements = async () => {
	const { status, ...params } = queryParams;

	return announcementsService.getAnnouncements(
		'collection',
		props.identifier,
		{ ...params, ...(status ? { status } : {}) },
	);
};

const updateList = async () => {
	isLoadingList.value = true;

	try {
		const data = await fetchAnnouncements();

		store.commit('announcements/setCurrentList', data?.announcements);
		updateCursor(data.cursor);
	} catch (e) {
		throw new Error(e);
	} finally {
		isLoadingList.value = false;
		emit('stop-loading');
	}
};

const currentColumns = computed(() => columns(isTablet.value, currentList.value, props.identifier));

const handleClickTrash = async () => {
	try {
		await announcementsService.deleteAnnouncements(
			'collection',
			props.identifier,
			{
				airdrops: [],
				ads: listToTrash.value,
				permanent: false,
			},
		);

		await store.dispatch('alert/processing', { message: 'Announcements moved to Trash', duration: 5000 });

		store.commit('announcements/removeAllItemToListTrash');
		emit('trash-action');
	} catch (e) {
		throw new Error(e);
	}

	await updateList();
};

const handleClickRemove = () => {
	openAnnouncementModal({
		title: 'Remove Items',
		onSubmit: async () => {
			try {
				await announcementsService.deleteAnnouncements(
					'collection',
					props.identifier,
					{
						airdrops: [],
						ads: listToTrash.value,
						permanent: true,
					},
				);

				store.commit('announcements/removeAllItemToListTrash');
				await store.dispatch('modal/closeModal');
				emit('trash-action');
			} catch (e) {
				throw new Error(e);
			}

			await updateList();
		},
		body: 'Are you sure you want to remove the selected items? The Announcements will be deleted permanently.',
	});
};

const handleClick = () => {
	updateCursor(null);
	return props.status === COLLECTION_ANNOUNCEMENT_STATUSES.TRASH ? handleClickRemove() : handleClickTrash();
};

const onInfinite = async () => {
	if (!queryParams.cursor) return;

	isInfiniteLoading.value = true;

	try {
		const data = await fetchAnnouncements();

		store.commit('announcements/setCurrentList', [...currentList.value, ...data?.announcements]);
		updateCursor(data.cursor);
	} catch (e) {
		throw new Error(e);
	} finally {
		isInfiniteLoading.value = false;
		emit('stop-loading');
	}
};

watch(() => props.status, async (current, prev) => {
	if (current && prev && current !== prev) {
		updateStatus(current !== 'all' ? current : null);
		updateCursor(null);
		await updateList();
	}
});

watch(() => props.identifier, async (current, prev) => {
	if (prev !== current) {
		await updateList();
	}
});

onMounted(async () => {
	updateStatus(props.status !== 'all' ? props.status : null);
	store.state.editCollection?.collection?.contractAddress ? await updateList() : emit('stop-loading');
});
</script>

<style lang="scss" scoped>
.announcements-tab-component {
  &__wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
  }

	&__mobile-sticky {
		width: 100%;
		position: fixed;
		bottom: 0;
		border: 1px solid var(--color-shade60);
		background: var(--color-shade80);
		padding: 16px;
		z-index: 1000;
	}

	&__remove-mobile {
		width: 100%;
		background: var(--color-shade80);
	}

	&__nothing-found {
		@include flex(column, center, center);
		margin: 197px auto;
	}
}

</style>
