<template>
  <ModalHeader>
    <ModalTitle :value="title" />
  </ModalHeader>
  <ModalBody>
    <BodyContentContainer>
      <Fields />
      <Metadata />
    </BodyContentContainer>
  </ModalBody>
  <ModalFooter>
    <AcceptCancelButtons
      :disabled="notEditable"
      :accept-text="acceptText"
      @on-cancel="onClose"
      @on-accept="onSubmit"
    />
  </ModalFooter>
</template>

<script>
import { provide, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useForm } from 'vee-validate';

import AcceptCancelButtons from '@/components/ui/AcceptCancelButtons';
import { ModalHeader, ModalBody, ModalFooter, ModalTitle } from '@/components/common/modal';

import Fields from './Fields.vue';
import Metadata from '../components/metadata/index.vue';
import BodyContentContainer from '../components/BodyContentContainer.vue';
import { getInitialValues, validationSchema, parseValuesForList } from './helpers/form';

export default {
	components: {
		Fields,
		Metadata,
		ModalBody,
		ModalTitle,
		ModalHeader,
		ModalFooter,
		AcceptCancelButtons,
		BodyContentContainer,
	},
	props: {
		modalProps: {
			type: Object,
			required: true,
		},
	},
	setup (props) {
		const { dispatch } = useStore();
		const isMetaDataOpen = ref(false);

		const details = props?.modalProps?.details || null;
		const parentState = props?.modalProps?.state || null;
		const mainState = props?.modalProps?.mainState || null;

		const { handleSubmit, values: formValues } = useForm({
			validationSchema,
			initialValues: getInitialValues(details),
		});

		const onClose = () => dispatch('modal/closeModal');

		const setMetadataOpen = isOpen => {
			isMetaDataOpen.value = isOpen;
		};

		watch(() => formValues.edition, value => {
			if (value === 'open') {
				formValues.amountForSale = value ? 9999 : formValues.amountForSale;
				formValues.maxQuantity = value ? 9999 : formValues.maxQuantity;
			}
		});

		const onSubmit = handleSubmit(values => {
			if (!isMetaDataOpen.value) {
				const data = parseValuesForList(values);

				if (details) {
					const foundIndex = (parentState?.formValues?.nfts || []).findIndex(item => item?.id === details?.id);

					if (foundIndex !== -1) {
						parentState.formValues.nfts[foundIndex] = data;
					}
				} else {
					if (parentState.formValues?.nfts) {
						parentState.formValues?.nfts.push(data);
					} else {
						parentState?.setFieldValue?.('nfts', [data]);
					}
				}

				onClose();
			}
		});

		provide('state', {
			formValues,
			isMetaDataOpen,
			setMetadataOpen,
			notEditable: mainState.notEditable,
		});

		return {
			onClose,
			onSubmit,
			notEditable: mainState.notEditable,
		};
	},
	computed: {
		details () {
			return this.modalProps?.details;
		},
		detailsState () {
			return this.modalProps?.state;
		},
		title () {
			return `${this.details ? 'Edit' : 'Add'} NFT`;
		},
		acceptText () {
			return `${this.details ? 'Update' : 'Add'} NFT`;
		},
	},
};
</script>
