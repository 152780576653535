<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.91447 3.78947C3.91447 5.87874 5.61468 7.57895 7.70395 7.57895C9.79321 7.57895 11.4934 5.87874 11.4934 3.78947C11.4934 1.70021 9.79321 0 7.70395 0C5.61468 0 3.91447 1.70021 3.91447 3.78947ZM14.4408 16H15.2829V15.1579C15.2829 11.9082 12.6378 9.26316 9.38816 9.26316H6.01974C2.76921 9.26316 0.125 11.9082 0.125 15.1579V16H14.4408Z"
      fill="currentColor"
    />
  </svg>
</template>
