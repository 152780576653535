<template>
  <div class="collections-row">
    <div
      v-for="collection in slicedCollections"
      :key="collection"
      class="collections-row__item"
    >
      <p>{{ formatAddress(collection) }}</p>
      <CopyButton :value="collection" />
    </div>
    <div
      v-if="!!otherCount"
      class="collections-row__other"
    >
      <span>and</span>
      <router-link :to="`/collections/custom/${containerId}?tab=container`">
        {{ otherCount }} other
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';

import { formatAddress } from '@/utility';

import CopyButton from '@/components/ui/CopyButton';

const props = defineProps({
	collections: {
		type: Array,
		required: true,
	},
	containerId: {
		type: String,
		default: '',
	},
});

const slicedCollections = computed(() => props.collections.slice(0, 2));
const otherCount = computed(() => props.collections.length > 2 ? props.collections.length - 2 : 0);
</script>

<style lang=scss scoped>
.collections-row {
  display: flex;
  align-items: center;
  column-gap: 8px;
  white-space: nowrap;

  .collections-row__item {
    column-gap: 4px;
    display: flex;
    align-items: center;

    & > p {
      font-size: 12px;
      color: $shade60;
      font-weight: 400;
    }

    :deep(.copy-button) {
      padding: 0;
      color: $shade60;
    }
  }

  .collections-row__other {
    display: flex;
    align-items: center;
    column-gap: 4px;

    font-size: 12px;
    color: $shade60;
    font-weight: 400;

    & > a {
      text-decoration: underline;

      cursor: pointer;
    }
  }
}
</style>
