<template>
  <ConfirmationModal
    :title="forModal.title"
    :accept-text="forModal.acceptBtnText"
    :accept-button-props="{ color: 'black' }"
    :cancel-button-props="{ color: 'black' }"
    :cancel-text="forModal.declineBtnText"
    :disable-btns="banLoading"
    @on-accept="onAccept"
  >
    <div class="ban-message">
      {{ forModal.text }}
    </div>
  </ConfirmationModal>
</template>

<script setup>
import { ref, useAttrs } from 'vue';
import { useStore } from 'vuex';
import { ConfirmationModal } from '@/components/common/modal';
import { confirmModalContent } from '@/components/common/modal/data/confirmModalContent';

const store = useStore();
const attrs = useAttrs();
const id = attrs.modalProps.id;
const isBanned = attrs.modalProps.isBanned;
const fromEditing = attrs.modalProps?.fromEditing;
const banLoading = ref(false);
const forModal = isBanned ? confirmModalContent.collectionBanned : confirmModalContent.collection;

const onAccept = async () => {
	if (fromEditing) {
		banLoading.value = true;
		await store.dispatch('editCollection/toggleBan')
			.finally(() => { banLoading.value = false; });
		store.dispatch('modal/closeModal');
	} else {
		banLoading.value = true;
		await store.dispatch(isBanned ? 'collections/unbanCollection' : 'collections/banCollection', id)
			.finally(() => { banLoading.value = false; });
		store.dispatch('modal/closeModal');
	}
};
</script>

<style lang="scss" scoped>
.ban-message {
  max-width: 360px;
  padding: 12px 24px 24px;
  text-align: center;
}
</style>
