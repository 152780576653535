<template>
  <ModalContentContainer class="announcement-preview-modal">
    <ModalHeader class="announcement-preview-modal__header">
      <h2 class="announcement-preview-modal__header-heading text-subhead">
        {{ heading }}
      </h2>
    </ModalHeader>
    <AnnouncementModalBody
      :body="announcement.body"
      :claim-url="announcement.claimURL"
      :ends-at="announcement.endsAt"
      :handle-catch-loading-error="handleLoadingError"
      :handle-loading-successful="handleLoadingSuccess"
      :image-loading-error="imageLoadingError"
      :published-at="new Date()"
      :starts-at="announcement.startsAt"
      :thumbnail="announcement.thumbnail"
      :title="announcement.title"
      :tweet-url="announcement.tweetURL"
      :type="announcement.type"
    />
  </ModalContentContainer>
</template>

<script setup>
import { ref, useAttrs } from 'vue';

import { ModalContentContainer, ModalHeader } from '@/components/common/modal/index';
import AnnouncementModalBody from '@/components/common/modal/announcementDetailsModal/components/AnnouncementModalBody.vue';

const attrs = useAttrs();

const announcement = attrs?.modalProps?.announcement;

const heading = 'Preview Announcement';

const imageLoadingError = ref(false);

function handleLoadingSuccess () {
	imageLoadingError.value = false;
}

function handleLoadingError () {
	imageLoadingError.value = true;
}
</script>

<style scoped lang="scss">
$width: 600px;

.announcement-preview-modal {
	min-width: $width !important;
	max-width: $width !important;

	&__header {
		position: relative;
		height: 64px;
		width: 100%;
		@include flex(row, flex-start);
    flex-grow: 1;

    :deep(.close-action-wrapper) {
      width: unset;
    }
  }
}
</style>
