<template>
  <BaseTabItem
    :is-selected="isActive"
    @click="activeTab"
  >
    <slot />
  </BaseTabItem>
</template>

<script>
import { computed, inject, watchEffect, getCurrentInstance } from 'vue';

import BaseTabItem from '@/components/ui/BaseTabItem';

export default {
	name: 'Tab',
	components: { BaseTabItem },
	props: {
		value: {
			type: String,
			required: false,
		},
	},
	setup (props) {
		const instance = getCurrentInstance();

		const { tabs, selectTab, active } = inject('tabsState', {
			tabs: [],
			selectTab: () => {
			},
		});

		const index = computed(() => tabs.value.findIndex(target => target.uid === instance.uid));

		const isActive = computed(() => {
			const value = props?.value;

			if (value) {
				return tabs.value[index.value]?.props?.value === active.value;
			}

			return index.value === active.value;
		});

		const activeTab = () => {
			const value = props?.value;

			selectTab(value ? tabs.value[index.value]?.props?.value : index.value);
		};

		watchEffect(() => {
			if (index.value === -1) {
				tabs.value.push(instance);
			}
		});

		return {
			activeTab,
			isActive,
		};
	},
};
</script>
