<template>
  <div class="list">
    <LabeledContainer
      v-for="item in invalidList"
      :key="item?.id"
      class="list-item"
      label="The below addresses cannot be processed"
    >
      <div class="list-item-content">
        Line {{ item?.numberValue }}: {{ item.line }} {{ item?.errorMessage }}
      </div>
      <template #labelContent>
        <div class="remove-action-wrapper">
          <span
            class="remove-action"
            @click="removeInvalidAddress(item)"
          >Delete Them</span>
        </div>
      </template>
    </LabeledContainer>
  </div>
</template>

<script>
import { filter, map } from 'lodash';

import LabeledContainer from '@/components/ui/LabeledContainer';
import { ethereumAddressPattern } from '@/utility';
import { formatAddressListToArray, formatAddressListToString } from '@/pages/drops/details/helpers';

export default {
	components: { LabeledContainer },
	inject: ['formState'],
	computed: {
		list () {
			return formatAddressListToArray(this.formState?.formValues?.accessList);
		},
		invalidList () {
			const list = map(this.list, item => {
				let errorMessage = null;

				if (!item?.line) {
					errorMessage = 'address cannot be empty';
				} else {
					if (!ethereumAddressPattern.test(item.line)) {
						errorMessage = 'is an invalid wallet address';
					}

					if (filter(this.list, v => v?.line === item?.line).length > 1) {
						errorMessage = 'this address is already on the list';
					}
				}

				return { ...item, errorMessage };
			});

			return filter(list, item => item.errorMessage != null);
		},
	},
	methods: {
		removeInvalidAddress (item) {
			const localList = [...this.list];

			localList.splice(item.numberValue - 1, 1);

			this.formState?.setFieldValue('accessList', formatAddressListToString(localList.map(item => item?.line || '')));
		},
	},
};
</script>

<style scoped lang='scss'>
  .list {
    color: $darkRed;
    margin-top: 20px;
  }

  .list-item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &-content {
      padding: 20px;
      border: 1px solid $darkRed;
      border-radius: 5px;
      background-color: $white;
      color: $darkRed;
      font-size: 13px;
      word-break: break-word;
    }

    .remove-action-wrapper {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      .remove-action {
        cursor: pointer;
        font-weight: normal;
      }
    }
  }
</style>
