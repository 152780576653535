<template>
  <div class="report-actions-block">
    <div class="report-actions-block__search">
      <SearchField
        :model-value="search"
        to-query-param
        :placeholder="placeholder"
        @enter:model-value="changeSearchString"
      />
    </div>
    <div class="report-actions-block__filters">
      <LabeledContainer
        class="report-actions-block__filter"
        label="Show"
        for-id="show"
      >
        <Select
          :options="showOptions"
          :model-value="getShowOptionByValue(showLength)"
          name="show"
          @update:model-value="changeLength"
        >
          <template #icon>
            <ChevronDownIcon />
          </template>
        </Select>
      </LabeledContainer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ChevronDownIcon } from '@/components/icons';
import { Select } from '@/components/ui/select';
import { SearchField } from '@/components/ui/text-fields';
import LabeledContainer from '@/components/ui/LabeledContainer';

export default {
	components: {
		LabeledContainer,
		ChevronDownIcon,
		SearchField,
		Select,
	},
	data () {
		return {
			showOptions: [
				{ label: '10', value: 10 },
				{ label: '25', value: 25 },
				{ label: '50', value: 50 },
				{ label: '100', value: 100 },
			],
			searchPlaceholders: {
				nft: 'NFT',
				user: 'users',
				collection: 'collections',
			},
		};
	},
	computed: {
		...mapState({
			showLength: state => state.banned.showLength,
			search: state => state.banned.search,
			tab: state => state.banned.type,
		}),
		getShowOptionByValue () {
			return value => this.showOptions.find(opt => opt.value === value);
		},
		placeholder () {
			return `Search ${this.searchPlaceholders[this.tab]}`;
		},
	},
	methods: {
		...mapActions('banned', ['changeShowLength', 'changeSearch']),
		changeLength (option) {
			this.changeShowLength(option.value);
		},
		changeSearchString (search) {
			this.changeSearch(search);
		},
	},
};
</script>

<style scoped lang="scss">
.report-actions-block {
  display: flex;
  justify-content: flex-start;

  &__filters {
    display: flex;
    margin-bottom: 24px;
  }

  &__filter {
    display: flex;
    align-items: center;
    width: 115px;
    margin-right: 20px;

    &_status:deep(.dropdown__list) {
      width: 115px;
    }

    &:deep(.label) {
      margin: 0;
      padding-right: 4px;
    }
  }

  &__action {
    width: 135px;
  }

  &__search {
    width: 415px;
    margin-right: 20px;
  }
}
</style>
