<template>
  <div
    class="tag"
    :class="[color, isUppercase ? 'uppercase' : undefined, isRounded ? 'rounded' : undefined]"
  >
    <slot />
  </div>
</template>

<script>
export default {
	props: {
		isRounded: {
			type: Boolean,
			required: false,
			default: true,
		},
		color: {
			type: String,
			required: false,
		},
		isUppercase: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
};
</script>

<style scoped lang='scss'>
  .tag {
    color: $white;
    background-color: $grey;
    padding: 5px 8px;
    width: fit-content;
    box-sizing: border-box;

    &.rounded {
      padding: 5px 10px;
      border-radius: 24px;
    }

    &.uppercase {
      text-transform: uppercase;
    }

    &.white {
      background-color: $white;
      color: $grey;
    }

    &.red {
      background-color: $red;
      color: $white;
    }

    &.grey {
      background-color: rgba(0, 0, 0, .5);
    }
  }
</style>
