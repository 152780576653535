<template>
  <div>
    <div class="trait-container">
      <Indicators :disabled="disabled" />
      <Button
        v-if="!state.isMetaDataOpen.value"
        :disabled="disabled"
        variant="outlined"
        @click="state.setMetadataOpen(true)"
      >
        + Add Metadata Trait
      </Button>
      <MetaForm
        v-else
        @on-accept="acceptMeta"
        @on-cancel="state.setMetadataOpen(false)"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/ui/Button';

import MetaForm from './components/MetaForm.vue';
import Indicators from './components/Indicators.vue';

export default {
	components: {
		Button,
		MetaForm,
		Indicators,
	},
	inject: ['state'],
	data () {
		return {
			isShowMetaForm: false,
		};
	},
	computed: {
		disabled () {
			return this.state.notEditable;
		},
	},
	methods: {
		acceptMeta (values) {
			this.state.formValues.metadata.push(values);

			this.state.setMetadataOpen(false);
		},
	},
};
</script>

<style scoped lang='scss'>

</style>
