import requests from './requests.service';

const baseUrl = process.env.VUE_APP_ADMIN_PANEL_BASE_URL;
const basePath = `${baseUrl}/admin/v1/tokens`;

export const tokensService = {
	getList: params => requests.get(basePath, { params }),
	deleteToken: address => requests.delete(`${basePath}/${address}`),
	addToken: address => requests.post(basePath, { address }),
};
