import { reportsService } from '@/services';
import { storeLoadingWrapper } from '@/utility';
import { find, forEach } from 'lodash';

export default {
	async fetchReportsList ({ commit, state }, { reset, limit, offset, search }) {
		if (limit) commit('setShowLength', limit);
		if (offset) commit('setOffset', offset);
		if (search) commit('setSearch', search);

		await storeLoadingWrapper(
			async () => {
				if (reset) {
					state.list = [];
					state.selected = {};
				}
				const response = await reportsService.getList(state.type, state.showLength, state.offset, state.search, state.status);
				state.totalCount = response.totalCount;
				state.list = response.reports;
			},
			commit,
			'setLoading',
		);
	},
	async changeActiveTab ({ dispatch, commit }, type) {
		commit('setActiveTab', type);
		commit('setShowLength', 10);
		commit('setOffset', 0);
		commit('setSearch', '');
		await dispatch('fetchReportsList', { reset: true });
	},
	async changeShowLength ({ dispatch, commit }, length) {
		commit('setShowLength', length);
		await dispatch('fetchReportsList', { reset: true });
	},
	async changeOffset ({ dispatch, commit }, offset) {
		commit('setOffset', offset);
		await dispatch('fetchReportsList', { reset: true });
	},
	async changeSort ({ dispatch, commit }, sort) {
		commit('setSort', sort);
		commit('setOffset', 0);
		await dispatch('fetchReportsList', { reset: true });
	},
	async changeSearch ({ dispatch, commit }, search) {
		commit('setSearch', search);
		commit('setOffset', 0);
		await dispatch('fetchReportsList', { reset: true });
	},
	async changeStatus ({ dispatch, commit }, status) {
		commit('setStatus', status);
		commit('setOffset', 0);
		await dispatch('fetchReportsList', { reset: true });
	},
	async acceptReports ({ commit, state }, reportIds) {
		await storeLoadingWrapper(
			async () => {
				await reportsService.acceptReports(reportIds);
				forEach(reportIds, id => {
					find(state.list, { id }).status = 'accepted';
				});
			},
			commit,
			'setAcceptLoading',
		);
	},
	async declineReports ({ state }, reportIds) {
		await storeLoadingWrapper(
			async () => {
				await reportsService.declineReports(reportIds);
				forEach(reportIds, id => {
					find(state.list, { id }).status = 'declined';
				});
			},
		);
	},
	async revertReports ({ state }, reportIds) {
		await storeLoadingWrapper(
			async () => {
				await reportsService.revertReports(reportIds);
				forEach(reportIds, id => {
					find(state.list, { id }).status = 'pending';
				});
			},
		);
	},
	async clearSelect ({ commit }) {
		commit('setChecks', {});
	},
};
