<template>
  <InfiniteLoading
    :is-loading="isLoading"
    :is-show-spinner="isLoading && list.length !== 0"
    :page="page"
    @infinite="infinite"
  >
    <List
      :list="list"
      :is-loading="isLoading"
    />
  </InfiniteLoading>
</template>

<script>
import InfiniteLoading from '@/components/utility/InfiniteLoading';

import List from '../components/list/index.vue';
import useDropsList from '../components/list/useDropsList';

export default {
	components: {
		List,
		InfiniteLoading,
	},
	setup () {
		const { page, getList, isLoading, list } = useDropsList('ended');

		const infinite = async () => {
			await getList({ isInit: false });
		};

		return {
			page,
			list,
			infinite,
			isLoading,
		};
	},
};
</script>
