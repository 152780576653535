import { createRouter, createWebHistory } from 'vue-router';

import store from '@/store';

import routePaths from './routes';

import MainLayout from '@/layouts/main-layout';
import SecondaryLayout from '@/layouts/secondary-layout';
import ProxyLayout from '@/layouts/proxy-layout';
import AuthPage from '@/pages/auth';
import UsersPage from '@/pages/users';
import NftsPage from '@/pages/nfts';
import SettingsPage from '@/pages/settings';
import CollectionsPage from '@/pages/collections/main';
import DropsMainPage from '@/pages/drops/main';
import DropsDetailsPage from '@/pages/drops/details';
import ReportsPage from '@/pages/reports';
import BannedPage from '@/pages/banned';
import CollectionEditPage from '@/pages/collections/edit';
import CustomCollectionEditPage from '@/pages/collections/edit-container';
import UserEditPage from '@/pages/users/edit';
import DefaultTokensPage from '@/pages/defaultTokens';
import AnnouncementsPage from '@/pages/collections/announcements';
import AirdropsPage from '@/pages/collections/airdrops';
import AddCollectionContainerPage from '@/pages/collections/add-container';
import EditAnnouncement from '@/pages/collections/announcements/EditAnnouncement';

import { ANNOUNCEMENT_TYPES } from '@/pages/collections/announcements/data';

const USERS = {
	path: routePaths.user,
	component: MainLayout,
	children: [
		{ path: '', name: 'Users', component: UsersPage },
		{ path: ':user', name: 'UserEdit', component: UserEditPage, props: true },
	],
};

const NFTS = {
	path: routePaths.nfts,
	component: MainLayout,
	children: [
		{ path: '', name: 'NFTs', component: NftsPage },
	],
};

const ANNOUNCEMENTS = {
	path: 'announcements',
	component: ProxyLayout,
	children: [
		{ path: '', name: 'CollectionAnnouncements', component: AnnouncementsPage },
		{
			path: 'create',
			name: 'CollectionAnnouncementCreate',
			component: EditAnnouncement,
			props: route => ({ identifier: route.params.identifier, type: ANNOUNCEMENT_TYPES.ANNOUNCEMENT }),
		},
		{
			path: ':ts',
			name: 'CollectionAnnouncementEdit',
			component: EditAnnouncement,
			props: route => ({ identifier: route.params.identifier, type: ANNOUNCEMENT_TYPES.ANNOUNCEMENT, ts: route.params.ts }),
		},
	],
};

const AIRDROPS = {
	path: 'airdrops',
	component: ProxyLayout,
	children: [
		{ path: '', name: 'CollectionAirdrops', component: AirdropsPage },
		{
			path: 'create',
			name: 'CollectionAirdropCreate',
			component: EditAnnouncement,
			props: route => ({ identifier: route.params.identifier, type: ANNOUNCEMENT_TYPES.AIRDROP }),
		},
		{
			path: ':ts',
			name: 'CollectionAirdropEdit',
			component: EditAnnouncement,
			props: route => ({ identifier: route.params.identifier, type: ANNOUNCEMENT_TYPES.AIRDROP, ts: route.params.ts }),
		},
	],
};

const COLLECTIONS = {
	path: routePaths.collections,
	component: MainLayout,
	children: [
		{ path: '', name: 'Collections', component: CollectionsPage },
		{
			path: ':identifier',
			component: ProxyLayout,
			children: [
				{ path: '', name: 'CollectionEdit', component: CollectionEditPage, props: true },
				ANNOUNCEMENTS,
				AIRDROPS,
			],
		},
		{
			path: 'add-container',
			name: 'CustomCollectionAdd',
			component: AddCollectionContainerPage,
		},
		{
			path: 'custom',
			component: ProxyLayout,
			children: [
				{
					path: ':identifier',
					component: ProxyLayout,
					children: [
						{ path: '', name: 'CustomCollectionEdit', component: CustomCollectionEditPage, props: true },
					],
				},
			],
		},
	],
};

const DROPS = {
	path: routePaths.drops,
	component: ProxyLayout,
	children: [
		{
			path: '',
			component: MainLayout,
			children: [
				{ path: '', name: 'Drops', component: DropsMainPage },
			],
		},
		{
			path: ':drop',
			component: SecondaryLayout,
			children: [
				{ path: '', name: 'DropDetails', component: DropsDetailsPage, props: true },
			],
		},
	],
};

const REPORTS = {
	path: routePaths.reports,
	component: MainLayout,
	children: [
		{ path: '', name: 'Reports', component: ReportsPage },
	],
};

const BANNED = {
	path: routePaths.banned,
	component: MainLayout,
	children: [
		{ path: '', name: 'Banned', component: BannedPage },
	],
};

const DEFAULT_TOKENS = {
	path: routePaths.defaultTokens,
	component: MainLayout,
	children: [
		{ path: '', name: 'Default Tokens', component: DefaultTokensPage },
	],
};

const SETTINGS = {
	path: routePaths.settings,
	component: MainLayout,
	children: [
		{ path: '', name: 'Settings', component: SettingsPage },
	],
};

const AUTH = { path: routePaths.auth, name: 'Auth', component: AuthPage };

const NOT_FOUND = { path: '/:pathMatch(.*)*', redirect: routePaths.user };

export const routes = [
	USERS,
	NFTS,
	COLLECTIONS,
	DROPS,
	REPORTS,
	BANNED,
	DEFAULT_TOKENS,
	AUTH,
	NOT_FOUND,
	SETTINGS,
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	const publicPath = [routePaths.auth];
	const authRequired = !publicPath.includes(to.path);
	const loggedIn = store.state?.auth?.tokens?.access != null;

	if (authRequired && !loggedIn) {
		return next(routePaths.auth);
	}

	if (loggedIn && publicPath.includes(to.path)) {
		return next(routePaths.main);
	}

	next();
});

export default router;
