<script>
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Cell',
	render () {
		return <div class="cell">{this.$slots?.default?.()}</div>;
	},
});
</script>

<style scoped lang="scss">
.cell {
  padding: 16px;
  word-break: break-word;
  font-size: 1rem;
  line-height: 1.43;
  font-weight: normal;
  display: flex;
  align-items: center;
  white-space: pre-line;
}
</style>
