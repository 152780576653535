<template>
  <hr class="base-ui-divider">
</template>

<style scoped lang='scss'>
.base-ui-divider {
  padding: 0;
  margin: 0;
  border: none;
  border-top: 1px solid $shade30;
}
</style>
