import * as yup from 'yup';
import {
	uniq,
	isEmpty,
	forEach,
	startCase,
	lowerCase,
} from 'lodash';

import {
	url,
	email,
	object,
	maxString,
	stringSchema,
	trim,
} from '@/components/common/form/formValidation';

export const defaultValues = {
	socialLinks: [],
	email: '',
	websiteUrl: '',
	username: '',
	avatarUrl: '',
	bannerUrl: '',
	description: '',
	hideAverageSaleAmount: false,
	hideTradedAmount: false,
};

function initLinks (values) {
	const links = [];

	if (!isEmpty(values)) {
		for (const key in values) {
			forEach(uniq(values[key]), link => links.push({
				link,
				type: { label: startCase(key), value: lowerCase(key) },
			}));
		}
	}

	return links;
}

export const initValues = (dataset = null) => {
	if (isEmpty(dataset)) {
		return defaultValues;
	}
	return {
		socialLinks: initLinks(dataset.socialLinks),
		email: dataset.email || defaultValues.email,
		websiteUrl: dataset.websiteUrl || defaultValues.websiteUrl,
		username: dataset.username || defaultValues.username,
		avatarUrl: dataset.avatarUrl || defaultValues.avatarUrl,
		bannerUrl: dataset.bannerUrl || defaultValues.bannerUrl,
		description: dataset.description || defaultValues.description,
		hideAverageSaleAmount: dataset.hideAverageSaleAmount || defaultValues.hideAverageSaleAmount,
		hideTradedAmount: dataset.hideTradedAmount || defaultValues.hideTradedAmount,
	};
};

export const validationSchema = object({
	websiteUrl: url,
	email: email(),
	description: stringSchema.concat(maxString(500)),
	username: trim.concat(maxString(100)).matches('^[a-zA-Z0-9_-]*$', 'The username can not contain spaces and symbols: !"#$%&\'()*+,./:;<=>?@[\\]^`{|}~.'),
	socialLinks: yup.array().of(yup.object({
		link: stringSchema
			.when('type.value', value => value === 'email' ? email() : url),
	})),
});

function getSocialLinks (values) {
	let socialLinks = {};

	if (!isEmpty(values)) {
		socialLinks = values.reduce((acc, val) => {
			if (!acc[val.type.value]) acc[val.type.value] = [];

			if (val.link.length > 0) acc[val.type.value].push(val.link);

			return acc;
		}, {});
	}

	return socialLinks;
}

export const submitFormValues = async (data, values) => {
	if (!data && !values) return;

	const { update } = data;
	const dataset = {
		avatarUrl: values.avatarUrl || null,
		bannerUrl: values.bannerUrl || null,
		username: isEmpty(values.username) ? null : values.username,
		socialLinks: getSocialLinks(values.socialLinks),
		email: isEmpty(values.email) ? null : values.email,
		websiteUrl: isEmpty(values.websiteUrl) ? null : values.websiteUrl,
		description: isEmpty(values.description) ? null : values.description,
		hideAverageSaleAmount: values.hideAverageSaleAmount || false,
		hideTradedAmount: values.hideTradedAmount || false,
	};

	await update(dataset);
};
