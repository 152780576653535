export default {
	getCollection: (state, payload) => {
		state.collection = payload;
		state.initState = payload;
	},
	getCollaborators: (state, payload) => {
		state.collaborators = payload;
	},
	updateCollection: (state, payload) => {
		for (const field in payload.updatedValues) {
			state.collection[field] = payload.updatedValues[field];
		}
		state.initState = state.collection;
	},
	startLoading (state) {
		state.loading = true;
	},
	finishLoading (state) {
		state.loading = false;
	},
	toggleBan: (state) => {
		state.collection.collectionStatus = state.collection.collectionStatus === 'active' ? 'banned' : 'active';
	},
	removeCollaborator: (state, id) => {
		state.collaborators.collaborators = state.collaborators.collaborators.filter(collab => collab.id !== id);
	},
};
