<template>
  <BaseCheckbox
    :name="name"
    :disabled="disabled"
    :is-secondary="isSecondary"
    :is-large="isLarge"
    :value="value"
    :checked="checked"
    @input="handleChange"
  >
    <slot />
  </BaseCheckbox>
</template>

<script>
import { useField } from 'vee-validate';

import BaseCheckbox from '@/components/ui/BaseCheckbox';

export default {
	components: { BaseCheckbox },
	props: {
		name: {
			type: String,
			required: true,
		},
		value: {
			type: [Boolean, String],
		},
		isSecondary: {
			type: Boolean,
			required: false,
		},
		isLarge: {
			type: Boolean,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	setup (props) {
		const { checked, handleChange } = useField(props?.name || '', undefined, {
			type: 'checkbox',
			checkedValue: props.value,
			uncheckedValue: false,
			initialValue: false,
		});

		return {
			checked,
			handleChange,
		};
	},
};
</script>
