export default {
	addItemToListTrash (state, itemId) {
		state.listToTrash.push(itemId);
	},

	addAllItemToListTrash (state, listItemId) {
		state.listToTrash = [...listItemId];
	},

	removeItemToListTrash (state, itemId) {
		state.listToTrash = state.listToTrash.filter((item) => item !== itemId);
	},

	removeAllItemToListTrash (state) {
		state.listToTrash = [];
	},

	changeAllChecked (state, payload) {
		state.isAllChecked = payload;
	},

	changeIsResetAllChecked (state, payload) {
		state.isForcedReset = payload;
	},

	resetState (state) {
		state.listToTrash = [];
		state.isAllChecked = false;
		state.isForcedReset = false;
	},

	setCurrentList (state, list) {
		state.currentList = list;
	},

	setNotDataAll (state, payload) {
		state.isNotDataAll = payload;
	},
	setContext (state, payload) {
		state.context = payload;
	},
};
