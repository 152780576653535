<template>
  <svg
    fill="none"
    height="18"
    viewbox="0 0 15 18"
    width="15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clip-rule="evenodd"
      d="M8.25375 1.25C8.25375 0.835786 7.91796 0.5 7.50375 0.5C7.08953 0.5 6.75375 0.835786 6.75375 1.25V8.74999C6.75375 9.16421 7.08953 9.49999 7.50375 9.49999C7.91796 9.49999 8.25375 9.16421 8.25375 8.74999V1.25ZM3.25667 5.26024C3.54951 4.9673 3.54944 4.49242 3.25649 4.19958C2.96355 3.90673 2.48868 3.90681 2.19584 4.19975C1.14719 5.24874 0.433125 6.58512 0.143935 8.03991C-0.145256 9.4947 0.00341193 11.0026 0.571141 12.3729C1.13887 13.7432 2.10016 14.9144 3.33348 15.7384C4.56679 16.5624 6.01674 17.0022 7.5 17.0022C8.98325 17.0022 10.4332 16.5624 11.6665 15.7384C12.8998 14.9144 13.8611 13.7432 14.4289 12.3729C14.9966 11.0026 15.1453 9.4947 14.8561 8.03991C14.5669 6.58512 13.8528 5.24874 12.8042 4.19975C12.5113 3.90681 12.0364 3.90673 11.7435 4.19958C11.4506 4.49242 11.4505 4.9673 11.7433 5.26024C12.5822 6.09943 13.1535 7.16853 13.3848 8.33236C13.6162 9.49619 13.4973 10.7025 13.0431 11.7987C12.5889 12.895 11.8199 13.8319 10.8332 14.4911C9.84656 15.1503 8.6866 15.5022 7.5 15.5022C6.31339 15.5022 5.15343 15.1503 4.16678 14.4911C3.18013 13.8319 2.4111 12.895 1.95691 11.7987C1.50273 10.7025 1.38379 9.49619 1.61515 8.33236C1.8465 7.16853 2.41775 6.09943 3.25667 5.26024Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>
