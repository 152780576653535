<template>
  <main class="main">
    <slot />
  </main>
</template>

<style scoped lang='scss'>
  .main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 60px;
    width: 100%;
  }
</style>
