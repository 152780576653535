import requestsService from './requests.service';

const announcementsPath = `${process.env.VUE_APP_ADMIN_PANEL_BASE_URL}/admin/v1/announcements`;

export const announcementsService = {
	createAnnouncement: async (urnType, identifier, type, data) => requestsService.post(`${announcementsPath}/${urnType}:${identifier}/${type}`, data),
	getAnnouncementDetails: async (urnType, identifier, type, ts) => requestsService.get(`${announcementsPath}/${urnType}:${identifier}/${type}/${ts}`),
	getAnnouncements: async (urnType, identifier, queryParams = {}) => {
		const queryKeys = Object.keys(queryParams).filter(key => ['type', 'status', 'limit', 'cursor', 'visibility', 'showScheduled'].includes(key));
		const queryParamsString = queryKeys.length
			? `?${queryKeys.map(key => `${key}=${queryParams[key]}`).join('&')}`
			: '';
		return requestsService.get(`${announcementsPath}/${urnType}:${identifier}${queryParamsString}`);
	},
	updateAnnouncement: async (urnType, identifier, type, ts, data) => requestsService.patch(`${announcementsPath}/${urnType}:${identifier}/${type}/${ts}`, data),
	deleteAnnouncements: async (urnType, identifier, data) => requestsService.post(`${announcementsPath}/${urnType}:${identifier}/delete`, data),
	getAnnouncementsCounters: async (urnType, identifier, queryParams = {}) => {
		const queryKeys = Object.keys(queryParams).filter(key => ['type', 'status', 'showScheduled'].includes(key));
		const queryParamsString = queryKeys.length
			? `?${queryKeys.map(key => `${key}=${queryParams[key]}`).join('&')}`
			: '';
		return requestsService.get(`${announcementsPath}/${urnType}:${identifier}/counters${queryParamsString}`);
	},
};
