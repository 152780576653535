<template>
  <picture>
    <source
      :srcset="pictureCustomSrcSet || pictureSrcSet"
    >
    <img
      v-bind="pictureOtherProps"
      ref="renderedImage"
      :src="pictureBaseUrl"
      :style="pictureStyles"
      :alt="pictureAltText"
      @error="onPictureLoadError"
      @load="onPictureLoad"
    >
  </picture>
</template>

<script setup>
import { computed, defineProps, ref } from 'vue';

const props = defineProps({
	pictureCustomSrcSet: {
		type: String,
		required: false,
	},
	pictureBaseUrl: {
		type: String,
		required: true,
	},
	pictureStyles: {
		type: Object,
		required: false,
	},
	pictureAltText: {
		type: String,
		required: false,
	},
	pictureOtherProps: {
		type: Object,
		required: false,
	},
	isPictureRetina3x: {
		type: Boolean,
		required: false,
		default: false,
	},
	onPictureLoadError: {
		type: Function,
		required: false,
	},
	onPictureLoad: {
		type: Function,
		required: false,
	},
	isZoomOpened: {
		type: Boolean,
		default: false,
	},
});

const renderedImage = ref(null);
const imageHeight = computed(() => renderedImage?.value?.clientHeight);
const imageWidth = computed(() => renderedImage?.value?.clientWidth);

const pictureSrcSet = computed(() => {
	if (!renderedImage.value) {
		return null;
	}

	if (!imageWidth?.value || !imageHeight?.value) {
		return [
			props?.pictureBaseUrl,
		];
	}

	if (props?.isPictureRetina3x) {
		return [
			resizeImage(props?.pictureBaseUrl, 1),
			resizeImage(props?.pictureBaseUrl, 1.5),
			resizeImage(props?.pictureBaseUrl, 2),
			resizeImage(props?.pictureBaseUrl, 3),
			resizeImage(props?.pictureBaseUrl, 4),
		].join(', ');
	}
	return [
		resizeImage(props?.pictureBaseUrl, 1),
		resizeImage(props?.pictureBaseUrl, 1.5),
		resizeImage(props?.pictureBaseUrl, 2),
		resizeImage(props?.pictureBaseUrl, 4),
	].join(', ');
});

function resizeImage (url, pixelDensity) {
	if (!pixelDensity || props.isZoomOpened) {
		return url;
	}

	if (imageWidth?.value === imageHeight?.value) {
		return `${url}?s=${Math.round(imageHeight?.value * pixelDensity)}x${Math.round(imageWidth?.value * pixelDensity)}${(pixelDensity) ? ` ${pixelDensity}x` : ''}`;
	}
	if (imageWidth?.value > imageHeight?.value) {
		return `${url}?s=${Math.round(imageWidth?.value * ((imageWidth?.value * pixelDensity) / imageHeight?.value))}x${Math.round(imageWidth?.value * pixelDensity)}${(pixelDensity) ? ` ${pixelDensity}x` : ''}`;
	}
	if (imageWidth?.value < imageHeight?.value) {
		return `${url}?s=${Math.round(imageHeight?.value * pixelDensity)}x${Math.round(imageHeight?.value * ((imageHeight?.value * pixelDensity) / imageWidth?.value))}${(pixelDensity) ? ` ${pixelDensity}x` : ''}`;
	}
}
</script>
