<script>
import { defineComponent } from 'vue';

import Cell from '../Cell.vue';

export default defineComponent({
	name: 'HeadCell',
	components: {
		Cell,
	},
	render () {
		return <Cell class="head-cell">{this.$slots?.default?.()}</Cell>;
	},
});
</script>

<style lang="scss" scoped>
.head-cell {
  font-weight: bold;
}
</style>
