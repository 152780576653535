<template>
  <div
    class="datepicker"
    :class="[disabled ? 'disabled' : undefined]"
  >
    <Datepicker
      ref="picker"
      v-model="current"
      class="datepicker__picker"
      :uid="uid"
      :is24="false"
      :enable-time-picker="true"
      :dark="false"
      auto-apply
      position="center"
      @closed="onClosed"
    />

    <TextField
      :id="id"
      type="text"
      class="datepicker__input"
      :readonly="clearable"
      :disabled="disabled"
      :name="name"
      :value="inputValue"
      :placeholder="placeholder"
      :error-message="errorMsg"
      :is-error-message-visible="isHasErrorMessage"
      @input="handleChange"
      @blur="handleBlur"
      @click.stop="onClick"
    />
  </div>
</template>

<script>
'use strict';

import { format } from 'date-fns';
import { useField } from 'vee-validate';
import Datepicker from '@vuepic/vue-datepicker';
import { defineComponent, onMounted, ref, watch } from 'vue';
import TextField from '@/components/ui/text-fields/TextField';

export default defineComponent({
	components: { TextField, Datepicker },

	props: {
		id: {
			type: String,
			required: false,
		},
		name: {
			type: String,
			required: true,
		},
		value: {
			type: String,
			required: false,
			default: null,
		},
		placeholder: {
			type: String,
			default: '',
		},
		format: {
			type: String,
			required: true,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		clearable: {
			type: Boolean,
			required: false,
			default: true,
		},
	},

	setup (props) {
		const uid = ref(String(Date.now()));
		const visible = ref(false);
		const picker = ref(null);
		const { value: inputValue, errorMessage, handleBlur, handleChange } = useField(props?.name || '', undefined, {
			initialValue: props.value ? format(new Date(props.value), props.format) : '',
		});
		// eslint-disable-next-line no-nested-ternary
		const current = ref(props.value
			? new Date(props.value)
			: inputValue.value
				? new Date(inputValue.value)
				: props.value);

		onMounted(() => {
			if (current.value) {
				inputValue.value = format(current.value, props.format);
			}
		});

		watch(current, newValue => {
			inputValue.value = format(newValue, props.format);
		});

		watch(visible, newValue => {
			if (!picker.value) {
				return;
			}

			if (!newValue) {
				picker.value.closeMenu();
			} else {
				picker.value.openMenu();
			}
		});

		function onClick () {
			if (!props.disabled) {
				visible.value = !visible.value;
			}
		}

		function onClosed () {
			visible.value = false;
		}

		return {
			uid,
			picker,
			current,
			inputValue,
			handleChange,
			handleBlur,
			errorMessage,
			onClick,
			onClosed,
		};
	},

	computed: {
		errorMsg () {
			return this?.errorMessage || '';
		},
		isHasErrorMessage () {
			return !!this.errorMsg;
		},
	},
});
</script>

<style scoped lang='scss'>
.datepicker {
  position: relative;

  &:not(.disabled) {
    .datepicker__input:deep(input) {
      cursor: pointer !important;
    }
  }
}

.datepicker__picker {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
