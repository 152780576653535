<template>
  <TitledPage :title="`Add Custom Collection`">
    <TabsQueryRouter
      v-slot="{ activeTab }"
      :items="addCustomCollectionTabs"
      :is-sticky="false"
    >
      <TabsContentContainer class="add-container-tabs__container">
        <Metadata v-show="activeTab === addCustomCollectionTabs[0].value" />
        <Container
          v-show="activeTab === addCustomCollectionTabs[1].value"
          :handle-change-address="validateCollection"
          :on-change-draft-address="validateDraftAddress"
          @on-remove-element="removeElementFromSplitCollectionValues"
        />
      </TabsContentContainer>
    </TabsQueryRouter>

    <div class="add-container-tabs__actions">
      <Tooltip
        value="Fill in the container information"
        :hide-tooltip="!disableSubmit || isLoading"
      >
        <Button
          :width="200"
          :disabled="disableSubmit"
          @click="onSubmit"
        >
          Save Changes
        </Button>
      </Tooltip>
    </div>
  </TitledPage>
</template>

<script setup>
import { useStore } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import { useForm } from 'vee-validate';
import { useRoute, useRouter } from 'vue-router';
import { computed, provide, ref, onUnmounted } from 'vue';

import {
	defaultValues,
	getValidationSchema,
	submitFormValues,
} from '@/pages/collections/helpers/form';
import Button from '@/components/ui/Button';
import Tooltip from '@/components/ui/Tooltip.vue';
import TitledPage from '@/layouts/components/TitledPage';
import collectionsService from '@/services/collections.service';
import { addSuccess } from '@/components/utility/notifications';
import { isObjectEmpty } from '@/utility/object/isObjectEmpty.js';
import Metadata from '@/pages/collections/add-container/sections/Metadata.vue';
import { TabsQueryRouter, TabsContentContainer } from '@/components/common/tabs';
import Container from '@/pages/collections/add-container/sections/Container.vue';
import collectionContainerService from '@/services/collection-container.service';
import { removeElementFromArrayByIndex } from '@/utility/array/removeElements.js';
import { fetchCollectionDetails } from '@/services/collections/collection-details.js';
import { capitalizeFirstLetter, isCombinedCollection, isSplitContainer } from '@/utility';
import { COLLECTION_STATUS, COLLECTIONS_CONSTANTS, CONTAINER_OPTIONS, COLLECTION_ERRORS }
	from '@/pages/collections/constants';
import { validateDraftAddressErrorsHandler } from '@/pages/collections/add-container/sections/helpers/addContainerErrorsHandling.js';

const store = useStore();
const route = useRoute();
const router = useRouter();

const isLoading = ref(false);
const isCollectionLoading = ref(false);

const collectionError = ref({});
const draftError = ref(null);

const addCustomCollectionTabs = computed(() => [
	{ label: `Metadata`, value: 'metadata' },
	{ label: `Container`, value: 'container' },
]);

const actionType = computed(() => values.actionType);
const mergeCollections = computed(() => store.state.collections.customCollectionMergeContractAddresses);
const notEnoughCollections = computed(
	() => {
		switch (actionType.value.value) {
			case CONTAINER_OPTIONS.SPLIT:
				return values.splitAddresses?.length < COLLECTIONS_CONSTANTS.MIN_COUNT_FOR_SPLIT_COLLECTION;
			case CONTAINER_OPTIONS.MERGE:
				return mergeCollections.value?.length < COLLECTIONS_CONSTANTS.MIN_COUNT_FOR_MERGE_COLLECTIONS;
			default:
				return null;
		}
	},
);

const { values, setFieldValue, handleSubmit, setErrors, errors, setFieldError } = useForm({
	initialValues: defaultValues,
	validationSchema: getValidationSchema({ isEdit: false }),
});

const removeElementFromSplitCollectionValues = (collectionId) => {
	values.splitAddresses = removeElementFromArrayByIndex(values.splitAddresses, collectionId);
	values.ranges = removeElementFromArrayByIndex(values.ranges, collectionId);

	collectionError.value[`${collectionId}`] && delete collectionError.value[`${collectionId}`];
};

provide('formState', { setFieldValue, values, splitAddressErrors: collectionError.value, draftError });

const disableSubmit = computed(
	() => notEnoughCollections.value
    || isLoading.value
    || isCollectionLoading.value
    || !isObjectEmpty(errors.value)
    || isSplitFieldsEmpty.value
    || (!isEmpty(collectionError.value) && actionType.value.value === CONTAINER_OPTIONS.SPLIT)
    || (draftError.value && actionType.value.value === CONTAINER_OPTIONS.DRAFT),
);

const isSplitFieldsEmpty = computed(() => {
	if (actionType.value.value === CONTAINER_OPTIONS.SPLIT) {
		let isEmptyRange = false;
		let isEmptyAddress = false;

		values.splitAddresses.forEach((address) => {
			if (!address.length)isEmptyAddress = true;
		});

		values.ranges.forEach((range) => {
			if (!range.length)isEmptyRange = true;
		});

		if (isEmptyRange || isEmptyAddress) return true;
	}

	return false;
});

const validateCollection = (address, index) => {
	setTimeout(async () => {
		collectionError.value[`${index}`] = '';
		if (address && !errors.value[`splitAddresses[${index}]`]) {
			try {
				isCollectionLoading.value = true;

				const collectionInfo = (await collectionsService.getCollections({ search: address }))?.collections?.[0];

				if (!collectionInfo) {
					setFieldError(`splitAddresses[${index}]`, COLLECTION_ERRORS.NOT_FOUND);
					collectionError.value[`${index}`] = COLLECTION_ERRORS.NOT_FOUND;
					return;
				}

				if (collectionInfo.collectionStatus === COLLECTION_STATUS.BANNED) {
					setFieldError(`splitAddresses[${index}]`, COLLECTION_ERRORS.IS_BANNED);
					collectionError.value[`${index}`] = COLLECTION_ERRORS.IS_BANNED;
					return;
				}

				if (isCombinedCollection(collectionInfo.id) && !isSplitContainer(collectionInfo.id)) {
					setFieldError(`splitAddresses[${index}]`, COLLECTION_ERRORS.INCLUDED_IN_MERGE);
					collectionError.value[`${index}`] = COLLECTION_ERRORS.INCLUDED_IN_MERGE;
					return;
				}

				delete collectionError.value[`${index}`];
			} finally {
				isCollectionLoading.value = false;
			}
		}
	}, 50);
};

const validateDraftAddress = () => setTimeout(async () => {
	draftError.value = null;

	if (!!values.draftAddress && !errors.value.draftAddress) {
		try {
			isCollectionLoading.value = true;

			const collectionInfo = await fetchCollectionDetails(values.draftAddress);

			if (collectionInfo) {
				setFieldError(`draftAddress`, COLLECTION_ERRORS.ALREADY_EXISTS);
				draftError.value = COLLECTION_ERRORS.ALREADY_EXISTS;

				return;
			}

			draftError.value = null;
		} catch (err) {
			draftError.value = validateDraftAddressErrorsHandler({ err, setErrorFunc: setFieldError });
		} finally {
			isCollectionLoading.value = false;
		}
	}
}, 50);

const addContainer = async (fieldValues) => {
	try {
		isLoading.value = true;

		await collectionContainerService.addContainer(fieldValues);

		addSuccess('Changes successfully saved');

		if (actionType.value.value === CONTAINER_OPTIONS.DRAFT && fieldValues.draftAddress) {
			await router.push({
				path: '/collections',
				query: { tab: 'all' },
			});

			return;
		}

		await router.push({
			path: '/collections',
			query: { tab: 'custom' },
		});
	} catch (error) {
		if (error.response?.data?.payload?.errors) {
			error.response.data.payload.errors.forEach((message) => {
				if (message.split(' ').includes('URL')) {
					setErrors({ slug: capitalizeFirstLetter(message) });
				}
			});
		}
		throw new Error(error);
	} finally {
		isLoading.value = false;
	}
};

const onSubmit = () => {
	const submit = handleSubmit((formValues) => {
		submitFormValues({ update: addContainer, actionType: actionType.value.value, mergeCollections: mergeCollections.value }, formValues);
	});

	isEmpty(errors.value) && submit();

	if ((!values.name || !values.slug) && route.query.tab === 'container') {
		router.push({ query: { tab: 'metadata' } });
	}
};

onUnmounted(() => {
	store.dispatch('collections/clearContainerContractAddressForMerge');
});
</script>

<style lang="scss" scoped>
.add-container-tabs {
  &__container {
    height: 100%;
    margin-bottom: 120px;

    &:deep(.labeled-wrapper) {
      label {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $black;
        margin-bottom: 8px;
      }
    }
  }

  &__actions {
    background: $white;
    border-width: 1px 0;
    border-style: solid;
    border-color: $table-border;
    position: fixed;
    bottom: 0;
    padding: 16px 20px;
    margin: 0 0 0 -20px;
    width: 100%;
    z-index: 1;
  }
}
</style>
