<template>
  <div
    :class="{
      'wrapper': true,
      rounded,
      'type-banner': type === 'banner1' || type === 'banner2',
      'type-card': type === 'card',
    }"
  >
    <BaseSkeleton class="card" />
    <div class="inner">
      <BaseSkeleton
        v-for="({width, height, pill, maxWidth}, index) in contentByType()"
        :key="index"
        :width="width"
        :height="height"
        :pill="pill"
        class="content"
        :style="{maxWidth}"
      />
    </div>
  </div>
</template>

<script>
import { BaseSkeleton } from '@/components/common/skeletons';

export default {
	components: { BaseSkeleton },
	props: {
		type: {
			type: String,
			required: false,
			default: 'card',
		},
		rounded: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	methods: {
		contentByType () {
			switch (this.type) {
				case 'banner1':
					return [
						{ width: '190', height: '30' },
						{ maxWidth: '713px', width: '100%', height: '87' },
						{ width: '87', height: '30' },
						{ width: '105', height: '15' },
						{ width: '99', height: '40', pill: true },
					];
				case 'banner2':
					return [
						{ width: '190', height: '30' },
						{ maxWidth: '713px', width: '100%', height: '87' },
						{ width: '292', height: '32' },
						{ width: '289', height: '69', pill: true },
						{ width: '99', height: '40', pill: true },
					];
				case 'card':
					return [
						{ width: '109', height: '14' },
						{ maxWidth: '303px', width: '100%', height: '34' },
						{ width: '176', height: '14' },
						{ width: '238', height: '60', pill: true },
						{ width: '99', height: '40', pill: true },
					];
				default:
					return [];
			}
		},
	},
};
</script>

<style scoped lang='scss'>
.wrapper {
  position: relative;
  min-height: 385px;

  &.type-banner {
    height: 500px;
  }

  &.rounded {
    .card {
      border-radius: 30px;
    }
  }
}

.card {
  height: 100%;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .12);
  border-radius: 0;
  background-color: #7E7E7E;
}

.inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.content {
  background-color: #ACACAC;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
</style>
