<template>
  <div
    class="table-cell"
    :style="cssVars"
  >
    <slot />
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
	padding: String,
});

const cssVars = computed(() => ({
	'--padding': props.padding,
}));
</script>

<style lang="scss">
//@import "@/assets/style/typography";

.table-cell {
	padding: var(--padding);
  box-sizing: border-box;
	word-break: break-word;
	display: flex;
	align-items: center;
	white-space: pre-line;
	//@extend .text-body;
}
</style>
