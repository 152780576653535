<template>
  <div class="container-content__split">
    <div class="container-content__wrap">
      <div class="container-content__header">
        <LabeledContainer label="Split Collection" />
      </div>
      <h4>Child collections</h4>
      <div class="container-content__table">
        <SplitCollectionTable
          :collections="collections"
          is-edit-mode
        />
      </div>
      <div class="container-content__button">
        <Button
          color="red"
          variant="outlined"
          width="200"
          @click="openModalRevertSplit"
        >
          Revert split
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { markRaw, ref } from 'vue';
import { useRouter } from 'vue-router';

import Button from '@/components/ui/Button';
import { addSuccess } from '@/components/utility/notifications';
import LabeledContainer from '@/components/ui/LabeledContainer.vue';
import ConfirmationModal from '@/components/common/modal/ConfirmationModal';
import collectionContainerService from '@/services/collection-container.service';
import SplitCollectionTable from '@/components/common/tables/SplitCollectionTable/SplitCollectionTable';

const props = defineProps({
	containerId: {
		type: String,
		default: '',
	},
	collections: {
		type: Array,
		default: () => [],
	},
});

const store = useStore();
const router = useRouter();

const isLoading = ref(false);

const openModalRevertSplit = () => {
	store.dispatch('modal/openModal', {
		content: markRaw(ConfirmationModal),
		props: {
			modalStyles: 'max-width: 300px',
			title: 'Are you sure you want to revert split?',
			contentText: 'The split collection container will be removed from the site',
			acceptText: 'Revert',
			acceptButtonProps: { color: 'black', action: revertSplit },
			cancelButtonProps: { color: 'black' },
		},
	});
};

const revertSplit = async () => {
	if (isLoading.value) {
		return;
	}

	isLoading.value = true;
	try {
		await collectionContainerService.deleteContainer(props.containerId);

		addSuccess('Container successfully deleted');

		await router.push({
			path: '/collections',
			query: { tab: 'all' },
		});
	} catch (e) {
		throw new Error(e);
	} finally {
		isLoading.value = false;
	}
};
</script>

<style lang="scss" scoped>
.container {
  &-content {
    &__wrap {
      h4 {
        margin-bottom: 40px;
      }
    }

    &__header {
      margin-bottom: 28px;
    }

    &__table {
      margin-bottom: 20px;
    }
  }
}
</style>
