export function generateGrid (columns) {
	const defaultMin = '90px';
	const defaultMax = '1fr';

	const props = columns.map(column => {
		const { name, width, isHide } = column;

		if (!isHide) {
			// for unnamed column with action buttons
			if (!name) {
				return width ? `${width?.max || defaultMax}` : `minmax(${defaultMin}, ${defaultMax})`;
			}

			return width ? `minmax(${width?.min || defaultMin}, ${width?.max || defaultMax})`
				: `minmax(${defaultMin}, ${defaultMax})`;
		}

		return '';
	});

	return { display: 'grid', gridTemplateColumns: props.join(' ') };
}
