<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="13"
    fill="none"
    viewBox="0 0 10 13"
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M1.25 0h5L10 3.75v7.5a1.25 1.25 0 01-1.25 1.25h-7.5A1.25 1.25 0 010 11.25v-10A1.25 1.25 0 011.25 0zm4.38.94v3.44h3.43L5.62.94zM2.56 8.57h.86V8H1v.57h.85V11h.72V8.57zm2.71 1.89l.29.54h.82l-.94-1.54.9-1.46h-.82l-.27.55c-.08.17-.14.3-.21.5h-.01a8.36 8.36 0 00-.24-.5L4.52 8H3.7l.88 1.48L3.66 11h.82l.26-.54.07-.13.18-.41.07.11.22.43zM9 8.57h-.86V11h-.72V8.57h-.85V8H9v.57z"
      clip-rule="evenodd"
    />
  </svg>
</template>
