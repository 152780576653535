import { nftsService } from '@/services';

export default {
	async getNFTs ({ state, commit }, { reset, limit, offset, search }) {
		if (limit) commit('setShowLength', parseInt(limit, 10));
		if (offset) commit('setOffset', parseInt(offset, 10));
		if (search) commit('setSearch', search);

		commit('startLoading', { reset });

		const params = {
			limit: state.showLength,
			offset: state.offset,
		};
		if (state.search.length) params.search = state.search;
		try {
			const response = await nftsService.getList(params);
			commit('getNFTs', { nfts: response.nfts, totalCount: response.totalCount });
		} finally {
			commit('finishLoading');
		}
	},
	async toggleBan ({ commit }, { id, isBanned }) {
		if (isBanned) await nftsService.unbanNft(id);
		else await nftsService.banNft(id);
		commit('toggleBan', id);
	},
	async changeShowLength ({ dispatch, commit }, length) {
		commit('setShowLength', length);
		await dispatch('getNFTs', { reset: true });
	},
	async changeSearch ({ dispatch, commit }, search) {
		commit('setSearch', search);
		commit('setOffset', 0);
		await dispatch('getNFTs', { reset: true });
	},
	async changeOffset ({ dispatch, commit }, offset) {
		commit('setOffset', offset);
		await dispatch('getNFTs', { reset: true });
	},
	resetPage ({ commit }) {
		commit('setSearch', '');
		commit('setOffset', 0);
		commit('getNFTs', { nfts: [], totalCount: 0 });
	},
};
