import { createStore } from 'vuex';

import modal from './modal';
import auth from './auth';
import ethers from './ethers';
import collections from './collections';
import nfts from './nfts';
import reports from './reports';
import banned from './banned';
import users from './users';
import editUser from './edit-user';
import editCollection from './edit-collection';
import tokens from './tokens';
import announcements from './announcements';
import airdrops from './airdrops';

export default createStore({
	modules: {
		auth,
		modal,
		ethers,
		collections,
		nfts,
		reports,
		users,
		banned,
		editUser,
		editCollection,
		tokens,
		announcements,
		airdrops,
	},
});
