export const convertDateFormat = (date) => {
	const splittedDate = date.split('-').join(' ').split(' ');

	const month = splittedDate[0];
	const day = splittedDate[1];
	const year = splittedDate[2];

	const time = splittedDate[3].split(':');

	const hour = splittedDate[4] === 'AM' ? time[0] : Number(time[0]) + 12;
	const minute = time[1];

	const formattedDate = new Date(Date.UTC(year, month, day, hour, minute));

	return formattedDate.toISOString();
};
