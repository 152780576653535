'use strict';

// Imports.
import { ethers } from 'ethers';
import { CacheHelper } from '@/utility/cache-helper';
import config from '@/config';
import { addError, addSuccess, clearAllNotifications } from '@/components/utility/notifications';

// If an Ethereum provider is available locally, disable its automatic reload on
// network change. This avoids a warning when MetaMask is the local provider.
if (window.ethereum) {
	window.ethereum.autoRefreshOnNetworkChange = false;
}

// The rate in milliseconds at which to poll for a local Ethereum provider.
const PROVIDER_POLL_RATE = 1000;

// Accessible status messages for future localization.
const MESSAGES = {
	NO_PROVIDER: `<span>You have no Ethereum provider. Try <a target="_blank" rel="noopener noreferrer" href="https://metamask.io/">installing MetaMask</a>.</span>`,
	REJECTED: `You rejected the request to connect your wallet. Please attempt to connect again and accept the request this time.`,
};

// Begin polling for the availability of our local Ethereum provider.
// If it is available, attach event handlers. This function is given a reference
// to the VueX event dispatcher which this servive uses elsewhere.
let dispatch;
const callbacks = [];
let watcherInterval;
let localProvider;

const startProviderWatcher = async function (_dispatch, callback) {
	callbacks.push(callback);

	// No-op on multiple calls to start the provider.
	if (dispatch) {
		return;
	}
	dispatch = _dispatch;
	console.log('Starting a watcher for the local Ethereum provider ...');

	// Poll for changes of availability in our local Ethereum provider. If the
	// local provider doesn't exist, notify the user and give a read-only option.
	async function checkProviderAvailability () {
		console.log('... check provider availability');

		if (window.ethereum) {
			await dispatch('ethers/hasLocalProvider', true, { root: true });
			const activeAddresses = await getEthereumAccounts();

			// If there exists a selected address, a signing account is connected.
			// We can therefore shortcut requiring the user to connect via a prompt.
			if (activeAddresses.length > 0) {
				await connectLocalEthereumProvider();

				// Otherwise, the signer is not yet connected. Clear the local Ethereum
				// provider to a blank slate and fall back upon the default Infura
				// provider.
			} else {
				await disconnectLocalEthereumProvider();
			}
		}

		if (!localProvider && !watcherInterval) {
			watcherInterval = setInterval(checkProviderAvailability, PROVIDER_POLL_RATE);
		}
	}

	// Begin checking for changes to provider availability on the polling interval.
	await checkProviderAvailability();
};

// Stop the polling interval looking for the local Ethereum provider.
const stopProviderWatcher = async function () {
	if (watcherInterval) {
		clearInterval(watcherInterval);
		watcherInterval = null;
	}
};

// Attempt to connect to the local Ethereum provider's currently active account.
// If there is no local Ethereum provider, notify the user.
// If the connection to the local account already exists, this function will not
// attempt to replace that signer connection. It will dispatch a VueX update.
const connectLocalEthereumProvider = async function (reconnect = false, options = undefined) {
	// If a local provider is available but not connected yet, attempt to connect
	// and retrieve a signing account.
	if (window.ethereum) {
		if (!localProvider || reconnect) {
			const ethereumAddresses = await enableEthereumAccounts(options?.onReject);

			// The addresses returned may be null if the user intervenes to reject the
			// connection request.
			if (ethereumAddresses) {
				window.ethereum.selectedAddress = ethereumAddresses[0];
				try {
					localProvider = new ethers.providers.Web3Provider(window.ethereum);
					const network = await localProvider.getNetwork();
					const networkId = ethers.utils.hexValue(network.chainId);
					localProvider.pollingInterval = config.networkPollingIntervals[networkId];

					// Emit events whenever the provider detects a change in block.
					localProvider.on('block', handleBlockChanged);

					// Retrieve the user's accounts and handle any account-changed event.
					// User accounts are only exposed for the local Ethereum provider.
					const accounts = await window.ethereum.request({ method: 'eth_accounts' });
					await handleAccountsChanged(accounts, true);
					window.ethereum.on('accountsChanged', handleAccountsChanged);

					// Set our Ethereum network and handle any network change events.
					window.ethereum.on('chainChanged', handleChainChanged);
					console.log('... local Ethereum provider successfully initialized.');

					// An unexpected error has occurred retrieving our provider references.
				} catch (error) {
					console.error('... encountered error initializing local Ethereum provider.', error);
					await dispatch('ethers/initializeFailure', 'UNKNOWN', { root: true });
					return;
				}

				// We've completed initialization of the Ethereum provider.
				await dispatch(
					'ethers/initializeSuccess',
					{
						canSign: true,
						provider: localProvider,
						callbacks,
					},
					{ root: true },
				);

				await options?.onConnected?.();

				// The user rejected the connection, so suspend the current local provider
				// if it happens to exist and restore the Infura provider.
			} else {
				if (localProvider) {
					localProvider.pollingInterval = 1000000;
					localProvider = null;
				}
			}
		}
	}
};

// Clear the local Ethereum provider. This may happen due to signing account
// disconnection, or may happen as a no-op when the user's local Ethereum
// provider is locked. When the local Ethereum provider is cleared, it is
// replaced with a default Infura provider.
const disconnectLocalEthereumProvider = async function () {
	ethereumAccountsCache = null;
	currentAddress = null;
	localProvider = null;
	window.ethereum.selectedAddress = null;
};

// If a local Ethereum provider has been detected, attempt to retrieve signing
// accounts known to the local provider. Accounts will not be visible if they
// have not been unlocked using `enableEthereumAccounts`.
const getEthereumAccounts = async function () {
	return window.ethereum.request({ method: 'eth_accounts' });
};

// If a local Ethereum provider has been detected, attempt to retrieve signing
// accounts known to the local provider. Stash the results in our cache.
// This method prompts the user to unlock their accounts if they've not already
// done so; once unlocked the accounts can be seen by `getEthereumAccounts`.
let ethereumAccountsCache;
const enableEthereumAccounts = async function (onReject = undefined) {
	if (!ethereumAccountsCache) {
		// Configure a new cache to unlock and store the potential signing accounts.
		ethereumAccountsCache = new CacheHelper();

		// If this the first attempt at a connection to the local Ethereum provider,
		// display messages regarding connection status.
		if (!window.ethereum.selectedAddress) {
			ethereumAccountsCache.onBeginWorking = async () => {
				addSuccess('Please connect to this application with your wallet');
			};
			ethereumAccountsCache.onError = async () => {
				clearAllNotifications();
				addError(MESSAGES.REJECTED);

				ethereumAccountsCache = undefined;
				if (onReject) {
					onReject();
				}
			};
			ethereumAccountsCache.onFinishWorking = async response => {
				if (response) {
					clearAllNotifications();
					addSuccess('We have successfully connected to your wallet');
				}
			};
		}
	}

	// Retrieve the local Ethereum provider's signing accounts.
	const requestAccountsPromiseGetter = () => window.ethereum.request({ method: 'eth_requestAccounts' });
	return ethereumAccountsCache.cache(requestAccountsPromiseGetter);
};

// Handle an event where the provider's block number changes by updating VueX.
const handleBlockChanged = async function (blockNumber) {
	await dispatch('ethers/changeBlockNumber', blockNumber, { root: true });
};

// Handle an event where the Ethereum network is changed by updating VueX.
const handleChainChanged = async function (chainId) {
	await dispatch('ethers/changeChainId', chainId, { root: true });
};

// Handle an event where the user's account is changed. If they've no accounts,
// then the local Ethereum provider is locked or the user has not connected
// any accounts. Update the current account address in VueX.
let priorAccountsLength = 0;
let currentAddress;
const handleAccountsChanged = async function (accounts, initial = false) {
	if (accounts.length === 0) {
		// The user has disconnected; there are no accounts where there used to be.
		// Reset the local provider in response.
		if (priorAccountsLength > 0) {
			await disconnectLocalEthereumProvider();
		}

		await dispatch('auth/logout', {}, { root: true });

		// The active signing address has changed; reconnect the local provider.
	} else if (accounts[0] !== currentAddress) {
		currentAddress = accounts[0];
		await dispatch('ethers/changeCurrentAddress', currentAddress, { root: true });
		if (!initial) {
			await dispatch('auth/logout', {}, { root: true });
		}
	}
	priorAccountsLength = accounts.length;
};

// Return a reference to the current available Ethereum provider.
const getProvider = function () {
	return localProvider;
};

// Return the name of a particular network.
const getNetworkName = function (networkId) {
	switch (networkId) {
		case '0x1':
			return 'Mainnet';
		case '0x2':
			return 'Morden (deprecated)';
		case '0x3':
			return 'Ropsten Test Network';
		case '0x4':
			return 'Rinkeby Test Network';
		case '0x5':
			return 'Goerli Test Network';
		case '0x2a':
			return 'Kovan Test Network';
		case '0xd903':
			return 'Papyrus Test Network';
		case undefined:
		case null:
			return 'No Chain!';

			// Local testing networks should land here.
		default:
			return 'Local Test Network';
	}
};

// Export the various management functions of the Ethers service.
export const ethersService = {
	startProviderWatcher,
	stopProviderWatcher,
	connectLocalEthereumProvider,
	disconnectLocalEthereumProvider,
	getProvider,
	getNetworkName,
	getEthereumAccounts,
};
