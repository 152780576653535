<template>
  <div
    class="accept-cancel-wrapper"
    :class="{'reverse': reverse}"
  >
    <slot
      name="cancel"
      :cancelText="cancelText"
    >
      <Button
        :variant="cancelButtonProps?.variant ?? 'outlined'"
        :color="cancelButtonProps?.color ?? ''"
        @click="$emit('onCancel', $event)"
      >
        {{ cancelText }}
      </Button>
    </slot>
    <slot
      name="accept"
      :acceptText="acceptText"
    >
      <Button
        :variant="acceptButtonProps?.variant ?? ''"
        :color="acceptButtonProps?.color ?? ''"
        :disabled="disabled"
        @click="$emit('onAccept', $event)"
      >
        {{ acceptText }}
      </Button>
    </slot>
  </div>
</template>

<script>
import Button from '@/components/ui/Button';

export default {
	components: {
		Button,
	},
	props: {
		acceptText: {
			type: String,
			required: false,
			default: 'Accept',
		},
		cancelText: {
			type: String,
			required: false,
			default: 'Cancel',
		},
		reverse: {
			type: Boolean,
			required: false,
			default: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		acceptButtonProps: {
			type: Object,
			required: false,
			default: () => {},
		},
		cancelButtonProps: {
			type: Object,
			required: false,
			default: () => {},
		},
	},
	emits: ['onCancel', 'onAccept'],
};
</script>

<style scoped lang='scss'>
  .accept-cancel-wrapper {
    width: 100%;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;

    &.reverse {
      direction: rtl;

    }
  }
</style>
