<template>
  <section class="left-side-content">
    <h3 class="title">
      Drop Details
    </h3>
    <div class="two columns-row">
      <LabeledContainer
        label="Contract Address"
        for-id="contract-address"
      >
        <FormTextField
          id="contract-address"
          :disabled="notEditable"
          name="contractAddress"
          placeholder="Enter a contract address"
        />
      </LabeledContainer>

      <LabeledContainer
        label="Collection Name"
        for-id="collection-name"
      >
        <FormTextField
          id="collection-name"
          :disabled="notEditable"
          name="collectionName"
          placeholder="Enter a collection name"
        />
      </LabeledContainer>
    </div>

    <div class="two columns-row">
      <LabeledContainer label="Thumbnail">
        <FormThumbnail
          name="thumbnail"
          :disabled="notEditable"
        />
      </LabeledContainer>
      <LabeledContainer label="Banner">
        <FormBanner
          type="drop/banner"
          name="banner"
          :disabled="notEditable"
        />
      </LabeledContainer>
    </div>

    <div class="two columns-row">
      <LabeledContainer
        label="Drop Creator"
        for-id="drop-creator"
      >
        <FormTextField
          id="drop-creator"
          name="creatorName"
          :disabled="notEditable"
          placeholder="Enter a username"
        />
      </LabeledContainer>
      <LabeledContainer
        label="Drop Title"
        for-id="drop-title"
      >
        <FormTextField
          id="drop-title"
          name="title"
          :disabled="notEditable"
          placeholder="Enter a title"
        />
      </LabeledContainer>
    </div>

    <div class="two columns-row">
      <LabeledContainer
        label="Start Time (Local time)"
        for-id="start-time"
      >
        <FormDatetime
          id="start-time"
          name="startsAt"
          :disabled="notEditable"
          format="MM-dd-yyyy hh:mm a"
          placeholder="MM-DD-YYYY HH:mm a"
        />
      </LabeledContainer>
      <LabeledContainer
        label="End Time (Local time)"
        for-id="end-time"
      >
        <FormDatetime
          id="end-time"
          name="endsAt"
          :disabled="notEditable"
          format="MM-dd-yyyy hh:mm a"
          placeholder="MM-DD-YYYY HH:mm a"
        />
      </LabeledContainer>
    </div>

    <div class="columns-row">
      <LabeledContainer
        label="Drop Description"
        for-id="description"
      >
        <FormTextarea
          id="description"
          name="description"
          :disabled="notEditable"
          class="description-field"
          placeholder="Enter a description"
        />
      </LabeledContainer>
    </div>

    <div class="columns-row three">
      <LabeledContainer
        label="Access Type"
        for-id="access-type"
      >
        <FormNativeSelect
          id="access-type"
          name="access"
          :disabled="notEditable"
        >
          <option value="">
            Select one...
          </option>
          <option value="public">
            Public
          </option>
          <option value="whitelist">
            Whitelist
          </option>
          <option value="nft">
            NFT
          </option>
        </FormNativeSelect>
      </LabeledContainer>
      <LabeledContainer
        label="Issue Type"
        for-id="issue-type"
      >
        <!-- todo [hardcode]: remove hardcode 'disabled' & return correct value (notEditable) -->
        <FormNativeSelect
          id="issue-type"
          name="issue"
          :disabled="true"
        >
          <option value="">
            Select one...
          </option>
          <option value="numbered">
            Numbered
          </option>
          <option value="unnumbered">
            Unnumbered
          </option>
        </FormNativeSelect>
      </LabeledContainer>
      <LabeledContainer
        label="Max Allocation"
        for-id="max-allocation"
      >
        <FormTextField
          id="max-allocation"
          is-integer
          type="number"
          :disabled="notEditable"
          name="maxAllocation"
          placeholder="Enter a number"
        />
      </LabeledContainer>
    </div>
  </section>
</template>

<script>
import LabeledContainer from '@/components/ui/LabeledContainer';
import FormBanner from '@/components/common/form/components/FormBanner.vue';
import FormTextarea from '@/components/common/form/components/FormTextarea.vue';
import FormDatetime from '@/components/common/form/components/FormDatetime.vue';
import FormTextField from '@/components/common/form/components/FormTextField.vue';
import FormThumbnail from '@/components/common/form/components/FormThumbnail.vue';
import FormNativeSelect from '@/components/common/form/components/FormNativeSelect.vue';

export default {
	components: {
		FormBanner,
		FormTextarea,
		FormDatetime,
		FormThumbnail,
		FormTextField,
		LabeledContainer,
		FormNativeSelect,
	},
	inject: ['mainState'],
	computed: {
		notEditable () {
			return this.mainState?.notEditable?.value;
		},
	},
};
</script>

<style scoped lang='scss'>
.left-side-content {
  .title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .description-field {
    :deep(textarea) {
      min-height: 56px;
    }
  }

  .columns-row {
    width: 100%;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;

    &.two {
      grid-template-columns: repeat(2, 1fr);
    }

    &.three {
      grid-template-columns: repeat(3, 1fr);
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
</style>
