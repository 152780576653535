<template>
  <div class="meta-block">
    <IconButton
      :disabled="disabled"
      class="action"
      @click="$emit('onDelete')"
    >
      <CrossIcon />
    </IconButton>
    <slot />
  </div>
</template>

<script>
import IconButton from '@/components/ui/IconButton';
import { CrossIcon } from '@/components/icons';

export default {
	components: {
		CrossIcon,
		IconButton,
	},
	props: {
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	emits: ['onDelete'],
};
</script>

<style scoped lang='scss'>
  .meta-block {
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 12%);
    border-radius: 30px;
    background-color: $white;
    transition: box-shadow 200ms cubic-bezier(.215, .61, .355, 1);
    padding: 20px;
    position: relative;

    &:hover {
      box-shadow: 0 14px 40px 0 rgb(0 0 0 / 12%);
    }

    .action {
      position: absolute;
      top: -5px;
      right: -5px;
      width: 30px;
      height: 30px;
      background-color: $white;
      display: none;
      z-index: 1;

      &:hover {
        background-color: #ffe1e6;
        color: $darkRed;
      }
    }

    &:hover {
      .action {
        display: flex;
      }
    }
  }
</style>
