<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20pt"
    height="20pt"
    viewBox="0 0 20 20"
  >
    <path
      fill="currentColor"
      d="M 4.285156 10 L 10.4375 13.75 L 16.59375 10 L 10.4375 0 Z M 4.285156 11.25 L 10.4375 15 L 16.59375 11.25 L 10.4375 20 Z M 4.285156 11.25 "
    />
  </svg>
</template>
