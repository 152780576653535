<template>
  <div
    class="status"
    :class="{'is-light': isLight}"
  >
    <div
      v-if="access"
      class="status-item"
      :class="{'is-uppercase': access === 'nft-card'}"
    >
      <strong>{{ access }}</strong>
      <div class="status-item-type">
        Access
      </div>
    </div>
    <div
      v-if="issueType"
      class="status-item"
    >
      <strong>{{ issueType }}</strong>
      <div class="status-item-type">
        Issue Type
      </div>
    </div>
    <div
      v-if="maxAllocation"
      class="status-item"
    >
      <strong>{{ !maxAllocation ? 'Unlimited' : maxAllocation }}</strong>
      <div class="status-item-type">
        Max Allocation
      </div>
    </div>
    <div
      v-if="maxIssue"
      class="status-item"
    >
      <strong>{{ maxIssue }}</strong>
      <div class="status-item-type">
        Max Issue
      </div>
    </div>
  </div>
</template>

<script>
export default {
	props: {
		access: {
			type: String,
			required: false,
		},
		issueType: {
			type: String,
			required: false,
		},
		maxAllocation: {
			type: [Number, String],
			required: false,
		},
		maxIssue: {
			type: Number,
			required: false,
		},
		isLight: {
			type: Boolean,
			required: false,
		},
	},
};
</script>

<style scoped lang='scss'>
  .status {
    display: flex;
    margin: 0 -10px 15px;
    justify-content: center;
    flex-wrap: wrap;

    &-item {
      display: flex;
      padding: 5px 10px;

      &.is-uppercase {
        strong {
          text-transform: uppercase;
        }
      }

      strong {
        text-transform: capitalize;
      }

      &-type {
        margin-left: 5px;
        opacity: .5;
      }
    }

    &.is-light {
      strong {
        color: $white;
      }
    }
  }
</style>
