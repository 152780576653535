<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.3333 7.99996C15.3333 12.05 12.05 15.3333 7.99996 15.3333C3.94996 15.3333 0.666626 12.05 0.666626 7.99996C0.666626 3.94996 3.94996 0.666626 7.99996 0.666626C12.05 0.666626 15.3333 3.94996 15.3333 7.99996Z"
      stroke="black"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 7.33337V12"
      stroke="black"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 4.6667V4.66003"
      stroke="black"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
