export function getExactOption (options = [], key) {
	return options?.find(item => item?.value === key);
}

export function getOptionsByType (baseOptions = [], searchList = []) {
	if (searchList?.length === 0) return [];

	return baseOptions
		.filter(item => searchList.includes(item.value))
		.sort((a, b) => searchList.indexOf(a?.value) - searchList.indexOf(b?.value));
}
