<template>
  <TitledPage
    class="tokens-page"
    :title="`Default tokens (${formatNumber(total, 0)})`"
  >
    <div class="tokens-page__actions-block">
      <Filters class="actions-block__filters" />
      <Button
        class="actions-block__add-button"
        variant="outlined"
        @click="openAddModal"
      >
        + Add a token
      </Button>
    </div>
    <Table
      v-if="tokensList.length || loading"
      :data="tokensList"
      :columns="columns"
      class="tokens-slot"
      :loading="loading"
      :pagination="{ limit, offset, total }"
      @offset:change="handleOffsetChange"
    />
    <div
      v-else
      class="tokens-slot_empty"
    >
      <span class="tokens-slot__label">Specified token is not found</span>
      <Button
        class="tokens-slot__button"
        variant="outlined"
        color="white"
        @click="toDefaultTokens"
      >
        Back to all tokens
      </Button>
    </div>
  </TitledPage>
</template>

<script setup>
import { computed, onMounted, shallowRef } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { assign } from 'lodash';
import TitledPage from '@/layouts/components/TitledPage';
import Table from '@/components/ui/table/Table';
import Filters from '@/pages/defaultTokens/components/Filters';
import Button from '@/components/ui/Button';
import { columnsForTable } from '@/pages/defaultTokens/helpers';
import { formatNumber } from '@/utility';
import AddTokenModal from '@/components/common/modal/AddTokenModal.vue';

const store = useStore();

const route = useRoute();

const tokensList = computed(() => store.state.tokens.tokens);
const total = computed(() => store.state.tokens.count);
const limit = computed(() => store.state.tokens.showLength);
const offset = computed(() => store.state.tokens.offset);
const loading = computed(() => store.state.tokens.loading);
const columns = computed(() => columnsForTable);

const handleOffsetChange = (value) => {
	store.dispatch('tokens/changeOffset', value);
};

const addToken = async newAddress => {
	await store.dispatch('tokens/addToken', newAddress.address);
};

const openAddModal = () => {
	store.dispatch('modal/openModal', {
		content: shallowRef(AddTokenModal),
		props: addToken,
	});
};

const toDefaultTokens = () => {
	store.dispatch('tokens/changeSearch', '');
};

onMounted(() => store.dispatch('tokens/getTokens', assign({}, { reset: true }, route.query)));
</script>

<style scoped lang="scss">
.tokens-page {
  &__actions-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
}

.tokens-slot {
  :deep(.tokens-slot__media) {
    width: 40px;
    height: 40px;
  }

  &__label {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
  }

  &_empty {
    width: 100%;
    min-height: 230px;
    border: 1px solid $shade10;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
