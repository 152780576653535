<template>
  <section class="right-side">
    <div class="right-side-inner">
      <h3 class="title">
        Drop card preview:
      </h3>
      <DropCard
        :username="previewValues.creator"
        :title="previewValues.title"
        :image-url="previewValues.imageUrl"
        :hoverable="!!previewValues.imageUrl"
        :is-with-drop-state="false"
        :provider="mainState?.provider?.value"
        status="upcoming"
      >
        <UpcomingCountdown
          small-font
          is-with-reload
          :date-value="previewValues.startDate"
          :start-date="previewValues.startDate"
        />
      </DropCard>
      <div class="preview-action-wrapper">
        <Button
          variant="outlined"
          @click="toPreview?.()"
        >
          View full page preview
        </Button>
      </div>
    </div>
  </section>
</template>

<script>
import Button from '@/components/ui/Button';
import DropCard from '@/pages/drops/main/components/dropCard';

import { getPreviewValues } from '../helpers';
import UpcomingCountdown from '../../components/UpcomingCountdown.vue';

export default {
	components: {
		Button,
		DropCard,
		UpcomingCountdown,
	},
	inject: ['mainState', 'formState', 'toPreview'],
	computed: {
		previewValues () {
			return getPreviewValues(this.formState?.formValues);
		},
	},
};
</script>

<style scoped lang='scss'>
.right-side-inner {
  position: sticky;
  top: 100px;
  display: flex;
  flex-direction: column;

  .title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
  }

  .preview-action-wrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
