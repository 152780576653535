<template>
  <div class="edit-announcement__panel">
    <div class="edit-announcement__panel-heading">
      <h2>{{ props.title }}</h2>
      <slot name="subtitle" />
    </div>
    <div class="edit-anouncement__panel-body">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
	title: String,
});
</script>

<style lang="scss">
.edit-announcement__panel {
  width: 100%;
  padding: 16px;
  background-color: var(--color-shade80);
  border: 1px solid var(--color-shade70);
  border-radius: 10px;

  &-heading {
    @include flex(column, flex-start, flex-start);
    margin-bottom: 16px;

    h2 {
      line-height: 1.3;
    }
  }

  &-body {
    width: 100%;
  }
}
</style>
