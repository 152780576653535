<template>
  <div
    v-if="src || backendImageSrc"
    class="image-container"
  >
    <img
      v-if="['png', 'jpg', 'jpeg', 'gif', 'svg', 'webp'].includes(mediaType) || backendImageSrc"
      :src="imgSrc"
      :style="{ objectFit: fit }"
      @error="onMediaError"
    >
    <video
      v-if="mediaType === 'mp4'"
      ref="video"
      class="video"
      loop
      muted
      :autoplay="autoplay"
      playsinline
      :style="{ objectFit: fit }"
      @error="onMediaError"
    >
      <source
        :src="src"
        :type="`video/${mediaType}`"
      >
    </video>
  </div>
</template>

<script>
export default {
	props: {
		src: {
			type: String,
			required: false,
			default: '',
		},
		backendImageSrc: {
			type: String,
			required: false,
			default: '',
		},
		placeholderSrc: {
			type: String,
			required: false,
			default: '',
		},
		autoplay: {
			type: Boolean,
			required: false,
		},
		fit: {
			type: String,
			required: false,
			default: undefined,
		},
	},
	emits: ['onError'],
	data: () => ({
		showPlaceholder: false,
	}),
	computed: {
		mediaType () {
			return this.src ? this.getExtension(this.src) : null;
		},
		imgSrc () {
			return this.placeholderSrc && this.showPlaceholder ? this.placeholderSrc : this.src || this.backendImageSrc;
		},
	},
	methods: {
		getExtension (url) {
			return url?.split(/[#?]/)[0].split('.').pop().trim();
		},
		onMediaError () {
			this.showPlaceholder = true;

			this.$emit('onError');
		},
	},
};
</script>

<style scoped lang='scss'>
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 auto;
  width: 100%;
  height: 100%;
  & > * {
    width: inherit;
    height: inherit;
    vertical-align: middle;
    display: inline-block;
  }
  .video {
    width: inherit;
    height: inherit;
  }
}
</style>
