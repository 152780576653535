export default {
	getTokens: (state, payload) => {
		state.tokens = payload.tokens;
		state.count = payload.totalCount;
	},
	startLoading (state, { reset = true }) {
		if (reset) {
			state.tokens = [];
		}
		state.loading = true;
	},
	finishLoading (state) {
		state.loading = false;
	},
	setShowLength (state, payload) {
		state.showLength = payload;
	},
	setSearch (state, payload) {
		state.search = payload;
		state.offset = 0;
	},
	setOffset (state, payload) {
		state.offset = payload;
	},
	deleteToken (state, id) {
		state.tokens = state.tokens.filter(token => token.address !== id);
	},
};
