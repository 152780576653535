<template>
  <div
    :class="{'overflow-x-auto': !stickyHeader}"
    class="table-container"
  >
    <div class="table-container__inner">
      <div class="table">
        <TableHead
          v-if="!hideHeader"
          :sticky="stickyHeader"
          @checkbox-click="handleHeadCheckboxClick"
        />

        <InfiniteLoading
          :cursor="cursor"
          :height="550"
          :is-loading="infiniteLoading"
          on-container
          @infinite="handleInfinite"
        >
          <TableBody
            :cell-padding="cellPadding"
            :row-animation-name="rowAnimationName"
            :row-click="rowClick"
            :row-hover="rowHover"
            :with-different-row="withDifferentRow"
            @checkbox-click="handleCheckboxClick"
          >
            <template #loading>
              <div v-if="$slots?.skeleton">
                <slot name="skeleton" />
              </div>
              <div
                v-else
                class="table-container__loading"
              >
                <LoaderIcon size="36px" />
              </div>
            </template>
            <template #placeholder>
              <Placeholder>
                <slot name="placeholder" />
                {{ placeholderText }}
              </Placeholder>
            </template>
          </TableBody>
        </InfiniteLoading>
      </div>
    </div>
    <div
      v-if="pagination"
      class="table-container__pagination"
    >
      <Pagination @offset:change="onOffsetChanged" />
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits, provide, readonly, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import Pagination from '@/components/ui/pagination/Pagination.vue';
import { LoaderIcon } from '@/components/icons';

import TableHead from '@/components/pages/announcements_and_airdrops/announcement-table/table/components/head/Head.vue';
import TableBody from '@/components/pages/announcements_and_airdrops/announcement-table/table/components/body/Body.vue';
import Placeholder from '@/components/ui/table/components/Placeholder.vue';
import InfiniteLoading from '@/components/utility/InfiniteLoading.vue';
import { generateGrid } from '@/components/pages/announcements_and_airdrops/announcement-table/helpers';

const route = useRoute();
const router = useRouter();

const emit = defineEmits(['bulkActionsChange', 'offset:change', 'infinite']);

const props = defineProps({
	loading: Boolean,
	infiniteLoading: Boolean,
	rowHover: Boolean,
	data: { type: Array, default: () => [] },
	columns: { type: Array, default: () => [] },
	rowClick: { type: Function, default: undefined },
	placeholderText: { type: String, default: 'List is empty' },
	withDifferentRow: { type: Boolean, default: false },
	cellPadding: { type: String, default: '16px 0' },
	pagination: { type: Object, required: false, default: undefined },
	stickyHeader: { type: Boolean, required: false, default: false },
	rowAnimationName: { type: String, default: '' },
	hideHeader: { type: Boolean, required: false, default: false },
	cursor: { type: String, required: true },
});

const handleInfinite = () => {
	emit('infinite');
};

const selectedCheckboxes = ref(new Set([]));

const gridStyles = computed(() => generateGrid(props.columns));

provide('gridStyles', readonly(gridStyles));
provide('selectedCheckboxes', selectedCheckboxes);
provide('data', computed(() => props.data));
provide('columns', computed(() => props.columns));
provide('loading', computed(() => props.loading));
provide('total', computed(() => props.pagination?.total));
provide('limit', computed(() => props.pagination?.limit));
provide('offset', computed(() => props.pagination?.offset));

watch(() => props.data.length, () => {
	selectedCheckboxes.value = new Set([]);
});

watch(
	() => props.pagination?.limit,
	(value) => {
		router.replace({
			query: { ...route.query, limit: value, offset: props.pagination?.offset },
		});
	},
);

const onOffsetChanged = (offset) => {
	emit('offset:change', offset);
	router.replace({
		query: { ...route.query, limit: props.pagination?.limit, offset },
	});
};

function handleHeadCheckboxClick () {
	if (selectedCheckboxes.value.size !== props.data.length) {
		props.data.forEach(item => {
			if (!selectedCheckboxes.value.has(item?.id)) {
				selectedCheckboxes.value.add(item.id);
			}
		});
	} else {
		selectedCheckboxes.value.clear();
	}
}

function handleCheckboxClick (data) {
	if (selectedCheckboxes.value.has(data?.id)) {
		selectedCheckboxes.value.delete(data?.id);
	} else {
		selectedCheckboxes.value.add(data?.id);
	}
}

</script>

<style lang="scss" scoped>
.overflow-x-auto {
	overflow-x: auto;
}

.table-container {
	width: 100%;
	display: grid;

	@include max-screen-lg() {
		:deep(.table-row) {
			column-gap: 16px;
		}
	}

	&__loading {
		width: 100%;
		height: 100%;
		min-height: 120px;
		@include flex();
	}

	&__pagination {
		display: flex;
		justify-content: center;
		padding-top: 24px;
	}

	:deep(.table-head-row) {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		background: var(--color-shade80);
	}

  :deep(.table-head-cell) {
    padding: 0 !important;
  }

  :deep(.cell) {
    padding: 16px 0;
  }

  :deep(.table-cell) {
    padding: 0;
  }

	:deep(.table-body) {
		background: var(--color-shade80);
	}

	@include screen-lg() {
		:deep(.table-cell:not(:first-child)) {
			padding-left: 16px;
		}
	}
}
</style>
