<template>
  <svg
    fill="none"
    height="16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clip-rule="evenodd"
      d="M14.22 16H1.78C.8 16 0 15.2 0 14.22V1.78C0 .8.8 0 1.78 0h12.44C15.2 0 16 .8 16 1.78v12.44c0 .98-.8 1.78-1.78 1.78zm-2.82-2.22h2.38V8.9c0-2.06-1.17-3.06-2.8-3.06-1.64 0-2.33 1.27-2.33 1.27V6.07H6.36v7.7h2.3V9.74c0-1.08.49-1.72 1.44-1.72.88 0 1.3.62 1.3 1.72v4.05zM2.22 3.64c0 .79.63 1.43 1.41 1.43.78 0 1.41-.64 1.41-1.43 0-.78-.63-1.42-1.4-1.42-.79 0-1.42.64-1.42 1.42zm2.62 10.14H2.45v-7.7h2.39v7.7z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>
