const clickOutside = {
	beforeMount (el, binding) {
		el.clickOutsideEvent = function (event) {
			if (!(el === event.target || el.contains(event.target)) && !binding.value.exclude?.contains(event.target)) {
				binding.value.handler(event, el);
			}
		};
		document.body.addEventListener('click', el.clickOutsideEvent);
	},
	unmounted (el) {
		document.body.removeEventListener('click', el.clickOutsideEvent);
	},
};

export default clickOutside;
