<template>
  <li class="table__row">
    <FormTextField
      :value="collectionAddress"
      :disabled="isEditMode"
      :name="`splitAddresses[${collectionId}]`"
      :custom-error="splitAddressErrors?.[`${collectionId}`]"
      class="row__address"
      placeholder="Enter a collection address"
      @on:update="handleChangeAddress($event, collectionId)"
    />
    <FormTextarea
      :value="collectionRange"
      :disabled="isEditMode"
      :name="`ranges[${collectionId}]`"
      class="row__range"
      placeholder="Example 10;26;35;100-456"
    />
    <Button
      variant="outlined"
      color="red"
      size="small"
      class="row__button_remove"
      :disabled="isLastItemInArray || isEditMode"
      @click="removeElement"
    >
      Remove
    </Button>
  </li>
</template>

<script setup>
import { defineProps, defineEmits, inject } from 'vue';

import Button from '@/components/ui/Button.vue';
import FormTextarea from '@/components/common/form/components/FormTextarea';
import FormTextField from '@/components/common/form/components/FormTextField';

const props = defineProps({
	collectionAddress: {
		type: String,
		required: true,
	},
	collectionRange: {
		type: String,
		required: true,
	},
	collectionId: {
		type: Number,
		required: true,
	},
	isLastItemInArray: {
		type: Boolean,
		required: true,
	},
	isEditMode: {
		type: Boolean,
		default: false,
	},
	handleChangeAddress: {
		type: Function,
	},
});

const emit = defineEmits(['onRemoveElement']);
const { splitAddressErrors } = inject('formState');

const removeElement = () => emit('onRemoveElement', props.collectionId);
</script>

<style lang=scss scoped>
.table__row {
  display: flex;
  width: 100%;
  padding: 16px 20px;
  column-gap: 32px;

  .row__address {
    width: 100%;
    max-width: 45%;
  }

  .row__range {
    width: 100%;
    max-width: 45%;
  }

  .row__button_remove {
    width: 100%;
    max-width: 10%;
  }
}
</style>
