<template>
  <div class="item-component">
    <div class="item-component__item">
      <CheckButton
        class="item-component__button"
        :checked="isCheck"
        :is-icon="isCheck"
        @change-check="clickCheck"
      />
      <span v-if="isTitleColumn">Item</span>
      <Avatar
        v-else
        class="item-component__avatar"
        size="64px"
        :src="urlAvatar"
        is-thumbnail
      >
        <template #placeholderIcon>
          <NoEventsIcon />
        </template>
      </Avatar>
    </div>
    <div class="item-component__title">
      <span v-if="isTitleColumn">Title</span>
      <TextLink
        v-else
        :content="announcement.title"
        tag="router-link"
        is-clamp
        class="item-component__link"
        :src="urlEdit"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, defineExpose, defineProps } from 'vue';
import { useStore } from 'vuex';

import CheckButton from '@/components/ui/CheckButton.vue';
import Avatar from '@/components/ui/Avatar.vue';
import { NoEventsIcon } from '@/components/icons';
import TextLink from '@/components/ui/TextLink.vue';
import useCheck from '../helpers/useCheck';

const props = defineProps({
	isTitleColumn: Boolean,
	announcements: Array,
	announcement: Object,
	identifier: String,
});

const store = useStore();
const collection = computed(() => store.state.editCollection.collection);
const urlAvatar = computed(() => props.announcement?.thumbnail || collection.value?.avatarUrl);
const urlEdit = computed(() => `/collections/${collection.value?.id || props.identifier}/announcements/${props.announcement.sortTimestamp}`);

const listItemId = computed(() => {
	if (props.announcements && props.announcements?.length !== 0) {
		return props.announcements.map((item) => item.sortTimestamp);
	}

	return [];
});

const id = computed(() => {
	if (props.announcement) {
		return props.announcement.sortTimestamp;
	}

	return '';
});

const {
	handlerClickCheck,
	handlerClickAllCheck,
	isAllChecked,
	isChecked,
} = useCheck({ listItemId, itemId: id, listAnnouncements: props.announcements, isAll: props.isTitleColumn });

const clickCheck = () => {
	if (props.isTitleColumn) {
		handlerClickAllCheck();
		return;
	}

	handlerClickCheck();
};

const isCheck = computed(() => {
	if (props.isTitleColumn) {
		return isAllChecked.value;
	}

	return isChecked.value;
});

defineExpose({
	urlAvatar,
});
</script>

<style lang="scss" scoped>
.item-component {
	@include flex();

	&__item {
		@include flex(row, flex-start);
		padding-right: 16px;
		max-width: 112px;
		min-width: 112px;
		gap: 0 8px;
	}

	&__avatar {
		:deep(.avatar) {
			border-radius: unset;
		}
	}

	&__button {
		min-width: 24px;
		min-height: 24px;
		max-width: 24px;
		max-height: 24px;
		border-radius: 4px;
		border-color: var(--color-shade60);
		background: var(--color-shade80);
	}

	&__title {
		max-width: 240px;
		min-width: 240px;
	}

	&__link {
		:deep(.text-link-item__content__hoverable) {
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
</style>
