<template>
  <Cell
    class="table-head-cell"
    :style="style"
  >
    <slot />
  </Cell>
</template>

<script setup>
import { computed, defineProps } from 'vue';

import Cell from '../Cell.vue';

const props = defineProps({
	isHide: Boolean,
});

const style = computed(() => ({
	display: props.isHide ? 'none' : undefined,
}));
</script>

<style lang="scss">
.table-head-cell {
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
