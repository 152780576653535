<template>
  <LoadingSpinnerIcon v-if="loading" />
  <div
    v-else
    class="settings-form"
  >
    <FormLinedTextarea
      name="textareaItems"
      :placeholder="placeholder"
      color="grey"
      class="settings-form__textarea"
    />
    <Button
      :width="235"
      :disabled="isButtonDisabled || !isChanged"
      class="settings-form__button"
      @click="onSubmit"
    >
      Save Changes
    </Button>
  </div>
</template>

<script setup>
import { useForm } from 'vee-validate';
import { defineProps, defineEmits, ref, watch } from 'vue';

import Button from '@/components/ui/Button';
import { FormLinedTextarea } from '@/components/common/form';
import LoadingSpinnerIcon from '@/components/icons/LoadingSpinnerIcon';

import { initValues, getDataDifference } from '../helpers/form';

const emit = defineEmits(['submit']);

const props = defineProps({
	textareaItems: {
		type: Array,
		default: () => [],
	},
	loading: {
		type: Boolean,
		default: false,
	},
	isButtonDisabled: {
		type: Boolean,
		default: false,
	},
	placeholder: {
		type: String,
		default: '',
	},
});

const { values, handleSubmit, setFieldValue } = useForm({ initialValues: initValues(props.textareaItems) });

const isChanged = ref(false);

watch(() => props.textareaItems, () => {
	isChanged.value = false;
	setFieldValue('textareaItems', initValues(props.textareaItems).textareaItems);
});

watch(values, (updatedValues) => {
	isChanged.value = initValues(props.textareaItems).textareaItems !== updatedValues.textareaItems;
});

const onSubmit = handleSubmit(values => {
	emit('submit', getDataDifference(props.textareaItems, values.textareaItems));
});
</script>

<style lang="scss" scoped>
.settings-form {
  &__textarea {
    max-width: 1090px;
    margin-bottom: 24px;
  }

  &__button:disabled {
    opacity: 1;
    background-color: $shade60;
  }
}
</style>
