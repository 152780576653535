<template>
  <svg
    fill="none"
    height="13"
    viewbox="0 0 16 13"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4 0H1.6C0.7 0 0 0.7 0 1.6L0 11.2C0 12.1 0.7 12.8 1.6 12.8H14.4C15.3 12.8 16 12.1 16 11.2V1.6C16 0.7 15.3 0 14.4 0ZM14.4 3.2L8 7.2 1.6 3.2V1.6L8 5.6 14.4 1.6V3.2Z"
      fill="currentColor"
    />
  </svg>
</template>
