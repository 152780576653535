import requestsService from '@/services/requests.service';

const baseUrl = `${process.env.VUE_APP_ADMIN_PANEL_BASE_URL}/admin/v1/collection-containers`;

export default {
	getContainer: async (containerId) => requestsService.get(`${baseUrl}/${containerId}`),
	addContainer: async (data) => requestsService.post(`${baseUrl}`, data),
	editContainer: async (containerId, data) => requestsService.patch(`${baseUrl}/${containerId}`, data),
	deleteContainer: async (containerId) => requestsService.delete(`${baseUrl}/${containerId}`),
};
