<template>
  <ConfirmationModal
    title="Delete NFT?"
    accept-text="Delete it"
    @on-accept="onAccept"
  >
    <div class="content">
      <div class="media-container">
        <Media
          fit="contain"
          autoplay
          :src="mediaUrl"
        />
      </div>
      <div>This will remove it from the drop.</div>
    </div>
  </ConfirmationModal>
</template>

<script>
import { ConfirmationModal } from '@/components/common/modal';

import Media from '@/components/common/Media';

export default {
	components: {
		Media,
		ConfirmationModal,
	},
	computed: {
		detailsState () {
			return this.$attrs?.modalProps?.state;
		},
		item () {
			return this.$attrs?.modalProps?.item;
		},
		mediaUrl () {
			return this.item?.mediaUrl;
		},
	},
	methods: {
		onDelete () {
			const detailsState = this.detailsState;
			const isNew = detailsState.isNew;

			if (this.item) {
				const list = detailsState.formValues.nfts.filter(item => item?.id !== this.item?.id);

				detailsState.setFieldValue('nfts', list);

				if (!isNew) {
					if (!(/new/.test(this.item?.id))) {
						const foundItem = detailsState.formValues.removeNFTs.find(item => item?.id === this.item?.id);

						if (!foundItem && this.item?.id) {
							detailsState.formValues.removeNFTs.push(this.item.id);
						}
					}
				}
			}
		},
		onAccept (close) {
			close?.();

			this.onDelete();
		},
	},
};
</script>

<style scoped lang='scss'>
  .content {
    padding: 20px;
    text-align: center;

    .media-container {
      width: 160px;
      height: 160px;
      margin: 0 auto 20px auto;
    }
  }
</style>
