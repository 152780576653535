<template>
  <div
    v-if="isLoading"
    class="loader-wrapper"
  >
    <LoadingSpinnerIcon class="spinner" />
  </div>
  <slot v-else />
</template>

<script>
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';

import LoadingSpinnerIcon from '@/components/icons/LoadingSpinnerIcon';

export default {
	components: { LoadingSpinnerIcon },
	props: {
		callback: {
			type: Function,
			required: false,
		},
	},
	setup (props) {
		const isLoading = ref(true);
		const store = useStore();
		const ethereum = computed(() => store.state.ethers);

		onMounted(async () => {
			try {
				if (!ethereum.value.provider) {
					await store.dispatch('ethers/initialize', async () => {
						await props?.callback?.();
					});
				} else {
					await props?.callback?.();
				}
			} finally {
				isLoading.value = false;
			}
		});

		return { isLoading };
	},
};
</script>

<style scoped lang='scss'>
  .loader-wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner {
      width: 100px;
      height: 100px;
    }
  }
</style>
