<template>
  <div class="buttons-block">
    <Button
      variant="outlined"
      color="red"
      size="small"
      @click="openConfirmationModal"
    >
      Remove
    </Button>
  </div>
</template>

<script>
import { defineComponent, shallowRef } from 'vue';
import { useStore } from 'vuex';

import Button from '@/components/ui/Button';
import RemoveModal from '@/pages/collections/edit/components/RemoveModal';

export default defineComponent({
	name: 'TableButtons',
	components: { Button },
	props: {
		id: {
			type: String,
			required: true,
		},
		address: String,
	},
	setup (props) {
		const store = useStore();

		const openConfirmationModal = () => {
			store.dispatch('modal/openModal', {
				content: shallowRef(RemoveModal),
				props: {
					id: props.id,
					address: props.address,
				},
			});
		};

		return {
			openConfirmationModal,
		};
	},
});
</script>

<style scoped lang="scss">
.buttons-block {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>
