<template>
  <div class="image-dropzone">
    <BaseMediahubDropzone
      :name="name"
      type="drop/avatar"
      :max-file-size="maxFileBytes"
      :external-error="error"
      title="Drop your image here or click to upload"
      :accept="['image/png', 'image/jpg', 'image/jpeg', 'image/gif']"
      @on-drop="$emit('onUploadingComplete', $event)"
    />
    <HelperText class="helper-text">
      JPG, GIF or PNG only.<br>
      <template v-if="maxFileBytes && maxFileBytes !== 0">
        Max file size {{ maxFileSize }}
      </template>
    </HelperText>
  </div>
</template>

<script>
import HelperText from '@/components/ui/HelperText';

import { formatBytes } from '@/utility';

import BaseMediahubDropzone from './BaseMediahubDropzone.vue';

export default {
	components: {
		BaseMediahubDropzone,
		HelperText,
	},
	props: {
		maxFileBytes: {
			type: Number,
			required: false,
			default: () => 250 * 1024 * 1024,
		},
		name: {
			type: String,
			required: false,
		},
		error: {
			type: String,
			required: false,
		},
	},
	emits: ['onUploadingComplete'],
	computed: {
		maxFileSize () {
			return formatBytes(this.maxFileBytes);
		},
	},
};
</script>

<style scoped lang='scss'>
  .helper-text {
    margin-top: 5px;
  }
</style>
