<template>
  <div
    class="dropdown"
    :class="{
      centered,
      'default-disabled': isDisableHoverActive,
      'is-active': isActive,
      'is-disabled': disabled,
    }"
  >
    <div
      ref="toggle"
      :class="{ dropdown__toggle_opened: opened && bgShadow }"
      class="dropdown__toggle"
      @click="toggleAction"
    >
      <slot :opened="opened" />
    </div>

    <transition name="fade">
      <div
        v-if="opened && showDropdown"
        v-click-outside="{
          handler: click,
          exclude: $refs.toggle
        }"
        class="dropdown__list"
      >
        <slot name="dropdown" />
      </div>
    </transition>
  </div>
</template>

<script>
import clickOutside from '@/utility/directives/click-outside';

export default {
	directives: {
		clickOutside,
	},
	props: {
		bgShadow: {
			type: Boolean,
			default: true,
		},
		centered: {
			type: Boolean,
			default: false,
		},
		isDisableHoverActive: {
			type: Boolean,
			required: false,
			default: false,
		},
		showDropdown: {
			type: Boolean,
			default: true,
		},
		ignoreTriggerClose: {
			type: Boolean,
		},
		isActive: {
			type: Boolean,
			default: false,
			required: false,
		},
		disabled: Boolean,
	},
	emits: ['onClose'],
	data () {
		return {
			opened: false,
		};
	},
	methods: {
		toggleAction (e) {
			if (!this.disabled) {
				e.preventDefault();

				this.ignoreTriggerClose ? (this.opened = true) : (this.opened = !this.opened);
			}
		},
		click () {
			if (this.opened) {
				this.opened = false;

				this.$emit('onClose');
			}
		},
		hide () {
			this.opened = false;
		},
	},
};
</script>

<style lang="scss" scoped>
  .dropdown {
    position: relative;
    display: inline-block;

    &__toggle {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:not(.default-disabled) {
      .dropdown__toggle {
        & > :first-child {
          transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        &:hover {
          & > :first-child {
            color: $violet;
            background-color: rgba(113, 18, 255, .1) !important;
          }
        }

        &.dropdown__toggle_opened,
        &:active {
          & > :first-child {
            color: $violet;
            background-color: rgba(113, 18, 255, .2) !important;
          }
        }
      }
    }

    &__list {
      position: absolute;
      right: 0;
      border: 1px solid rgba(113, 18, 255, .2);
      border-radius: 30px;
      background-color: $white;
      top: calc(100% + 3px);
      overflow: hidden;
      z-index: 10;
    }

    &.centered {
      .dropdown__list {
        right: 50%;
        transform: translateX(50%);
      }
    }
  }
</style>
