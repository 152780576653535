import { shallowRef } from 'vue';

import store from '@/store';

import CreateEditModal from './index.vue';

export const openCreateEditNftModal = data => {
	store.dispatch('modal/openModal', {
		content: shallowRef(CreateEditModal),
		props: data,
	});
};
