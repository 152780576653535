<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.84204 1H2.26315C1.56842 1 1 1.53181 1 2.18182V10.4546H2.26315V2.18182H9.84204V1ZM11.7368 3.36363H4.78945C4.09472 3.36363 3.5263 3.89545 3.5263 4.54545V12.8181C3.5263 13.4682 4.09472 14 4.78945 14H11.7368C12.4316 14 13 13.4682 13 12.8181V4.54545C13 3.89545 12.4316 3.36363 11.7368 3.36363ZM11.7368 12.8181H4.78945V4.54545H11.7368V12.8181Z"
      fill="currentColor"
    />
  </svg>
</template>
