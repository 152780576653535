<template>
  <div
    v-if="showSeconds"
    class="base-countdown"
    :class="{'is-light': isLight}"
  >
    <div
      class="countdown-grid"
      :style="gridColumns"
    >
      <div
        v-if="showDays"
        class="time-block"
      >
        <div class="time-value">
          {{ days }}
        </div>
        <div class="time-division">
          {{ daysLabel }}
        </div>
      </div>
      <div
        v-if="showHours"
        class="time-block"
      >
        <div class="time-value">
          {{ hours }}
        </div>
        <div class="time-division">
          {{ hoursLabel }}
        </div>
      </div>
      <div
        v-if="showMinutes"
        class="time-block"
      >
        <div class="time-value">
          {{ minutes }}
        </div>
        <div class="time-division">
          {{ minutesLabel }}
        </div>
      </div>
      <div
        v-if="showSeconds"
        class="time-block"
      >
        <div class="time-value">
          {{ seconds }}
        </div>
        <div class="time-division">
          {{ secondsLabel }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useCountdown from './hooks/useCountdown';

export default {
	props: {
		startDate: {
			type: [Date, String, Number],
		},
		isLight: {
			type: Boolean,
			default: false,
			required: false,
		},
		smallFont: {
			type: Boolean,
			default: false,
			required: false,
		},
		isWithReload: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	emits: ['onEnd', 'onUpdate'],
	setup (props, { emit }) {
		const data = useCountdown(props.startDate, {
			onEnd: () => {
				emit('onEnd');
			},
			onUpdate: distance => {
				emit('onUpdate', distance);
			},
		});

		return { ...data };
	},
	computed: {
		daysLabel () {
			return this.days !== 1 ? 'Days' : 'Day';
		},
		hoursLabel () {
			return this.hours !== 1 ? 'Hours' : 'Hour';
		},
		minutesLabel () {
			return this.minutes !== 1 ? 'Minutes' : 'Minute';
		},
		secondsLabel () {
			return this.seconds !== 1 ? 'Seconds' : 'Second';
		},
		gridColumns () {
			if (this.showDays) {
				return { gridTemplateColumns: 'repeat(4, auto)' };
			} else if (this.showHours) {
				return { gridTemplateColumns: 'repeat(3, auto)' };
			} else if (this.showMinutes) {
				return { gridTemplateColumns: 'repeat(2, auto)' };
			}
			return { gridTemplateColumns: 'repeat(1, auto)' };
		},
	},
	watch: {
		startDate (value) {
			if (this.isWithReload) {
				this?.reinitialize(value);
			}
		},
	},
};
</script>

<style scoped lang='scss'>
  .base-countdown {
    color: $white;
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    border-radius: 20px;
    text-align: left;
    width: fit-content;
    margin: 0 auto;

    &.is-light {
      color: var(--text-color);
    }
  }

  .countdown-grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    grid-template-rows: auto;
  }

  .time-value {
    font-size: 30px;
    font-weight: 600;
  }

  .time-division {
    opacity: 0.5;
    font-weight: 500;
  }

  .time-block {
    text-align: center;
  }
</style>
