<template>
  <TitledPage title="Drops">
    <template #titleContent>
      <Button @click="openNewDropModal">
        + New Drop
      </Button>
    </template>
    <TabsQueryRouter
      v-slot="{activeTab}"
      :items="options"
      :is-sticky="false"
    >
      <TabsContentContainer class="tabs-content">
        <component :is="renderTabContentByType(activeTab)" />
      </TabsContentContainer>
    </TabsQueryRouter>
  </TitledPage>
</template>

<script>
import { shallowRef } from 'vue';
import { mapActions } from 'vuex';

import routes from '@/router/routes';

import { dropsService } from '@/services';

import Button from '@/components/ui/Button';
import { TabsQueryRouter, TabsContentContainer } from '@/components/common/tabs';

import TitledPage from '@/layouts/components/TitledPage';

import Live from './sections/Live.vue';
import Ended from './sections/Ended.vue';
import Trash from './sections/Trash.vue';
import Drafts from './sections/Drafts.vue';
import Upcoming from './sections/Upcoming.vue';
import ProviderSelectModal from './modals/ProviderSelectModal.vue';

export default {
	components: {
		Button,
		TitledPage,
		TabsQueryRouter,
		TabsContentContainer,
	},
	provide () {
		return {
			getCounters: this.getCounters,
		};
	},
	data () {
		return {
			routes,
			counters: null,
		};
	},
	computed: {
		options () {
			const draft = this.counters?.draft || 0;
			const live = this.counters?.live || 0;

			return [
				{ label: `Drafts (${draft})`, value: 'drafts' },
				{ label: `Live (${live})`, value: 'live' },
				{ label: 'Upcoming', value: 'upcoming' },
				{ label: 'Ended', value: 'ended' },
				{ label: 'Trash', value: 'trash' },
			];
		},
	},
	async created () {
		await this.getCounters();
	},
	methods: {
		...mapActions('modal', ['openModal']),
		openNewDropModal () {
			this.openModal({
				content: shallowRef(ProviderSelectModal),
			});
		},
		renderTabContentByType (type) {
			switch (type) {
				case this.options[0].value:
					return Drafts;
				case this.options[1].value:
					return Live;
				case this.options[2].value:
					return Upcoming;
				case this.options[3].value:
					return Ended;
				case this.options[4].value:
					return Trash;
				default:
					return null;
			}
		},
		async getCounters () {
			const res = await dropsService.getCounters();

			if (res) {
				this.counters = res?.counters || null;
			}
		},
	},
};
</script>
