<template>
  <div>
    <div class="tokens-container">
      <PaymentToken
        v-for="(token, idx) in fields"
        :key="idx"
        :token="token.value"
        @delete-token="deleteModal(idx)"
      />
    </div>
    <Button
      class="add-button"
      variant="outlined"
      @click="openAddModal"
    >
      + Add token
    </Button>
  </div>
</template>

<script>
'use strict';

import { find } from 'lodash';
import { useStore } from 'vuex';
import { shallowRef } from 'vue';
import { useFieldArray } from 'vee-validate';

import { collectionsService } from '@/services';

import Button from '@/components/ui/Button';
import DeleteModal from '@/components/common/modal/DeleteModal';
import AddTokenModal from '@/components/common/modal/AddTokenModal.vue';
import PaymentToken from '@/components/common/paymentToken/PaymentToken.vue';

export default {
	components: {
		Button,
		PaymentToken,
	},
	props: {
		name: { type: String, required: true },
	},

	setup (props) {
		const store = useStore();
		const { fields, remove, push } = useFieldArray(props.name);

		const addToken = async newAddress => {
			if (!find(
				fields.value,
				token => token.value.contractAddress.toLowerCase() === newAddress.address.toLowerCase(),
			)
			) {
				const newToken = await collectionsService.addNewToken(newAddress);
				push(newToken);
			} else {
				throw new Error('Token is already on the list');
			}
		};

		const deleteToken = (idx) => {
			remove(idx);
		};

		const deleteModal = (idx) => store.dispatch('modal/openModal', {
			content: shallowRef(DeleteModal),
			props: () => deleteToken(idx),
		});

		const openAddModal = () => {
			store.dispatch('modal/openModal', {
				content: shallowRef(AddTokenModal),
				props: addToken,
			});
		};

		return { fields, deleteModal, openAddModal };
	},
};
</script>

<style scoped lang="scss">
  .tokens-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .add-button {
    margin-top: 20px;
  }
</style>
