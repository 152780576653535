<template>
  <WithEmptyState
    :value="collectionsSearch"
    :count="collections?.length"
  >
    <template #filters="{emptyState}">
      <Actions :is-empty-state="emptyState" />
    </template>

    <Table
      :columns="defaultColumns"
      :data="collections"
      :loading="loading"
      :pagination="{ limit, offset, total }"
      class="collections-slot"
      @offset:change="offsetChanged"
    />
  </WithEmptyState>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { computed, ref, watch } from 'vue';

import Table from '@/components/ui/table/Table';
import WithEmptyState from '@/layouts/components/WithEmptyState';

import { Actions } from '../components';
import { defaultColumns } from '../main/helpers';

const store = useStore();
const route = useRoute();

const isRouterQueryWatch = ref(true);

const total = computed(() => store.state.collections.allTotal);
const limit = computed(() => store.state.collections.limit);
const offset = computed(() => store.state.collections.offset);
const loading = computed(() => store.state.collections.loading);
const collections = computed(() => store.state.collections.defaultCollections);
const collectionsSearch = computed(() => store.state.collections.search);

const offsetChanged = (offset) => {
	store.dispatch('collections/setOffset', offset);
};

watch(() => route.query, (currentQuery) => {
	if (currentQuery && isRouterQueryWatch.value) {
		store.dispatch('collections/getCollections', {
			...route.query,
			tab: route.query.tab || 'all',
			limit: route.query.limit || 100,
		});
		isRouterQueryWatch.value = false;
	}
}, { immediate: true });
</script>
