<template>
  <Filters />
  <Table
    :data="list"
    :columns="columns"
    class="banned-slot"
    :loading="loading"
    :pagination="{
      limit: showLength,
      offset,
      total: totalCount,
    }"
    @offset:change="handleOffsetChange"
  />
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { assign } from 'lodash';
import Filters from '@/pages/banned/components/Filters';
import { columnsForBanned } from '@/pages/banned/helpers';
import Table from '@/components/ui/table/Table';

const store = useStore();

const route = useRoute();

const tab = computed(() => store.state.banned.type);
const list = computed(() => store.state.banned.list);
const totalCount = computed(() => store.state.banned.totalCount);
const showLength = computed(() => store.state.banned.showLength);
const offset = computed(() => store.state.banned.offset);
const loading = computed(() => store.state.banned.loading);
const columns = computed(() => columnsForBanned[tab.value]);

const handleOffsetChange = (value) => {
	store.dispatch('banned/changeOffset', value);
};

onMounted(() => store.dispatch('banned/fetchBannedList', assign({}, { reset: true }, route.query)));
</script>

<style scoped lang="scss">
.nfts-slot {
  :deep(.nfts-slot__media) {
    width: 40px;
    height: 40px;
  }
}
</style>
