export default {
	list: [],
	type: 'nft',
	showLength: 10,
	totalCount: 0,
	sort: null,
	search: '',
	status: '',
	offset: 0,
	loading: false,
	selected: {},
	acceptLoading: false,
};
