<template>
  <div class="info-container">
    <div class="info-left">
      <div>
        <TitledText
          class="nft-titled-text"
          reverse
          :title="title"
        >
          {{ collectionName }}
        </TitledText>
      </div>
    </div>
  </div>
</template>

<script>
import TitledText from '@/components/ui/TitledText';

export default {
	components: { TitledText },
	props: {
		title: String,
		collectionName: String,
	},
};
</script>

<style scoped lang='scss'>
  .info-container {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: flex-start;
    word-break: break-word;

    .info-left {
      display: flex;
      flex-direction: column;
    }

    .nft-titled-text {
      :deep(.content) {
        opacity: .8 !important;
        font-size: 12px;
      }
    }
  }
</style>
