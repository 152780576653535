<template>
  <SelectList
    is-multiple
    :options="options"
    :model-value="modelValue"
    @update:model-value="onChange"
  >
    <template #default="{options, isActive, handleChange}">
      <div class="select-buttons-list">
        <SelectButton
          v-for="(option, index) in options"
          :key="index"
          class="select-buttons-item"
          :is-active="isActive(option)"
          @click="handleChange(option)"
        >
          <template
            v-if="!!option?.icon"
            #icon
          >
            <component :is="option.icon" />
          </template>
          {{ option.label }}
        </SelectButton>
      </div>
    </template>
  </SelectList>
</template>

<script>
import { SelectButton, SelectList } from '@/components/ui/select';

export default {
	components: { SelectButton, SelectList },
	props: {
		modelValue: {
			type: Object,
			required: true,
		},
		options: {
			type: Array,
			default: () => [],
		},
	},
	emits: ['update:modelValue'],
	methods: {
		onChange (value) {
			this.$emit('update:modelValue', value);
		},
	},
};
</script>
