export default {
	defaultCollections: [],
	customCollections: [],
	customCollectionMergeContractAddresses: [],
	mergeTypeCollectionsAddresses: [],
	currentTab: 'all',
	allTotal: 0,
	customTotal: 0,
	limit: 10,
	offset: 0,
	search: '',
	collectionStatus: '',
	sort: '',
	loading: false,
	banLoading: false,
	collection: null,
};
