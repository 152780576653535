export default {
	getProfile: (state, payload) => {
		state.profile = payload;
		state.initState = payload;
	},
	updateProfile: (state, payload) => {
		for (const field in payload.updatedValues) {
			state.profile[field] = payload.updatedValues[field];
		}
		state.initState = state.profile;
	},
	startLoading (state) {
		state.loading = true;
	},
	finishLoading (state) {
		state.loading = false;
	},
	toggleBan: (state, status) => {
		state.profile.userStatus = status;
	},
};
