<template>
  <div class="body-content">
    <slot />
  </div>
</template>

<style scoped lang='scss'>
  .body-content {
    display: grid;
    align-items: start;
    align-content: start;
    grid-auto-columns: 1fr;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
</style>
