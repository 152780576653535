const routes = {
	main: '/',
	user: '/users',
	auth: '/auth',
	nfts: '/nfts',
	collections: '/collections',
	drops: '/drops',
	reports: '/reports',
	banned: '/banned',
	defaultTokens: '/tokens',
	settings: '/settings',
};

export default routes;
