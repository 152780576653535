import { useStore } from 'vuex';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const useCheck = ({ itemId = '', listItemId = [], listAirdrops = [] }) => {
	const route = useRoute();
	const store = useStore();
	const listToTrash = computed(() => store.state.airdrops.listToTrash);
	const isAllChecked = computed(() => store.state.airdrops.isAllChecked);
	const isChecked = ref(listToTrash.value.includes(itemId.value));
	const isForcedReset = computed(() => store.state.airdrops.isForcedReset);

	const handlerClickCheck = () => {
		if (isAllChecked.value) {
			store.commit('airdrops/changeIsResetAllChecked', true);
			store.commit('airdrops/changeAllChecked', false);
		}

		isChecked.value = !isChecked.value;

		if (isChecked.value) {
			store.commit('airdrops/addItemToListTrash', itemId.value);
			const allSelected = listAirdrops.length === listToTrash.value.length;

			if (allSelected) {
				store.commit('airdrops/changeAllChecked', true);
			}

			return;
		}

		store.commit('airdrops/removeItemToListTrash', itemId.value);
	};

	const changeAllCheck = () => {
		store.commit('airdrops/changeIsResetAllChecked', false);
		store.commit('airdrops/changeAllChecked', !isAllChecked.value);

		if (isAllChecked.value) {
			store.commit('airdrops/addAllItemToListTrash', listItemId.value);
			return;
		}

		store.commit('airdrops/removeAllItemToListTrash');
	};

	const handlerClickAllCheck = () => {
		changeAllCheck();
	};

	watch(() => isAllChecked.value, () => {
		if (!isForcedReset.value) {
			isChecked.value = isAllChecked.value;
		}
	});

	watch(() => route.query.tab, () => {
		if (!isAllChecked.value) {
			isChecked.value = isAllChecked.value;
		}

		store.commit('airdrops/resetState');
	});

	onMounted(() => {
		store.commit('airdrops/changeAllChecked', false);
	});

	return {
		isAllChecked,
		isChecked,
		handlerClickCheck,
		handlerClickAllCheck,
	};
};

export default useCheck;
