<template>
  <TitledPage :title="`NFTs (${formatNumber(total, 0)})`">
    <WithEmptyState
      :value="nftsSearch"
      :count="nftsList?.length"
    >
      <template #filters="{emptyState}">
        <Filters :is-empty-state="emptyState" />
      </template>

      <Table
        :data="nftsList"
        :columns="columns"
        class="nfts-slot"
        :loading="loading"
        :pagination="{ limit, offset, total }"
        @offset:change="handleOffsetChange"
      />
    </WithEmptyState>
  </TitledPage>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import TitledPage from '@/layouts/components/TitledPage';
import WithEmptyState from '@/layouts/components/WithEmptyState';

import Table from '@/components/ui/table/Table';

import Filters from '@/pages/nfts/components/Filters';
import { columnsForTable } from '@/pages/nfts/helpers';

import { formatNumber } from '@/utility';

const store = useStore();

const nftsList = computed(() => store.state.nfts.nfts);
const total = computed(() => store.state.nfts.count);
const limit = computed(() => store.state.nfts.showLength);
const offset = computed(() => store.state.nfts.offset);
const loading = computed(() => store.state.nfts.loading);
const columns = computed(() => columnsForTable);
const nftsSearch = computed(() => store.state.nfts.search);

const handleOffsetChange = (value) => {
	store.dispatch('nfts/changeOffset', value);
};
</script>

<style scoped lang="scss">
.nfts-slot {
  :deep(.nfts-slot__media) {
    width: 40px;
    height: 40px;
  }
}
</style>
