<template>
  <IconButton
    class="close-button"
    @click="closeModal"
  >
    <CrossIcon />
  </IconButton>
</template>

<script>
import { mapActions } from 'vuex';

import IconButton from '@/components/ui/IconButton';
import { CrossIcon } from '@/components/icons';

export default {
	components: {
		CrossIcon,
		IconButton,
	},
	methods: {
		...mapActions('modal', ['closeModal']),
	},
};
</script>
