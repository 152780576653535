<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="13"
    fill="none"
    viewBox="0 0 10 13"
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M1.25 0h5L10 3.75v7.5a1.25 1.25 0 01-1.25 1.25h-7.5A1.25 1.25 0 010 11.25v-10A1.25 1.25 0 011.25 0zm4.38.94v3.44h3.43L5.62.94zM2.75 8c.14 0 .25-.11.25-.25v-.5A.25.25 0 002.75 7H2.5C1.67 7 1 7.67 1 8.5v1c0 .83.67 1.5 1.5 1.5h.25c.14 0 .25-.11.25-.25v-.5a.25.25 0 00-.25-.25H2.5a.5.5 0 01-.5-.5v-1c0-.28.22-.5.5-.5h.25zM4 11h.38c.73 0 1.33-.54 1.33-1.2 0-.34-.15-.66-.42-.89l-.68-.58c-.05-.04-.07-.08-.07-.12 0-.1.14-.21.33-.21h.38c.14 0 .25-.11.25-.25v-.5A.25.25 0 005.25 7h-.38c-.73 0-1.33.54-1.33 1.2 0 .34.15.66.42.89l.68.58c.05.04.07.08.07.12 0 .1-.14.21-.33.21H4a.25.25 0 00-.25.25v.5c0 .14.11.25.25.25zm3-3.75v.65c0 .63.18 1.26.5 1.78.32-.52.5-1.15.5-1.78v-.65c0-.14.11-.25.25-.25h.5c.14 0 .25.11.25.25v.65c0 1.1-.4 2.15-1.13 2.94a.5.5 0 01-.74 0A4.3 4.3 0 016 7.9v-.65c0-.14.11-.25.25-.25h.5c.14 0 .25.11.25.25z"
      clip-rule="evenodd"
    />
  </svg>
</template>
