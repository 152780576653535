<template>
  <ModalContentContainer class="edit-link-modal">
    <ModalHeader class="edit-link-modal__header">
      {{ heading }}
      <!-- <CloseModalButton class="edit-link-modal__header-close-button" /> -->
    </ModalHeader>
    <ModalBody class="edit-link-modal__body">
      <div class="edit-link-modal__body-field">
        <div class="edit-link-modal__body-field-label text-body-16 text-semibold">
          Text
        </div>
        <FormTextField name="text" />
      </div>
      <div class="edit-link-modal__body-field">
        <div class="edit-link-modal__body-field-label">
          Link
        </div>
        <FormTextField name="link" />
      </div>
      <div class="edit-link-modal__body-actions">
        <Button
          class="edit-link-modal__body-actions-cancel"
          is-secondary
          @click.stop="handleCancel"
        >
          Cancel
        </Button>
        <Button
          class="edit-link-modal__body-actions-save"
          @click.stop="handleSubmit"
        >
          SAVE
        </Button>
      </div>
    </ModalBody>
  </ModalContentContainer>
</template>

<script setup>
import { computed, useAttrs } from 'vue';
import { useForm } from 'vee-validate';
import { useStore } from 'vuex';

import {
	ModalContentContainer,
	ModalHeader,
	ModalBody,
} from '@/components/common/modal/index';
import FormTextField from '@/components/common/form/components/FormTextField.vue';
import Button from '@/components/ui/Button.vue';

import { object, requiredString, url } from '@/components/common/form/formValidation';

const attrs = useAttrs();

const store = useStore();

const modalProps = attrs?.modalProps;
const edit = computed(() => modalProps?.value?.text && modalProps?.value?.link);
const onSave = attrs?.modalProps?.onSave;

const heading = computed(() => `${edit.value ? 'Edit' : 'Add'} link`);

const form = useForm({
	initialValues: {
		text: modalProps?.value?.text || '',
		link: modalProps?.value?.link || '',
	},
	validationSchema: object({
		text: requiredString(),
		link: url.concat(requiredString()),
	}),
});

async function close () {
	await store.dispatch('modal/closeModal', 'quill-editor-link');
}

const handleSubmit = form.handleSubmit(async (values) => {
	if (onSave) onSave(values);
	await close();
});

async function handleCancel () {
	await close();
}
</script>

<style lang="scss">
.edit-link-modal {
  min-width: 456px;

  &__header {
    @include flex();
    position: relative;

    &-close-button {
      position: absolute;
      top: 12px;
      right: 16px;
      border-radius: 50% !important;
    }
  }

  &__body {
    &.body {
      padding: 0;
    }

    &-field {
      padding: 16px;
      border-bottom: 1px solid var(--color-shade70);

      &-label {
        margin-bottom: 8px;
      }
    }

    &-actions {
      padding: 16px;
      @include flex();
      column-gap: 16px;

      &-save, &-cancel {
        height: 48px;
        width: 120px;
        font-weight: 600;
      }
    }
  }
}

</style>
