<template>
  <div class="price-wrapper">
    <div class="nft-available">
      {{ getRemaining }} / {{ amountForSale || 0 }} {{ isUpcoming ? 'available' : 'left' }}
    </div>
    <div class="price-row">
      <div class="price-row-inner">
        <div class="price-title">
          Price
        </div>
        <Price
          :value="price?.value"
          :currency="price?.currency"
          :decimals="0"
        />
      </div>
      <template v-if="isLive">
        <Tag
          v-if="!isEligible"
          color="grey"
          is-uppercase
        >
          Not eligible
        </Tag>
        <template v-else>
          <Tag
            v-if="getRemaining <= 0"
            is-uppercase
            color="red"
          >
            Sold out
          </Tag>
        </template>
      </template>

      <Tag v-if="isUpcoming">
        Available {{ upcomingTime }}
      </Tag>
      <Tag
        v-if="isEnded"
        is-uppercase
      >
        Ended
      </Tag>
    </div>
  </div>
</template>

<script>
import Tag from '@/components/ui/Tag';
import Price from '@/components/common/price';

import { formatRelative } from '@/utility';

export default {
	components: {
		Tag,
		Price,
	},
	props: {
		sold: Number,
		price: Object,
		status: String,
		startsAt: [String, Number, Date],
		isEligible: {
			type: Boolean,
			required: false,
			default: true,
		},
		amountForSale: Number,
	},
	computed: {
		upcomingTime () {
			return formatRelative(this.startsAt, true);
		},
		isUpcoming () {
			return this.status === 'upcoming';
		},
		isLive () {
			return this.status === 'live';
		},
		isEnded () {
			return this.status === 'ended';
		},
		getRemaining () {
			const soldValue = this.sold || 0;
			const totalValue = this.amountForSale || 0;

			return totalValue - soldValue;
		},
	},
};
</script>

<style scoped lang='scss'>
  .price-wrapper {
    word-break: break-word;

    .nft-available {
      margin-bottom: 5px;
    }

    .price-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .price-row-inner {
        flex: 1;
        margin-right: 16px;
      }

      .price-title {
        opacity: 0.8;
        font-size: 12px;
      }
    }
  }
</style>
