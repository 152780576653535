<template>
  <div class="report-actions-block">
    <div class="report-actions-block__search">
      <SearchField
        :model-value="search"
        placeholder="Search reports"
        to-query-param
        @enter:model-value="onSearchChanged"
      />
    </div>
    <div class="report-actions-block__filters">
      <LabeledContainer
        class="report-actions-block__filter"
        label="Show"
        for-id="show"
      >
        <Select
          :options="showOptions"
          :model-value="getShowOptionByValue(showLength)"
          name="show"
          @update:model-value="changeLength"
        >
          <template #icon>
            <ChevronDownIcon />
          </template>
        </Select>
      </LabeledContainer>

      <LabeledContainer
        class="report-actions-block__filter"
        label="Status"
        for-id="status"
      >
        <Select
          :options="statusOptions"
          :model-value="getStatusOptionByValue(status)"
          class="report-actions-block__filter_status"
          name="status"
          @update:model-value="updateStatus"
        >
          <template #icon>
            <ChevronDownIcon />
          </template>
        </Select>
      </LabeledContainer>

      <Select
        :options="bulkActions"
        label="Bulk Actions"
        class="report-actions-block__action"
        @update:model-value="executeBulkAction"
      >
        <template #icon>
          <ChevronDownIcon />
        </template>
      </Select>
    </div>
  </div>
</template>

<script>
import { shallowRef } from 'vue';
import { mapActions, mapState } from 'vuex';
import { find } from 'lodash';
import LabeledContainer from '@/components/ui/LabeledContainer';
import { ChevronDownIcon } from '@/components/icons';
import { Select } from '@/components/ui/select';
import { SearchField } from '@/components/ui/text-fields';
import AcceptModal from './AcceptModal.vue';
import { acceptConstants } from '../helpers';

export default {
	components: {
		LabeledContainer,
		ChevronDownIcon,
		SearchField,
		Select,
	},
	data () {
		return {
			showOptions: [
				{ label: '10', value: 10 },
				{ label: '25', value: 25 },
				{ label: '50', value: 50 },
				{ label: '100', value: 100 },
			],
			statusOptions: [
				{ label: 'All', value: '' },
				{ label: 'Pending', value: 'pending' },
				{ label: 'Accepted', value: 'accepted' },
				{ label: 'Declined', value: 'declined' },
			],
			bulkActions: [
				{ label: 'Accept Multiple', value: 'accept' },
				{ label: 'Decline Multiple', value: 'decline' },
				// { label: 'Revert', value: 'revert' },
			],
		};
	},
	computed: {
		...mapState({
			showLength: state => state.reports.showLength,
			selected: state => state.reports.selected,
			search: state => state.reports.search,
			status: state => state.reports.status,
			reports: state => state.reports.list,
			type: state => state.reports.type,
		}),
		getShowOptionByValue () {
			return value => find(this.showOptions, { value: parseInt(value, 10) });
		},
		getStatusOptionByValue () {
			return value => find(this.statusOptions, { value });
		},
	},
	methods: {
		...mapActions('reports', ['changeShowLength', 'changeSearch', 'changeStatus', 'declineReports', 'revertReports', 'clearSelect']),
		...mapActions('modal', ['openModal']),
		changeLength (option) {
			this.changeShowLength(option.value);
		},
		updateStatus (option) {
			this.changeStatus(option.value);
		},
		executeBulkAction (option) {
			const pendingIds = Object.keys(this.selected).filter(id => this.reports.find(report => report.id === id)?.status === 'pending');
			const revertIds = Object.keys(this.selected).filter(id => this.reports.find(report => report.id === id)?.status !== 'pending');
			switch (option.value) {
				case 'accept':
					if (pendingIds.length) {
						this.openModal({
							content: shallowRef(AcceptModal),
							props: {
								ids: pendingIds,
								title: acceptConstants.plural.title.replace('{length}', pendingIds.length),
								message: acceptConstants.plural.message.replace('{length}', pendingIds.length).replace('{type}', this.type),
								callback: this.clearSelect,
							},
						});
					}
					break;
				case 'decline':
					this.declineReports(pendingIds);
					this.clearSelect();
					break;
				case 'revert':
					this.revertReports(revertIds);
					this.clearSelect();
					break;
			}
		},
		onSearchChanged (search) {
			this.changeSearch(search);
		},
	},
};
</script>

<style scoped lang="scss">
.report-actions-block {
  display: flex;
  justify-content: flex-start;

  &__filters {
    display: flex;
    margin-bottom: 24px;
  }

  &__filter {
    display: flex;
    align-items: center;
    min-width: 115px;
    margin-right: 20px;

    &_status:deep(.dropdown__list) {
      width: 115px;
    }

    &:deep(.label) {
      margin: 0;
      padding-right: 4px;
    }
  }

  &__action {
    width: 150px;
  }

  &__search {
    width: 415px;
    margin-right: 20px;
  }
}
</style>
