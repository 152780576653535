import { ethers } from 'ethers';

import { collectionsService } from '@/services';
import { detectChanges } from '@/utility';
import { addSuccess } from '@/components/utility/notifications';

export default {
	async getCollection ({ commit }, { id }) {
		commit('startLoading');

		try {
			const collection = await collectionsService.getCollection(id);
			try {
				collection.mintPrice.value = ethers.utils.formatEther(collection.mintPrice.value);
			} catch (e) {
				// noop
			}
			commit('getCollection', collection);
			const collaborators = await collectionsService.getCollaborators(id);
			commit('getCollaborators', collaborators);
		} finally {
			commit('finishLoading');
		}
	},
	async updateCollection ({ state, commit }, payload) {
		const params = detectChanges(state.initState, payload.updatedValues);

		if (params.royalties?.length === 1 && params.royalties[0].percentage === '' && params.royalties[0].walletAddress === '') {
			params.royalties = [];
		}

		try {
			params.mintPrice.value = ethers.utils.parseEther(params.mintPrice.value).toString();
		} catch (e) {
			// noop
		}
		await collectionsService.editCollection(payload.id, params);
		addSuccess('Changes successfully saved');
		await commit('updateCollection', payload);
	},
	async toggleBan ({ commit, state }) {
		if (state.collection.collectionStatus === 'banned') await collectionsService.unbanCollection(state.collection.id);

		else await collectionsService.banCollection(state.collection.id);

		commit('toggleBan');
	},
	async removeCollaborator ({ state, commit }, { userId, signature, signatureTime }) {
		try {
			const collectionId = state.collection?.id;
			await collectionsService.removeCollaborator(collectionId, userId, { signature, signatureTime });
			commit('removeCollaborator', userId);
		} catch {
			return false;
		}
	},
	async addCollaborator ({ state, commit }, { walletAddress, signature, signatureTime }) {
		try {
			const collectionId = state.collection?.id;
			await collectionsService.addCollaborator(collectionId, { walletAddress, signature, signatureTime });

			const response = await collectionsService.getCollaborators(collectionId);
			commit('getCollaborators', response);
		} catch {
			return false;
		}
	},
};
