<template>
  <div class="form-thumbnail">
    <CollectionUserDropzone
      v-if="!inputValue"
      :name="name"
      :type="type"
      :error="errorMsg"
      :max-file-bytes="maxFileBytes"
      :recommended-text="recommendedText"
      :address="address"
      :class="classes"
      @on-uploading-complete="onUploadingComplete"
    />
    <MediaPreview
      v-else
      class="media-preview-wrapper"
      :class="classes"
      :src="inputValue"
      :disabled="disabled"
      :helper-text="helperText"
      @on-delete="onDelete"
    />
  </div>
</template>

<script>
import { useField } from 'vee-validate';
import MediaPreview from '@/components/common/files/MediaPreview.vue';
import CollectionUserDropzone from '@/components/common/files/dropZone/CollectionUserDropzone.vue';

export default {
	components: {
		MediaPreview,
		CollectionUserDropzone,
	},
	props: {
		name: {
			type: String,
			required: true,
		},
		value: {
			type: String,
			required: false,
			default: '',
		},
		type: {
			type: String,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		helperText: {
			type: String,
		},
		maxFileBytes: {
			type: Number,
			required: false,
			default: () => 250 * 1024 * 1024,
		},
		recommendedText: {
			type: String,
			required: false,
		},
		address: {
			type: String,
			required: false,
		},
	},
	setup (props) {
		const {
			value: inputValue,
			errorMessage,
			handleChange,
		} = useField(props?.name || '', undefined, {
			initialValue: props?.value,
		});

		return {
			inputValue,
			handleChange,
			errorMessage,
		};
	},
	computed: {
		errorMsg () {
			return this?.errorMessage || '';
		},
		isHasErrorMessage () {
			return !!this.errorMsg;
		},
		classes () {
			if (!this.type) {
				return '';
			}
			const path = this.type.split('/');
			return path[path.length - 1];
		},
	},
	methods: {
		onUploadingComplete (file) {
			this.handleChange(file?.url || '');
		},
		onDelete () {
			this.handleChange('');
		},
	},
};
</script>

<style scoped lang='scss'>
  .media-preview-wrapper {
    :deep(.media-preview) {
      width: fit-content;
      height: 110px;
    }
  }

  .avatar {
    :deep(.media-preview) {
      height: 140px;

      .action {
        display: flex;
        background-color: $red;
        color: $white;
        top: -15px;
        right: -10px;
      }
    }

    :deep(.media) {
      width: 140px;
      height: 140px;
      overflow: hidden;
      border-radius: 100%;
    }

    :deep(img) {
      object-fit: cover
    }
  }

  .banner {
    :deep(.media-preview) {
      height: 140px;

      .action {
        display: flex;
        background-color: $red;
        color: $white;
        top: -15px;
        right: -10px;
      }
    }

    :deep(.media) {
      overflow: hidden;
      height: 140px;
      border-radius: 8px;
    }
  }
</style>
