<template>
  <h2
    v-if="value"
    class="modal-title"
  >
    {{ value }}
  </h2>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			required: true,
		},
	},
};
</script>

<style scoped lang='scss'>
  .modal-title {
    font-size: 20px;
    font-weight: 700;
    word-break: break-word;
  }
</style>
