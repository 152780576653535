<template>
  <svg
    width="13"
    height="16"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 0C2.16938 3.64 0 6.784 0 9.44C0 13.424 3.0875 16 6.5 16C9.9125 16 13 13.424 13 9.44C13 6.784 10.8306 3.64 6.5 0ZM3.11187 9.6C3.4125 9.6 3.65625 9.808 3.71312 10.096C4.04625 11.872 5.56563 12.48 6.67063 12.392C7.02 12.376 7.3125 12.648 7.3125 12.992C7.3125 13.312 7.0525 13.576 6.7275 13.592C4.99687 13.696 2.97375 12.72 2.51063 10.296C2.49651 10.2102 2.50147 10.1225 2.52519 10.0388C2.5489 9.95507 2.5908 9.87745 2.64797 9.81126C2.70515 9.74507 2.77625 9.6919 2.85634 9.65543C2.93644 9.61895 3.02363 9.60004 3.11187 9.6V9.6Z"
      fill="currentColor"
    />
  </svg>
</template>
