import { shallowRef } from 'vue';

import store from '@/store';

import DeletionModal from './index.vue';

export const openDeleteNftModal = data => {
	store.dispatch('modal/openModal', {
		content: shallowRef(DeletionModal),
		props: data,
		disableOverlayClick: true,
	});
};
