import requestsService from './requests.service';

const baseUrl = `${process.env.VUE_APP_ADMIN_PANEL_BASE_URL}/admin/v1/collections`;

export default {
	getCollections: async ({ limit, offset, search, sort, collectionStatus }) => {
		const params = { limit, offset };
		if (search) params.search = search;
		if (sort) params.sort = sort;
		if (collectionStatus) params.collectionStatus = collectionStatus;

		return requestsService.get(baseUrl, { params });
	},
	getCollection: async (id) => requestsService.get(`${baseUrl}/${id}`),
	getCollaborators: async (id) => requestsService.get(`${baseUrl}/${id}/collaborators`),
	removeCollaborator: async (collectionId, userId, { signature, signatureTime }) => requestsService
		.post(`${baseUrl}/${collectionId}/collaborators/${userId}`, { signature, signatureTime }),
	addCollaborator: async (collectionId, { walletAddress, signature, signatureTime }) => requestsService
		.post(`${baseUrl}/${collectionId}/collaborators`, { walletAddress, signature, signatureTime }),
	editCollection: async (id, payload) => requestsService.patch(`${baseUrl}/${id}`, payload),
	banCollection: async (id) => requestsService.post(`${baseUrl}/${id}/ban`),
	unbanCollection: async (id) => requestsService.delete(`${baseUrl}/${id}/ban`),
	addNewToken: async (address) => requestsService.post(`${baseUrl}/tokens`, address),
	refreshMetadataFromOpenSea: async (addresses, overwrite = false) => requestsService
		.post(`${baseUrl}/import-opensea-metadata`, { addresses, overwrite }, undefined, true),
	refreshNFTsMetadata: async id => requestsService
		.post(`${baseUrl}/${id}/refresh`, undefined, undefined, true),
	getContext: async data => requestsService.get(`${baseUrl}/context`, data),
};
