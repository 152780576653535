<template>
  <button
    :class="{
      'select-button': true,
      'active': isActive,
      'left': iconPosition === 'left',
      'right': iconPosition === 'right',
      'with-icon': hasIcon,
      'hoverable': hoverable,
      'error': hasError
    }"
  >
    <div
      v-if="hasIcon"
      class="icon-wrapper"
    >
      <slot name="icon" />
    </div>
    <div
      v-if="hasContent"
      class="content-wrapper"
    >
      <slot />
    </div>
  </button>
</template>

<script>
export default {
	props: {
		isActive: {
			type: Boolean,
			required: false,
		},
		hoverable: {
			type: Boolean,
			default: false,
			required: false,
		},
		iconPosition: {
			type: String,
			default: 'left',
			required: false,
		},
		hasError: {
			type: Boolean,
			required: false,
		},
	},
	computed: {
		hasIcon () {
			return !!this.$slots.icon;
		},
		hasContent () {
			return !!this.$slots.default;
		},
	},
};
</script>

<style scoped lang='scss'>
  .select-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    height: 40px;
    outline: none;
    border: none;
    box-shadow: 0 0 0 1px rgba(113, 18, 255, .2);
    text-align: center;
    transition: background-color 200ms cubic-bezier(.215, .61, .355, 1);
    border-radius: 24px;
    background-color: $white;

    .content-wrapper {
      width: 100%;
    }

    &.with-icon {
      .content-wrapper {
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.left {
      .icon-wrapper {
        margin-right: 10px;
      }
    }

    &.right {
      flex-direction: row-reverse;

      .icon-wrapper {
        margin-right: 0;
        margin-left: 10px;
      }
    }

    &.active {
      cursor: default;
      color: $white;
      background-color: $grey;
    }

    &:not(.active) {
      cursor: pointer;

      &.hoverable:hover {
        color: $violet;
        background-color: rgba(113, 18, 255, .1);
      }
    }

    &.error {
      box-shadow: 0 0 0 1px rgba(241, 40, 72, .20);
    }
  }
</style>
