'use strict';

import { find } from 'lodash';
import { collectionsService } from '@/services';
import { storeLoadingWrapper } from '@/utility';
import { getCollectionsContext } from '@/utility/getItemsContext.js';
import { fetchCollectionContainers } from '@/services/collections/collection-containers.js';
import { customCollectionFormatter } from '@/services/collections/helpers/customCollectionFormatter';

/**
 * @param {object} collection
 * @returns {object}
 */
function convertCollection (collection) {
	return {
		...collection,
		context: { loading: true, available: false },
	};
}

export default {
	getCollections: async ({ commit, getters }, { limit, offset, search, sort, collectionStatus, tab }) => {
		if (limit) commit('setLimit', parseInt(limit, 10));
		if (offset) commit('setOffset', parseInt(offset, 10));
		if (search) commit('setSearch', search);
		if (sort) commit('setSort', sort);
		if (collectionStatus) commit('setCollectionStatus', collectionStatus);
		if (tab) commit('setCurrentTab', tab);

		await storeLoadingWrapper(
			async () => {
				const actualTab = tab || getters.getCollectionsArgs.tab;

				const response = actualTab === 'all'
					? await collectionsService.getCollections(getters.getCollectionsArgs)
					: await fetchCollectionContainers({ isMock: false, limit });

				switch (actualTab) {
					case 'all':
						return commit('setDefaultCollections', response);
					case 'custom':
						return commit('setCustomCollections', { containers: customCollectionFormatter({ collections: response.containers }) });
					default:
						throw new Error('"Collections" table tab is not selected');
				}
			},
			commit,
			'setLoading',
		);
	},
	banCollection: async ({ state }, id) => {
		await collectionsService.banCollection(id);
		find(state.defaultCollections, { id }).collectionStatus = 'banned';
	},
	unbanCollection: async ({ state }, id) => {
		await collectionsService.unbanCollection(id);
		find(state.defaultCollections, { id }).collectionStatus = 'active';
	},
	setLimit: async ({ commit, dispatch }, limit) => {
		commit('setLimit', limit);
		dispatch('getCollections', {});
	},
	setOffset: async ({ commit, dispatch }, offset) => {
		commit('setOffset', offset);
		dispatch('getCollections', {});
	},
	setSearch: async ({ commit, dispatch }, search) => {
		commit('setSearch', search);
		commit('setOffset', 0);
		dispatch('getCollections', {});
	},
	resetPage: ({ commit }) => {
		commit('setSearch', '');
		commit('setOffset', 0);
		commit('setDefaultCollections', { collections: [], totalCount: 0 });
		commit('setCustomCollections', { collections: [], totalCount: 0 });
	},
	getCollection: async ({ dispatch }, { id }) => {
		await dispatch('fetchCollection', id);
	},
	async fetchCollection ({ commit }, id) {
		const collection = await collectionsService.getCollection(id);

		if (collection) {
			commit('setCollection', convertCollection(collection));
		}
	},
	async fetchContext ({ state, commit }, withLoader = true) {
		if (withLoader) {
			commit('updateContext', { loading: true });
		}

		try {
			const [response] = getCollectionsContext([state.collection.id]);
			const { context } = await response();

			commit('updateContext', { available: true, context: context[state.collection.id] });
		} catch {
			commit('updateContext', { available: false });
		}
	},
	addContainerContractAddressForMerge ({ commit }, data) {
		commit('addContainerContractAddressForMerge', data);
	},
	removeContainerContractAddressForMerge ({ commit }, id) {
		commit('removeContainerContractAddressForMerge', id);
	},
	clearContainerContractAddressForMerge ({ commit }) {
		commit('clearContainerContractAddressForMerge');
	},
	setMergeCollections ({ commit }, data) {
		commit('setMergeCollections', data);
	},
};
