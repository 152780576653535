<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.4 0H4.8C3.9176 0 3.2 0.7176 3.2 1.6V11.2C3.2 12.0824 3.9176 12.8 4.8 12.8H14.4C15.2824 12.8 16 12.0824 16 11.2V1.6C16 0.7176 15.2824 0 14.4 0ZM4.8 11.2V1.6H14.4L14.4016 11.2H4.8ZM1.6 4.8H0V14.4C0 15.2824 0.7176 16 1.6 16H11.2V14.4H1.6V4.8ZM7.2 7.2L8 8L10.4 4.8L13.6 9.6H5.6L7.2 7.2Z"
      fill="currentColor"
    />
  </svg>
</template>
