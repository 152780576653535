<template>
  <svg
    width="16"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13 11"
  >
    <path
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M11 2L5.5 8.5 2 5"
    />
  </svg>
</template>
