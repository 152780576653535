import { formatAddress } from '@/utility';
import CopyButton from '@/components/ui/CopyButton.vue';
import { CollectionCell, TableActions } from '@/pages/collections/components';

export const containerCollectionTableColumns = [
	{
		name: 'Collection',
		value: (data) => <CollectionCell collection={data} show-address={true} />,
	},
	{
		name: 'Contract Address',
		value: (data) => <CopyButton value={data?.contractAddress} label={formatAddress(data?.contractAddress || '')} />,
	},
	{
		name: '',
		value: (data) => <TableActions collection={data} hideShareButton={false} hideRemoveButton={true} disableRemoveButton={true} justifyContent="flex-end" />,
	},
];

export const containerRenderTypes = [
	{ value: 'merge' },
	{ value: 'split' },
	{ value: 'draft' },
];
