import {
	requiredString,
	minMaxString,
	requiredNumber,
	decimal,
	maxString,
	trim,
	positiveNumber,
	integerNumber,
	leadingZeros,
	maxNumber,
} from '@/components/common/form';
import { genUniqueKey } from '@/utility';

export const generateId = () => `new${genUniqueKey()}`;

export const baseValidation = {
	name: minMaxString(1, 100).concat(requiredString()).concat(trim),
	description: maxString(500).concat(trim),
	mediaUrl: requiredString(),
	previewUrl: requiredString(),
	amountForSale: requiredNumber()
		.concat(positiveNumber)
		.concat(integerNumber)
		.concat(leadingZeros(2))
		.test('more-than-maxQuantity', 'Amount for sale can\'t be more Max issue', (value, ctx) => {
			const maxQuantity = ctx?.parent?.maxQuantity;

			if (value && maxQuantity) {
				return value <= maxQuantity;
			}

			return true;
		}),
	maxQuantity: requiredNumber()
		.concat(positiveNumber)
		.concat(integerNumber)
		.concat(leadingZeros(2))
		.test('less-than-amountForSale', 'Max issue can\'t be less Amount for sale', (value, ctx) => {
			const amountForSale = ctx?.parent?.amountForSale;

			if (value && amountForSale) {
				return value >= amountForSale;
			}

			return true;
		}),
	maxAllocation: positiveNumber
		.concat(integerNumber)
		.concat(leadingZeros(1))
		.concat(maxNumber(99999))
		.test('less-than-amountForSale', 'Max allocation can\'t be more Amount for sale', (value, ctx) => {
			const amountForSale = ctx?.parent?.amountForSale;
			if (value && amountForSale) {
				return value <= amountForSale;
			}
			return true;
		}),
	price: requiredString().concat(decimal.concat(maxString(30))),
	// price: Yup.string().when('acceptEth', {
	//     is: value => value === true,
	//     then: requiredString().concat(decimal.concat(maxString(30)).concat(leadingZerosString(1)))
	// })

};
