import Status from '@/components/ui/Status';
import TableActions from '@/pages/nfts/components/TableActions';
import VerificationLabel from '@/components/ui/VerificationLabel';
import { formatDate } from '@/utility';
import PreviewLink from '@/components/ui/PreviewLink';

const baseMarketplaceUrl = process.env.VUE_APP_WEBSITE_BASE_URL;

export const columnsForTable = [
	{
		name: 'Date',
		value: (data) => formatDate(data?.createdAt, 'EEE LLL d, p'),
	},
	{
		name: 'Item',
		value: (data) => <PreviewLink
			preview={data?.previewUrl}
			label={data?.name || data?.id}
			withStringFormatting={!data?.name}
			transitionTo={`${baseMarketplaceUrl}/collections/${data.collection?.contractAddress || data.collection?.slug}/${data.tokenId}`}
		/>,
	},
	{
		name: 'Collection name',
		value: (data) => <VerificationLabel name={data?.collection?.name} isVerified={data?.collection?.verified} />,
	},
	{
		name: 'Token ID',
		value: (data) => data?.tokenId,
	},
	{
		name: 'Status',
		value: (data) => <Status type={data?.nftStatus === 'banned' ? 'banned' : 'active'}>{data?.nftStatus === 'banned' ? 'Banned' : 'Active'}</Status>,
	},
	{
		name: 'Actions',
		value: (data) => <TableActions isBanned={data?.nftStatus === 'banned'} id={data?.id} tokenId={data?.tokenId} collection={data?.collection} />,
		width: '216px',
	},
];
