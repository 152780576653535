<template>
  <div class="select-content__draft">
    <DraftCollectionsAnnotation />
    <FormTextField
      label="Contract address"
      name="draftAddress"
      placeholder="Enter a contract address"
      :custom-error="draftError"
      @on:update="onChangeDraftAddress()"
    />
  </div>
</template>

<script setup>
import { inject } from 'vue';

import FormTextField from '@/components/common/form/components/FormTextField.vue';
import DraftCollectionsAnnotation
	from '@/pages/collections/add-container/sections/components/DraftCollections/DraftCollectionsAnnotation.vue';

defineProps({
	onChangeDraftAddress: {
		type: Function,
	},
});

const { draftError } = inject('formState');
</script>

<style lang=scss scoped>
.select-content__draft {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}
</style>
