import { markRaw } from 'vue';

import Item from '../components/Item.vue';
import TimeStatus from '@/components/pages/announcements_and_airdrops/announcement-table/components/TimeStatus.vue';
import { tableAnnouncementBodyFormatter } from '@/components/ui/table/helpers/components';

const desktopConfig = {
	item: { width: { min: '352px', max: '352px' } },
	body: { width: { min: '320px', max: '600px' } },
	start: { width: { min: '156px', max: '156px' } },
	end: { width: { min: '156px', max: '156px' } },
};

const tabletConfig = {
	item: { width: { min: '352px', max: desktopConfig.item.width.min } },
	body: { width: { min: '320px', max: desktopConfig.body.width.min } },
	start: { width: { min: '156px', max: '156px' } },
	end: { width: { min: '156px', max: '156px' } },
};

function columns (isTablet = false, airdrops, identifier) {
	return [
		{
			name: () => ({
				props: {
					isTitleColumn: true,
					airdrops,
				},
				component: markRaw(Item),
			}),
			width: isTablet ? tabletConfig.item.width : desktopConfig.item.width,
			value: (data) => ({
				component: markRaw(Item),
				props: {
					airdrop: data,
					airdrops,
					identifier,
				},
			}),
		},
		{
			name: 'Claim Requirements',
			width: isTablet ? tabletConfig.body.width : desktopConfig.body.width,
			value: (data) => tableAnnouncementBodyFormatter({ content: data.body }),
		},
		{
			name: 'Start Date',
			width: isTablet.value ? tabletConfig.start.width : desktopConfig.start.width,
			value: (data) => ({
				props: {
					date: new Date(data.startsAt),
				},
				component: markRaw(TimeStatus),
			}),
		},
		{
			name: 'End Date',
			width: isTablet.value ? tabletConfig.start.width : desktopConfig.start.width,
			value: (data) => ({
				props: {
					date: new Date(data.endsAt),
				},
				component: markRaw(TimeStatus),
			}),
		},
	];
}

export default columns;
