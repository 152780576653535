<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="13"
    fill="none"
    viewBox="0 0 11 13"
  >
    <path
      fill="currentColor"
      d="M6.75.25h-5A1.25 1.25 0 00.5 1.5v10a1.25 1.25 0 001.25 1.25h7.5a1.25 1.25 0 001.25-1.25V4L6.75.25zM7.88 11.5H6.75L5.5 9.37 4.25 11.5H3.12l1.82-2.81-1.82-2.81h1.13L5.5 8l1.25-2.13h1.13L6.05 8.7l1.82 2.81zM6.13 4.62V1.2l3.43 3.44H6.12z"
    />
  </svg>
</template>
