<template>
  <div class="name-type-card">
    <div
      v-if="name"
      class="name-type-card-item"
    >
      {{ name }}
    </div>
    <div
      v-if="value"
      class="name-type-card-item"
    >
      <strong>{{ value }}</strong>
    </div>
    <div class="name-type-card-item description">
      -
    </div>
  </div>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
		},
		value: {
			type: String,
		},
	},
};
</script>

<style scoped lang='scss'>
  .name-type-card {
    display: flex;
    flex-direction: column;
    text-align: center;
    word-break: break-word;

    &-item {
      &.description {
        opacity: .8;
      }

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
</style>
