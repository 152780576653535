import * as yup from 'yup';
import {
	uniq,
	isEmpty,
	forEach,
	startCase,
	lowerCase,
} from 'lodash';

import {
	url,
	trim,
	email,
	object,
	maxString,
	stringSchema,
	decimal,
	ethereumAddress,
} from '@/components/common/form/formValidation';

import { ethereumAddressPattern } from '@/utility';
import { FORM_FIELD_ERRORS } from '@/constants/errors';

export const defaultValues = {
	socialLinks: [],
	websiteUrl: '',
	name: '',
	avatarUrl: '',
	bannerUrl: '',
	description: '',
	tokens: [],
	isVerified: false,
	slug: '',
	mintPrice: {
		value: '',
		currencyAddress: '0x0000000000000000000000000000000000000000',
	},
};

function initLinks (values) {
	const links = [];

	if (!isEmpty(values)) {
		for (const key in values) {
			forEach(uniq(values[key]), link => links.push({
				link,
				type: { label: startCase(key), value: lowerCase(key) },
			}));
		}
	}

	return links;
}

export const initValues = (dataset = null) => {
	if (isEmpty(dataset)) {
		return defaultValues;
	}
	return {
		socialLinks: initLinks(dataset.socialLinks),
		websiteUrl: dataset.websiteUrl || defaultValues.websiteUrl,
		name: dataset.name || defaultValues.name,
		avatarUrl: dataset.avatarUrl || defaultValues.avatarUrl,
		bannerUrl: dataset.bannerUrl || defaultValues.bannerUrl,
		description: dataset.description || defaultValues.description,
		tokens: !isEmpty(dataset.tokens) ? dataset.tokens : defaultValues.tokens,
		isVerified: dataset.isVerified || defaultValues.isVerified,
		slug: dataset?.slug || defaultValues.slug,
		mintPrice: dataset?.mintPrice || defaultValues.mintPrice,
	};
};

export const validationSchema = object({
	websiteUrl: url,
	description: stringSchema.concat(maxString(1000)),
	name: stringSchema.concat(trim).concat(maxString(256)),
	socialLinks: yup.array().of(yup.object({
		link: stringSchema
			.when('type.value', value => value === 'email' ? email() : url),
	})),
	slug: stringSchema.concat(maxString(256)).concat(trim).test({
		name: 'testSlug',
		test (value) {
			if (ethereumAddressPattern.test(value)) {
				return this.createError({
					path: 'slug',
					message: 'Wrong format. Must not contain Ethereum address',
				});
			}
			if (!value?.match(/^[0-9a-z-]*$/)) {
				return this.createError({
					path: 'slug',
					message: FORM_FIELD_ERRORS.FORM_SLUG_FIELD_WRONG_FORMAT,
				});
			}
			return true;
		},
	}),
	mintPrice: object({
		value: decimal.test('mint-price', 'Unacceptable value', value => {
			const number = +value;
			return !number || (number < 10e18 && number > 10e-18);
		}),
		currencyAddress: ethereumAddress(),
	}),
});

function getSocialLinks (values) {
	let socialLinks = {};

	if (!isEmpty(values)) {
		socialLinks = values.reduce((acc, val) => {
			if (!acc[val.type.value]) acc[val.type.value] = [];

			if (val.link.length > 0) acc[val.type.value].push(val.link);

			return acc;
		}, {});
	}

	return socialLinks;
}

export const submitFormValues = async (data, values) => {
	if (!data && !values) return;

	const { update } = data;

	const dataset = {
		avatarUrl: values.avatarUrl || null,
		bannerUrl: values.bannerUrl || null,
		name: isEmpty(values.name) ? null : values.name,
		socialLinks: getSocialLinks(values.socialLinks),
		websiteUrl: isEmpty(values.websiteUrl) ? null : values.websiteUrl,
		description: isEmpty(values.description) ? null : values.description,
		tokens: values.tokens || null,
		isVerified: values.isVerified,
		slug: values?.slug || null,
		mintPrice: values?.mintPrice?.value ? values?.mintPrice : null,
	};

	await update(dataset);
};
