<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.93699 3.94602C6.11133 3.94602 7.87398 3.06267 7.87398 1.97301C7.87398 0.883347 6.11133 0 3.93699 0C1.76264 0 0 0.883347 0 1.97301C0 3.06267 1.76264 3.94602 3.93699 3.94602ZM12.473 7.94602C14.391 7.94602 15.9459 7.06267 15.9459 5.97301C15.9459 4.88335 14.391 4 12.473 4C10.5549 4 8.99996 4.88335 8.99996 5.97301C8.99996 7.06267 10.5549 7.94602 12.473 7.94602ZM12.5249 9.08102C10.041 9.08102 9.05197 8.04001 9.05197 7.43701V10.02C9.05197 11.11 10.607 11.992 12.5249 11.992C14.4449 11.992 16 11.11 16 10.02V7.49902C16 8.10302 15.0099 9.08102 12.5249 9.08102ZM9.05197 11.468C9.05197 12.071 10.303 13.072 12.5249 13.072C14.748 13.072 16 12.04 16 11.437V14.0271C16 15.117 14.4449 16 12.5249 16C10.607 16 9.05197 15.117 9.05197 14.0271V11.468ZM0.124999 3.43701C0.124999 4.04001 1.24699 5.08102 4.06199 5.08102C6.87698 5.08102 7.99998 4.10302 7.99998 3.49902V6.02002C7.99998 7.11003 6.23798 7.99203 4.06199 7.99203C1.888 7.99203 0.124999 7.11003 0.124999 6.02002V3.43701ZM4.06199 9.07202C1.54299 9.07202 0.124999 8.07102 0.124999 7.46801V10.027C0.124999 11.117 1.888 12 4.06199 12C6.23798 12 7.99998 11.117 7.99998 10.027V7.43701C7.99998 8.04001 6.57999 9.07202 4.06199 9.07202ZM0.124999 11.468C0.124999 12.071 1.54299 13.072 4.06199 13.072C6.57999 13.072 7.99998 12.04 7.99998 11.437V14.0271C7.99998 15.117 6.23798 16 4.06199 16C1.888 16 0.124999 15.117 0.124999 14.0271V11.468Z"
      fill="currentColor"
    />
  </svg>
</template>
