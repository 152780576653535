<template>
  <TitledPage :title="`Collections`">
    <TabsQueryRouter
      v-slot="{ activeTab }"
      :items="collectionsTabs"
      :is-sticky="false"
    >
      <TabsContentContainer class="report-tabs__container">
        <component :is="renderTabContentByType(activeTab)" />
      </TabsContentContainer>
    </TabsQueryRouter>
  </TitledPage>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';

import { formatNumber } from '@/utility';
import { TabsQueryRouter, TabsContentContainer } from '@/components/common/tabs';

import TitledPage from '@/layouts/components/TitledPage.vue';
import AllSection from '@/pages/collections/sections/All.vue';
import CustomSection from '@/pages/collections/sections/Custom.vue';

const store = useStore();

const activeTab = computed(() => 'all');
const allTotal = computed(() => store.state.collections.allTotal || 0);
const collectionsTabs = computed(() => [
	{ label: `All (${formatNumber(allTotal.value, 0)})`, value: 'all' },
	{ label: `Custom`, value: 'custom' },
]);

const renderTabContentByType = computed(() => type => {
	activeTab.value = type;
	switch (type) {
		case collectionsTabs.value[0]?.value:
			return AllSection;
		case collectionsTabs.value[1]?.value:
			return CustomSection;
		default:
			return null;
	}
});
</script>

<style scoped lang='scss'>
.collections-slot {
  :deep(.collections-slot__media) {
    width: 40px;
    height: 40px;
  }
}
</style>
