<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.59293 2.343C5.7175 -0.781 10.7827 -0.781 13.9066 2.343C17.0311 5.467 17.0311 10.533 13.9066 13.657C10.782 16.781 5.71683 16.781 2.59293 13.657C-0.530976 10.5323 -0.530976 5.46767 2.59293 2.343ZM7.68277 10.9077L12.0893 5.767C12.3293 5.48767 12.2966 5.06633 12.016 4.827C11.7373 4.58767 11.316 4.62033 11.076 4.899L7.48277 9.09167L5.31617 7.46633C5.02085 7.245 4.60353 7.30567 4.38287 7.59967C4.16221 7.89433 4.22154 8.31233 4.5162 8.533L7.68277 10.9077Z"
      fill="#7112FF"
    />
  </svg>
</template>
