<template>
  <div
    class="item"
    :class="classes"
  >
    <slot />
  </div>
</template>

<script>
export default {
	props: {
		isActive: {
			type: Boolean,
			default: false,
			required: false,
		},
		isHoverable: {
			type: Boolean,
			required: false,
			default: true,
		},
		isRounded: {
			type: Boolean,
			required: false,
			default: true,
		},
		color: {
			type: String,
			required: false,
		},
	},
	computed: {
		classes () {
			let classes = '';

			if (this.isActive) classes += 'active ';
			if (this.isHoverable) classes += 'hoverable ';
			if (this.isRounded) classes += 'rounded ';
			if (this.color) classes += this.color;

			return classes;
		},
	},
};
</script>

<style scoped lang='scss'>
  .item {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    color: $grey;
    transition: all 200ms cubic-bezier(.215, .61, .355, 1);

    &.rounded {
      border-radius: 24px;
    }

    &.hoverable:not(.active) {
      cursor: pointer;

      &.violet:hover {
        color: $violet;
        background-color: rgba(113, 18, 255, 0.1);
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }

      &:active {
        background-color: rgba(0, 0, 0, 0.2);

        &.violet {
          background-color: rgba(113, 18, 255, 0.2);
        }
      }
    }

    &.active {
      cursor: default;
      background-color: rgba(0, 0, 0, .05);

      &.violet {
        color: $violet;
        background-color: rgba(113, 18, 255, 0.2);
      }
    }
  }
</style>
