<template>
  <div class="progress-bar-container">
    <div
      class="remaining"
      :style="{width: `${progressWidth}%`}"
    />
  </div>
</template>

<script>
export default {
	props: ['total', 'remaining'],
	computed: {
		progressWidth () {
			return Math.floor((this.remaining / this.total) * 100);
		},
	},
};
</script>

<style scoped>
  .progress-bar-container {
    height: 6px;
    border-radius: 10px;
    background-color: #f12848;
    width: 100%;
  }

  .remaining {
    background-color: #28cc1c;
    height: inherit;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
</style>
