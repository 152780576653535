<script>
import { defineComponent } from 'vue';

import Row from '../Row.vue';

export default defineComponent({
	name: 'HeadRow',
	components: {
		Row,
	},
	render () {
		return <Row class="head__row">{this.$slots?.default?.()}</Row>;
	},
});
</script>

<style lang="scss" scoped>
.head__row {
  border-bottom: 1px solid $table-border;
}
</style>
