<template>
  <div ref="scroll">
    <slot />
  </div>
  <LoadingSpinnerIcon
    v-if="isShowSpinner != null ? isShowSpinner : isLoading"
    class="spinner"
  />
</template>

<script>
import LoadingSpinnerIcon from '@/components/icons/LoadingSpinnerIcon';

export default {
	components: { LoadingSpinnerIcon },
	props: {
		page: String,
		isLoading: Boolean,
		isShowSpinner: {
			type: Boolean,
			required: false,
			default: undefined,
		},
		threshold: {
			type: Number,
			required: false,
			default: 350,
		},
	},
	emits: ['infinite'],
	computed: {
		hasFetchedAllData () {
			return this.page === null && !this.isLoading;
		},
	},
	mounted () {
		window.addEventListener('scroll', this.handleScroll);
	},
	unmounted () {
		window.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		handleScroll () {
			const scrollZone = this.$refs.scroll;

			if (scrollZone) {
				if (scrollZone.scrollTop + scrollZone.clientHeight >= scrollZone.scrollHeight - Math.abs(this.threshold)) {
					if (this.hasFetchedAllData || this.isLoading) return;

					this.$emit('infinite');
				}
			}
		},
	},
};
</script>

<style scoped lang='scss'>
  .spinner {
    width: 100px;
    height: 100px;
    margin-top: 20px;
  }
</style>
