import { computed, ref } from 'vue';

export const useLoading = () => {
	// eslint-disable-next-line no-underscore-dangle
	const _loading = ref(false);

	const startLoading = () => {
		_loading.value = true;
	};

	const stopLoading = () => {
		_loading.value = false;
	};

	return {
		loading: computed(() => _loading.value),
		startLoading,
		stopLoading,
	};
};
