<template>
  <div
    class="tab-container"
    :class="styleClasses"
  >
    {{ title }}<span
      v-if="description"
      class="tab-container__description text-footnote"
      :class="{'is-active': isSelected}"
    >{{ description }}</span>
  </div>
</template>

<script>
export default {
	props: {
		title: String,
		color: String,
		isSelected: Boolean,
		description: String,
		rounded: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		styleClasses () {
			let classes = '';

			if (this.isSelected) classes += 'is-active ';
			if (this.color) classes += this.color;
			if (this.rounded) classes += ' tab-container_rounded ';

			return classes;
		},
	},
};
</script>

<style lang="scss" scoped>
/* temporary for the sake of time-- when we have a chance want to migrate both tab components into one */
.tab-container {
  height: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
  padding: 18px 24px;
  @include defaultTransition(background-color, color);
	color: $shade30;
	text-align: center;
	font-weight: 500;
	white-space: nowrap;
  position: relative;

  @include max-screen-md() {
    width: 100%;
  }

  &_rounded {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  @media (any-hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }

  &__description {
    margin-left: 8px;
    color: $shade60 !important;

    &.is-active {
      color: $shade60 !important;
    }
  }

	&.is-active {
		color: $black;

    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background-color: $violet;
    }
	}

	&.red {
		color: var(--color-accent3);

		&.is-active {
      &::after {
        background-color: var(--color-accent3);
      }
		}
	}
}
</style>
