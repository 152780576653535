export const asyncTryCatchWrapper = async (tryFn, beforeFn, finallyFn, catchFn) => {
	if (beforeFn) beforeFn();
	try {
		await tryFn();
	} catch (error) {
		if (catchFn) catchFn(error);
	} finally {
		if (finallyFn) finallyFn();
	}
};

export const storeLoadingWrapper = async (fn, commit, key, errorFn) => {
	await asyncTryCatchWrapper(
		fn,
		() => commit && key ? commit(key, true) : null,
		() => commit && key ? commit(key, false) : null,
		errorFn,
	);
};

export const refLoadingWrapper = async (fn, ref, errorFn) => {
	await asyncTryCatchWrapper(
		fn,
		// eslint-disable-next-line no-return-assign
		() => ref ? ref.value = true : null,
		// eslint-disable-next-line no-return-assign
		() => ref ? ref.value = false : null,
		errorFn,
	);
};
