import actions from './actions';
import mutations from './mutations';
import getters from './getters';

import { authService } from '@/services';

const currentData = authService?.getCurrentAuth();

const state = {
	address: currentData?.address,
	tokens: {
		access: currentData?.access,
		refresh: currentData?.refresh,
	},
	isLoading: false,
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
};
