import actions from './actions';
import mutations from './mutations';

const state = {
	profile: null,
	loading: false,
	initState: null,
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
