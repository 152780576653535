<template>
  <IconText :reversed="true">
    <VerifyCheckIcon v-if="isVerified" />
    <template #content>
      {{ name }}
    </template>
  </IconText>
</template>

<script>
import IconText from '@/components/ui/IconText';
import { VerifyCheckIcon } from '@/components/icons';

export default {
	components: { IconText, VerifyCheckIcon },
	props: {
		name: String,
		isVerified: Boolean,
	},
};
</script>
