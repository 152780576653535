<template>
  <div class="tabs-content">
    <slot />
  </div>
</template>

<style scoped lang='scss'>
  .tabs-content {
    padding-top: 20px;
  }
</style>
