import CopyButton from '@/components/ui/CopyButton';

import { formatAddress, formatDate } from '@/utility';

import TableButtons from '../TableButtons.vue';

export const columns = [
	{ name: 'Collaborator', value: (data) => data?.username || formatAddress(data?.ethAddress) },
	{ name: 'Date Added', value: (data) => formatDate(data?.createdAt, 'd/M/yy p') },
	{ name: 'Wallet Address',
		value: (data) => <CopyButton value={data?.ethAddress} label={formatAddress(data?.ethAddress)} /> },
	{
		name: '',
		value: (data) => <TableButtons id={data?.id} address={data?.ethAddress} />,
	},
];
