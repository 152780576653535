import actions from './actions';
import mutations from './mutations';

const state = {
	list: [],
	type: 'nft',
	showLength: 10,
	totalCount: 0,
	offset: 0,
	loading: false,
	search: '',
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
