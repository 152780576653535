<template>
  <div class="collections-actions-bar">
    <div class="collections-actions-bar__search">
      <SearchField
        :model-value="search"
        placeholder="Search collections"
        to-query-param
        @enter:model-value="onSearchChanged"
      />
    </div>
    <div class="collections-actions-bar__filters">
      <LabeledContainer
        class="collections-actions-bar__filter"
        label="Show"
        for-id="show"
      >
        <Select
          :options="showOptions"
          name="show"
          :model-value="limitOption()"
          :is-disabled-dropdown="isEmptyState"
          @update:model-value="onLimitChanged"
        >
          <template #icon>
            <ChevronDownIcon />
          </template>
        </Select>
      </LabeledContainer>
    </div>
  </div>
</template>

<script setup>
import { find } from 'lodash';
import { useStore } from 'vuex';
import { computed, defineProps } from 'vue';

import { Select } from '@/components/ui/select';
import { ChevronDownIcon } from '@/components/icons';
import { SearchField } from '@/components/ui/text-fields';
import LabeledContainer from '@/components/ui/LabeledContainer';

import { showOptions, MIN_LIMIT_CHARACTERS_FOR_SEARCH } from '@/constants/filters';

defineProps({
	isEmptyState: {
		type: Boolean,
		default: false,
	},
});

const store = useStore();

const limit = computed(() => store.state.collections.limit);
const search = computed(() => store.state.collections.search);

const onLimitChanged = (option) => {
	store.dispatch('collections/setLimit', option.value);
};

const onSearchChanged = (search) => {
	if (search?.trim().length >= MIN_LIMIT_CHARACTERS_FOR_SEARCH) {
		store.dispatch('collections/setSearch', search);
	} else {
		store.dispatch('collections/resetPage');
	}
};

const limitOption = () => find(showOptions, { value: limit.value });
</script>

<style scoped lang="scss">
.collections-actions-bar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;
  column-gap: 20px;

  &__filters {
    display: flex;
    flex-direction: row;
  }

  &__filter {
    display: flex;
    align-items: center;
    width: 115px;
    margin-right: 20px;

    &_status:deep(.dropdown__list) {
      width: 115px;
    }

    &:deep(.label) {
      margin: 0;
      padding-right: 4px;
    }
  }

  &__search {
    width: 415px;
  }
}
</style>
