<template>
  <div
    class="action-buttons"
    :style="{ 'width': width + 'px', 'justify-content': justifyContent }"
  >
    <Button
      v-for="button of buttons"
      v-show="button.show()"
      :key="button"
      :disabled="button.disabled()"
      :variant="button.variant"
      :round="button.round"
      :size="button.size"
      :class="button.class || ''"
      :color="button.color || ''"
      @click="button.clickFn"
    >
      <component :is="button.icon" />{{ button.label }}
    </Button>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed, defineProps, shallowRef, ref } from 'vue';

import routes from '@/router/routes';
import Button from '@/components/ui/Button.vue';
import { PencilIcon, ShareIcon } from '@/components/icons';

import BanModal from './BanModal.vue';

const store = useStore();
const router = useRouter();

const props = defineProps({
	collection: { type: Object, required: true },
	hideEditButton: { type: Boolean, required: false, default: false },
	hideBanButton: { type: Boolean, required: false, default: undefined },
	hideUnbanButton: { type: Boolean, required: false, default: undefined },
	hideRemoveButton: { type: Boolean, required: false, default: false },
	hideShareButton: { type: Boolean, required: false, default: true },
	justifyContent: { type: String, required: false, default: 'flex-start' },
	isCustomCollection: {
		type: Boolean,
		default: false,
	},
	disableRemoveButton: { type: Boolean, required: false },
});

const marketplaceUrl = process.env.VUE_APP_WEBSITE_BASE_URL;

const buttons = shallowRef([
	{
		variant: 'outlined',
		show: () => !props.hideBanButton && props.collection.collectionStatus === 'active',
		disabled: () => false,
		clickFn: () => onBanUnbanClick(),
		size: 'flex',
		label: 'Ban',
	},
	{
		variant: 'outlined',
		show: () => !props.hideUnbanButton && props.collection.collectionStatus === 'banned',
		disabled: () => unbanLoading.value,
		clickFn: () => onBanUnbanClick(),
		size: 'flex',
		label: 'Unban',
	},
	{
		variant: 'outlined',
		show: () => props.hideEditButton,
		disabled: () => false,
		round: true,
		clickFn: () => onEditClick(),
		icon: PencilIcon,
	},
	{
		variant: 'outlined',
		show: () => props.hideShareButton,
		disabled: () => false,
		round: true,
		clickFn: () => onLinkClick(),
		icon: ShareIcon,
		class: 'share',
	},
	{
		variant: 'outlined',
		show: () => props.hideRemoveButton,
		disabled: () => props.disableRemoveButton || false,
		clickFn: () => onRemoveClick(),
		class: 'remove',
		label: 'Remove',
		color: 'red',
	},
]);

const unbanLoading = ref(false);

const width = computed(() => props.hideEditButton ? 136 : 184);

const onBanUnbanClick = () => {
	store.dispatch('modal/openModal', {
		content: shallowRef(BanModal),
		props: {
			id: props.collection.id,
			isBanned: props.collection.collectionStatus === 'banned',
		},
	});
};

const onEditClick = () => {
	const route = props.isCustomCollection
		? `/collections/custom/${props.collection.id}?tab=metadata`
		: `${routes.collections}/${props.collection.id}`;

	router.push(route);
};

const onLinkClick = () => {
	window.open(
		`${marketplaceUrl}/collections/${props.collection.slug || props.collection[props.isCustomCollection ? 'id' : 'contractAddress']}`,
	);
};

const onRemoveClick = () => {
	store.dispatch('collections/removeContainerContractAddressForMerge', props.collection.id);
};
</script>

<style scoped lang="scss">
.action-buttons {
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  button.share svg {
    margin-left: 2px;
  }
}
</style>
