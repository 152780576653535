<template>
  <div class="website-component-container">
    <Button
      v-if="!isActive"
      variant="outlined"
      :width="170"
      @click="toggleActive()"
    >
      + Add a website
    </Button>
    <div
      v-else
      class="website-component-content"
    >
      <h4 class="website-component-title">
        Website Address
      </h4>

      <div class="website-component-field-wrapper">
        <div>
          <FormTextField
            is-rounded
            :name="name"
            variant="rounded"
          />
        </div>
        <Button
          type="button"
          class="website-component-button"
          is-text
          @click="toggleActive()"
        >
          <CrossIcon />
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
'use strict';

import { isEmpty } from 'lodash';

import { CrossIcon } from '@/components/icons';
import Button from '@/components/ui/Button.vue';
import FormTextField from '@/components/common/form/components/FormTextField.vue';

export default {
	components: { Button, FormTextField, CrossIcon },
	inject: ['formState'],
	props: {
		name: { type: String, required: true },
	},
	data (props) {
		let activeState = false;

		if (!isEmpty(this.formState?.values[props.name])) activeState = true;

		return {
			isActive: activeState,
		};
	},
	computed: {
		formValues () {
			return this.formState?.values;
		},
	},
	methods: {
		toggleActive () {
			this.formState?.setFieldValue(this.name, '');
			this.isActive = !this.isActive;
		},
	},
};
</script>

<style scoped lang="scss">
.website-component {
  &-container {
    width: 100%;
  }

  &-content {
    display: grid;
    grid-template-columns: 100%;
    gap: 5px;
  }

  &-field-wrapper {
    display: grid;
    grid-template-columns: auto 40px;
    gap: 10px;

    :deep(.text) {
      color: $violet;
    }
  }

  &-button {
    width: 40px;
    height: 40px;
  }

  &-title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}
</style>
