<template>
  <section class="left-side">
    <section class="left-side-header section">
      <ProviderLogo
        v-if="mainState.isNew.value"
        :value="mainState.provider.value"
        class="provider-logo"
      />

      <h1 class="title">
        {{ title }} Drop
      </h1>
    </section>

    <Fields class="section border-hidden" />

    <section
      v-if="isWitAccessList"
      class="section border-hidden"
    >
      <AccessList
        type="whitelist"
        :disabled="notEditable"
      />
    </section>

    <section class="section">
      <NftsList />
    </section>

    <Actions @action-click="$emit('submit', $event)" />
  </section>
</template>

<script>
import Fields from './Fields.vue';
import Actions from '../../Actions.vue';
import NftsList from '../../nfts-list/index.vue';
import ProviderLogo from '../../ProviderLogo.vue';
import AccessList from '../../access-list/index.vue';

export default {
	components: {
		Fields,
		Actions,
		NftsList,
		AccessList,
		ProviderLogo,
	},
	inject: ['formState', 'mainState'],
	emits: ['submit'],
	computed: {
		isWitAccessList () {
			return this.formState.formValues?.acceptWhitelist;
		},
		notEditable () {
			return this.mainState?.notEditable?.value;
		},
		title () {
			return this.mainState.isNew.value ? 'New Ethereum' : 'Edit';
		},
	},
};
</script>

<style scoped lang='scss'>
  .left-side {
    .section {
      width: 100%;

      &:not(:last-child) {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, .2);
      }

      &.border-hidden {
        border-bottom: none;
        margin-bottom: 0;
      }
    }

    &-header {
      .provider-logo {
        margin-bottom: 20px;
      }

      .title {
        font-size: 30px;
        font-weight: 700;
      }
    }
  }
</style>
