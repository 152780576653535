<template>
  <div class="content">
    <slot />
  </div>
</template>

<style scoped lang='scss'>
  .content {
    padding: 20px;
    box-sizing: border-box;
  }
</style>
