<template>
  <LinkToEditPage :identifier="$route.params.identifier" />
  <div class="edit-announcement__wrapper">
    <div class="edit-announcement">
      <div class="edit-announcement__left">
        <EditAnnouncementPanel
          class="body-panel"
          :title="heading"
        >
          <template #subtitle>
            <div class="body-panel__subtitle">
              in <VerificationLabel
                :name="formatAddressName(collection?.name, collection?.addressName)"
                :is-verified="collection?.verified"
              />
            </div>
          </template>
          <template #default>
            <FormTextField
              name="title"
              placeholder="Enter title here"
              trim
            />
            <div
              v-if="props.type === 'airdrop'"
              class="body-panel__input-row"
            >
              <EditAnnouncementInputWrapper label="Start Time">
                <FormDatetime
                  name="startsAt"
                  :format="DEFAULT_DATE_FORMAT"
                  placeholder="Start date"
                  clearable
                />
              </EditAnnouncementInputWrapper>
              <EditAnnouncementInputWrapper label="End Time">
                <FormDatetime
                  name="endsAt"
                  :format="DEFAULT_DATE_FORMAT"
                  placeholder="End date"
                  clearable
                />
              </EditAnnouncementInputWrapper>
            </div>
            <div
              v-if="props.type === 'airdrop'"
              class="body-panel__input-row"
            >
              <EditAnnouncementInputWrapper label="Claim URL">
                <FormTextField
                  name="claimURL"
                  placeholder="Enter claim URL"
                />
              </EditAnnouncementInputWrapper>
              <EditAnnouncementInputWrapper label="Airdrop announcement tweet">
                <FormTextField
                  name="tweetURL"
                  class="body-panel__tweet-url"
                  placeholder="Enter tweet URL"
                >
                  <template #beforeInput>
                    <span class="body-panel__tweet-url-mask">{{ TWEET_URL_MASK }}</span>
                  </template>
                </FormTextField>
              </EditAnnouncementInputWrapper>
            </div>
            <EditAnnouncementInputWrapper
              class="body-panel__text-editor-wrapper"
              :label="props.type === 'airdrop' ? 'Claim requirements' : null"
            >
              <FormTextEditor
                ref="formTextEditorRef"
                name="body"
                placeholder="Enter body text here"
                mediahub-image-type="announcement/media"
              />
            </EditAnnouncementInputWrapper>
          </template>
        </EditAnnouncementPanel>
      </div>
      <div class="edit-announcement__right">
        <EditAnnouncementPanel
          class="publish-panel"
          title="Publish"
        >
          <div class="publish-panel__selects">
            <div
              v-for="(select, index) of publishPanelSelects"
              :key="index"
              class="publish-panel__selects-item"
            >
              <label>{{ select.label }}</label>
              <FormSelect
                :name="select.name"
                list-position="left"
                :value="formValues[select.name]"
                :label="formValues[select.name]?.label"
                :options="select.options"
                content-width="208px"
                append-to-body
              />
            </div>
            <FormDatetime
              v-show="formValues.publish.value === PUBLISH_OPTIONS.custom.value"
              name="publishedAt"
              :format="DEFAULT_DATE_FORMAT"
              placeholder="Published At"
              clearable
            />
          </div>
          <Button
            class="publish-panel__submit-button"
            :submitting="publishing"
            :disabled="(moveToTrashLoading || saveToDraftLoading || thumbnailLoading)"
            @click.stop="handleSubmitButtonClick"
          >
            {{ !draftedAs && !props.ts ? 'Publish' : 'Update' }}
          </Button>
          <div class="publish-panel__actions">
            <Button
              class="publish-panel__actions-button publish-panel__actions-save-draft"
              variant="outlined"
              :disabled="(publishing || moveToTrashLoading || saveToDraftLoading || thumbnailLoading)"
              @click="handleSaveDraftClick"
            >
              Save Draft
            </Button>
            <Button
              class="publish-panel__actions-button publish-panel__actions-preview"
              variant="outlined"
              @click.stop="handlePreviewClick"
            >
              Preview
            </Button>
            <Button
              class="publish-panel__actions-button publish-panel__actions-delete"
              variant="outlined"
              :disabled="(publishing || moveToTrashLoading || saveToDraftLoading || thumbnailLoading)"
              @click.stop="handleMoveToTrashClick"
            >
              <TrashIcon size="24px" />
            </Button>
          </div>
        </EditAnnouncementPanel>
        <EditAnnouncementPanel
          class="thumbnail-panel"
          title="Featured Image"
        >
          <ImageDropzone
            name="thumbnail"
            :accept="THUMBNAIL_ACCEPT_OPTIONS"
            :loading="thumbnailLoading || loading"
            :image-url="thumbnailUrl"
            :clear="handleThumbnailClear"
            @drop="handleThumbnailDrop"
          >
            <div class="thumbnail-panel__placeholder text-footnote">
              <span>Upload a featured image</span> <br> or drag it here
            </div>
          </ImageDropzone>
        </EditAnnouncementPanel>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useForm } from 'vee-validate';
import { isUndefined, isNull, reduce } from 'lodash';

import Button from '@/components/ui/Button.vue';
import VerificationLabel from '@/components/ui/VerificationLabel';
import ImageDropzone from '@/components/common/files/ImageDropzone.vue';
import FormTextField from '@/components/common/form/components/FormTextField.vue';
import FormTextEditor from '@/components/common/form/components/FormTextEditor.vue';
import FormSelect from '@/components/common/form/components/FormSelect.vue';
import FormDatetime from '@/components/common/form/components/FormDatetime.vue';
import { TrashIcon } from '@/components/icons';

import { mediaHubService, announcementsService } from '@/services';

import { formatAddressName } from '@/utility/format';
import { DEFAULT_DATE_FORMAT } from '@/utility/datetime.js';

import EditAnnouncementPanel from './components/EditAnnouncementPanel.vue';
import EditAnnouncementInputWrapper from './components/EditAnnouncementInputWrapper.vue';
import {
	AD_INITIAL_VALUES,
	AD_SCHEMA,
	AIRDROP_INITIAL_VALUES,
	AIRDROP_SCHEMA,
	STATUS_OPTIONS,
	VISIBILITY_OPTIONS,
	PUBLISH_OPTIONS,
	THUMBNAIL_ACCEPT_OPTIONS,
	TWEET_URL_MASK,
} from './helpers/form';
import useAnnouncementPreviewModal from './components/composables/useAnnouncementPreviewModal';
import useSaveAsDraftConfirmationModal from './components/composables/useSaveAsDraftConfirmationModal';
import { COLLECTION_ANNOUNCEMENT_TYPES } from '@/components/pages/collection/announcements/data';
import LinkToEditPage from '@/pages/collections/announcements/components/components/LinkToEditPage';

const props = defineProps({
	identifier: String,
	ts: String,
	type: String,
});

const store = useStore();

const router = useRouter();

const { sendFile } = mediaHubService;

const { openAnnouncementPreviewModal } = useAnnouncementPreviewModal();
const { openSaveAsDraftConfirmationModal } = useSaveAsDraftConfirmationModal();

const loading = ref(false);
const thumbnailLoading = ref(false);
const moveToTrashLoading = ref(false);
const saveToDraftLoading = ref(false);

const draftedAs = ref(false);

const thumbnailUrl = ref(null);

const formTextEditorRef = ref(null);

const heading = computed(() => `${props.ts ? 'Edit' : 'New'} ${props.type === 'airdrop' ? 'Airdrop ' : ''} Announcement`);

const collection = computed(() => store.state.collections.collection);

const publishing = ref(false);

const publishPanelSelects = [
	{
		label: 'Status',
		name: 'status',
		options: [
			STATUS_OPTIONS.draft,
			STATUS_OPTIONS.published,
		],
	},
	{
		label: 'Visibility',
		name: 'visibility',
		options: [
			VISIBILITY_OPTIONS.public,
			VISIBILITY_OPTIONS.private,
		],
	},
	{
		label: 'Publish',
		name: 'publish',
		options: [
			PUBLISH_OPTIONS.immediately,
			PUBLISH_OPTIONS.custom,
		],
	},
];

const { values: formValues, setValues, validate, setFieldValue } = useForm({
	initialValues: props.type === COLLECTION_ANNOUNCEMENT_TYPES.AD ? AD_INITIAL_VALUES : AIRDROP_INITIAL_VALUES,
	validationSchema: props.type === COLLECTION_ANNOUNCEMENT_TYPES.AD ? AD_SCHEMA : AIRDROP_SCHEMA,
});

async function handleThumbnailDrop (data) {
	if (data?.image) {
		thumbnailLoading.value = true;
		try {
			const url = await sendFile(data?.image, 'announcement/thumbnail');
			thumbnailUrl.value = url;
		} finally {
			thumbnailLoading.value = false;
		}
	}
}

function handleThumbnailClear () {
	thumbnailUrl.value = null;
}

function createPayload (values, protectedKeys, keysToDelete) {
	return Object.fromEntries(Object.entries({
		thumbnail: thumbnailUrl.value,
		type: props.type,
		...values,
	})
		.map(([key, value]) => {
			if (['status', 'visibility'].includes(key)) return [key, value.value];
			if (['startsAt', 'endsAt'].includes(key)) return [key, new Date(value).getTime()];
			if (key === 'publishedAt') return values.publish.value === PUBLISH_OPTIONS.custom.value ? [key, new Date(value).getTime()] : [];
			if (key === 'tweetURL') return [key, TWEET_URL_MASK + value];
			return [key, value];
		})
		.filter(([key, value]) => (protectedKeys && protectedKeys.includes(key)) || (!isUndefined(value) && !isNull(value) && !keysToDelete.includes(key)))
		.map(([key, value]) => [key, value || null]));
}

async function createAnnouncement (values, loading, draft) {
	loading.value = true;
	let response;

	const payload = createPayload(values, ['publishedAt'], ['publish']);

	try {
		response = await announcementsService.createAnnouncement(
			'collection',
			collection.value?.slug || collection.value?.contractAddress,
			payload.type,
			payload,
		);

		draftedAs.value = response.ts;

		if (draft) store.dispatch('alert/success', { message: 'Draft saved' });
	} finally {
		loading.value = false;
	}
	return response;
}

async function updateAnnouncement (values, loading, draft) {
	loading.value = true;
	let response;
	try {
		const payload = createPayload(values, ['publishedAt', 'thumbnail'], ['publish']);

		response = await announcementsService.updateAnnouncement(
			'collection',
			collection.value.slug || collection.value.contractAddress || props.identifier,
			props.type,
			draftedAs.value || props.ts,
			payload,
		);

		draftedAs.value = response.ts;

		if (draft) store.dispatch('alert/success', { message: 'Draft saved' });
	} finally {
		loading.value = false;
	}
	return response;
}

async function getAnnouncement (loading) {
	loading.value = true;
	let response;
	try {
		response = await announcementsService.getAnnouncementDetails('collection', collection.value.slug || collection.value.contractAddress || props.identifier, props.type, props.ts);
	} finally {
		loading.value = false;
	}
	return response;
}

async function getCollection () {
	if (!collection.value || collection.value.slug !== props.identifier || collection.value.contractAddress !== props.identifier) {
		await store.dispatch('collections/getCollection', { id: props.identifier });
		// await store.dispatch('collections/fetchContext');
	}

	// if (!store.state.collection.collection.context?.editable) {
	// 	router.push({
	// 		name: 'not-found',
	// 		path: route.path,
	// 		params: { catchAll: `collections/${props.identifier}/announcements/${props.ts || 'create'}` || 'not-found' },
	// 	});
	// }
}

async function getValidationErrorsList (validationResult, fieldsToIgnore) {
	return Array.from(reduce(validationResult.errors, (acc, val, key) => {
		if (!fieldsToIgnore.includes(key)) acc.add(val);
		return acc;
	}, new Set([])));
}

async function getNoErrorsExceptRequired (validationResult, fieldsToIgnore) {
	const validationErrorsList = await getValidationErrorsList(validationResult, fieldsToIgnore);
	return !validationErrorsList.length || (validationErrorsList.length === 1 && validationErrorsList.includes('Required field'));
}

async function getNoErrors (validationResult, fieldsToIgnore) {
	const validationErrorsList = await getValidationErrorsList(validationResult, fieldsToIgnore);
	return !validationErrorsList.length;
}

async function save (loading) {
	!draftedAs.value && !props.ts
		? await createAnnouncement(formValues, loading)
		: await updateAnnouncement(formValues, loading);
	await router.push(
		`/collections/${props.identifier}/${props.type === COLLECTION_ANNOUNCEMENT_TYPES.AD ? 'announcements' : 'airdrops'}?tab=${formValues.status.value}`,
	);
}

async function saveToDraft (loading, goToDraftList) {
	!draftedAs.value && !props.ts
		? await createAnnouncement({ ...formValues, status: STATUS_OPTIONS.draft }, loading, true)
		: await updateAnnouncement({ ...formValues, status: STATUS_OPTIONS.draft }, loading, true);
	setFieldValue('status', STATUS_OPTIONS.draft);
	if (goToDraftList) {
		await router.push(
			`/collections/${props.identifier}/${props.type === COLLECTION_ANNOUNCEMENT_TYPES.AD ? 'announcements' : 'airdrops'}?tab=draft`,
		);
	}
}

async function saveToTrash (loading) {
	!draftedAs.value && !props.ts
		? await createAnnouncement({ ...formValues, status: { value: 'trash' } }, loading)
		: await updateAnnouncement({ ...formValues, status: { value: 'trash' } }, loading);
	await router.push(`/collections/${props.identifier}/${props.type === COLLECTION_ANNOUNCEMENT_TYPES.AD ? 'announcements' : 'airdrops'}?tab=trash`);
}

async function handleSubmitButtonClick () {
	const isDraft = formValues.status.value === STATUS_OPTIONS.draft.value;

	const validationResult = await validate(isDraft ? { mode: 'silent' } : {});
	const fieldsToIgnore = formValues.publish.value === PUBLISH_OPTIONS.immediately.value ? ['publishedAt'] : [];
	const noErrors = await getNoErrors(validationResult, fieldsToIgnore);

	if (validationResult.valid || noErrors) return save(publishing);

	const noErrorsExceptRequired = await getNoErrorsExceptRequired(validationResult, fieldsToIgnore);

	if (noErrorsExceptRequired) {
		if (isDraft) {
			saveToDraft(publishing, true);
		} else {
			openSaveAsDraftConfirmationModal(() => saveToDraft(publishing, true));
		}
	}
}

async function handleSaveDraftClick () {
	const validationResult = await validate({ mode: 'silent' });
	const fieldsToIgnore = formValues.publish.value === PUBLISH_OPTIONS.immediately.value ? ['publishedAt'] : [];
	const noErrorsExceptRequired = await getNoErrorsExceptRequired(validationResult, fieldsToIgnore);

	if (validationResult.valid || noErrorsExceptRequired) return saveToDraft(saveToDraftLoading);

	validate();
}

async function handleMoveToTrashClick () {
	const validationResult = await validate({ mode: 'silent' });
	const fieldsToIgnore = formValues.publish.value === PUBLISH_OPTIONS.immediately.value ? ['publishedAt'] : [];
	const noErrorsExceptRequired = await getNoErrorsExceptRequired(validationResult, fieldsToIgnore);

	if (validationResult.valid || noErrorsExceptRequired) return saveToTrash(moveToTrashLoading);

	validate();
}

function handlePreviewClick () {
	openAnnouncementPreviewModal({
		...formValues,
		type: props.type,
		thumbnail: thumbnailUrl.value,
		...(props.type === COLLECTION_ANNOUNCEMENT_TYPES.AIRDROP ? { tweetURL: TWEET_URL_MASK + formValues.tweetURL } : {}),
	});
}

onMounted(async () => {
	await getCollection();
	if (props.ts) {
		const announcement = await getAnnouncement(loading);

		setValues({
			title: announcement.title || '',
			body: announcement.body || '',
			status: STATUS_OPTIONS[announcement.status] || STATUS_OPTIONS.draft,
			visibility: VISIBILITY_OPTIONS[announcement.visibility] || VISIBILITY_OPTIONS.public,
			publish: PUBLISH_OPTIONS.immediately,
			publishedAt: announcement.publishedAt ? new Date(announcement.publishedAt).toISOString() : '',
		});

		watch(() => formTextEditorRef.value, (value) => {
			if (value) formTextEditorRef.value.setTextEditorValue(announcement.body);
		}, { immediate: true });

		if (props.type === 'airdrop') {
			if (announcement.startsAt) setFieldValue('startsAt', new Date(announcement.startsAt).toISOString());
			if (announcement.endsAt) setFieldValue('endsAt', new Date(announcement.endsAt).toISOString());
			if (announcement.claimURL) setFieldValue('claimURL', announcement.claimURL);
			if (announcement.tweetURL) setFieldValue('tweetURL', announcement.tweetURL.replace(/^https:\/\/twitter.com\//, ''));
		}

		thumbnailUrl.value = announcement.thumbnail;
	}
});
</script>

<style lang="scss" src="./edit-announcement.scss"></style>
