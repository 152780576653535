import { authService } from '@/services';
import router from '@/router';
import routePaths from '@/router/routes';
import { addSuccess } from '@/components/utility/notifications';

export default {
	async login ({ commit, state, dispatch }, { address }) {
		const formattedAddress = address ? address.toLowerCase() : undefined;
		const current = authService.getCurrentAuth();

		if (current !== null) {
			commit('login', current);
		}

		if (!address || state.address === formattedAddress) {
			if (router.currentRoute.path === routePaths.auth) {
				router.replace(routePaths.main);
			}

			return;
		}

		try {
			commit('setLoading', true);

			const credentials = await authService.login(formattedAddress);

			commit('login', credentials);

			addSuccess('Successfully logged in');

			router.replace(routePaths.main);
		} catch (error) {
			const code = error?.response?.data?.code;

			if (state?.address && code === 'AuthorizationError') {
				await dispatch('logout');
			}
		} finally {
			commit('setLoading', false);
		}
	},
	async logout ({ commit, dispatch }) {
		authService.clearAuth();
		commit('logout');
		await router.replace(routePaths.auth);
		await dispatch('ethers/disconnectWallet', {}, { root: true });
	},
	async refresh ({ state, commit, dispatch }) {
		try {
			const credentials = await authService.refreshToken(state.address);

			commit('login', credentials);
		} catch {
			dispatch('logout');

			throw new Error('');
		}
	},
};
