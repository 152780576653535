<template>
  <div class="image-dropzone">
    <BaseMediahubDropzone
      :name="name"
      :type="type"
      :max-file-size="maxFileBytes"
      :external-error="error"
      title="Drop your image/video here or click to upload"
      :accept="acceptedMimeTypes"
      @on-drop="$emit('onUploadingComplete', $event)"
    />
    <HelperText class="helper-text">
      JPG, PNG, GIF, JPEG{{ formatList }} only.<br>
      <template v-if="maxFileBytes && maxFileBytes !== 0">
        Max file size {{ maxFileSize }}
      </template>
    </HelperText>
  </div>
</template>

<script>
import HelperText from '@/components/ui/HelperText';

import { formatBytes } from '@/utility';

import BaseMediahubDropzone from './BaseMediahubDropzone.vue';

export default {
	components: {
		BaseMediahubDropzone,
		HelperText,
	},
	props: {
		maxFileBytes: {
			type: Number,
			required: false,
			default: () => 250 * 1024 * 1024,
		},
		name: {
			type: String,
			required: false,
		},
		error: {
			type: String,
			required: false,
		},
		type: {
			type: String,
			required: false,
		},
	},
	emits: ['onUploadingComplete'],
	computed: {
		maxFileSize () {
			return formatBytes(this.maxFileBytes);
		},
		acceptedMimeTypes () {
			const baseMimeTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
			switch (this.type) {
				case 'drop/banner':
					return [...baseMimeTypes, 'video/mp4'];
				case 'drop/nft/media':
					return [...baseMimeTypes, 'video/mp4', 'application/octet-stream', 'model/gltf-binary', 'model/obj'];
				case 'drop/nft/preview':
					return [...baseMimeTypes];
				default:
					return [];
			}
		},
		formatList () {
			switch (this.type) {
				case 'drop/banner':
					return ', MP4';
				case 'drop/nft/media':
					return ', MP4, FBX, GLB, OBJ';
				case 'drop/nft/preview':
					return '';
				default:
					return '';
			}
		},
	},
};
</script>

<style scoped lang='scss'>
.helper-text {
  margin-top: 5px;
}
</style>
