import { formatDate, formatAddress } from '@/utility';

/** Methods object for formatting cell values in a table */
export const formatValuesForTable = {
	/** Date in format 'EEE LLL d, p' */
	date: date => formatDate(date, 'EEE LLL d, p'),
	/** Account address truncated to 10 characters */
	accountId: accountId => formatAddress(accountId),
};

export const acceptConstants = {
	single: {
		title: 'Accept Report?',
		message: `If you accept the report, reported {type} will be banned.
      It will not be available on the website anymore and all trading operations will be seized for it.
      You can restore the report later by pressing the revert button.`,
	},
	plural: {
		title: 'Accept Reports ({length})?',
		message: `If you accept the reports ({length}), reported {type}s will be banned.
      They will not be available on the website anymore and all trading operations will be seized for them.
      You can restore the report later by pressing the revert button.`,
	},
};

export const reportOptionsTypes = {
	COPYRIGHT_ISSUE: 'copyright_issue',
	PRIVACY_ISSUE: 'privacy_issue',
	TRADEMARK_INFRINGEMENT: 'trademark_infringement',
	DEFAMATION: 'defamation',
	COUNTERFEIT: 'counterfeit',
	OTHER_LEGAL_ISSUE: 'other',
	SEXUAL_CONTENT: 'sexual_content',
	VIOLENT_OR_REPULSIVE_CONTENT: 'violent_or_repulsive_content',
	HATEFUL_OR_ABUSIVE_CONTENT: 'hateful_or_abusive_content',
	HARASSMENT_OR_BULLYING: 'harassment_or_bullying',
	HARMFUL_OR_DANGEROUS_ACTS: 'harmful_or_dangerous_acts',
	CHILD_ABUSE: 'child_abuse',
	PROMOTES_VIOLENCE: 'promotes_violence',
	SPAM_OR_MISLEADING: 'spam_or_misleading',
};

export const reportOptions = [
	{ label: 'Copyright issue', value: reportOptionsTypes.COPYRIGHT_ISSUE },
	{ label: 'Privacy issue', value: reportOptionsTypes.PRIVACY_ISSUE },
	{ label: 'Trademark infringement', value: reportOptionsTypes.TRADEMARK_INFRINGEMENT },
	{ label: 'Defamation', value: reportOptionsTypes.DEFAMATION },
	{ label: 'Counterfeit', value: reportOptionsTypes.COUNTERFEIT },
	{ label: 'Other legal issue', value: reportOptionsTypes.OTHER_LEGAL_ISSUE },
	{ label: 'Sexual content', value: reportOptionsTypes.SEXUAL_CONTENT },
	{ label: 'Violent or repulsive content', value: reportOptionsTypes.VIOLENT_OR_REPULSIVE_CONTENT },
	{ label: 'Hateful or abusive content', value: reportOptionsTypes.HATEFUL_OR_ABUSIVE_CONTENT },
	{ label: 'Harassment or bullying', value: reportOptionsTypes.HARASSMENT_OR_BULLYING },
	{ label: 'Harmful or dangerous acts', value: reportOptionsTypes.HARMFUL_OR_DANGEROUS_ACTS },
	{ label: 'Child abuse', value: reportOptionsTypes.CHILD_ABUSE },
	{ label: 'Promotes violence', value: reportOptionsTypes.PROMOTES_VIOLENCE },
	{ label: 'Spam or misleading', value: reportOptionsTypes.SPAM_OR_MISLEADING },
];

export const marketplaceUrls = {
	nft: ({ address, tokenId }) => `collections/${address}/${tokenId}`,
	collection: ({ address }) => `collections/${address}`,
	user: ({ address }) => address,
};
