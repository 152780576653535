<template>
  <div
    class="tab-item"
    :class="classes"
  >
    <slot />
  </div>
</template>

<script>
export default {
	props: {
		isSelected: {
			type: Boolean,
			required: false,
		},
	},
	computed: {
		classes () {
			let classes = '';

			if (this.isSelected) classes += 'is-active ';

			return classes;
		},
	},
};
</script>

<style scoped lang='scss'>
  .tab-item {
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-self: center;
    padding: 20px;
    transition: background-color 200ms cubic-bezier(.215, .61, .355, 1);
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    background-color: transparent;
    color: rgba(0, 0, 0, .5);

    &.is-active {
      cursor: default;
      box-shadow: inset 0 -3px 0 0 $violet;
      color: $violet;
    }

    &:not(.is-active) {
      cursor: pointer;

      &:hover {
        background-color: rgba(113, 18, 255, .1);
        color: $violet;
      }

      &:active {
        background-color: rgba(113, 18, 255, .2);
      }
    }
  }
</style>
