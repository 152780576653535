import {
	DiscordIcon,
	EmailIcon,
	FacebookIcon,
	GitHubIcon,
	InstagramIcon,
	LinkedinIcon,
	MediumIcon,
	SnapchatIcon,
	TelegramIcon,
	TwitchIcon,
	TwitterIcon,
	YoutubeIcon,
} from '@/components/icons';

export const renderSocialIconByKey = key => {
	switch (key) {
		case 'twitter':
			return TwitterIcon;
		case 'instagram':
			return InstagramIcon;
		case 'facebook':
			return FacebookIcon;
		case 'youtube':
			return YoutubeIcon;
		case 'linkedin':
			return LinkedinIcon;
		case 'email':
			return EmailIcon;
		case 'twitch':
			return TwitchIcon;
		case 'telegram':
			return TelegramIcon;
		case 'medium':
			return MediumIcon;
		case 'discord':
			return DiscordIcon;
		case 'snapchat':
			return SnapchatIcon;
		case 'github':
			return GitHubIcon;
		default:
			return null;
	}
};
