<template>
  <button
    :disabled="disabled"
    class="icon-button"
    :class="[color]"
  >
    <slot />
  </button>
</template>

<script>
export default {
	props: {
		color: {
			type: String,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
};
</script>

<style scoped lang='scss'>
  .icon-button {
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    width: 40px;
    height: 40px;
    padding: 0;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    transition: color 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
    background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    color: $grey;
    background-color: transparent;

    &:hover {
      background-color: rgba(0, 0, 0, .1);
      cursor: pointer;
    }

    &:active {
      background-color: rgba(0, 0, 0, .2);
    }

    &.violet {
      color: $violet;

      &:hover {
        background-color: rgba(113, 18, 255, 0.1);
      }

      &:active {
        background-color: rgba(113, 18, 255, 0.2);
      }
    }
  }
</style>
