<template>
  <div class="table__head">
    <HeadRow :style="gridStyles">
      <HeadCell v-if="withCheckbox">
        <BaseCheckbox
          :value="headCheckbox"
          :checked="headCheckbox"
          @click.prevent="$emit('headCheck')"
        />
      </HeadCell>
      <HeadCell
        v-for="(col, key) in columns"
        :key="key"
      >
        {{ col.name }}
      </HeadCell>
    </HeadRow>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import HeadRow from './HeadRow.vue';
import HeadCell from './HeadCell.vue';
import BaseCheckbox from '@/components/ui/BaseCheckbox';

export default defineComponent({
	name: 'Head',
	components: {
		HeadRow,
		HeadCell,
		BaseCheckbox,
	},
	inject: ['columns', 'gridStyles', 'withCheckbox', 'headCheckbox'],
	emits: ['headCheck'],
});
</script>
