import { ref, onMounted, onUnmounted } from 'vue';

/**
 * This function detects when an element with position:sticky hits the border of the viewport (with top offset).
 * @param { import('vue').Ref<HTMLElement> } element
 * @param { number } topOffset
 * @returns { import('vue').Ref<boolean> }
 */
export default function usePositionStickyDetection ({ element, topOffset }) {
	const isPinned = ref(false);

	let observer;

	onMounted(() => {
		observer = new IntersectionObserver(([event]) => {
			isPinned.value = event.intersectionRatio < 1;
		}, {
			rootMargin: `${-1 * topOffset - 1}px 0px 0px 0px`,
			threshold: [1],
		});

		observer.observe(element.value);
	});

	onUnmounted(() => {
		observer?.disconnect();
	});

	return isPinned;
}
