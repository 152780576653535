<template>
  <div
    class="select"
    :class="{'error': errorMessage, 'disabled': disabled}"
  >
    <select
      :id="id"
      :disabled="disabled"
      class="select-default"
      v-bind="$attrs"
    >
      <slot />
    </select>
  </div>
  <ErrorMessage v-if="isErrorMessageVisible && !!errorMessage">
    {{ errorMessage }}
  </ErrorMessage>
</template>

<script>
import { ErrorMessage } from '@/components/ui/text-fields';

export default {
	components: { ErrorMessage },
	props: {
		id: {
			type: String,
			required: false,
		},
		errorMessage: {
			type: String,
			required: false,
		},
		isErrorMessageVisible: {
			type: Boolean,
			required: false,
			default: true,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
};
</script>

<style scoped lang='scss'>
  .select {
    display: grid;
    grid-template-areas: 'select';
    border: 1px none transparent;
    height: 38px;
    border-radius: 10px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333333;
    transition: box-shadow 200ms ease;
    background-color: $white;
    box-shadow: 0 0 0 1px rgba(113, 18, 255, .2);
    cursor: pointer;
    align-items: center;

    &.disabled {
      opacity: .5;
      cursor: default;
    }

    &:not(.disabled) {
      &:hover {
        box-shadow: 0 0 0 1px rgba(113, 18, 255, .5);
      }

      &:focus-within {
        border-width: 2px;
        box-shadow: 0 0 0 2px $violet;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 10px;
      height: 5px;
      background-image: url("~@/images/chevron-down.svg");
      background-repeat: no-repeat;
      grid-area: select;
      justify-self: end;
      padding-right: 11px;
    }

    &.error {
      box-shadow: 0 0 0 1px rgba(241, 40, 72, .20);

      &:not(.disabled) {
        &:hover {
          box-shadow: 0 0 0 1px rgba(241, 40, 72, .50);
        }

        &:focus-within {
          box-shadow: 0 0 0 2px $red;
        }
      }
    }
  }

  .select-default {
    grid-area: select;
    background-color: transparent;
    padding: 8px 32px 8px 12px;
    outline: none;
    vertical-align: middle;
    appearance: none;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    width: 100%;
    height: 100%;
    font: -moz-pull-down-menu;
    border: none;

    :deep(option) {
      font: -moz-pull-down-menu;
    }
  }
</style>
