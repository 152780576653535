<template>
  <div
    class="upcoming-countdown"
    :class="{'small-font': smallFont}"
  >
    <h2 class="date">
      {{ dateString }}
    </h2>
    <BaseCountdown
      :start-date="dateValue"
      :is-light="isLight"
      :small-font="smallFont"
      :is-with-reload="isWithReload"
      @on-update="$emit('onUpdate', $event)"
      @on-end="$emit('onEnd')"
    />
  </div>
</template>

<script>
import { formatWithTimezone } from '@/utility';

import { BaseCountdown } from '@/components/common/countdown';

export default {
	components: { BaseCountdown },
	props: {
		startDate: {
			type: [Date, String],
			required: true,
		},
		dateValue: {
			type: [Date, String, Number],
			required: true,
		},
		isLight: {
			type: Boolean,
			default: false,
			required: false,
		},
		smallFont: {
			type: Boolean,
			default: false,
			required: false,
		},
		isWithReload: [Boolean],
	},
	emits: ['onUpdate', 'onEnd'],
	computed: {
		dateString () {
			return formatWithTimezone(this.startDate, 'dddd MMMM D, ha');
		},
	},
};
</script>

<style scoped lang='scss'>
  .upcoming-countdown {
    &.small-font {
      .date {
        font-size: 16px;
      }
    }
  }

  .date {
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 1em;
    text-align: center;
    font-weight: normal;

    @include media-max-width($mediumScreen) {
      font-size: 20px;
    }
  }
</style>
