<template>
  <div class="nft-card">
    <div class="nft-media-wrapper">
      <slot name="mediaContainerInner" />
      <Media
        v-if="mediaUrl"
        fit="contain"
        :src="mediaUrl"
        autoplay
      />
    </div>
    <div class="nft-content">
      <slot name="content">
        <slot
          name="infoTop"
          :title="title"
          :collectionName="collectionName"
        >
          <InfoContainer
            :title="title"
            :collection-name="collectionName"
          />
        </slot>
        <slot name="infoBottom" />
      </slot>
    </div>
  </div>
</template>

<script>
import Media from '@/components/common/Media';

import InfoContainer from './components/InfoTop.vue';

export default {
	components: {
		Media,
		InfoContainer,
	},
	props: {
		title: String,
		mediaUrl: String,
		collectionName: String,
	},
};
</script>

<style scoped lang='scss'>
  .nft-card {
    display: flex;
    flex-direction: column;
    transition: box-shadow 200ms cubic-bezier(.215, .61, .355, 1);
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
    border: 1px solid transparent;
    max-width: 100%;
    border-radius: 0 0 30px 30px;

    &:hover {
      box-shadow: 0 14px 40px 0 rgb(0 0 0 / 25%);
    }

    &:active {
      box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
    }

    .nft-media-wrapper {
      display: flex;
      height: 304px;
      flex: 0 auto;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .nft-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
    }
  }
</style>
