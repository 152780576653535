<template>
  <TitledPage :title="`Reports (${formatNumber(totalCount, 0)})`">
    <TabsQueryRouter
      v-slot="{ activeTab }"
      :items="tabs"
      :is-sticky="false"
    >
      <TabsContentContainer class="report-tabs__container">
        <component :is="renderTabContentByType(activeTab)" />
      </TabsContentContainer>
    </TabsQueryRouter>
  </TitledPage>
</template>

<script>
import { assign } from 'lodash';
import { mapActions, mapState } from 'vuex';

import { TabsQueryRouter, TabsContentContainer } from '@/components/common/tabs';

import TitledPage from '@/layouts/components/TitledPage';

import { formatNumber } from '@/utility';

import NFTs from './sections/NFTs.vue';
import User from './sections/User.vue';
import Collections from './sections/Collections.vue';

export default {
	components: {
		TitledPage,
		TabsQueryRouter,
		TabsContentContainer,
	},
	data () {
		return {
			activeTab: 'nft',
			reportCounter: 0,
			tabs: [
				{ label: 'NFTs', value: 'nft' },
				{ label: 'Users', value: 'user' },
				{ label: 'Collections', value: 'collection' },
			],
			formatNumber,
		};
	},
	computed: {
		...mapState('reports', ['totalCount']),
		renderTabContentByType () {
			return type => {
				this.activeTab = type;
				switch (type) {
					case this.tabs[0].value:
						return NFTs;
					case this.tabs[1].value:
						return User;
					case this.tabs[2].value:
						return Collections;
					default:
						return null;
				}
			};
		},
	},
	watch: {
		activeTab (value) {
			this.changeActiveTab(value);
		},
	},
	async mounted () {
		await this.fetchReportsList(assign({}, { reset: true }, this.$route.query));
	},
	methods: {
		...mapActions('reports', ['changeActiveTab', 'fetchReportsList']),
	},
};
</script>

<style scoped lang="scss">
.report-tabs__container {
  padding-top: 24px;
}
</style>
