import { markRaw } from 'vue';

import Item from '../components/Item.vue';
import TimeStatus from '@/components/pages/announcements_and_airdrops/announcement-table/components/TimeStatus.vue';
import { tableAnnouncementBodyFormatter } from '@/components/ui/table/helpers/components';

const desktopConfig = {
	item: { width: { min: '352px', max: '352px' } },
	body: { width: { min: '350px', max: '700px' } },
	date: { width: { min: '156px', max: '156px' } },
};

const tabletConfig = {
	item: { width: { min: '352px', max: desktopConfig.item.width.min } },
	body: { width: { min: '350px', max: desktopConfig.body.width.min } },
	date: { width: { min: '140px', max: '140px' } },
};

function columns (isTablet = false, announcements, identifier) {
	return [
		{
			name: () => ({
				props: {
					isTitleColumn: true,
					announcements,
				},
				component: markRaw(Item),
			}),
			width: isTablet ? tabletConfig.item.width : desktopConfig.item.width,
			value: (data) => ({
				component: markRaw(Item),
				props: {
					announcement: data,
					announcements,
					identifier,
				},
			}),
		},
		{
			name: 'Body',
			width: isTablet ? tabletConfig.body.width : desktopConfig.body.width,
			value: (data) => tableAnnouncementBodyFormatter({ content: data.body }),
		},
		{
			name: 'Date',
			width: isTablet.value ? tabletConfig.date.width : desktopConfig.date.width,
			value: (data) => ({
				props: {
					date: new Date(parseFloat(data.sortTimestamp.split(':').at(0))),
				},
				component: markRaw(TimeStatus),
			}),
		},
	];
}

export default columns;
