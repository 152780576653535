<template>
  <div class="select-wrapper">
    <BaseDropdown
      ref="dropdown"
      class="dropdown-wrapper"
      :disabled="isDisabledDropdown"
    >
      <SelectButton
        class="select-action"
        icon-position="right"
        :has-error="hasError"
      >
        <template #icon>
          <slot
            v-if="isHasIcon"
            name="icon"
          />
          <ChevronDownIcon v-else />
        </template>
        <template #default>
          <div class="select-option-row">
            <div
              v-if="optionIcon"
              class="select-option-icon-wrapper"
            >
              <component
                :is="optionIcon"
                class="select-option-icon"
              />
            </div>
            <span class="select-button-text">{{ !!label ? label : modelValue?.label || '-' }}</span>
          </div>
        </template>
      </SelectButton>
      <template #dropdown>
        <DropdownContent
          :width="contentWidth"
          :max-height="contentMaxHeight"
          :height="contentHeight"
        >
          <SelectList
            :model-value="modelValue"
            :is-multiple="isMultiple"
            :is-clearable="isClearable"
            :options="options"
            @single-change="singleChange"
            @update:model-value="handleChange"
          />
        </DropdownContent>
      </template>
    </BaseDropdown>
    <ErrorMessage v-if="hasError">
      {{ errorMessage }}
    </ErrorMessage>
  </div>
</template>

<script>
import { BaseDropdown, DropdownContent } from '@/components/ui/baseDropdown';
import { ChevronDownIcon } from '@/components/icons';
import { ErrorMessage } from '@/components/ui/text-fields';

import SelectList from './SelectList.vue';
import SelectButton from './SelectButton.vue';

export default {
	components: {
		BaseDropdown,
		DropdownContent,
		SelectButton,
		ChevronDownIcon,
		SelectList,
		ErrorMessage,
	},
	props: {
		modelValue: {
			type: [Array, Object],
			default: null,
			required: false,
		},
		isMultiple: {
			type: Boolean,
			required: false,
			default: false,
		},
		isClearable: {
			type: Boolean,
			required: false,
			default: false,
		},
		label: {
			type: String,
			required: false,
		},
		options: {
			type: Array,
			required: true,
			default: () => [],
		},
		contentWidth: {
			type: String,
			required: false,
			default: '100%',
		},
		contentHeight: {
			type: String,
			required: false,
		},
		contentMaxHeight: {
			type: String,
			required: false,
		},
		isSelectWithOptionIcon: {
			type: Boolean,
			required: false,
		},
		errorMessage: {
			type: String,
			required: false,
		},
		isErrorMessageVisible: {
			type: Boolean,
			required: false,
			default: true,
		},
		isDisabledDropdown: {
			type: Boolean,
			required: false,
		},
	},
	emits: ['update:modelValue'],
	computed: {
		isHasIcon () {
			return !!this.$slots.icon;
		},
		hasError () {
			return this.isErrorMessageVisible && !!this.errorMessage;
		},
		optionIcon () {
			if (this.isSelectWithOptionIcon) {
				const foundOption = this.options.find(item => item?.value === this.modelValue?.value);

				if (foundOption) {
					return foundOption?.icon || null;
				}
			}

			return null;
		},
	},
	methods: {
		handleChange (option) {
			this.$emit('update:modelValue', option);
		},
		singleChange () {
			this.$refs?.dropdown?.click();
		},
	},
};
</script>

<style
  scoped
  lang="scss"
>
.select-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.dropdown-wrapper {
  :deep(.dropdown__list) {
    width: 100%;
  }
}

.dropdown-wrapper, .select-action {
  width: 100%;
}

.select-option-row {
  display: flex;
  align-items: center;
}

.select-option-icon-wrapper {
  display: flex;
}

.select-option-icon {
  margin-right: 10px;
}

.select-button-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}
</style>
