<template>
  <div class="list-wrapper">
    <DropNftCard
      v-for="(item, index) in list"
      :key="index"
      :status="status"
      :sold="item?.sold"
      :title="item?.name"
      :price="item?.price"
      :provider="mainState?.provider?.value"
      :starts-at="previewValues.startDate"
      :media-url="item?.mediaUrl"
      :hide-actions="hideActions"
      :collection-name="collectionName"
      :amount-for-sale="item?.amountForSale"
      @on-click-list-item="onClickListItem($event, item)"
    />
  </div>
</template>

<script>
import { DropNftCard } from '@/components/common/nft-card';

import { openDeleteNftModal } from '../../../modals/deletionModal/actions';
import { openCreateEditNftModal } from '../../../modals/createEditNftModal/actions';
import { getPreviewValues } from '../../../helpers';

export default {
	components: { DropNftCard },
	inject: ['mainState', 'formState'],
	computed: {
		previewValues () {
			return getPreviewValues(this.formState?.formValues);
		},
		list () {
			return this?.formState?.formValues?.nfts || [];
		},
		collectionName () {
			return this?.formState?.formValues?.collectionName || '';
		},
		status () {
			return this.mainState?.status?.value;
		},
		hideActions () {
			return !this.mainState?.notEditable?.value;
		},
	},
	methods: {
		onClickListItem (data, item) {
			data?.close?.();

			if (data.type === 'remove') {
				openDeleteNftModal({ item, state: this.formState });
			} else if (data.type === 'edit') {
				openCreateEditNftModal({ details: item, state: this.formState, mainState: this.mainState });
			}
		},
	},
};
</script>

<style scoped lang='scss'>
  .list-wrapper {
    padding: 30px 20px;
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;

    :deep(.card) {
      width: 100%;
      margin: 0;
    }

    @include media-max-width($smallScreen) {
      grid-template-columns: repeat(auto-fit, 100%);
    }
  }
</style>
