<template>
  <Select
    :label="label"
    :options="options"
    :error-message="errorMsg"
    :is-multiple="isMultiple"
    :model-value="inputValue"
    :is-clearable="isClearable"
    :content-width="contentWidth"
    :content-height="contentHeight"
    :content-max-height="contentMaxHeight"
    :is-error-message-visible="isHasErrorMessage"
    :is-select-with-option-icon="isSelectWithOptionIcon"
    @update:model-value="handleChange"
  />
</template>

<script>
import { useField } from 'vee-validate';

import { Select } from '@/components/ui/select';

export default {
	components: {
		Select,
	},
	props: {
		name: {
			type: String,
			required: true,
		},
		value: {
			type: Object,
			required: false,
			default: null,
		},
		isMultiple: {
			type: Boolean,
			required: false,
			default: false,
		},
		isClearable: {
			type: Boolean,
			required: false,
			default: false,
		},
		label: {
			type: String,
			required: false,
		},
		options: {
			type: Array,
			required: true,
			default: () => [],
		},
		contentWidth: {
			type: String,
			required: false,
			default: '100%',
		},
		contentHeight: {
			type: String,
			required: false,
		},
		contentMaxHeight: {
			type: String,
			required: false,
		},
		isSelectWithOptionIcon: {
			type: Boolean,
			required: false,
		},
	},
	setup (props) {
		const {
			value: inputValue,
			errorMessage,
			handleBlur,
			handleChange,
		} = useField(props?.name || '', undefined, {
			initialValue: props?.value,
		});

		return {
			inputValue,
			handleBlur,
			handleChange,
			errorMessage,
		};
	},
	computed: {
		errorMsg () {
			return this?.errorMessage || '';
		},
		isHasErrorMessage () {
			return !!this.errorMsg;
		},
	},
};
</script>
