import requestsService from './requests.service';

const settingsPath = `${process.env.VUE_APP_ADMIN_PANEL_BASE_URL}/admin/v1/settings`;

export const settingsService = {
	getSoftBannedCollections: async () => requestsService.get(`${settingsPath}?parameter=soft_banned_collections`),
	updateSoftBannedCollections: async data => requestsService.post(`${settingsPath}?parameter=soft_banned_collections`, data),
	getNotableCollections: async () => requestsService.get(`${settingsPath}?parameter=notable_collections`),
	updateNotableCollections: async data => requestsService.post(`${settingsPath}?parameter=notable_collections`, data),
};
