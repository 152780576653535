export default {
	getUsers: (state, payload) => {
		state.users = payload.users;
		state.count = payload.totalCount;
	},
	startLoading (state, { reset = true }) {
		if (reset) {
			state.users = [];
		}
		state.loading = true;
	},
	finishLoading (state) {
		state.loading = false;
	},
	toggleBan: (state, { id, action }) => {
		const forToggle = state.users.find(user => user.id === id);
		forToggle.userStatus = forToggle.userStatus === 'active' ? 'banned' : 'active';
		if (action === 'ban') {
			forToggle.username = null;
			forToggle.avatarUrl = null;
		}
	},
	setShowLength (state, payload) {
		state.showLength = payload;
	},
	setSearch (state, payload) {
		state.search = payload;
		state.offset = 0;
	},
	setSort (state, payload) {
		state.sort = payload;
		state.offset = 0;
	},
	setOffset (state, payload) {
		state.offset = payload;
	},
};
