<template>
  <div
    class="dropzone-wrapper"
    :class="classes"
    v-bind="getRootProps()"
  >
    <input
      :name="name"
      v-bind="getInputProps()"
      :disabled="isUploading"
    >
    <slot
      :isDragReject="isDragReject"
      :acceptedFiles="acceptedFiles"
      :fileRejections="fileRejections"
    >
      <div class="dropzone-info">
        <template v-if="!isUploading">
          <UploadIcon v-if="withIcon" />
          <p v-if="title">
            {{ title }}
          </p>
          <ErrorMessage v-if="!!error || !!externalError">
            {{ error || externalError }}
          </ErrorMessage>
        </template>
        <div v-else>
          <span>uploaded {{ fileUploadingProgress?.progress }} / all {{ fileUploadingProgress?.size }}</span>
          <LoadingSpinnerIcon class="spinner" />
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import { useDropzone } from 'vue3-dropzone';
import { ref, reactive, computed } from 'vue';
import { includes, flatMap, map } from 'lodash';

import { commonService } from '@/services';

import { UploadIcon } from '@/components/icons';
import ErrorMessage from '@/components/ui/text-fields/ErrorMessage';
import LoadingSpinnerIcon from '@/components/icons/LoadingSpinnerIcon';

import { formatBytes } from '@/utility';

export default {
	name: 'BaseDropzone',
	components: {
		UploadIcon,
		ErrorMessage,
		LoadingSpinnerIcon,
	},
	props: {
		accept: {
			type: Array,
			required: false,
		},
		name: {
			type: String,
			required: false,
		},
		title: {
			type: String,
			required: false,
			default: () => 'Drop your files here or click to upload',
		},
		maxFileSize: {
			type: Number, // bytes
			required: false,
		},
		type: {
			type: String,
			required: false,
		},
		isWithServerUploading: {
			type: Boolean,
			required: false,
			default: true,
		},
		externalError: {
			type: String,
			required: false,
			default: () => '',
		},
		withIcon: {
			type: Boolean,
			required: false,
			default: true,
		},
		address: {
			type: String,
			required: false,
		},
	},
	emits: ['onDrop'],
	setup (props, { emit }) {
		const error = ref(null);
		const isUploading = ref(false);
		const progressData = reactive({ uploaded: 0, fileSize: 0 });
		const fileUploadingProgress = computed(() => ({
			progress: formatBytes(progressData.uploaded),
			size: formatBytes(progressData.fileSize),
		}));

		const changeProgress = (val) => {
			progressData.uploaded = val;
		};

		const getFileStateByTarget = (fileRejections, target) => includes(flatMap(fileRejections, item => map(item?.errors || [], err => err?.code)), target);

		const serverUploading = async acceptedFiles => {
			progressData.fileSize = acceptedFiles[0].size;
			const chunks = await commonService.largeFile.getChunks(
				{
					uploadType: props.type,
					uploadSize: acceptedFiles[0].size,
					mimeType: acceptedFiles[0].type,
					address: props?.address,
				},
			);

			const uploadedPartsInfo = await commonService.largeFile.uploadParts(acceptedFiles[0], chunks.parts, chunks.parts[0].size, changeProgress);

			const linkForUploadedFile = await commonService.largeFile.completeMultiUpload(chunks.uploadId, uploadedPartsInfo);

			if (linkForUploadedFile) {
				emit('onDrop', linkForUploadedFile);
			}
		};

		const dropLogic = async acceptedFiles => {
			isUploading.value = true;

			try {
				if (props.isWithServerUploading && props.type) {
					await serverUploading(acceptedFiles);
				} else {
					emit('onDrop', acceptedFiles?.[0]);
				}
			} catch {
				error.value = 'Something went wrong';
			} finally {
				isUploading.value = false;
			}
		};

		const onDrop = async (acceptedFiles, fileRejections) => {
			if (isUploading.value) return;

			const isInvalidType = getFileStateByTarget(fileRejections, 'file-invalid-type');
			const isToLarge = getFileStateByTarget(fileRejections, 'file-too-large');

			if (isInvalidType) {
				error.value = 'Invalid file type';

				return;
			}

			if (isToLarge) {
				error.value = 'The uploading file is too large';

				return;
			}

			if (!acceptedFiles.length) return;

			if (error.value) {
				error.value = null;
			}

			await dropLogic(acceptedFiles);
		};

		const { getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles, fileRejections } = useDropzone({
			onDrop,
			accept: props?.accept,
			minSize: 0,
			multiple: false,
			maxSize: props?.maxFileSize,
		});

		return {
			error,
			isUploading,
			isDragActive,
			isDragReject,
			getRootProps,
			acceptedFiles,
			getInputProps,
			fileRejections,
			fileUploadingProgress,
		};
	},
	computed: {
		isFileLarge () {
			return !!this.maxFileSize && this.fileRejections.length > 0 && this.fileRejections[0].file.size > this.maxFileSize;
		},
		hasError () {
			return this.isDragReject || this.isFileLarge || !!this.error || this.externalError;
		},
		classes () {
			let classes = '';

			if (this.isDragActive) classes += 'active ';
			if (this.hasError) classes += 'error ';
			if (this.isUploading) classes += 'is-disabled ';

			return classes;
		},
	},
};
</script>

<style scoped lang='scss'>
  .dropzone-wrapper {
    position: relative;
    min-height: 110px;
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $violet;
    text-align: center;
    text-decoration: none;
    border-radius: 10px;
    border: 2px dashed rgba(113, 18, 255, .2);
    transition: border-color 200ms cubic-bezier(.215, .61, .355, 1), background-color 200ms cubic-bezier(.215, .61, .355, 1);
    cursor: pointer;

    .spinner {
      width: 60px;
      height: 60px;
    }

    &.is-disabled {
      cursor: default;
    }

    &:not(.is-disabled) {
      &:hover {
        border-color: rgba(113, 18, 255, .5);
        background-color: rgba(113, 18, 255, .05);
      }

      &:active, &.active {
        border-color: $violet;
        background-color: rgba(113, 18, 255, .1);
      }
    }

    &.error {
      color: $red;
      border-color: rgba(241, 40, 72, .20);

      &:not(.is-disabled) {
        &:hover {
          background-color: rgba(241, 40, 72, .05);
          border-color: rgba(241, 40, 72, .50);
        }

        &:active, &.active {
          border-color: $red;
          background-color: rgba(241, 40, 72, .1);
        }
      }
    }
  }
</style>
