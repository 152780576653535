<template>
  <Button
    v-if="isBanned"
    variant="outlined"
    size="flex"
    class="button-action button-action_accept button-action_unban"
    @click="openConfirmModal"
  >
    Unban
  </Button>
  <Button
    v-else-if="!isBanned"
    variant="outlined"
    size="flex"
    class="button-action button-action_accept button-action_ban"
    @click="openConfirmModal"
  >
    Ban
  </Button>
  <Button
    round
    variant="outlined"
    class="button-action button-action_accept"
    @click="toEditProfile"
  >
    <PencilIcon />
  </Button>
  <Button
    round
    variant="outlined"
    class="button-action button-action_accept share"
    @click="toProfile"
  >
    <ShareIcon />
  </Button>
</template>

<script>
import { defineComponent, shallowRef } from 'vue';

import Button from '@/components/ui/Button';
import BanModal from '@/pages/users/components/BanModal';
import { useStore } from 'vuex';
import PencilIcon from '@/components/icons/PencilIcon';
import ShareIcon from '@/components/icons/ShareIcon';
import { useRouter } from 'vue-router';
import routes from '@/router/routes';

const baseMarketplaceUrl = process.env.VUE_APP_WEBSITE_BASE_URL;

export default defineComponent({
	name: 'TableActions',
	components: {
		ShareIcon,
		PencilIcon,
		Button,
	},
	props: {
		isBanned: Boolean,
		id: String,
		ethAddress: String,
	},
	setup (props) {
		const store = useStore();
		const router = useRouter();

		const openConfirmModal = () => store.dispatch('modal/openModal', {
			content: shallowRef(BanModal),
			props,
		});

		const toProfile = () => {
			window.open(`${baseMarketplaceUrl}/${props.ethAddress}`);
		};

		const toEditProfile = () => {
			router.push({ path: `${routes.user}/${props.id}` });
		};

		return {
			openConfirmModal,
			toProfile,
			toEditProfile,
		};
	},
});
</script>

<style scoped lang="scss">
.button-action {
  font-size: 14px;
  &_accept {
    margin-right: 8px;
  }
  &_ban, &_unban {
    min-width: 88px;
  }
}
button.share svg {
  margin-left: 2px;
}
.ban-button {
  width: 88px;
}
</style>
