import capitalize from 'lodash/capitalize';

import { isCombinedCollection, formatAddress, formatDate } from '@/utility';

import Status from '@/components/ui/Status.vue';
import CopyButton from '@/components/ui/CopyButton.vue';

import { CollectionCell, TableActions, CustomCollectionCell } from '../components';

export const defaultColumns = [
	{ name: 'Date created', value: (data) => formatDate(data?.createdAt, 'EEE LLL d, p') },
	{ name: 'Collection name', value: (data) => <CollectionCell collection={data} /> },
	{
		name: 'Contract Address',
		value: (data) => data?.contractAddress
			? <CopyButton value={data?.contractAddress} label={formatAddress(data?.contractAddress || '')} />
			: '-',
	},
	{
		name: 'Status',
		value: (data) => <Status type={data?.collectionStatus}>
			{ capitalize(data?.collectionStatus) }
		</Status>,
	},
	{
		name: 'Actions',
		value: (data) => {
			const isCombineCollection = isCombinedCollection(data?.id);

			return <TableActions collection={data} hideEditButton={true} hideUnbanButton={isCombineCollection}
				hideBanButton={isCombineCollection} isCustomCollection={isCombineCollection} />;
		},
		width: '216px',
	},
];

export const customColumns = [
	{ name: 'Date created', value: (data) => formatDate(new Date(data?.createdAt), 'EEE LLL d, p') },
	{ name: 'Container name', value: (data) => <CustomCollectionCell collection={data} /> },
	{
		name: 'Type',
		value: (data) => capitalize(data.type),
	},
	{
		name: 'Actions',
		value: (data) => <TableActions isCustomCollection={true} collection={data} hideEditButton={true} hideBanButton={false} />,
		width: '216px',
	},
];

export const banModalText = `Chosen collection and all it's NFTs will be banned.
They will not be available on the website anymore and all trading operations will be seized for them.
You can restore the collection later by pressing the unban button.`;
