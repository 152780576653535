import { useWindowSize } from '@/utility';
import { computed } from 'vue';
import styles from '@/utility/formatted-styles.js';

export const useDeviceDetector = () => {
	const { width } = useWindowSize();

	const isTablet = computed(() => width.value > parseInt(styles.screenLg, 10)
		&& width.value <= parseInt(styles.screenLg, 10));
	const isMobile = computed(() => (width.value <= parseInt(styles.screenSm, 10)));
	const is3XlScreen = computed(() => (width.value > parseInt(styles.screen3Xl, 10)));

	return {
		isMobile,
		isTablet,
		is3XlScreen,
	};
};
