<template>
  <div :class="['lined-textarea-wrapper', `lined-textarea-wrapper_${color}`, !!errorMessage ? 'error' : undefined, disabled ? 'disabled' : undefined]">
    <LinedTextareaExternal
      :id="id"
      :name="name"
      :disabled="disabled"
      :value="value"
      v-bind="$attrs"
      :nowrap="nowrap"
      :styles="styles"
      class="lined-textarea"
      :class="`lined-textarea_${color}`"
      :placeholder="placeholder"
    />
    <ErrorMessage v-if="isErrorMessageVisible && !!errorMessage">
      {{ errorMessage }}
    </ErrorMessage>
  </div>
</template>

<script>
import ErrorMessage from './ErrorMessage.vue';
import LinedTextareaExternal from './LinedTextareaExternal.vue';

export default {
	components: {
		ErrorMessage,
		LinedTextareaExternal,
	},
	props: {
		id: {
			type: String,
			required: false,
		},
		errorMessage: {
			type: String,
			required: false,
		},
		isErrorMessageVisible: {
			type: Boolean,
			required: false,
			default: true,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		nowrap: [Boolean],
		placeholder: [String],
		styles: {
			type: Object,
			default: () => ({
				minHeight: '175px',
			}),
		},
		value: [String],
		name: [String],
		color: {
			type: String,
			default: 'purple',
			validator (value) {
				return ['purple', 'grey'].includes(value);
			},
		},
	},
};
</script>

<style scoped lang='scss'>
  .lined-textarea-wrapper {
    &.disabled {
      opacity: .5;
      cursor: default;
    }

    &:not(.disabled) {
      .lined-textarea {
        &_purple {
          &:hover {
            box-shadow: 0 0 0 1px rgba(113, 18, 255, .50);
          }

          &:focus-within {
            box-shadow: 0 0 0 2px $violet;
          }
        }

        &_grey {
          &:hover {
            border: 1px solid $shade60;
          }

          &:focus-within {
            border: 2px solid $shade60;
          }
        }
      }

      &.error {
        .lined-textarea {
          &:hover {
            box-shadow: 0 0 0 1px rgba(241, 40, 72, .50);
          }

          &:focus-within {
            box-shadow: 0 0 0 2px $red;
          }
        }
      }
    }

    &.error {
      .lined-textarea {
        box-shadow: 0 0 0 1px rgba(241, 40, 72, .20);
      }
    }
  }

  .lined-textarea {
    border-radius: 10px;
    background-color: $white;
    color: $grey;
    transition: 200ms cubic-bezier(.215, .61, .355, 1);
    width: 100%;

    &_purple {
      border: 1px solid transparent;
      box-shadow: 0 0 0 1px rgba(113, 18, 255, .20);
    }

    &_grey {
      border: 1px solid $shade10;
    }

    :deep(textarea) {
      &:disabled {
        background-color: inherit !important;
        color: inherit !important;
      }
    }

    :deep(.lined-textarea__lines) {
      background-color: $white;
      border: none;
      font-weight: 700;
      font-family: 'Inconsolata', monospace;
    }

    :deep(.lined-textarea__content) {
      padding: 10px 12px;
      border: none;
      resize: vertical;
    }

    &::placeholder {
      opacity: 1;
      color: rgba(0, 0, 0, .4);
      font: 400 13.3333px Arial;
    }
  }
</style>
