<template>
  <div
    class="status"
    :class="classes"
  >
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Status',
	props: {
		type: String,
	},
	computed: {
		classes () {
			let classes = '';

			if (this.type) {
				classes += this.type;
			}

			return classes;
		},
	},
});
</script>

<style scoped lang="scss">
.status {
  font-weight: 600;

  &.pending {
    color: $red;
  }

  &.done {
    color: $green;
  }

  &.banned {
    color: $red;
  }

  &.active {
    color: $green;
  }
}
</style>
