<template>
  <button
    class="button"
    :disabled="disabled"
    :class="classes"
    :style="{'width': `${width}px`}"
  >
    <slot />
  </button>
</template>

<script>
export default {
	props: {
		color: { // red, white, black, grey
			type: String,
			required: false,
		},
		variant: { // outlined
			type: String,
			required: false,
		},
		size: { // large, flex
			type: String,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		round: {
			type: Boolean,
			required: false,
			default: false,
		},
		isText: {
			type: Boolean,
			default: false,
		},
		upperCase: {
			type: Boolean,
			default: false,
		},
		width: {
			type: Number,
			required: false,
		},
	},
	computed: {
		classes () {
			let classes = '';

			if (this.color) classes += `${this.color} `;
			if (this.size) classes += `${this.size} `;
			if (this.variant) classes += this.variant;
			if (this.round) classes += ' round';
			if (this.isText) classes += ' text';
			if (this.isText) classes += ' text';
			if (this.upperCase) classes += ' uppercase';

			return classes;
		},
	},
};
</script>

<style scoped lang='scss'>
  .button {
    position: relative;
    z-index: 1;
    display: flex;
    height: 40px;
    padding: 0 15px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: $violet;
    transition: color 200ms cubic-bezier(.215, .61, .355, 1),
                background-color 200ms cubic-bezier(.215, .61, .355, 1),
                box-shadow 200ms cubic-bezier(.215, .61, .355, 1);
    color: $white;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: none;
    white-space: nowrap;
    -webkit-tap-highlight-color: transparent;

    &.uppercase {
      text-transform: uppercase;
    }

    &:not(:disabled) {
      &:hover {
        box-shadow: 0 10px 10px 0 rgb(0 0 0 / 22%), 0 14px 28px 0 rgb(0 0 0 / 25%);
      }

      &:active {
        box-shadow: none;
      }
    }

    &:disabled {
      background-color: $white !important;

      color: $shade60 !important;

      cursor: default;
      border: 1px solid $shade60 !important;

      &:hover {
        cursor: not-allowed;
      }
    }

    &.outlined {
      border: 1px solid $violet;
      background-color: $white;
      color: $violet;

      &:not(:disabled) {
        &:hover {
          background-color: $violet;
          color: $white;
        }
      }
    }

    &.red {
      background-color: $darkRed;
      color: $white;

      &.outlined {
        background-color: $white;
        border-color: $darkRed;
        color: $darkRed;

        &:not(:disabled) {
          &:hover {
            background-color: $darkRed;
            color: $white
          }
        }
      }
    }

    &.white {
      background-color: $white;
      color: $black;

      &.outlined {
        border-color: $black;
        background-color: transparent;
        color: $black;
        box-shadow: none;

        &:not(:disabled) {
          &:hover {
            background-color: $black;
            color: $white;
          }
        }
      }
    }

    &.black {
      background-color: $black;
      color: $white;

      &.outlined {
        border-color: $black;
        background-color: transparent;
        color: $black;
        box-shadow: none;

        &:not(:disabled) {
          &:hover {
            background-color: $black;
            color: $white;
          }
        }
      }
    }

    &.grey {
      background-color: $shade60;
      color: $white;

      &.outlined {
        border-color: $shade60;
        background-color: transparent;
        color: $shade60;
        box-shadow: none;

        &:not(:disabled) {
          &:hover {
            background-color: $shade60;
            color: $white;
          }
        }
      }
    }

    &.large {
      height: 60px;
      padding-left: 40px;
      padding-right: 40px;
      border-radius: 30px;
      font-size: 18px;
    }

    &.flex {
      flex: 1;
    }

    &.small {
      width: max-content;
    }

    &.round {
      padding: 10px;
      width: 40px;
      border-radius: 24px;
    }

    &.forConfirmModal {
      width: 120px;
      height: 40px;
      border-radius: 30px;
      font-size: 14px;
    }

    &.text {
      color: $black;
      background-color: $white;
      border: none;
      box-shadow: none !important;
      transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &.text:hover {
      background-color: $shade10;
    }

    &.text:active {
      background-color: $shade30;
    }
  }
</style>
