<template>
  <TitledPage :title="`Edit Custom Collection`">
    <div
      v-if="isLoading"
      class="loader-wrapper"
    >
      <LoadingSpinnerIcon class="spinner" />
    </div>
    <template v-else>
      <CollectionCell
        class="edit-container__heading"
        is-link
        :collection="{...values, id: identifier}"
      />
      <TabsQueryRouter
        v-slot="{ activeTab }"
        :items="editCustomCollectionTabs"
        :is-sticky="false"
      >
        <TabsContentContainer class="edit-container__tabs-container">
          <Metadata v-show="activeTab === editCustomCollectionTabs[0].value" />
          <Container
            v-show="activeTab === editCustomCollectionTabs[1].value"
            :collections="containerInfo?.collections"
            :container-id="containerInfo?.id"
            :action-type="containerInfo?.type"
          />
        </TabsContentContainer>
      </TabsQueryRouter>

      <div class="edit-container__actions">
        <Button
          :width="200"
          :disabled="disableSubmit"
          @click="onSubmit"
        >
          Save Changes
        </Button>

        <Button
          v-if="showDeleteButton"
          :width="200"
          color="red"
          variant="outlined"
          @click="openModalDeleteContainer"
        >
          Delete container
        </Button>
      </div>
    </template>
  </TitledPage>
</template>

<script setup>
import { useStore } from 'vuex';
import isEqual from 'lodash/isEqual';
import { useForm } from 'vee-validate';
import { useRoute, useRouter } from 'vue-router';
import { computed, defineProps, markRaw, onMounted, provide, ref, watch } from 'vue';

import {
	initValues,
	defaultValues,
	getValidationSchema,
	submitFormValues,
} from '@/pages/collections/helpers/form';
import Button from '@/components/ui/Button';
import { capitalizeFirstLetter } from '@/utility';
import TitledPage from '@/layouts/components/TitledPage';
import { addSuccess } from '@/components/utility/notifications';
import { CONTAINER_OPTIONS } from '@/pages/collections/constants';
import LoadingSpinnerIcon from '@/components/icons/LoadingSpinnerIcon';
import ConfirmationModal from '@/components/common/modal/ConfirmationModal';
import CollectionCell from '@/pages/collections/components/CollectionCell.vue';
import Metadata from '@/pages/collections/edit-container/sections/Metadata.vue';
import { TabsQueryRouter, TabsContentContainer } from '@/components/common/tabs';
import Container from '@/pages/collections/edit-container/sections/Container.vue';
import collectionContainerService from '@/services/collection-container.service';

const props = defineProps({
	collection: {
		type: Object,
		required: true,
		default: null,
	},
	identifier: {
		type: String,
		required: false,
		default: '',
	},
});

const route = useRoute();
const router = useRouter();
const store = useStore();

const { values, handleSubmit, setFieldValue, setValues, setErrors } = useForm({
	validationSchema: getValidationSchema({ isEdit: true }),
	initialValues: defaultValues,
});

const isLoading = ref(false);
const hasChanged = ref(false);
const isDeleting = ref(false);
const isSubmitting = ref(false);
const containerInfo = ref(null);

const editCustomCollectionTabs = computed(() => [
	{ label: `Metadata`, value: 'metadata' },
	{ label: `Container`, value: 'container' },
]);

const disableSubmit = computed(() => isSubmitting.value || !hasChanged.value);
const showDeleteButton = computed(() => containerInfo.value?.type === CONTAINER_OPTIONS.DRAFT);

const getContainerInfo = async (id) => {
	isLoading.value = true;
	try {
		containerInfo.value = await collectionContainerService.getContainer(id);
		setValues(initValues(containerInfo.value));
	} catch (e) {
		await router.push({
			path: '/collections',
			query: { tab: 'custom' },
		});
		throw new Error(e);
	} finally {
		isLoading.value = false;
	}
};

const updateContainer = async (data) => {
	try {
		isSubmitting.value = true;

		await collectionContainerService.editContainer(props.identifier, data);
		addSuccess('Changes successfully saved');

		if (containerInfo.value.type === CONTAINER_OPTIONS.DRAFT && data.replacementContractAddress) {
			await router.push({
				path: '/collections',
				query: { tab: 'all' },
			});

			return;
		}

		await router.push({
			path: '/collections',
			query: { tab: 'custom' },
		});
	} catch (error) {
		if (error.response?.data?.payload?.errors) {
			error.response.data.payload.errors.forEach((message) => {
				if (message.split(' ').includes('URL')) {
					setErrors({ slug: capitalizeFirstLetter(message) });
				}
			});
		}
		throw new Error(error);
	} finally {
		isSubmitting.value = false;
	}
};

const onSubmit = () => {
	const submit = handleSubmit(formValues => {
		submitFormValues({ update: updateContainer, actionType: containerInfo.value.type }, formValues);
	});

	submit();

	if ((!values.name || !values.slug) && route.query.tab === 'container') {
		router.push({ query: { tab: 'metadata' } });
	}
};

const openModalDeleteContainer = () => {
	store.dispatch('modal/openModal', {
		content: markRaw(ConfirmationModal),
		props: {
			modalStyles: 'max-width: 300px; display: flex; flex-direction: column; gap: 20px',
			title: 'Are you sure you want to delete container?',
			acceptText: 'Delete',
			acceptButtonProps: { color: 'black', action: deleteContainer },
			cancelButtonProps: { color: 'black' },
		},
	});
};

const deleteContainer = async () => {
	if (isDeleting.value) {
		return;
	}

	try {
		isDeleting.value = true;

		await collectionContainerService.deleteContainer(props.identifier);

		addSuccess('Container successfully deleted');

		await router.push({
			path: '/collections',
			query: { tab: 'custom' },
		});
	} catch (error) {
		throw new Error(error);
	} finally {
		isDeleting.value = false;
	}
};

onMounted(() => {
	getContainerInfo(props.identifier);
});

watch(() => values, () => {
	hasChanged.value = !isEqual(values, initValues(containerInfo.value));
}, {
	deep: true,
});

provide('formState', { setFieldValue, values });
</script>

<style lang="scss" scoped>
.edit-container {
  &__heading {
    margin: 0 0 20px;

    &:deep(.icon-text-content) {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__tabs {
    &-container {
      height: 100%;
      margin-bottom: 120px;

      &:deep(.labeled-wrapper) {
        label {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: $black;
          margin-bottom: 8px;
        }
      }

    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 20px;

    background: $white;
    border-width: 1px 0;
    border-style: solid;
    border-color: $table-border;
    position: fixed;
    bottom: 0;
    padding: 16px 20px;
    margin: 0 0 0 -20px;
    width: 100%;
    z-index: 1;
  }
}
</style>
