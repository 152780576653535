import * as Yup from 'yup';
import { get } from 'lodash';
import { addMinutes, isAfter } from 'date-fns';

import { ethereumAddressPattern, nullableEthereumAddressPattern } from '@/utility';

export const requiredMessage = 'Required field';

export const wrongFormat = 'Wrong format';
export const wrongRangeFormat = 'Wrong format. Example: 10;26;35;100-456';

export const positiveNumberText = 'Must be a positive number';
export const integerNumberText = 'Must be a integer number';

export const minStringMessage = min => `At least ${min} characters`;
export const maxStringMessage = max => `A maximum of ${max} characters`;

const minNumberMessage = min => `No less than ${min}`;
const maxNumberMessage = max => `No more than ${max}`;

export const stringSchema = Yup.string();

// eslint-disable-next-line no-nested-ternary
export const numberSchema = Yup.number().transform((currentValue, originalValue) => originalValue === '' ? undefined : isNaN(currentValue) ? undefined : currentValue);

const date = Yup.date().typeError('Wrong date format');

export const minString = min => stringSchema.nullable().min(min, params => minStringMessage(params.min));
export const maxString = max => stringSchema.nullable().max(max, params => maxStringMessage(params.max));
export const minMaxString = (min, max) => minString(min).concat(maxString(max));

export const minNumber = min => numberSchema.min(min, params => minNumberMessage(params.min));
export const maxNumber = max => numberSchema.max(max, params => maxNumberMessage(params.max));
export const minMaxNumber = (min, max) => minNumber(min).concat(maxNumber(max));
export const positiveNumber = numberSchema.positive(positiveNumberText);
export const integerNumber = numberSchema.integer(integerNumberText);

export const engLetters = stringSchema.matches(/^[a-zA-Z0-9!@#$%^&*)(:;,/\s+=._-]+$/g, 'Only English letters');

export const trim = stringSchema.trim('Spaces cannot be at the beginning or at the end').strict();

export const requiredString = (message = requiredMessage) => stringSchema.required(message);

export const leadingZerosString = (from = 1) => stringSchema.test('leading-zeros-string', wrongFormat, value => !(new RegExp(`^0{${from},}`).test(value)));

export const leadingZeros = (from = 1) => numberSchema.test('leading-zeros-number', wrongFormat, (_, ctx) => {
	const originalValue = ctx?.options?.originalValue || '';

	if (originalValue && originalValue !== '0') {
		return !(new RegExp(`^0{${from},}`).test(originalValue));
	}

	return true;
});

export const positiveNumberString = stringSchema.test('positive-string', positiveNumberText, value => {
	const trimmedValue = value ? value.trim() : value;
	const numberValue = Number(trimmedValue);

	if (!trimmedValue || isNaN(numberValue)) {
		return true;
	}

	return Math.sign(numberValue) > 0;
});

export const isNumericString = stringSchema.test('is-numeric-string', 'Must be a numeric value', value => {
	if (value) {
		return !isNaN(Number(value));
	}

	return true;
});

export const requiredNumber = (message = requiredMessage) => numberSchema.required(message).nullable();

export const requiredDate = (message = requiredMessage) => date.required(message).nullable();

export const requiredMixed = (message = requiredMessage) => Yup.mixed().required(message);

export const requiredArray = (message = requiredMessage) => Yup.array().compact().required(message);

export const object = fields => Yup.object().shape(fields);

export const email = (max = 500) => stringSchema.email(wrongFormat).concat(maxString(max));

export const url = stringSchema.nullable().url(wrongFormat);

export const requiredOption = (message = requiredMessage) => Yup.mixed().test({ name: 'optionTest', message, test: (value) => value?.value });

export const ethereumAddress = (message = 'Invalid Ethereum address') => stringSchema.matches(ethereumAddressPattern, message);

export const nullableEthereumAddress = (message = 'Invalid Ethereum address') => stringSchema.matches(nullableEthereumAddressPattern, message);

export const decimal = stringSchema.matches(/^[0-9]\d*(\.(\d{1,18}))?$/, { message: wrongFormat, excludeEmptyString: true });

export const timeAfter = ({ indent, errorMessage, fromKeys }) => Yup.string().test(
	'time-after',
	errorMessage || `Date must be at least ${indent} minutes after the current date`,
	(value, context) => {
		const start = addMinutes(fromKeys ? new Date(get(context.parent, fromKeys)) : new Date(), indent ? indent - 1 : 0);
		const end = new Date(value);
		return !isAfter(start, end);
	},
);

export const maxStringMasked = (max, maskLength) => Yup.string().max(max - maskLength, maxStringMessage(max));

export const range = (message) => stringSchema
	.test({
		name: 'rangeFormat',
		message: message || wrongRangeFormat,
		test: (value) => !value || new RegExp('^(((\\d+-\\d+)|(\\d+));)*((\\d+-\\d+)|(\\d+))$').test(value),
	});
