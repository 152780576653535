<template>
  <div class="indicators-wrapper">
    <div
      v-if="nameType && nameType.length !== 0"
      class="name-type-wrapper"
    >
      <MetaBlock
        v-for="item in nameType"
        :key="item?.id"
        :disabled="disabled"
        @on-delete="removeMeta(item?.id)"
      >
        <NameType
          :name="item?.name"
          :value="item?.value"
        />
      </MetaBlock>
    </div>
    <div
      v-if="rankings && rankings.length !== 0"
      class="rankings-wrapper"
    >
      <MetaBlock
        v-for="item in rankings"
        :key="item?.id"
        :disabled="disabled"
        @on-delete="removeMeta(item?.id)"
      >
        <RankingItem
          :label="item?.name"
          :current="item?.value"
          :total="item?.maxValue"
        />
      </MetaBlock>
    </div>
    <div
      v-if="boosts && boosts.length !== 0"
      class="boosts-wrapper"
    >
      <MetaBlock
        v-for="item in boosts"
        :key="item?.id"
        :disabled="disabled"
        @on-delete="removeMeta(item?.id)"
      >
        <BoostItem
          :name="item?.name"
          :current="item?.value"
          :is-percentage="item?.type === 'boost_percentage'"
          :total="item?.maxValue"
        />
      </MetaBlock>
    </div>
    <div
      v-if="stats && stats.length !== 0"
      class="stat-wrapper"
    >
      <MetaBlock
        v-for="item in stats"
        :key="item?.id"
        class="state-item"
        :disabled="disabled"
        @on-delete="removeMeta(item?.id)"
      >
        <StatItem
          :name="item?.name"
          :value="item?.value"
          :max-value="item?.maxValue"
        />
      </MetaBlock>
    </div>
  </div>
</template>

<script>
import StatItem from './components/StatItem.vue';
import NameType from './components/NameType.vue';
import BoostItem from './components/BoostItem.vue';
import MetaBlock from './components/MetaBlock.vue';
import RankingItem from './components/RankingItem.vue';

export default {
	components: {
		StatItem,
		NameType,
		BoostItem,
		MetaBlock,
		RankingItem,
	},
	inject: ['state'],
	props: {
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		metadata () {
			return this.state?.formValues?.metadata || [];
		},
		nameType () {
			return this.getListByType('name');
		},
		rankings () {
			return this.getListByType('ranking');
		},
		boosts () {
			return this.getListByType(['boost_number', 'boost_percentage']);
		},
		stats () {
			return this.getListByType('stats');
		},
	},
	methods: {
		getListByType (type) {
			return this.metadata.filter(item => {
				if (Array.isArray(type)) {
					return type.includes(item?.type);
				}

				return item?.type === type;
			});
		},
		removeMeta (id) {
			this.state.formValues.metadata = [...this.metadata].filter(item => item.id !== id);
		},
	},
};
</script>

<style scoped lang='scss'>
  .progress-item, .rankings-wrapper {
    margin-bottom: 20px;
  }

  .stat-wrapper {
    .state-item {
      margin-bottom: 20px;
    }
  }

  .boosts-wrapper {
    display: grid;
    margin-bottom: 20px;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    grid-auto-columns: 1fr;
  }

  .name-type-wrapper {
    display: grid;
    grid-auto-columns: 1fr;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    grid-template-rows: auto;
    margin-bottom: 20px;
  }

  .rankings-wrapper {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr;
  }
</style>
