<template>
  <div
    class="text-editor"
    :class="{ 'text-editor__error': props.error }"
  >
    <div class="text-editor__body">
      <QuillEditor
        v-show="tab === 'visual'"
        ref="quillEditorRef"
        class="text-editor__quill"
        :placeholder="props.placeholder"
        :mediahub-image-type="props.mediahubImageType"
        @update:model-value="handleQuillEditorUpdate"
      />
      <Textarea
        v-show="tab === 'text'"
        class="text-editor__textarea"
        :placeholder="props.placeholder"
        :value="textareaValue"
        @input="handleTexareaInput"
      />
    </div>

    <div class="text-editor__footer">
      <div class="text-editor__footer-stats">
        <slot name="error" />
        <div class="text-editor__footer-stats-item text-footnote">
          Characters: {{ stats.chars }}
        </div>
        <div class="text-editor__footer-stats-item text-footnote">
          Words: {{ stats.words }}
        </div>
      </div>
      <Tabs
        :model-value="tab"
        @update:model-value="handleTabUpdate"
      >
        <Tab
          v-for="{ key, title } of tabs"
          :key="key"
          :title="title"
          :value="key"
        />
      </Tabs>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineExpose, defineProps, reactive, ref } from 'vue';
import { reduce } from 'lodash';

import QuillEditor from './components/QuillEditor/QuillEditor.vue';
import Textarea from '@/components/ui/text-fields/Textarea.vue';
import Tabs from '@/components/ui/tabs/Tabs.vue';
import Tab from '@/components/ui/tabs/Tab.vue';

const quillEditorRef = ref(null);

const tabs = [
	{ key: 'visual', title: 'Visual' },
	{ key: 'text', title: 'Text' },
];

const stats = reactive({ chars: 0, words: 0 });

const textareaValue = ref(null);
const quillEditorValue = ref(null);
const tab = ref('visual');

const empty = computed(() => !quillEditorValue.value || quillEditorValue.value === '<p><br></p>');

const props = defineProps({
	modelValue: String,
	placeholder: String,
	mediahubImageType: String,
	error: Boolean,
});

const emit = defineEmits(['update:modelValue']);

function setStats () {
	stats.chars = empty.value ? 0 : quillEditorValue.value.length;
	stats.words = empty.value ? 0 : reduce(quillEditorRef.value.getContents().ops, (acc, val) => {
		if (!val.insert || typeof val.insert !== 'string') return acc;
		return acc + val.insert.replace(/(\s+|\n+)/g, ' ').trim().split(' ').length;
	}, 0);
}

function handleTexareaInput (event) {
	textareaValue.value = event.target.value;
	setValue(textareaValue.value);
	setStats();
	emit('update:modelValue', textareaValue.value);
}

function handleQuillEditorUpdate (value) {
	quillEditorValue.value = value;
	setStats();
	emit('update:modelValue', empty.value ? '' : quillEditorValue.value);
}

function handleTabUpdate (value) {
	if (value === 'visual') {
		setValue(textareaValue.value);
	} else {
		textareaValue.value = empty.value ? '' : quillEditorValue.value;
	}
	tab.value = value;
}

function getText () {
	return quillEditorRef.value.getText();
}

function setValue (value) {
	quillEditorRef.value.setModelValue(value);
}

defineExpose({ getText, setValue });
</script>

<style lang="scss">
.text-editor {
  width: 100%;
  height: 100%;
  @include flex(column, stretch);

  &__body {
    height: calc(100% - 56px);
    width: 100%;
    max-height: calc(100% - 56px);
  }

  &__quill {
    width: 100%;
    height: 100%;
  }

  // .quill-editor {
  //   width: 100%;
  //   border-color: $shade30;
  // }

  // &__error {
  //   .quill-editor {
  //     border: 1px solid $red;
  //   }
  // }

	// .ql-editor {
  //   height: calc(100% - 56px);
  //   max-height: 100%;
  //   background-color: $white;
  // }

  &__textarea {
    width: 100%;
    height: 100%;
    border-radius: 0 !important;
  }

  &__footer {
    width: 100%;
    @include flex(row, space-between, flex-start);

    &-stats {
      @include flex(column, center, flex-start);

      &-item {
        color: var(--color-shade40);
      }
    }

    .tab-container.is-active::after {
      bottom: unset;
      top: 0;
    }
  }
}
</style>
