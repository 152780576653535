export default {
	startLoading (state, { reset = true }) {
		if (reset) state.list = [];
		state.loading = true;
	},
	finishLoading (state) {
		state.loading = false;
	},
	setShowLength (state, payload) {
		state.showLength = payload;
	},
	setActiveTab (state, payload) {
		state.type = payload;
	},
	setOffset (state, payload) {
		state.offset = payload;
	},
	setSearch (state, payload) {
		state.search = payload;
	},
};
