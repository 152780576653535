import { ethers } from 'ethers';

import config from '@/config';
import { addError, addWarning } from '@/components/utility/notifications';
import { genUniqueKey } from '@/utility';

import requests from './requests.service';
import { ethersService } from './ethers.service';

const baseUrl = process.env.VUE_APP_DROPS_BASE_URL;

const basePath = `${baseUrl}/admin/v1/drops`;

const NULL_ADDRESS = `0x0000000000000000000000000000000000000000`;

const createContractData = values => {
	const drop = values?.drop;
	const nfts = values?.nfts || [];
	const whitelist = drop?.accessList || [];
	const hasWhitelist = whitelist.length !== 0;

	const collectionName = drop?.collectionName;
	const uri = `${process.env.VUE_APP_METADATA_BASE_URL}/${values.dropId}/{id}.json`;
	const proxyRegistry = '0xf57b2c51ded3a29e6891aba85459d600256cf317'; // Hardcoded value.
	const globalPurchaseLimit = drop.maxAllocation || 0;

	const getSupplyType = edition => {
		switch (edition) {
			case 'limited':
				return 0;
			case 'open':
				return 1;
			default:
				return undefined;
		}
	};

	// todo [hardcode]: remove hardcode & return correct type
	// switch (drop.issueType) {
	//     case 'numbered':
	//         return 0
	//     case 'unnumbered':
	//         return 1
	//     default:
	//         return undefined
	// }
	const getItemType = () => 0 // hardcoded value
	;

	const getAccessType = () => {
		switch (drop.access) {
			case 'public':
				return 0;
			case 'token': // ERC20
				return 1;
			case 'nft':
				return 2;
			case 'point':
				return 3;
			default:
				return undefined;
		}
	};

	const itemGroupInput = nfts.map(nft => {
		const supplyType = getSupplyType(nft.edition);
		const itemType = getItemType();

		const getSupplyData = () => {
			switch (supplyType) {
				case 0:
				case 1:
					return nft.maxQuantity;
				default:
					return undefined;
			}
		};

		const supplyData = getSupplyData();

		return {
			name: nft?.name,
			supplyType,
			supplyData,
			itemType,
			itemData: 0, // Ignore in MVP
			burnType: 1, // Burnable
			burnData: supplyData, // If Burnable for MVP is supplyData value.
		};
	});

	const poolInput = () => {
		const accessType = getAccessType();

		const getValuesByType = () => {
			switch (accessType) {
				case 0:
					return {
						requiredAsset: NULL_ADDRESS,
						requiredAmount: 0,
					};
				default:
					return undefined;
			}
		};

		const valuesByType = getValuesByType();

		return [{
			name: collectionName,
			startTime: new Date(drop.startsAt).getTime() / 1000,
			endTime: new Date(drop.endsAt).getTime() / 1000,
			purchaseLimit: globalPurchaseLimit,
			singlePurchaseLimit: 1,
			requirement: {
				requiredType: accessType,
				requiredAsset: valuesByType?.requiredAsset,
				requiredAmount: valuesByType?.requiredAmount,
				whitelistId: hasWhitelist ? 1 : 0,
			},
			collection: NULL_ADDRESS,
		}];
	};

	const getPoolConfigurationData = () => {
		const caps = [];
		const prices = [];
		const groupIds = [];
		const issueNumberOffsets = [];

		const getPriceData = priceValue => ({
			assetType: 1,
			asset: NULL_ADDRESS,
			price: ethers.utils.parseEther(priceValue),
		});

		nfts.forEach((nft, index) => {
			caps.push(nft.amountForSale);

			groupIds.push(index + 1);

			issueNumberOffsets.push(1);

			prices.push([getPriceData(nft.price ? nft.price.value : '0')]);
		});

		return {
			caps,
			prices,
			groupIds,
			issueNumberOffsets,
		};
	};

	const poolData = getPoolConfigurationData();

	const poolConfigurationData = [poolData.groupIds, poolData.issueNumberOffsets, poolData.caps, poolData.prices];

	const whiteListData = {
		expiryTime: ethers.constants.MaxUint256,
		isActive: true,
		addresses: hasWhitelist ? [values.owner, ...whitelist] : [NULL_ADDRESS],
	};

	const salt = ethers.utils.formatBytes32String(genUniqueKey());

	return {
		uri,
		salt,
		poolInput: poolInput(),
		whiteListData,
		proxyRegistry,
		collectionName,
		itemGroupInput,
		globalPurchaseLimit,
		poolConfigurationData,
	};
};

const getContractAddresses = async (id, dropFactory) => {
	const drop = await dropFactory.getExactDrop(id);

	if (drop && (drop.mintShop1155 !== NULL_ADDRESS || drop.super1155 !== NULL_ADDRESS)) {
		return {
			mintShop1155: drop.mintShop1155,
			owner: drop.owner,
			super1155: drop.super1155,
		};
	}
};

const createContract = async values => {
	try {
		const contractAddress = process.env.VUE_APP_WEBSITE_CONTRACT_ADDRESS;
		const provider = await ethersService.getProvider();
		const signer = await provider.getSigner();
		const owner = await signer.getAddress();
		const paymentReceiver = owner;
		const dropFactory = new ethers.Contract(contractAddress, config.dropFactoryABI, signer);

		const contractData = createContractData({ ...values, owner });

		const gasEstimate = await dropFactory.estimateGas.createDrop(
			owner,
			contractData.collectionName,
			contractData.uri,
			contractData.proxyRegistry,
			paymentReceiver,
			contractData.globalPurchaseLimit,
			contractData.itemGroupInput,
			contractData.poolInput,
			[contractData.poolConfigurationData],
			[contractData.whiteListData],
			contractData.salt,
		);

		const data = await dropFactory.createDrop(
			owner,
			contractData.collectionName,
			contractData.uri,
			contractData.proxyRegistry,
			paymentReceiver,
			contractData.globalPurchaseLimit,
			contractData.itemGroupInput,
			contractData.poolInput,
			[contractData.poolConfigurationData],
			[contractData.whiteListData],
			contractData.salt,
			{ gasLimit: gasEstimate },
		);

		await data.wait();

		return await getContractAddresses(contractData.salt, dropFactory);
	} catch (error) {
		if (error?.code !== 4001) {
			throw addError(error?.message || 'Something went wrong');
		}

		throw addWarning('Operation was cancelled');
	}
};

const dropsService = {
	getList: params => requests.get(basePath, { params }),
	getEnded: params => requests.get(`${basePath}/ended`, { params }),
	getTrash: params => requests.get(`${basePath}/trash`, { params }),
	getCounters: () => requests.get(`${basePath}/counters`),
	getDetails: id => requests.get(`${basePath}/${id}`),
	getDetailsNfts: id => requests.get(`${basePath}/${id}/nfts`),
	create: data => requests.post(basePath, data),
	update: (id, data) => requests.put(`${basePath}/${id}`, data),
	deploy: (id, data) => requests.put(`${basePath}/${id}/deploy`, data),
	moveToTrash: id => requests.put(`${basePath}/${id}/trash`),
	createContract,
};
export default dropsService;
