import { createApp } from 'vue';

import './assets/style/main.scss';

import router from './router';
import App from './App.vue';
import store from './store';

const app = createApp(App);

app.use(router).use(store).mount('#app');

// Injected properties is a ref and will be auto-unwrapped and no longer needs `.value` in the next minor release.
// To opt-in to the new behavior now, set `app.config.unwrapInjectedRef = true`
// (this config is temporary and will not be needed in the future)
app.config.unwrapInjectedRef = true;
