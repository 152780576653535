<template>
  <TextEditor
    ref="textEditorRef"
    :placeholder="props.placeholder"
    :mediahub-image-type="mediahubImageType"
    :error="errorMessage"
    :model-value="bodyValue"
    @update:model-value="handleModelValueUpdate"
  >
    <template
      v-if="errorMessage"
      #error
    >
      <div class="form-text-editor__error-message">
        {{ errorMessage }}
      </div>
    </template>
  </TextEditor>
</template>

<script setup>
import { defineEmits, defineExpose, defineProps, ref } from 'vue';
import { useField } from 'vee-validate';

import TextEditor from '@/components/common/text-editor/TextEditor.vue';

const props = defineProps({
	name: String,
	value: String,
	modelValue: String,
	placeholder: String,
	mediahubImageType: String,
});

const emit = defineEmits(['update:modelValue']);

const textEditorRef = ref(null);

const { value: bodyValue, setValue, errorMessage } = useField(props.name, undefined, { initialValue: props?.value });

function handleModelValueUpdate (handledValue) {
	setValue(handledValue);
	emit('update:modelValue', handledValue);
}

function getText () {
	return textEditorRef.value.getText();
}

function setTextEditorValue (value) {
	textEditorRef.value.setValue(value);
}

defineExpose({ getText, setTextEditorValue });
</script>

<style lang="scss">
.form-text-editor {
	&__error-message {
		font-size: 12px;
		color: var(--color-accent3);
		margin-top: 4px;
		margin-bottom: 8px;
	}
}
</style>
