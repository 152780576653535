import { ref, computed, onUnmounted } from 'vue';

const s = 1000;
const d = s * 60 * 60 * 24;
const h = s * 60 * 60;
const m = s * 60;

const getDistance = distance => {
	const currentDate = new Date();
	let value = 0;

	if (distance instanceof Date) {
		value = distance?.getTime() - currentDate.getTime();
	} else {
		switch (typeof distance) {
			case 'string':
				value = new Date(distance)?.getTime() - currentDate.getTime();

				break;
			case 'number':
				value = distance;

				break;
			default:
				value = 0;
				break;
		}
	}

	return value < 0 ? 0 : value;
};

const useCountdown = (dateValue, { onUpdate, onEnd }) => {
	const days = ref(0);
	const hours = ref(0);
	const minutes = ref(0);
	const seconds = ref(0);
	const distance = ref(getDistance(dateValue));

	const showDays = computed(() => distance.value > d);
	const showHours = computed(() => distance.value > h);
	const showMinutes = computed(() => distance.value > m);
	const showSeconds = computed(() => distance.value > s);

	let interval = null;

	const initValues = () => {
		if (!dateValue) return;

		distance.value -= s;

		days.value = Math.floor(distance.value / d);
		hours.value = Math.floor((distance.value % d) / h);
		minutes.value = Math.floor((distance.value % h) / m);
		seconds.value = Math.floor((distance.value % m) / s);
	};

	const startTimer = () => {
		initValues();

		interval = setInterval(() => {
			initValues();

			onUpdate?.(distance.value);

			if (distance.value <= s) {
				clearInterval(interval);

				onEnd?.();
			}
		}, s);
	};

	const stopTimer = () => {
		clearInterval(interval);

		days.value = 0;
		hours.value = 0;
		minutes.value = 0;
		seconds.value = 0;
		distance.value = 0;
	};

	startTimer();

	const reinitialize = date => {
		stopTimer();

		distance.value = getDistance(date);

		startTimer();
	};

	onUnmounted(() => {
		stopTimer();
	});

	return { days, hours, minutes, seconds, distance, showDays, showHours, showMinutes, showSeconds, reinitialize };
};

export default useCountdown;
