<template>
  <BaseContentContainer>
    <FormContainer @submit="submitAction" />
    <CardPreviewContainer />

    <template #preview>
      <PreviewContent @on-init="setPreviewInstance" />
    </template>
  </BaseContentContainer>
</template>

<script>
import { provide, inject } from 'vue';
import { useField, useForm } from 'vee-validate';

import PreviewContent from '../preview-content/index.vue';
import FormContainer from './components/FormContainer.vue';
import CardPreviewContainer from '../CardPreviewContainer.vue';
import BaseContentContainer from '../BaseContentContainer.vue';
import { validationSchema, mapFormToRequestData, getInitialValues } from './helpers';

export default {
	components: {
		FormContainer,
		PreviewContent,
		CardPreviewContainer,
		BaseContentContainer,
	},
	setup () {
		const { data, isNew } = inject('mainState');
		const setPreviewInstance = inject('setPreviewInstance');

		const { handleSubmit, values: formValues, setFieldValue } = useForm({
			initialValues: getInitialValues(data?.initialValues.value),
			validationSchema,
		});

		useField('nfts');
		useField('removeNFTs');

		const onSubmit = handleSubmit(async values => data.submit(mapFormToRequestData(values, isNew.value)));

		const submitAction = type => {
			setFieldValue('type', type);

			onSubmit();
		};

		provide('formState', {
			formValues,
			setFieldValue,
		});

		return {
			submitAction,
			setPreviewInstance,
		};
	},
};
</script>
