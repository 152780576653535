<template>
  <ModalContentContainer class="create-edit-wrapper">
    <component
      :is="renderContentByProvider"
      :modal-props="modalProps"
    />
  </ModalContentContainer>
</template>

<script>
import { providerTypes } from '@/pages/drops/helpers';
import { ModalContentContainer } from '@/components/common/modal';

import ImxModal from './imx-modal/index.vue';
import EthModal from './eth-modal/index.vue';

export default {
	components: {
		ModalContentContainer,
	},
	computed: {
		modalProps () {
			return this.$attrs?.modalProps;
		},
		renderContentByProvider () {
			switch (this.modalProps?.mainState.provider) {
				case providerTypes.IMX:
					return ImxModal;
				case providerTypes.ETHEREUM:
					return EthModal;
				default:
					return null;
			}
		},
	},
};
</script>

<style scoped lang='scss'>
  .create-edit-wrapper {
    display: flex;
    flex-direction: column;
    width: 780px;
  }
</style>
