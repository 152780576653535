<template>
  <div class="top-wrapper">
    <Button
      class="actions-block__add-button"
      variant="outlined"
      @click="goToAddContainer"
    >
      + Add a container
    </Button>
  </div>
  <Table
    :columns="customColumns"
    :data="collections"
    :loading="loading"
    :pagination="{limit: 100}"
    class="collections-slot"
  />
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import Button from '@/components/ui/Button';
import Table from '@/components/ui/table/Table';

import { customColumns } from '../main/helpers';

const store = useStore();
const route = useRoute();
const router = useRouter();

const loading = computed(() => store.state.collections.loading);
const collections = computed(() => store.state.collections.customCollections);

const goToAddContainer = () => {
	router.push('/collections/add-container');
};

onMounted(async () => {
	await router.push({ path: route.path, query: { ...route.query, limit: 100 } });

	store.dispatch('collections/getCollections', route.query);
});
</script>

<style lang="scss" scoped>
.top-wrapper {
  margin: 0 0 20px;

  &:deep(.button) {
    width: max-content;
  }
}
</style>
