import { usersService } from '@/services';
import { detectChanges } from '@/utility';
import { addSuccess } from '@/components/utility/notifications';

export default {
	async getProfile ({ commit }, { id }) {
		commit('startLoading');

		try {
			const response = await usersService.getUser(id);
			commit('getProfile', response);
		} finally {
			commit('finishLoading');
		}
	},
	async updateProfile ({ state, commit }, payload) {
		const params = detectChanges(state.initState, payload.updatedValues);

		try {
			await usersService.editUser(payload.id, params);
			addSuccess('Changes successfully saved');
			commit('updateProfile', payload);
		} catch {
			// noop
		}
	},
	async toggleBan ({ commit, state, dispatch }) {
		if (state.profile.userStatus === 'banned') {
			await usersService.unbanUser(state.profile.id);
			commit('toggleBan', 'active');
		} else {
			await usersService.banUser(state.profile.id);
			await dispatch('getProfile', { id: state.profile.id });
			commit('toggleBan', 'banned');
		}
	},
};
