<template>
  <div class="table-body">
    <slot
      v-if="!loading && data.length === 0"
      name="placeholder"
    />
    <template v-else-if="!loading">
      <transition-group
        :name="rowAnimationName"
        appear
        mode="out-in"
        tag="div"
      >
        <TableListItem
          v-for="(dataItem, dataKey) in data"
          :key="columns?.[0].value(dataItem)?.subKey || dataItem?.id || dataKey"
          :cell-padding="cellPadding"
          :columns="columns"
          :data-key="dataKey"
          :grid-styles="gridStyles"
          :loading="loading"
          :row-animation-name="rowAnimationName"
          :row-click="rowClick"
          :row-url="rowUrlGetter ? rowUrlGetter(dataItem) : undefined"
          :row-hover="rowHover"
          :selected-checkboxes="selectedCheckboxes"
          :source="dataItem"
          :with-bulk-actions="withBulkActions"
          :with-different-row="withDifferentRow"
          class="table-list-item"
          @checkbox-click="handleCheckboxClick"
        />
      </transition-group>
    </template>
    <slot
      v-else-if="loading"
      name="loading"
    />
  </div>
</template>

<script setup>
import { inject, defineEmits, defineProps } from 'vue';
import TableListItem
	from '@/components/pages/announcements_and_airdrops/announcement-table/table/components/body/TableListItem';

const emit = defineEmits(['checkboxClick']);

defineProps({
	rowHover: Boolean,
	rowClick: { type: Function, default: undefined },
	withDifferentRow: { type: Boolean, default: false },
	cellPadding: String,
	rowAnimationName: String,
	enableListVirtualization: { type: Boolean, required: false, default: false },
	virtualScrollingItemSize: { type: [String, Number], required: false, default: 0 },
	virtualScrollingBuffer: { type: Number, required: false, default: 200 },
	rowUrlGetter: { type: Function, default: undefined },
});

const data = inject('data');
const loading = inject('loading');
const columns = inject('columns');
const gridStyles = inject('gridStyles');
const withBulkActions = inject('withBulkActions');
const selectedCheckboxes = inject('selectedCheckboxes');

function handleCheckboxClick (data) {
	emit('checkboxClick', data);
}
</script>

<style lang="scss">
.table-body {
	display: grid;
	grid-auto-rows: auto;
	grid-template-columns: 100%;

	.checkbox {
		margin-right: 16px;
	}
}

.secondary-color {
	background: var(--color-shade-list);
}

.scroller {
	height: 100%;
}

</style>
