<template>
  <img
    v-if="logoUrl"
    :src="logoUrl"
    class="provider-logo"
    :class="[value ? value : undefined]"
  >
</template>

<script>
import { providerTypes } from '@/pages/drops/helpers';

export default {
	props: {
		value: {
			type: String,
			required: false,
		},
	},
	computed: {
		logoUrl () {
			switch (this.value) {
				case providerTypes.IMX:
					return require('@/images/logos/imx-logo.png');
				case providerTypes.ETHEREUM:
					return require('@/images/logos/ethereum-logo.png');
				default:
					return null;
			}
		},
	},
};
</script>

<style scoped lang='scss'>
  .provider-logo {
    width: 240px;
    border-radius: 10px;
    display: inline-block;

    &.imx {
      padding: 20px;
      background-color: #000;
    }
  }
</style>
