<template>
  <div class="error-message">
    <h3>{{ text }}</h3>
  </div>
</template>

<script>
export default {
	props: {
		text: {
			type: String,
			required: false,
			default: 'Failed to get information',
		},
	},
};
</script>

<style scoped lang='scss'>
  .error-message {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $red;
    flex: 1;
  }
</style>
