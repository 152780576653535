<template>
  <Tabs
    :model-value="activeTab"
    :is-sticky="isSticky"
    :sticky-offset="stickyOffset"
    @update:model-value="changeActiveTab"
  >
    <Tab
      v-for="(item, index) in items"
      :key="index"
      :value="item?.value"
    >
      {{ item?.label }}
    </Tab>
  </Tabs>
  <slot :activeTab="activeTab" />
</template>

<script>
import { Tabs, Tab } from './';

export default {
	components: { Tabs, Tab },
	props: {
		items: {
			type: Array,
			required: true,
			default: () => [],
		},
		isSticky: {
			type: Boolean,
			required: false,
			default: true,
		},
		stickyOffset: {
			type: [String, Number],
			required: false,
		},
	},
	data () {
		return {
			activeTab: this.items[0]?.value || '',
		};
	},
	watch: {
		$route () {
			this.setActiveTab();
		},
	},
	created () {
		this.setActiveTab(true);
	},
	methods: {
		changeActiveTab (value) {
			if (value !== this.activeTab) {
				this.$router.push({
					path: this.$route.path,
					query: { ...this.$route.query, tab: value },
				});
			}
		},
		setActiveTab (isInit = false) {
			const foundItem = this.items.find(item => item?.value === this.$route.query?.tab);
			const active = foundItem?.value || (this.items[0]?.value || '');

			this.activeTab = active;

			if (isInit) {
				this.$router.replace({
					path: this.$route.path,
					query: { ...this.$route.query, tab: active },
				});
			}
		},
	},
};
</script>
