<template>
  <div class="edit-form">
    <span
      class="edit-form__header"
      @click="transitionTo"
    >
      {{ collection?.contractAddress }}
    </span>
    <TopActions
      :collection-id="collection.id"
      :collection-address="collection.contractAddress"
    />
    <AnnouncementAndAirdrops
      v-if="identifier"
      class="edit-form__announcements-links"
      :identifier="identifier"
    />
    <div class="edit-form__form">
      <div class="edit-form__form-field">
        <CollectionNameField field-name="name" />
      </div>
      <div class="edit-form__form-field">
        <DescriptionField field-name="description" />
      </div>
      <div class="edit-form__form-field">
        <ThumbnailField
          field-name="avatarUrl"
          :contract-address="collection?.contractAddress"
          is-big-label
        />
      </div>
      <div class="edit-form__form-field">
        <BannerField
          field-name="bannerUrl"
          :contract-address="collection?.contractAddress"
          is-big-label
        />
      </div>
      <BaseDivider class="form__divider" />
      <div class="edit-form__form-field">
        <CustomURLField
          field-name="slug"
          is-big-label
        />
      </div>
      <BaseDivider class="form__divider" />
      <div class="edit-form__form-field">
        <WebsitesAndSocialLinksField
          :fields-name="['websiteUrl', 'socialLinks']"
          is-big-label
        />
      </div>
      <BaseDivider class="form__divider" />
      <div class="edit-form__form-field">
        <VerifiedCollectionField
          field-name="isVerified"
        />
      </div>
      <BaseDivider class="form__divider-secondary" />
      <div class="edit-form__form-field">
        <PaymentTokensField field-name="tokens" />
      </div>
      <BaseDivider class="form__divider" />
      <div class="edit-form__form-field">
        <CollectionAnalyticsField
          is-big-label
        />
      </div>
      <BaseDivider class="form__divider" />
      <div class="form__action-btns">
        <Button
          type="submit"
          class="action_btns__btn_main"
          :width="416"
          :disabled="updateLoading || !isChanged"
          @click="onSubmit"
        >
          Save Changes
        </Button>
        <Button
          class="action_btns__btn_secondary"
          :disabled="banLoading"
          :color="banBtnColor"
          variant="outlined"
          @click="changeBan"
        >
          {{ banBtnText }} collection
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useForm } from 'vee-validate';
import { isEqual, forEach } from 'lodash';
import { computed, provide, ref, shallowRef, watch, defineProps } from 'vue';

import Button from '@/components/ui/Button';
import { capitalizeFirstLetter } from '@/utility';
import BaseDivider from '@/components/ui/BaseDivider';
import BanModal from '@/pages/collections/components/BanModal';
import TopActions from '@/pages/collections/edit/components/TopActions';
import BannerField from '@/pages/collections/components/form-fields/BannerField.vue';
import ThumbnailField from '@/pages/collections/components/form-fields/ThumbnailField.vue';
import CustomURLField from '@/pages/collections/components/form-fields/CustomURLField.vue';
import DescriptionField from '@/pages/collections/components/form-fields/DescriptionField.vue';
import PaymentTokensField from '@/pages/collections/components/form-fields/PaymentTokensField.vue';
import CollectionNameField from '@/pages/collections/components/form-fields/CollectionNameField.vue';
import AnnouncementAndAirdrops from '@/pages/collections/edit/components/AnnouncementAndAirdrops';
import VerifiedCollectionField from '@/pages/collections/components/form-fields/VerifiedCollectionField.vue';
import CollectionAnalyticsField from '@/pages/collections/components/form-fields/CollectionAnalyticsField.vue';
import WebsitesAndSocialLinksField from '@/pages/collections/components/form-fields/WebsitesAndSocialLinksField.vue';

import { initValues, validationSchema, submitFormValues } from './helpers';

const props = defineProps({
	collection: {
		type: Object,
		required: true,
		default: null,
	},
	identifier: {
		type: String,
		required: false,
		default: '',
	},
});

const marketplaceUrl = process.env.VUE_APP_WEBSITE_BASE_URL;
const store = useStore();
const banBtnText = computed(() => props.collection.collectionStatus === 'active' ? 'Ban' : 'Unban');
const banBtnColor = computed(() => props.collection.collectionStatus === 'active' ? 'red' : 'grey');
const isChanged = ref(false);
const updateLoading = ref(false);
const banLoading = ref(false);
const isSubmitting = ref(false);

const update = async payload => {
	updateLoading.value = true;
	await store.dispatch('editCollection/updateCollection', { id: props.collection.id, updatedValues: payload })
		.finally(() => {
			updateLoading.value = false;
			isChanged.value = false;
		});
};

const changeBan = computed(() => banBtnText.value === 'Unban'
	? async () => {
		banLoading.value = true;
		await store.dispatch('editCollection/toggleBan')
			.finally(() => { banLoading.value = false; });
	}
	: () => store.dispatch('modal/openModal', {
		content: shallowRef(BanModal),
		props: { ...props.collection, fromEditing: true },
	}));

const { values, handleSubmit, setFieldValue, setErrors } = useForm({
	initialValues: initValues(props.collection),
	validationSchema,
});

const onSubmit = handleSubmit(async values => {
	isSubmitting.value = true;

	try {
		await submitFormValues({ update }, values);
	} catch (error) {
		if (error.response?.data?.payload?.errors) {
			forEach(error.response.data.payload.errors, (message) => {
				if (message.split(' ').includes('URL')) {
					setErrors({ slug: capitalizeFirstLetter(message) });
				}
			});
		}
	} finally {
		isSubmitting.value = false;
	}
});

const transitionTo = () => {
	window.open(`${marketplaceUrl}/collections/${props.collection.slug || props.collection.contractAddress}`);
};

watch(values, (values) => {
	isChanged.value = !isEqual(values, initValues(store.state.editCollection.initState));
});

provide('formState', { setFieldValue, values });
</script>

<style scoped lang="scss">
.edit-form {
  max-width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__announcements-links {
    margin-top: 15px;
  }

  &__header {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 36px;
    margin-bottom: 16px;
    cursor: pointer;
  }

  &__form {
    width: 100%;
    min-width: 510px;
    margin-top: 3px;

    &-field {
      &:deep(.labeled-wrapper) {
        .label {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: $black;
          margin: 24px 0 4px;
        }

        .form__title_small {
          display: block;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          margin: 18px 0 12px 0;
        }
      }
    }

    .form {
      &__action-btns {
        display: flex;

        button:first-child {
          min-width: 235px;
          margin-right: 13px;
        }
      }

      &__divider {
        margin: 25px 0 19px 0;

        &-secondary {
          margin-top: 19px;
        }
      }
    }
  }
}
</style>
