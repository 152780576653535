<template>
  <div class="wrapper">
    <Tag
      color="red"
      :is-rounded="false"
    >
      Live Now
    </Tag>
    <div
      v-if="isInit"
      class="end-in"
    >
      ends in
      <span v-if="showDays">{{ days }}d </span>
      <span v-if="showHours">{{ hours }}h </span>
      <span v-if="showMinutes">{{ minutes }}m </span>
      <span v-if="showSeconds">{{ seconds }}s </span>
    </div>
  </div>
</template>

<script>
import Tag from '@/components/ui/Tag';

import { useCountdown } from '@/components/common/countdown';

export default {
	components: { Tag },
	props: {
		distance: [Date, String, Number],
	},
	emits: ['onEnd', 'onUpdate'],
	setup (props, { emit }) {
		if (props?.distance) {
			const data = useCountdown(props.distance, {
				onEnd: () => {
					emit('onEnd');
				},
				onUpdate: distance => {
					emit('onUpdate', distance);
				},
			});

			return { ...data, isInit: true };
		}

		return { isInit: false };
	},
};
</script>

<style scoped lang='scss'>
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .end-in {
    margin: 5px auto 0 auto;
  }
</style>
