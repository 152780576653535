<template>
  <Textarea
    :id="id"
    :name="name"
    :value="inputValue"
    :placeholder="placeholder"
    :error-message="errorMsg"
    :is-error-message-visible="isHasErrorMessage"
    @input="handleChange"
    @blur="handleBlur"
  />
</template>

<script>
import { useField } from 'vee-validate';

import { Textarea } from '@/components/ui/text-fields';

export default {
	components: { Textarea },
	props: {
		name: {
			type: String,
			required: true,
		},
		id: {
			type: String,
			required: false,
		},
		value: {
			type: String,
			required: false,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
	},
	setup (props) {
		const {
			value: inputValue,
			errorMessage,
			handleBlur,
			handleChange,
		} = useField(props?.name || '', undefined, {
			initialValue: props?.value,
		});

		return {
			inputValue,
			handleBlur,
			handleChange,
			errorMessage,
		};
	},
	computed: {
		errorMsg () {
			return this?.errorMessage || '';
		},
		isHasErrorMessage () {
			return !!this.errorMsg;
		},
	},
};
</script>
