<template>
  <div class="action-btns">
    <!-- Remove && false when unban feature will be implemented -->
    <Button
      v-if="status === 'banned' && false"
      class="action-btns__ban-unban-btn"
      size="flex"
      :disabled="unbanLoading"
      @click="unban"
    >
      Unban
    </Button>
    <Button
      v-else-if="status !== 'banned'"
      class="action-btns__ban-unban-btn"
      size="flex"
      @click="openBanModal"
    >
      Ban
    </Button>
    <!--    Uncomment when edit-page will be added-->
    <!--    <Button v-if="type !== 'nft'" variant="outlined" round @click="transitionToEdit"><PencilIcon /></Button>-->
    <Button
      class="share"
      variant="outlined"
      round
      @click="transitionToMarketplace"
    >
      <ShareIcon />
    </Button>
  </div>
</template>

<script setup>
import { defineProps, shallowRef, ref, computed } from 'vue';
import { useStore } from 'vuex';

import Button from '@/components/ui/Button.vue';
import BanModal from '@/pages/banned/components/BanModal.vue';
import { ShareIcon } from '@/components/icons';

import { marketplaceRoutes } from '@/pages/banned/helpers';
import { refLoadingWrapper } from '@/utility';

const baseMarketplaceUrl = process.env.VUE_APP_WEBSITE_BASE_URL;
const store = useStore();
const type = computed(() => store.state.banned.type);

const props = defineProps({
	status: { type: String, required: true },
	id: { type: String, required: true },
	ethAddress: { type: String, required: false },
	tokenId: { type: String, required: false },
	collection: { type: Object, required: false },
});

const openBanModal = () => {
	store.dispatch('modal/openModal', {
		content: shallowRef(BanModal),
		props,
	});
};

const unbanLoading = ref(false);

const unban = async () => {
	await refLoadingWrapper(
		async () => {
			await store.dispatch('banned/unban', props.id);
		},
		unbanLoading,
	);
};

const transitionToMarketplace = () => {
	window.open(`${baseMarketplaceUrl}/${marketplaceRoutes[type.value](props)}`);
};
</script>

<style scoped lang="scss">
  .action-btns {
    display: flex;

    button:not(:first-child) {
      margin-left: 8px;
    }

    &__ban-unban-btn {
      min-width: 88px;
    }
    button.share svg {
      margin-left: 2px;
    }
  }
</style>
