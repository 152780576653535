<template>
  <section
    class="titled-page-wrapper"
    :class="{'with-title-content': hasTitleContent}"
  >
    <div class="title-wrapper">
      <h3 class="title">
        {{ title }}
      </h3>
      <slot
        v-if="hasTitleContent"
        name="titleContent"
      />
    </div>
    <slot />
  </section>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: true,
		},
	},
	computed: {
		hasTitleContent () {
			return !!this.$slots.titleContent;
		},
	},
};
</script>

<style scoped lang='scss'>
  .titled-page-wrapper {
    width: 100%;
    height: 100%;

    .title-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    .title {
      font-size: 20px;
      font-weight: 700;
    }

    &.with-title-content {
      .title {
        margin-right: 20px;
      }
    }
  }
</style>
