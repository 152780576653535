<template>
  <ConfirmationModal
    :title="forModal.title"
    :accept-text="forModal.acceptBtnText"
    :accept-button-props="{ color: 'black' }"
    :cancel-button-props="{ color: 'black' }"
    :cancel-text="forModal.declineBtnText"
    :disable-btns="banLoading"
    @on-accept="onAccept"
  >
    <div class="ban-message">
      {{ forModal.text }}
    </div>
  </ConfirmationModal>
</template>

<script setup>
import { ref, useAttrs } from 'vue';
import { useStore } from 'vuex';
import { ConfirmationModal } from '@/components/common/modal';
import { confirmModalContent } from '@/components/common/modal/data/confirmModalContent';
import { ethersService } from '@/services';

const store = useStore();
const attrs = useAttrs();
const id = attrs.modalProps.id;
const banLoading = ref(false);
const forModal = confirmModalContent.collaborator;

const onAccept = async () => {
	banLoading.value = true;

	try {
		const signerAddress = store.state.ethers?.address?.toLowerCase();
		const collectionAddress = store.state.editCollection?.collection?.contractAddress?.toLowerCase();
		const userAddress = attrs.modalProps?.address?.toLowerCase();
		const provider = await ethersService.getProvider();
		const signer = await provider.getSigner();
		const signatureTime = Date.now();
		const message = `I, ${signerAddress}, want to delete the address ${userAddress} from the collection ${collectionAddress}.\n`
        + `Current time: ${`${new Date(signatureTime).toLocaleString('en-US', { timeZone: 'UTC' })} (UTC)`}`;
		const signature = await signer.signMessage(message);

		await store.dispatch('editCollection/removeCollaborator', { userId: id, signature, signatureTime });
		store.dispatch('modal/closeModal');
	} finally {
		banLoading.value = false;
	}
};
</script>

<style lang="scss" scoped>
.ban-message {
  max-width: 360px;
  padding: 12px 24px 24px;
  text-align: center;
}
</style>
