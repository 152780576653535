export const COLLECTIONS_CONSTANTS = {
	MIN_COUNT_FOR_MERGE_COLLECTIONS: 2,
	MAX_COUNT_FOR_MERGE_COLLECTIONS: 20,
	MIN_COUNT_FOR_SPLIT_COLLECTION: 1,
	MAX_COUNT_FOR_SPLIT_COLLECTIONS: 20,
};

export const CONTAINER_OPTIONS = {
	MERGE: 'merge',
	SPLIT: 'split',
	DRAFT: 'draft',
};

export const COLLECTION_STATUS = {
	BANNED: 'banned',
	ACTIVE: 'active',
};

export const COLLECTION_ERRORS = {
	INCLUDED_IN_MERGE: 'This collection is already included in another merge container',
	INCLUDED_IN_CONTAINER: 'This collection is already included in another container',
	NOT_FOUND: 'Collection not found',
	IS_BANNED: 'This collection is banned',
	ALREADY_IN_LIST: 'Collection is already in the list',
	ALREADY_EXISTS: 'This collection already exists. You can edit existing collection from the list of all collections.',
};
