<template>
  <div class="airdrop-date text-body-16">
    <CalendarIcon />
    <span
      v-if="title"
      class="title"
    >{{ title }}</span>
    <span class="airdrop-date__date-formatted">{{ formattedDate }}</span>
  </div>
</template>

<script setup>
import { computed, defineExpose, defineProps } from 'vue';
import CalendarIcon from '@/components/icons/CalendarIcon.vue';
import * as fns from 'date-fns';
import enUS from 'date-fns/locale/en-US';

const props = defineProps({
	date: {
		type: Number,
		required: false,
		default: 0,
	},
	title: {
		type: String,
		required: false,
		default: '',
	},
});

const formattedDate = computed(() => fns.format(props.date, 'MMM dd, yyyy hh:mm a', { locale: enUS }));

defineExpose({ formattedDate });
</script>

<style lang="scss" scoped>
.airdrop-date {
	@include flex(row, flex-start, center);
	gap: 6px;
}
</style>
