<template>
  <ModalContentContainer class="provider-select-wrapper">
    <ModalCloseButton class="modal-close-button" />
    <div class="title-wrapper">
      <h3>Choose a network</h3>
    </div>
    <router-link
      v-for="(item, index) in list"
      :key="index"
      class="item"
      :to="item.path"
    >
      <div
        class="card"
        :class="[item?.color ? item.color : undefined]"
      >
        <img :src="item.imagePath">
      </div>
    </router-link>
  </ModalContentContainer>
</template>

<script>
import routes from '@/router/routes';
import { ModalCloseButton, ModalContentContainer } from '@/components/common/modal';

import { providerTypes } from '../../helpers';

export default {
	components: {
		ModalCloseButton,
		ModalContentContainer,
	},
	data () {
		return {
			list: [
				{
					path: `${routes.drops}/${providerTypes.ETHEREUM}`,
					imagePath: require('@/images/logos/ethereum-logo-secondary.png'),
				},
				{
					color: 'black',
					path: `${routes.drops}/${providerTypes.IMX}`,
					imagePath: require('@/images/logos/imx-logo.png'),
				},
			],
		};
	},
};
</script>

<style scoped lang='scss'>
.provider-select-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 440px;
  border-radius: 60px;
  padding: 40px;
}

.modal-close-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.title-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;

  h3 {
    font-size: 24px;
    line-height: 1.3em;
    font-weight: 600;
  }
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 78px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
  background-color: $white;
  transition: box-shadow 200ms cubic-bezier(.215, .61, .355, 1);
  border-radius: 20px;

  &.black {
    background-color: #000;
    color: $white;
  }

  img {
    max-width: 185px;
    display: inline-block;
  }

  &:hover {
    box-shadow: 0 14px 40px 0 rgb(0 0 0 / 25%);
  }

  &:active {
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 12%);
  }
}

.item {
  display: inline-block;
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
</style>
