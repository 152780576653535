<template>
  <div class="payment-token-logo-wrapper">
    <Media
      v-if="mediaUrl && !isTokenImageLoadingError"
      fit="contain"
      :backend-image-src="mediaUrl"
      class="payment-token-logo-wrapper__logo"
      @on-error="handleTokenImageLoadingError"
    />
    <div
      v-else
      class="payment-token-logo-wrapper__placeholder"
    >
      <TokensIcon />
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';

import { TokensIcon } from '@/components/icons';
import Media from '@/components/common/Media.vue';

import { getUrlOfCurrencyLogo } from '@/utility';

export default {
	components: {
		Media,
		TokensIcon,
	},
	props: {
		contractAddress: {
			type: String,
			required: true,
			default: '',
		},
		size: {
			type: String,
			required: false,
			default: '40px',
		},
	},
	setup (props) {
		const isTokenImageLoadingError = ref(false);

		const mediaUrl = computed(() => {
			if (props.contractAddress) {
				return getUrlOfCurrencyLogo({ width: 40, height: 40, tokenAddress: props.contractAddress });
			}

			return '';
		});

		const handleTokenImageLoadingError = () => {
			if (!isTokenImageLoadingError.value) {
				isTokenImageLoadingError.value = true;
			}
		};

		return {
			mediaUrl,
			isTokenImageLoadingError,
			handleTokenImageLoadingError,
		};
	},
};
</script>

<style
  scoped
  lang="scss"
>
.payment-token-logo-wrapper {
  width: v-bind(size);
  height: v-bind(size);
  align-self: center;
  justify-self: center;

  &__placeholder {
    width: v-bind(size);
    height: v-bind(size);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $shade30;
    border-radius: 50%;
  }
}
</style>
