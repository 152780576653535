<template>
  <div class="drop-actions">
    <router-link
      v-if="isDraft || isTrashed"
      :to="viewDropPath"
    >
      <Button
        class="action"
        variant="outlined"
        color="white"
      >
        View Drop
      </Button>
    </router-link>
    <a
      v-else
      :href="viewDropPath"
      target="_blank"
    >
      <Button
        class="action"
        variant="outlined"
        color="white"
      >View Drop</Button>
    </a>
    <router-link :to="editDropPath">
      <Button
        class="action"
        variant="outlined"
        color="white"
      >
        Edit Drop
      </Button>
    </router-link>
    <template v-if="isDraft">
      <Button
        color="white"
        class="action"
        variant="outlined"
        :disabled="isActionLoading"
        @click="actionByType('deploy')"
      >
        Deploy Drop
      </Button>
      <Button
        color="white"
        class="action"
        variant="outlined"
        :disabled="isActionLoading"
        @click="actionByType('trash')"
      >
        Move to Trash
      </Button>
    </template>
  </div>
</template>
<script>
import { omit } from 'lodash';

import routes from '@/router/routes';
import { dropsService } from '@/services';
import Button from '@/components/ui/Button';

import { providerTypes } from '../../../helpers';

export default {
	components: { Button },
	props: {
		dropId: {
			type: String,
			required: true,
		},
		status: {
			type: String,
			required: false,
		},
		provider: {
			type: String,
			required: true,
		},
	},
	emits: ['deployed', 'trashed'],
	data () {
		return {
			isActionLoading: false,
		};
	},
	computed: {
		isDraft () {
			return this.status === 'draft';
		},
		isTrashed () {
			return this.status === 'trash';
		},
		editDropPath () {
			return `${routes.drops}/${this.dropId}`;
		},
		viewDropPath () {
			if (this.isDraft || this.isTrashed) {
				return `${this.editDropPath}?preview=true`;
			}
			return `${process.env.VUE_APP_WEBSITE_BASE_URL}/drops/${this.dropId}`;
		},
	},
	methods: {
		async actionByType (type) {
			this.isActionLoading = true;
			try {
				switch (type) {
					case 'deploy':
						await this.onDeploy();
						break;
					case 'trash':
						await this.onDecline();
						break;
					default:
						break;
				}
			} finally {
				this.isActionLoading = false;
			}
		},
		async onDeploy () {
			try {
				let contractAddress = null;
				let contractAddressSuper = null;

				if (this.provider === providerTypes.ETHEREUM) {
					const [dropData, nftsList] = await Promise.all([
						dropsService.getDetails(this.dropId),
						dropsService.getDetailsNfts(this.dropId),
					]);

					if ((dropData && nftsList) && !dropData?.contractAddress) {
						const requestData = {
							dropId: this.dropId,
							drop: {
								creatorUsername: dropData.creator.username,
								collectionName: dropData.collection.name,
								title: dropData.title,
								description: dropData.description,
								access: dropData.access,
								accessList: dropData.accessList,
								issueType: dropData.issueType,
								maxAllocation: dropData.maxAllocation,
								thumbnailUrl: dropData.thumbnailUrl,
								bannerUrl: dropData.bannerUrl,
								startsAt: dropData.startsAt,
								endsAt: dropData.endsAt,
							},
							nfts: nftsList.nfts.map(item => (omit(item, ['collection', 'views', 'totalMinted']))),
						};

						const contractData = await dropsService.createContract(requestData);

						if (contractData) {
							contractAddress = contractData.mintShop1155;
							contractAddressSuper = contractData.super1155;
						}
					}
				}

				const isBothContracts = !!contractAddress && !!contractAddressSuper;

				await dropsService.deploy(this.dropId, isBothContracts ? {
					contractAddress,
					contractAddressSuper,
				} : undefined);

				await this.$emit('deployed');
			} finally {
				// noop
			}
		},
		async onDecline () {
			await dropsService.moveToTrash(this.dropId);
			await this.$emit('trashed');
		},
	},
};
</script>
<style scoped lang='scss'>
.drop-actions {
  display: grid;
  gap: 10px;
  grid-auto-flow: row;
  grid-template-columns: repeat(2, 1fr);

  a {
    .action {
      width: 100%;
    }
  }
}
</style>
