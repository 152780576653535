<template>
  <div class="progress-wrapper">
    <div
      class="progress-row"
      :class="{'with-label': label}"
    >
      <span
        v-if="label"
        class="progress-name"
      >{{ label }}</span>
      <span class="progress-value">{{ value }}</span>
    </div>
    <Progress
      v-if="hasBothValues"
      class="progress-bar"
      :total="total"
      :current="current"
    />
  </div>
</template>

<script>
import Progress from './Progress.vue';

export default {
	components: { Progress },
	props: {
		label: {
			type: String,
			required: false,
		},
		current: {
			type: [Number, String],
			required: false,
			default: 0,
		},
		total: {
			type: [Number, String],
			required: false,
			default: 0,
		},
	},
	computed: {
		hasBothValues () {
			return this.current && this.total;
		},
		value () {
			if (this.hasBothValues) {
				return `${this.current} of ${this.total}`;
			}

			return this.current || this.total || '-';
		},
	},
};
</script>

<style scoped lang='scss'>
  .progress-wrapper {
    width: 100%;

    .progress-bar {
      margin-top: 10px;
    }
  }

  .progress-row {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.with-label {
      justify-content: space-between;
    }

    .progress-name {
      font-weight: 500;
      flex: 1;
      word-break: break-word;
    }

    .progress-value {
      margin-left: 16px;
      opacity: .5;
      word-break: break-word;
    }
  }
</style>
