import { markRaw } from 'vue';
import { useStore } from 'vuex';

import AnnouncementPreviewModal from '../AnnouncementPreviewModal.vue';

function useAnnouncementPreviewModal () {
	const store = useStore();

	async function openAnnouncementPreviewModal (announcement) {
		await store.dispatch('modal/openModal', {
			content: markRaw(AnnouncementPreviewModal),
			props: { announcement },
			type: 'announcement-preview',
		});
	}

	return { openAnnouncementPreviewModal };
}

export default useAnnouncementPreviewModal;
