export class StorageService {
	#storage = window.localStorage;

	get = key => this.#storage.getItem(key);

	set = (key, value) => this.#storage.setItem(key, value);

	remove = key => this.#storage.removeItem(key);
}

const localStorage = new StorageService();

export default localStorage;
