<template>
  <div class="remaining-wrapper">
    <div class="total-remaining">
      <strong>{{ remaining }}</strong>
      <span class="total-remaining-label">remaining</span>
    </div>
    <ProgressBar
      v-if="isShowProgress"
      :remaining="remaining"
      :total="total"
    />
  </div>
</template>

<script>
import ProgressBar from '@/components/ui/ProgressBar';

export default {
	components: {
		ProgressBar,
	},
	props: {
		total: {
			type: Number,
			required: true,
			default: 0,
		},
		remaining: {
			type: Number,
			required: true,
			default: 0,
		},
		isUpcoming: {
			type: Boolean,
			required: false,
		},
		isShowProgress: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
};
</script>

<style scoped lang='scss'>
  .remaining-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .total-remaining {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &-label {
        margin-left: 5px;
        opacity: 0.5;
      }
    }
  }
</style>
