<template>
  <TextField
    :id="id"
    :name="name"
    :value="fieldValue"
    :disabled="disabled"
    :placeholder="placeholder"
    :error-message="fieldErrorMessage"
    :is-error-message-visible="!!fieldErrorMessage"
    type="text"
    class="slug-field"
    @input="onFieldChange"
    @blur="handleBlur"
  >
    <template #beforeInput>
      <span class="slug-field__mask">
        https://[site name]/collections/
      </span>
    </template>
  </TextField>
</template>

<script setup>
import { computed } from 'vue';
import { useField } from 'vee-validate';

import { TextField } from '@/components/ui/text-fields';

// eslint-disable-next-line no-undef
const props = defineProps({
	id: { required: false, type: String },
	disabled: { required: false, type: Boolean },
	name: { required: false, type: String, default: 'slug' },
	autoFormattingLetterCase: { required: false, type: Boolean, default: false },
	placeholder: { required: false, type: String, default: 'Enter your custom URL' },
});

const {
	handleBlur,
	handleChange,
	errorMessage,
	value: fieldValue,
} = useField(props?.name || '');

const fieldErrorMessage = computed(() => errorMessage.value || '');

function onFieldChange (event) {
	if (props.autoFormattingLetterCase && event?.target?.value) {
		event.target.value = event.target.value.toLowerCase() || '';
	}

	handleChange(event);
}
</script>

<style lang="scss">
.slug-field {
  &.field-wrapper.error {
    .field-inner {
      box-shadow: 0 0 0 1px rgba(241, 40, 72, .20);

      &:focus-within {
        box-shadow: 0 0 0 2px $red;
      }
    }

    .field {
      box-shadow: none;

      &:not(:disabled) {
        &:hover {
          box-shadow: none;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .field-inner {
    position: relative;
    display: flex;
    align-items: center;
    outline: none;
    padding: 8px 12px;
    border: 1px none transparent;
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    border-radius: 10px;
    background-color: $white;
    color: $grey;
    box-shadow: 0 0 0 1px rgb(113 18 255 / 20%);
    width: 100%;
    @include defaultTransition(border-color);

    &:focus-within {
      box-shadow: 0 0 0 2px $violet;
    }

    &:hover {
      box-shadow: 0 0 0 1px rgba(113, 18, 255, .50);
    }
  }

  .field {
    border: none;
    outline: none;
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    color: $grey;
    flex-grow: 1;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    @include defaultTransition(border-color);

    &:not(:disabled) {
      &:hover {
        box-shadow: none !important;
      }

      &:focus {
        box-shadow: none !important;
      }
    }
  }

  &__mask {
    display: block;
    white-space: nowrap;
  }
}
</style>
