<template>
  <div class="table-container">
    <div class="table-container__inner">
      <div
        v-if="!loading"
        class="table"
      >
        <Head @head-check="handleHeadCheckbox" />
        <Body
          @row-click="onRowClick"
          @checkbox-click="handleCheckbox"
        />
      </div>
      <Placeholder v-if="!loading && data.length === 0" />
      <LoadingSpinnerIcon v-else-if="loading" />
    </div>
    <div
      v-if="props.pagination"
      class="table__pagination"
    >
      <Pagination @offset:change="onOffsetChanged" />
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { computed, defineEmits, defineProps, provide, readonly, watch } from 'vue';

import LoadingSpinnerIcon from '@/components/icons/LoadingSpinnerIcon';

import { generateGrid } from './helpers';
import Pagination from '../Pagination.vue';
import Body from './components/body/Body.vue';
import Head from './components/head/Head.vue';
import Placeholder from './components/Placeholder.vue';

const route = useRoute();
const router = useRouter();

const emit = defineEmits(['rowClick', 'offset:change', 'checked', 'headChecked']);

const props = defineProps({
	data: { type: Array, default: () => [] },
	columns: { type: Array, default: () => [] },
	withCheckbox: { type: Boolean, required: false, default: false },
	pagination: { type: Object, required: false, default: undefined },
	loading: { type: Boolean, default: false },
	checkboxValue: { type: Function, required: false, default: undefined },
	headCheckbox: { type: Boolean, required: false, default: false },
});

const gridStyles = computed(() => generateGrid(props.columns, props.withCheckbox));

provide('gridStyles', readonly(gridStyles));
provide('data', computed(() => props.data));
provide('columns', computed(() => props.columns));
provide('withCheckbox', computed(() => props.withCheckbox));
provide('checkboxValue', props.checkboxValue);
provide('headCheckbox', computed(() => props.headCheckbox));
provide('total', computed(() => props.pagination?.total));
provide('limit', computed(() => props.pagination?.limit));
provide('offset', computed(() => props.pagination?.offset));

const onRowClick = (data) => {
	emit('rowClick', data);
};

const onOffsetChanged = (offset) => {
	emit('offset:change', offset);
	router.replace({
		query: { ...route.query, limit: props.pagination?.limit, offset },
	});
};

const handleCheckbox = (item) => {
	emit('checked', item);
};

const handleHeadCheckbox = () => {
	emit('headChecked');
};

watch(
	() => props.pagination?.limit,
	(value) => {
		router.replace({
			query: { ...route.query, limit: value, offset: props.pagination?.offset },
		});
	},
);

const stopWatchLoading = watch(
	() => props.loading,
	(value) => {
		if (!value) {
			router.replace({
				query: { ...route.query, limit: props.pagination?.limit, offset: props.pagination?.offset },
			});
			stopWatchLoading();
		}
	},
);
</script>

<style scoped lang="scss">
.table {
  width: 100%;
  border: 2px solid $table-border;

  &__pagination {
    display: flex;
    justify-content: flex-end;
    padding-top: 40px;
  }
}
</style>
