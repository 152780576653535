<template>
  <div
    class="announcements-list-placeholder"
  >
    <NoEventsIcon
      class="announcements-list-placeholder__icon"
      size="64px"
    />
    <p class="announcements-list-placeholder__description">
      {{ placeholderText }}
    </p>
    <Button
      class="announcements-list-placeholder__button"
      upper-case
      @click="handleClickButton"
    >
      {{ buttonLabel }}
    </Button>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import Button from '@/components/ui/Button';
import { NoEventsIcon } from '@/components/icons';

defineProps({
	buttonLabel: {
		type: String,
		required: true,
	},
	placeholderText: {
		type: String,
		required: true,
	},
});

const emit = defineEmits(['click-button']);

const handleClickButton = () => emit('click-button');
</script>

<style lang="scss" scoped>
//@import '@/assets/style/typography';

.announcements-list-placeholder {
	@include flex(column, flex-start);
	text-align: center;

	&__description {
		margin-top: 16px;
		//@extend .text-subhead;
	}

	&__button {
		margin-top: 24px;
	}
}
</style>
