<template>
  <TextField
    class="search-field"
    :value="modelValue"
    is-rounded
    :placeholder="placeholder"
    @input="onChangeField"
    @keyup-enter="finishChange"
  >
    <template #inputIcon>
      <LoupeIcon class="icon" />
    </template>
  </TextField>
</template>

<script>
import { debounce, assign, has } from 'lodash';

import { LoupeIcon } from '@/components/icons';

import TextField from './TextField.vue';

export default {
	components: {
		LoupeIcon,
		TextField,
	},
	props: {
		modelValue: {
			type: String,
			required: false,
		},
		placeholder: {
			type: String,
			default: 'Search',
			required: false,
		},
		debounceTime: {
			type: Number,
			default: 300,
			required: false,
		},
		toQueryParam: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	emits: ['update:modelValue', 'enter:modelValue'],
	watch: {
		modelValue (value) {
			if (value === '') this.passValueToQueryParameter(value);
		},
	},
	created () {
		this.updateValue = debounce(this.updateValue, this.debounceTime);
	},
	methods: {
		onChangeField (e) {
			this.updateValue(e);
		},
		updateValue (e) {
			this.$emit('update:modelValue', e.target.value);
		},
		finishChange (e) {
			const value = e.target.value;
			this.$emit('enter:modelValue', value);
			if (this.toQueryParam) this.passValueToQueryParameter(value);
		},
		passValueToQueryParameter (value) {
			const query = assign({}, this.$route.query);
			if (!value && has(query, 'search')) delete query.search;
			if (value) assign(query, { search: value });
			this.$router.replace({ query });
		},
	},
};
</script>

<style scoped lang='scss'>
  .search-field {
    :deep(input) {
      &::placeholder {
        font-weight: 500;
      }
    }
  }

  .icon {
    color: $shade30;
  }
</style>
