<template>
  <div class="quill-editor__toolbar">
    <Tooltip
      v-for="button of buttons"
      :key="button.key"
      :value="button.label"
      hide-tooltip
    >
      <button
        v-if="!button.type || button.type === 'default'"
        :id="button.value ? `${button.key}-${button.value}` : `${button.key}`"
        type="button"
        class="quill-editor__toolbar-button default"
        :class="{
          'pressed': props.range && (button.hasOwnProperty('value')
            ? props.format?.[button.key] === button.value
            : props.format?.[button.key])
        }"
        @click="handleButtonClick($event, button)"
      >
        <component
          :is="button.icon"
          :size="button.iconSize || '16px'"
        />
      </button>
      <label
        v-if="button.type === 'file'"
        :id="button.key"
        class="quill-editor__toolbar-button file"
        @click.stop="handleFileLabelClick($event, button)"
      >
        <input
          type="file"
          :accept="button.accept"
          :name="button.key"
          @change="handleButtonClick($event, button)"
        >
        <component
          :is="button.icon"
          :size="button.iconSize || '16px'"
        />
      </label>
    </Tooltip>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, markRaw, computed } from 'vue';
import { isUndefined } from 'lodash';

import Tooltip from '@/components/ui/Tooltip.vue';
import {
	AlignCenterIcon,
	AlignLeftIcon,
	AlignRightIcon,
	BlockquoteIcon,
	BoldIcon,
	ImageIcon,
	ItalicIcon,
	LinkCurvedIcon,
	ListBulletIcon,
	ListOrderedIcon,
	StrikeIcon,
	UnlinkCurvedIcon,
} from '@/components/icons/text-editor';

const props = defineProps({
	format: Object,
	range: Object,
	mediahubImageType: String,
});

const emit = defineEmits(['formatChange', 'addLinkClick', 'unlinkClick', 'addFileClick']);

const buttons = computed(() => {
	const returnValue = [
		{ label: 'Bold', key: 'bold', icon: markRaw(BoldIcon) },
		{ label: 'Italic', key: 'italic', icon: markRaw(ItalicIcon) },
		{ label: 'Strike', key: 'strike', icon: markRaw(StrikeIcon), iconSize: '24px' },
		{ label: 'List bullet', key: 'list', icon: markRaw(ListBulletIcon), value: 'bullet' },
		{ label: 'List ordered', key: 'list', icon: markRaw(ListOrderedIcon), value: 'ordered' },
		{ label: 'Blockquote', key: 'blockquote', icon: markRaw(BlockquoteIcon) },
		{ label: 'Align left', key: 'align', icon: markRaw(AlignLeftIcon), value: undefined },
		{ label: 'Align center', key: 'align', icon: markRaw(AlignCenterIcon), value: 'center' },
		{ label: 'Align right', key: 'align', icon: markRaw(AlignRightIcon), value: 'right' },
	];

	returnValue.push(props.format.link
		? {
			label: 'Unlink',
			icon: markRaw(UnlinkCurvedIcon),
			action: (event) => emit('unlinkClick', event),
		} : {
			label: 'Link',
			icon: markRaw(LinkCurvedIcon),
			action: (event) => emit('addLinkClick', event),
		});

	returnValue.push({
		label: 'Add Image',
		key: 'image',
		icon: markRaw(ImageIcon),
		type: 'file',
		accept: 'image/png, image/jpeg, image/svg',
		mediahubType: props.mediahubImageType,
		action: (event, button) => emit('addFileClick', event, button),
	});

	return returnValue;
});

function handleButtonClick (event, button) {
	if (!button.action) {
		if (!isUndefined(button.value)) {
			return emit('formatChange', button.key, props.format?.[button.key] === button.value ? false : button.value);
		}
		return emit('formatChange', button.key, !props.format?.[button.key]);
	}
	return button.action(event, button);
}

function handleFileLabelClick (event, button) {
	const input = document.querySelector(`.quill-editor__toolbar-button.file input[name=${button.key}]`);
	input.value = '';
}
</script>

<style lang="scss">
.quill-editor__toolbar {
	width: 100%;
	@include flex(row, flex-start, stretch);
	column-gap: 4px;
	border-bottom: 2px solid rgb(113 18 255 / 20%);

	&-button {
		width: 40px;
		height: 38px;
		@include flex();
		background-color: transparent;
		border: none;
		color: $shade30;

		&:hover, &:active {
			cursor: pointer;
		}

		&:hover {
			color: $shade60;
		}

		&:active, &.pressed {
			color: $violet;
		}

		&.file {
			input {
				display: none;
			}
		}
	}
}
</style>
