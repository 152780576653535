import { isEmpty, omit, isString } from 'lodash';

import { object } from '@/components/common/form';
import { genUniqueKey } from '@/utility';

import { generateId, baseValidation } from '../../helpers/form';

export const defaultValues = {
	id: generateId(),
	name: '',
	description: '',
	mediaUrl: '',
	previewUrl: '',
	amountForSale: '',
	maxQuantity: '',
	maxAllocation: '',
	edition: 'limited',
	acceptEth: true,
	price: '0.000001',
	metadata: [],
};

export const validationSchema = object({
	name: baseValidation.name,
	description: baseValidation.description,
	mediaUrl: baseValidation.mediaUrl,
	previewUrl: baseValidation.previewUrl,
	amountForSale: baseValidation.amountForSale,
	maxQuantity: baseValidation.maxQuantity,
	maxAllocation: baseValidation.maxAllocation,
	price: baseValidation.price,
});

export const getInitialValues = details => {
	if (details && !isEmpty(details)) {
		return {
			...defaultValues,
			id: details?.id || defaultValues.id,
			mediaUrl: details?.mediaUrl || '',
			previewUrl: details?.previewUrl || '',
			name: details?.name || '',
			description: details?.description || '',
			amountForSale: details?.amountForSale || '',
			maxQuantity: details?.maxQuantity || '',
			price: details?.price ? details.price.value : defaultValues.price,
			edition: details?.edition || 'limited',
			acceptEth: !!details?.price,
			maxAllocation: details?.maxAllocation || '',
			metadata: (details?.metadata || []).map(item => ({
				...item,
				id: genUniqueKey(),
			})),
		};
	}

	return {
		...defaultValues,
		id: generateId(),
	};
};

export const parseValuesForList = values => ({
	id: values.id,
	name: values.name,
	description: values?.description || '',
	mediaUrl: values.mediaUrl,
	previewUrl: values.previewUrl,
	amountForSale: isString(values.amountForSale) ? parseInt(values.amountForSale, 10) : values.amountForSale,
	maxQuantity: isString(values.maxQuantity) ? parseInt(values.maxQuantity, 10) : values.maxQuantity,
	// eslint-disable-next-line no-nested-ternary
	maxAllocation: values?.maxAllocation
		? isString(values.maxAllocation) ? parseInt(values.maxAllocation, 10) : values.maxAllocation
		: undefined,
	price: values.acceptEth ? {
		value: values?.price || '',
		currency: 'ETH',
	} : null,
	edition: values.edition,
	metadata: values.metadata.map(item => omit(item, 'id')),
});
