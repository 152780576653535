<template>
  <div class="container-content__merge">
    <div class="container-content__wrap">
      <div class="container-content__header">
        <LabeledContainer label="Merge Collection" />
      </div>
      <div class="container-content__table">
        <Table
          :columns="containerCollectionTableColumns"
          :data="props.collections"
          class="collections-slot"
        />
      </div>
      <div class="container-content__button">
        <Button
          color="red"
          variant="outlined"
          width="200"
          @click="openModalRevertMerge"
        >
          Revert Merge
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { markRaw, defineProps, ref } from 'vue';

import Button from '@/components/ui/Button';
import Table from '@/components/ui/table/Table.vue';
import { addSuccess } from '@/components/utility/notifications';
import LabeledContainer from '@/components/ui/LabeledContainer.vue';
import ConfirmationModal from '@/components/common/modal/ConfirmationModal.vue';
import { containerCollectionTableColumns } from '@/pages/collections/edit-container/sections/helpers';
import collectionContainerService from '@/services/collection-container.service';

const props = defineProps({
	collections: {
		type: Array,
		default: () => [],
	},
	containerId: {
		type: String,
		default: '',
	},
});

const store = useStore();
const router = useRouter();

const isLoading = ref(false);

const openModalRevertMerge = () => {
	store.dispatch('modal/openModal', {
		content: markRaw(ConfirmationModal),
		props: {
			modalStyles: 'max-width: 300px',
			title: 'Are you sure you want to revert merge?',
			contentText: 'Collection contracts will be restored to the site. The container will be deleted.',
			acceptText: 'Revert',
			acceptButtonProps: { color: 'black', action: revertMerge },
			cancelButtonProps: { color: 'black' },
		},
	});
};

const revertMerge = async () => {
	if (isLoading.value) {
		return;
	}

	isLoading.value = true;
	try {
		await collectionContainerService.deleteContainer(props.containerId);

		addSuccess('Container successfully deleted');

		await router.push({
			path: '/collections',
			query: { tab: 'all' },
		});
	} catch (e) {
		throw new Error(e);
	} finally {
		isLoading.value = false;
	}
};
</script>

<style lang="scss" scoped>
.container-content {
  &__merge {
    max-width: 726px;
  }

  &__wrap {
    display: grid;
    row-gap: 26px;
  }

  &__table {
    max-height: 600px;
    overflow-y: auto;
  }
}
</style>
