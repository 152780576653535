'use strict';

export default {
	getCollectionsArgs: state => ({
		limit: state.limit,
		offset: state.offset,
		search: state.search,
		sort: state.sort,
		collectionStatus: state.collectionStatus,
		tab: state.currentTab,
	}),
};
