export const collectionContainersMockResponse = {
	containers: [
		{
			id: 'cn:fa32e95e-383f-44dd-a48e-a40ba357c570',
			name: 'Container_1',
			createdAt: 1672198883227,
			verified: true,
			type: 'merge',
			contractAddresses: [
				'0x3441567cC6Bec79B748E809657f8e6B9f5a22338',
				'0x3441567cC6Bec79B748E809657f2e6B9f5a22338',
				'0x3441567cC6Bec79B748E809657a8e6B9f5a22338',
				'0x3441567cC6Bec79B748E809657j8e6B9f5a22338',
				'0x3441567cC6Bec79B748E809657f9e6B9f5a22338',
			],
			slug: 'some-slug-12',
		},
		{
			id: 'cn:fa32e95e-383f-44dd-a48e-a40ba357c570',
			name: null,
			createdAt: 1672198883227,
			type: 'merge',
			verified: false,
			contractAddresses: [
				'0x3441567cC6Bec79B748E809657f8e6B9f5a22338',
				'0x3441567cC6Bec79B748E809657f2e6B9f5a22338',
				'0x3441567cC6Bec79B748E809657a8e6B9f5a22338',
			],
		},
		{
			id: 'cn:fa32e95e-383f-44dd-a48e-a40ba357c570',
			name: 'Container_3',
			createdAt: 1672198883227,
			type: 'merge',
			verified: true,
			contractAddresses: [
				'0x3441567cC6Bec79B748E809657f8e6B9f5a22338',
				'0x3441567cC6Bec79B748E809657f2e6B9f5a22338',
			],
			slug: 'any-slug-12',
		},
		{
			id: 'cn:fa32e95e-383f-44dd-a48e-a40ba357c570',
			avatarUrl: 'https://gigamart.com/prod/resize/collection/avatar/0x099689220846644f87d1137665cded7bf3422747/3a4ce8d2-88ee-49fc-a3e3-3a108bf60e3c.png',
			name: 'Container_4',
			createdAt: 1672198883227,
			type: 'merge',
			verified: true,
			contractAddresses: [
				'0x3441567cC6Bec79B748E809657f8e6B9f5a22338',
			],
		},
		{
			id: 'cn:fa32e95e-383f-44dd-a48e-a40ba357c570',
			name: null,
			createdAt: 1672198883227,
			type: 'merge',
			contractAddresses: [

			],
		},
	],
	cursor: 'wfqgqg2141sxx',
};
