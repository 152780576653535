export const confirmModalContent = {
	nft: {
		ban: {
			title: 'Ban this NFT?',
			text: 'Chosen NFT will be banned. They will not be available on the website anymore and all trading operations will be seized for them. You can undo the ban by pressing the Unban button.',
			acceptBtnText: 'Ban',
			declineBtnText: 'Cancel',
		},
		unban: {
			title: 'Unban this NFT?',
			text: 'Chosen NFT will be unbanned. They and all trading operations will be available on the website.',
			acceptBtnText: 'Unban',
			declineBtnText: 'Cancel',
		},
	},
	collection: {
		title: 'Ban this Collection?',
		text: 'Chosen collection and all NFTs in the collection will be banned. They will not be available on the website anymore and all trading operations will be seized for them. You can undo the ban by pressing the Unban button.',
		acceptBtnText: 'Ban',
		declineBtnText: 'Cancel',
	},
	collectionBanned: {
		title: 'Unban this Collection?',
		text: 'Chosen collection and all NFTs in the collection will be unbanned. They and all trading operations will be available on the website.',
		acceptBtnText: 'Unban',
		declineBtnText: 'Cancel',
	},
	user: {
		title: 'Ban this User?',
		text: 'Chosen user will be banned. They will not be available on the website anymore and all trading operations will be seized for them. You can undo the ban by pressing the Unban button.',
		acceptBtnText: 'Ban',
		declineBtnText: 'Cancel',
	},
	userBanned: {
		title: 'Unban this User?',
		text: 'Chosen user will be unbanned. They and all trading operations will be available on the website.',
		acceptBtnText: 'Unban',
		declineBtnText: 'Cancel',
	},
	collaborator: {
		title: 'Remove this Collaborator?',
		text: 'Chosen collaborator can\'t modify collection settings, receive payments for items they created, and change the collection\'s royalty payout address.',
		acceptBtnText: 'Remove',
		declineBtnText: 'Cancel',
	},
	token: {
		title: 'Are you sure you want to delete this token?',
		acceptBtnText: 'Delete',
		declineBtnText: 'Cancel',
	},
};
