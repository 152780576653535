<template>
  <div>
    <div class="list-wrapper">
      <List
        :disabled="disabled"
        :list="formState.formValues.nfts"
      />
    </div>
    <div class="actions">
      <Button
        class="action"
        variant="outlined"
        :disabled="disabled"
        @click="openCreation"
      >
        + Add NFT
      </Button>
      <a
        v-if="!mainState.isNew.value && isImx"
        class="action"
        :href="metadataSchemaUri"
        download="metadata-schema.json"
      >
        Export Metadata Schema
      </a>
    </div>
    <ErrorMessage v-if="!!error">
      {{ error }}
    </ErrorMessage>
  </div>
</template>

<script>
import Button from '@/components/ui/Button';
import { ErrorMessage } from '@/components/ui/text-fields';
import { openCreateEditNftModal } from '@/pages/drops/details/modals/createEditNftModal/actions';

import List from './components/List.vue';
import { providerTypes } from '../../../helpers';

export default {
	components: {
		List,
		Button,
		ErrorMessage,
	},
	inject: ['mainState', 'formState'],
	computed: {
		isImx () {
			return this.mainState.provider.value === providerTypes.IMX;
		},
		metadataSchemaUri () {
			const schema = this.isImx ? (this.mainState?.data?.drop?.value?.imxMetadataSchema || []) : [];

			return `data:application/json;charset=utf-8,${encodeURIComponent(JSON.stringify(schema))}`;
		},
		disabled () {
			return this.mainState.status.value !== null && this.mainState.status.value !== 'draft';
		},
		error () {
			return this.formState?.errors?.value?.nfts;
		},
	},
	methods: {
		openCreation () {
			openCreateEditNftModal({ details: null, state: this.formState, mainState: this.mainState });
		},
	},
};
</script>

<style scoped lang='scss'>
  .list-wrapper {
    margin-bottom: 20px;
  }

  .actions {
    display: flex;
    align-items: center;

    a.action {
      color: $violet;
    }

    .action + .action {
      margin-left: 20px;
    }
  }
</style>
