<template>
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.75 25.75C9.25 25.75 4.75 21.25 4.75 19.75C4.75 19.2505 5.2495 18.25 6.25 16.75L0.25 15.25L3.25 10.75L9.25 12.25C15.25 3.25 24.25 0.25 30.25 0.25C30.25 6.25 27.25 15.25 18.25 21.25L19.75 27.25L15.25 30.25L13.75 24.25C12.25 25.2505 11.2495 25.75 10.75 25.75ZM20.5 12.25C21.0967 12.25 21.669 12.0129 22.091 11.591C22.5129 11.169 22.75 10.5967 22.75 10C22.75 9.40326 22.5129 8.83097 22.091 8.40901C21.669 7.98705 21.0967 7.75 20.5 7.75C19.9033 7.75 19.331 7.98705 18.909 8.40901C18.4871 8.83097 18.25 9.40326 18.25 10C18.25 10.5967 18.4871 11.169 18.909 11.591C19.331 12.0129 19.9033 12.25 20.5 12.25V12.25ZM3.25 21.25L4.75 23.5C3.7495 24.5005 3.25 25.75 3.25 27.25C4.75 27.25 5.9995 26.7505 7 25.75L9.25 27.25C7.2505 29.2495 4.2505 30.25 0.25 30.25C0.25 26.2495 1.2505 23.2495 3.25 21.25Z"
      fill="currentColor"
    />
  </svg>
</template>
