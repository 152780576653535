<template>
  <div
    :class="{sticky}"
    class="table-head"
  >
    <HeadRow :style="gridStyles">
      <HeadCell
        v-for="(column, key) in columns"
        :key="key"
        :is-hide="column?.isHide"
      >
        <template v-if="withBulkActions && key === 0">
          <BaseCheckbox
            class="checkbox"
            :checked="headCheckboxValue"
            :value="headCheckboxValue"
            name="table-head_checkbox"
            size="24px"
            rectangle-form
            hide-unchecked-icon
            @click.prevent="$emit('checkboxClick')"
          />
        </template>
        <template v-if="column?.name && typeof column?.name === 'function'">
          <component
            :is="column.name().component"
            v-bind="column.name()?.props"
          />
        </template>
        <span v-else>{{ column?.name }}</span>
      </HeadCell>
    </HeadRow>
  </div>
</template>

<script setup>
import { inject, computed, defineEmits, defineProps } from 'vue';

import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';

import HeadRow from './HeadRow.vue';
import HeadCell from './HeadCell.vue';

defineProps({
	sticky: {
		type: Boolean,
		default: false,
		required: false,
	},
});

defineEmits(['checkboxClick']);

const data = inject('data');
const columns = inject('columns');
const gridStyles = inject('gridStyles');
const withBulkActions = inject('withBulkActions');
const selectedCheckboxes = inject('selectedCheckboxes');

const headCheckboxValue = computed(() => !!(data.value.length && selectedCheckboxes.value.size === data.value.length));
</script>

<style lang="scss" scoped>
.sticky {
	position: sticky;
	top: $tableHeaderHeight;
	z-index: 10;
	background-color: var(--color-shade80);
}

.checkbox {
	margin-right: 16px;
}
</style>
