<template>
  <div class="footer">
    <slot />
  </div>
</template>

<style scoped lang='scss'>
  .footer {
    padding: 20px;
    border-top: 1px solid rgba(0, 0, 0, .2);
  }
</style>
