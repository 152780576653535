<template>
  <div class="list">
    <template v-if="list.length !== 0 || !isLoading">
      <DropCard
        v-for="(item, index) in list"
        :id="item?.id"
        :key="index"
        :status="item?.status"
        :provider="item?.provider"
        :username="item?.creator?.username"
        :title="item?.title"
        :start-date="item?.startsAt"
        :end-date="item?.endsAt"
        :image-url="item?.thumbnailUrl"
        :seconds-remaining="item?.secondsRemaining"
      >
        <DropCardActions
          :drop-id="item?.id"
          :status="item?.status"
          :provider="item?.provider"
          @deployed="$emit('deployed')"
          @trashed="$emit('trashed')"
        />
      </DropCard>
    </template>
    <template v-else>
      <DropSkeleton
        v-for="item in 4"
        :key="item"
        rounded
      />
    </template>
    <div
      v-if="!isLoading && list.length === 0"
      class="empty-message"
    >
      No items to display
    </div>
  </div>
</template>

<script>
import DropCard from '../dropCard/index.vue';
import DropCardActions from '../dropCard/DropCardActions.vue';
import DropSkeleton from '../../../components/skeletons/DropSkeleton.vue';

export default {
	components: {
		DropCard,
		DropSkeleton,
		DropCardActions,
	},
	props: {
		isLoading: {
			type: Boolean,
			required: false,
			default: false,
		},
		list: {
			type: Array,
			required: false,
			default: () => [],
		},
	},
	emits: ['deployed', 'trashed'],
};
</script>

<style scoped lang='scss'>
  .list {
    display: grid;
    width: 100%;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
    grid-template-rows: auto;
    position: relative;

    &.empty {
      grid-template-columns: 1fr;
    }

    .item-link {
      text-decoration: none;
    }

    @include media-max-width($mediumScreen) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .empty-message {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
</style>
