import TableActions from '@/pages/defaultTokens/components/TableActions';

import PreviewLink from '@/components/ui/PreviewLink';
import CopyButton from '@/components/ui/CopyButton.vue';
import PaymentTokenImage from '@/components/common/paymentToken/PaymentTokenImage.vue';

import { formatDate, formatAddress } from '@/utility';

export const columnsForTable = [
	{
		name: 'Date',
		value: (data) => formatDate(data?.createdAt, 'EEE LLL d, p'),
	},
	{
		name: 'Item',
		value: (data) => (
			<PreviewLink
				preview={data?.logo}
				label={data?.name}
				mediaSlot={<PaymentTokenImage contractAddress={data?.contractAddress}/>}
				withStringFormatting={!data?.name}
			>
			</PreviewLink>
		),
	},
	{
		name: 'Symbol',
		value: (data) => data?.symbol,
	},
	{
		name: 'Address',
		value: (data) => <CopyButton label={formatAddress(data?.contractAddress)} value={data?.contractAddress} />,
	},
	{
		name: 'Actions',
		value: (data) => <TableActions isDisabled={!data?.deletable} id={data?.id} address={data?.contractAddress} />,
		width: '216px',
	},
];
