<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.4328 10.5062C11.4328 9.95484 11.8814 9.50618 12.4328 9.50618C12.9841 9.50618 13.4328 9.95484 13.4328 10.5062V11.9728C13.4328 13.1862 12.4454 14.1728 11.2328 14.1728H2.29944C1.08611 14.1728 0.0994415 13.1855 0.0994415 11.9728V3.03951C0.0994415 1.82617 1.08677 0.839508 2.29944 0.839508H3.76611C4.31744 0.839508 4.76611 1.28817 4.76611 1.83951C4.76611 2.39084 4.31744 2.83951 3.76611 2.83951H2.29944C2.18944 2.83951 2.09944 2.92951 2.09944 3.03951V11.9728C2.09944 12.0828 2.18944 12.1728 2.29944 12.1728H11.2328C11.3428 12.1728 11.4328 12.0828 11.4328 11.9728V10.5062ZM7.97144 1.97751C7.55144 1.55751 7.84877 0.839508 8.44277 0.839508H13.4328V5.82951C13.4328 6.42351 12.7148 6.72084 12.2948 6.30084L11.0754 5.08217L7.30411 8.85351C6.78344 9.37418 5.93944 9.37418 5.41877 8.85351C4.89811 8.33284 4.89811 7.48884 5.41877 6.96817L9.19011 3.19684L7.97144 1.97751Z"
      fill="currentColor"
    />
  </svg>
</template>
