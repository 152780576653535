export const currenciesTypes = {
	ETH: 'ETH',
	SUPER: 'SUPER',
};

export const getCurrencyByType = type => {
	switch (type) {
		case currenciesTypes.ETH:
			return currenciesTypes.ETH;
		case currenciesTypes.SUPER:
			return currenciesTypes.SUPER;
		default:
			return '';
	}
};
