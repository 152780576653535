/* Value in megabytes */
export const MAX_IMAGE_MB_SIZE = 100;

/**
 * @typedef {{
 *   label: string,
 *   value: string
 * }} Option
 * */

/**
 * @param {Option[]} options
 * @return {string}
 * */
export function getFormatsString (options) {
	let formats = '';

	options.forEach((option, idx) => {
		const isLast = idx === options.length - 1;
		const isPrevLast = idx === options.length - 2;

		if (isLast) {
			formats += `or ${option.label.toUpperCase()} only`;
		} else if (isPrevLast) {
			formats += `${option.label.toUpperCase()} `;
		} else {
			formats += `${option.label.toUpperCase()}, `;
		}
	});

	return formats;
}

export const mediaTypes = {
	png: { value: 'image/png', label: 'png' },
	jpg: { value: 'image/jpg', label: 'jpg' },
	mp4: { value: 'image/mp4', label: 'mp4' },
	gif: { value: 'image/gif', label: 'gif' },
	jpeg: { value: 'image/jpeg', label: 'jpeg' },
};

const { png, jpg, jpeg, gif } = mediaTypes;

function getList (types) {
	return Object.keys(types).map(key => types[key]);
}

export const imageTypes = { png, jpg, jpeg, gif };

export const mediaTypeOptions = getList(mediaTypes);
export const imageTypeOptions = getList(imageTypes);

function getBase64 (file) {
	const reader = new FileReader();

	return new Promise(resolve => {
		reader.onload = ev => {
			resolve(ev.target.result);
		};
		reader.readAsDataURL(file);
	});
}

/**
 * Return base64 url string
 *
 * @param {File} file
 */
export async function readUrl (file) {
	return getBase64(file);
}

export function megabytesToBytes (megabytes) {
	return megabytes * 1048576;
}

export function bytesToMegabytes (bytes) {
	return bytes / 1048576;
}

export * from './UseBaseDropdown.js';
