<script>
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Row',
	render () {
		return <div class="row">{this.$slots?.default?.()}</div>;
	},
});
</script>
<style scoped lang="scss">
.row {
  margin: 0;
  border-bottom: 1px solid transparent;
}
</style>
