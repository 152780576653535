<template>
  <svg
    fill="none"
    height="12"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <path
      clip-rule="evenodd"
      d="M14.26.34a2 2 0 011.41 1.41C16.01 3 16 5.61 16 5.61s0 2.6-.33 3.85a2 2 0 01-1.41 1.41c-1.25.33-6.26.33-6.26.33s-5 0-6.26-.34A2 2 0 01.33 9.45C0 8.21 0 5.6 0 5.6S0 3 .33 1.75C.5 1.07 1.07.51 1.74.33 2.99 0 8 0 8 0s5 0 6.26.34zM6.41 3.2l4.16 2.4L6.41 8V3.2z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>
