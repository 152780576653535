<template>
  <div class="select-content__split">
    <div class="select-content__wrap">
      <div class="select-content__header">
        <LabeledContainer label="Child collections">
          <p class="select-content__subtitle">
            {{ `You can split from ${COLLECTIONS_CONSTANTS.MIN_COUNT_FOR_SPLIT_COLLECTION} to ${COLLECTIONS_CONSTANTS.MAX_COUNT_FOR_SPLIT_COLLECTIONS} collections` }}
          </p>
        </LabeledContainer>
      </div>
      <SplitCollectionTable
        class="select-content__table"
        :collections="collections"
        :handle-change-address="handleChangeAddress"
        @on-remove-element="removeElementFromCollection"
      />

      <div class="select-content__actions">
        <Tooltip
          value="You have added the maximum number of collection address"
          :hide-tooltip="!disableAddButton"
        >
          <Button
            class="actions-block__add-button"
            variant="outlined"
            width="132"
            :disabled="disableAddButton"
            @click="addElementToCollection"
          >
            + Add address
          </Button>
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, ref } from 'vue';

import { COLLECTIONS_CONSTANTS } from '@/pages/collections/constants';
import { removeElementFromArrayByIndex } from '@/utility/array/removeElements.js';

import Button from '@/components/ui/Button';
import Tooltip from '@/components/ui/Tooltip.vue';
import LabeledContainer from '@/components/ui/LabeledContainer.vue';
import SplitCollectionTable from '@/components/common/tables/SplitCollectionTable/SplitCollectionTable.vue';

defineProps({
	handleChangeAddress: {
		type: Function,
	},
});

const emit = defineEmits(['onRemoveElement']);

const collections = ref(new Array(1).fill({
	contractAddress: '',
	ranges: '',
}));

const removeElementFromCollection = (collectionId) => {
	collections.value = removeElementFromArrayByIndex(collections.value, collectionId);

	emit('onRemoveElement', collectionId);
};
const addElementToCollection = () => collections.value.push({
	contractAddress: '',
	ranges: '',
});

const disableAddButton = computed(() => collections.value?.length >= COLLECTIONS_CONSTANTS.MAX_COUNT_FOR_SPLIT_COLLECTIONS);
</script>

<style lang="scss" scoped>
.select-content {

  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__split {
    position: relative;
  }
}
</style>
