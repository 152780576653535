<template>
  <TitledPage
    title="Settings"
    class="settings-page"
  >
    <TabsQueryRouter
      v-slot="{ activeTab }"
      :items="tabs"
      :is-sticky="false"
    >
      <TabsContentContainer>
        <component :is="renderTabContentByType(activeTab)" />
      </TabsContentContainer>
    </TabsQueryRouter>
  </TitledPage>
</template>

<script setup>
import { ref } from 'vue';

import TitledPage from '@/layouts/components/TitledPage';
import { TabsQueryRouter, TabsContentContainer } from '@/components/common/tabs';

import SoftBanCollections from './sections/SoftBanCollections.vue';
import NotableCollections from './sections/NotableCollections.vue';

const tabs = [
	{ label: 'Soft ban collections', value: 'softBanCollections' },
	{ label: 'Notable collections', value: 'notableCollections' },
];
const activeTab = ref(tabs[0].value);

function renderTabContentByType (type) {
	activeTab.value = type;

	switch (type) {
		case tabs[0].value:
			return SoftBanCollections;
		case tabs[1].value:
			return NotableCollections;
		default:
			return null;
	}
}
</script>

<style lang="scss" scoped>
.settings-page {
  :deep(.title) {
    font-size: 30px;
    line-height: 36px;
  }
}
</style>
