import requests from './requests.service';

const baseUrl = `${process.env.VUE_APP_ADMIN_PANEL_BASE_URL}/admin/v1/reports`;

export default {
	getList: async (type, limit = 10, offset = 0, search = '', filter = undefined) => {
		const params = { type, limit, offset };
		if (search) params.search = search;
		if (filter) params.filter = filter;

		return requests.get(baseUrl, { params });
	},
	acceptReports: async (reportIds = []) => requests.post(`${baseUrl}/accept`, { reportIds }),
	declineReports: async (reportIds = []) => requests.post(`${baseUrl}/decline`, { reportIds }),
	revertReports: async (reportIds = []) => requests.post(`${baseUrl}/revert`, { reportIds }),
};
