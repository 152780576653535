<template>
  <div class="media-preview-wrapper">
    <div class="media-preview">
      <IconButton
        class="action"
        :disabled="disabled"
        @click="$emit('onDelete')"
      >
        <CrossIcon />
      </IconButton>
      <Media
        class="media"
        autoplay
        :src="src"
      />
    </div>
    <HelperText
      v-if="helperText"
      class="helper-text"
    >
      {{ helperText }}
    </HelperText>
  </div>
</template>

<script>
import Media from '@/components/common/Media';
import { CrossIcon } from '@/components/icons';
import IconButton from '@/components/ui/IconButton';
import HelperText from '@/components/ui/HelperText';

export default {
	components: {
		Media,
		CrossIcon,
		HelperText,
		IconButton,
	},
	props: {
		src: {
			type: String,
			required: true,
		},
		helperText: {
			type: String,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	emits: ['onDelete'],
};
</script>

<style scoped lang='scss'>
  .media-preview-wrapper {
    height: 100%;
    width: 100%;

    .media-preview {
      width: min-content;
      height: inherit;
      position: relative;

      .action {
        position: absolute;
        top: -5px;
        right: -5px;
        width: 30px;
        height: 30px;
        background-color: $white;
        display: none;
        z-index: 1;

        &:hover {
          background-color: #ffe1e6;
          color: $darkRed;
        }
      }

      &:hover {
        .action {
          display: block;
        }
      }
    }

    .media {
      width: 100%;
      height: 100%;
      justify-content: flex-start;
    }
  }

  .helper-text {
    margin-top: 5px;
  }
</style>
