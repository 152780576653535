import { isEmpty, isString } from 'lodash';

import {
	minMaxString,
	object,
	requiredString,
	requiredMixed,
	ethereumAddress,
	leadingZeros,
	positiveNumber,
	trim,
	stringSchema,
	maxNumber,
	integerNumber,
} from '@/components/common/form';
import { formatDate } from '@/utility';

import {
	formatAddressListToString,
	formatAddressListToArray,
	baseEndsAtValidation,
	baseAccessListValidation,
	dateValidation,
	formatNftListToRequest,
	accessStartValidation,
	accessEndValidation,
} from '../../../helpers';
import { providerTypes } from '@/pages/drops/helpers';

export const defaultValues = {
	contractAddress: '',
	collectionName: '',
	creatorName: '',
	title: '',
	startsAt: '',
	endsAt: '',
	description: '',
	access: '',
	issue: 'numbered',
	maxAllocation: '',
	thumbnail: '',
	banner: '',
	accessList: '',
	type: null,
	nfts: [],
	removeNFTs: [],
};

export const validationSchema = object({
	contractAddress: ethereumAddress().concat(requiredString()),
	thumbnail: requiredString(),
	banner: requiredString(),
	collectionName: requiredString().concat(minMaxString(1, 100)).concat(trim),
	creatorName: requiredString().concat(minMaxString(1, 50)).concat(trim),
	title: requiredString().concat(minMaxString(1, 100)).concat(trim),
	description: requiredString().concat(minMaxString(1, 500)).concat(trim),
	access: requiredMixed().oneOf(['public', 'whitelist', 'nft'], 'No value selected'),
	issue: requiredMixed().oneOf(['numbered', 'unnumbered'], 'No value selected'),
	maxAllocation: positiveNumber
		.concat(integerNumber)
		.concat(leadingZeros())
		.concat(maxNumber(99999)),
	startsAt: dateValidation,
	endsAt: baseEndsAtValidation,
	accessList: stringSchema.when('access', {
		is: value => value === 'whitelist' || value === 'nft',
		then: baseAccessListValidation,
	}),
	accessStartsAt: accessStartValidation,
	accessEndsAt: accessEndValidation,
});

export const getInitialValues = details => {
	if (details && !isEmpty(details)) {
		const dateToString = date => formatDate(date, 'MM-dd-yyyy HH:mm');

		return {
			...defaultValues,
			accessList: formatAddressListToString(details?.accessList),
			contractAddress: details?.contractAddress || '',
			creatorName: details?.creator?.username || '',
			collectionName: details?.collection?.name || '',
			title: details?.title || '',
			description: details?.description || '',
			issue: details?.issueType || defaultValues.issue,
			maxAllocation: details?.maxAllocation || '',
			access: details?.access || '',
			thumbnail: details?.thumbnailUrl || '',
			banner: details?.bannerUrl || '',
			startsAt: details?.startsAt ? dateToString(details.startsAt) : '',
			endsAt: details?.endsAt ? dateToString(details.endsAt) : '',
			nfts: details?.nfts || [],
			accessStartsAt: details?.accessStartsAt ? dateToString(details.accessStartsAt) : '',
			accessEndsAt: details?.accessEndsAt ? dateToString(details.accessEndsAt) : '',
		};
	}

	return defaultValues;
};

export const mapFormToRequestData = (data, isNew) => {
	// eslint-disable-next-line no-nested-ternary
	const maxAllocation = data?.issue === 'numbered'
		? isString(data.maxAllocation) ? parseInt(data.maxAllocation, 10) || 0 : data.maxAllocation
		: undefined;

	return {
		deploy: data?.type === 'deploy',
		drop: {
			provider: providerTypes.IMX,
			contractAddress: data?.contractAddress,
			creatorUsername: data?.creatorName,
			title: data?.title,
			description: data?.description,
			access: data?.access,
			issueType: data?.issue,
			maxAllocation,
			thumbnailUrl: data?.thumbnail,
			bannerUrl: data?.banner,
			startsAt: data?.startsAt ? new Date(data.startsAt).toISOString() : undefined,
			endsAt: data?.endsAt ? new Date(data.endsAt).toISOString() : undefined,
			collectionName: data?.collectionName,
			accessList: data?.access !== 'public' ? formatAddressListToArray(data?.accessList).map(item => item.line) : undefined,
			accessStartsAt: data?.accessStartsAt ? new Date(data.accessStartsAt).toISOString() : null,
			accessEndsAt: data?.accessEndsAt ? new Date(data.accessEndsAt).toISOString() : null,
		},
		nfts: formatNftListToRequest(data?.nfts),
		removeNFTIds: !isNew ? (data?.removeNFTs || []) : undefined,
	};
};
