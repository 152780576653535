<template>
  <div
    class="global-base-icon-wrapper"
    :style="style"
  >
    <slot />
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
	size: [String, null],
	color: [String, null],
});

const style = computed(() => ({
	fontSize: props.size ? props.size : '1em',
	color: props.color ? props.color : 'currentColor',
}));
</script>

<style lang="scss">
.global-base-icon-wrapper {
  @include flex();
  width: 1em;
  height: 1em;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  svg {
    display: inline-block;
    width: 1em;
    height: 1em;
    min-width: 16px;
    min-height: 16px;
    padding: 0;
    margin: 0;
    font-size: inherit;
  }
}
</style>
