import { ref } from 'vue';
import { useDropzone } from 'vue3-dropzone';
import { map } from 'lodash/core';
import { flatMap, includes } from 'lodash/collection';

function getFileStateByTarget (fileRejections, target) {
	return includes(flatMap(fileRejections, item => map(item?.errors || [], err => err?.code)), target);
}

/**
 * Return image params
 *
 * @param {string} imageUrl
 * */
function getImgParams (imageUrl) {
	return new Promise(resolve => {
		const img = new Image();
		img.src = imageUrl;

		img.onload = function () {
			resolve({
				height: img.height,
				width: img.width,
			});
		};
	});
}

/**
 * Return image base64 url
 *
 * @param {File} image
 * */
function getImageUrl (image) {
	return new Promise(resolve => {
		const reader = new FileReader();

		reader.readAsDataURL(image);
		reader.onload = function () {
			resolve(reader.result);
		};
	});
}

/**
 * Return image params
 *
 * @param {File} image
 * */
// eslint-disable-next-line no-unused-vars
async function readFile (image) {
	let result;

	try {
		const imageUrl = await getImageUrl(image);

		result = await getImgParams(imageUrl);
	} catch (e) {
		result = null;
	}

	return result;
}

export function useBaseDropzone (props, emit) {
	const {
		name,
		accept,
		minFileBytes,
		maxFileBytes,
	} = props;

	const error = ref(null);
	const isUploading = ref(false);

	async function onDrop (acceptFiles, rejectReasons) {
		if (isUploading.value) {
			return;
		}

		const result = { name, acceptFiles, rejectReasons, error: null, image: null };
		const isInvalidType = getFileStateByTarget(rejectReasons, 'file-invalid-type');
		const isToLarge = getFileStateByTarget(rejectReasons, 'file-too-large');

		error.value = null;

		if (isInvalidType) {
			result.error = 'Wrong file format';
		} else if (isToLarge) {
			result.error = 'File is to large';
		}

		if (!acceptFiles.length) {
			result.acceptFiles = null;
		} else {
			result.acceptFiles = acceptFiles;
			result.image = acceptFiles[0];
		}

		if (!rejectReasons.length) {
			result.rejectReasons = null;
		} else {
			result.rejectReasons = rejectReasons;
		}

		error.value = result.error;
		emit('drop', result);
	}

	const {
		getRootProps,
		isDragActive,
		isDragReject,
		acceptedFiles,
		getInputProps,
		fileRejections,
	} = useDropzone({
		onDrop,
		multiple: false,
		minSize: minFileBytes,
		maxSize: maxFileBytes,
		accept: accept.map(option => option.value),
	});

	return {
		name,
		error,
		accept,
		isUploading,
		getRootProps,
		isDragActive,
		isDragReject,
		acceptedFiles,
		getInputProps,
		fileRejections,
	};
}
