<template>
  <div class="banner-wrapper">
    <Media
      class="media-box"
      autoplay
      :src="bannerUrl"
    />
    <div class="content">
      <BlockchainIdentifier
        v-if="provider"
        is-light
        :provider="provider"
        :is-drop-details="isMain"
        class="blockchain-identifier"
      />

      <div class="creator-name">
        <span>{{ username }}</span>
      </div>

      <h1 class="title">
        {{ title }}
      </h1>

      <UpcomingCountdown
        class="timer"
        is-with-reload
        :date-value="dateValue"
        :start-date="startsAt"
      />

      <DropStats
        :access="access"
        :issue-type="issueType"
        :max-allocation="maxAllocation"
        is-light
      />
      <div
        v-if="description"
        class="description"
      >
        {{ description }}
      </div>
      <RemainingProgress
        v-if="total && remaining"
        :total="total"
        :remaining="remaining"
      />
    </div>
  </div>
</template>

<script>
import Media from '@/components/common/Media';
import BlockchainIdentifier from '@/components/ui/BlockchainIdentifier';

import DropStats from './DropStats.vue';
import RemainingProgress from './RemainingProgress.vue';
import UpcomingCountdown from '../../../../components/UpcomingCountdown.vue';

export default {
	components: {
		Media,
		DropStats,
		UpcomingCountdown,
		RemainingProgress,
		BlockchainIdentifier,
	},
	props: {
		username: { type: String, required: true },
		title: { type: String, required: true },
		startsAt: { type: [String, Date, Number], required: true },
		dateValue: [Number, String, Date],
		description: String,
		bannerUrl: String,
		access: String,
		issueType: String,
		maxAllocation: [Number, String],
		total: { type: Number, default: 0 },
		sold: { type: Number, default: 0 },
		provider: String,
		isMain: { type: Boolean, required: false, default: true },
	},
	data () {
		return {
			currentStatus: this?.status,
		};
	},
	computed: {
		remaining () {
			return (this.total || 0) - (this.sold || 0);
		},
	},
};
</script>

<style scoped lang='scss'>
  .banner-wrapper {
    width: 100%;
    height: 100%;
    min-height: 500px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 20px;
    color: $white;
    text-align: center;

    @include media-max-width($mediumScreen) {
      min-height: 300px;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    }

    .media-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      :deep {
        & > * {
          object-fit: cover;
        }
      }
    }

    .content {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      .blockchain-identifier {
        padding: 10px;
      }

      .creator-name {
        margin-bottom: 10px;
        opacity: .5;
        font-size: 30px;
        line-height: 1em;
        word-break: break-word;

        @include media-max-width($mediumScreen) {
          font-size: 20px;
        }
      }

      .title {
        margin-bottom: 20px;
        font-size: 70px;
        line-height: 1em;
        font-weight: 500;
        word-break: break-word;

        @include media-max-width($mediumScreen) {
          font-size: 40px;
        }
      }

      .timer {
        margin-bottom: 20px;
      }

      .description {
        max-width: 60ch;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 1.5;
        text-align: center;
        word-break: break-word;
      }
    }
  }
</style>
