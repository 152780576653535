<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.89405 14.2101C6.08667 15.0546 7.52092 15.4357 8.95193 15.2853C9.82863 15.1931 10.6694 14.905 11.4218 14.4483C11.5601 14.3644 11.606 14.1656 11.5183 14.0295L10.6791 12.7261C10.5928 12.5922 10.417 12.549 10.2786 12.6277C9.80376 12.8976 9.281 13.0675 8.73881 13.1237C7.7948 13.2218 6.84624 12.9697 6.05433 12.4074C5.26198 11.8447 4.67451 11.0051 4.39626 10.0292C4.11796 9.05305 4.16735 8.0045 4.53551 7.06329C4.90357 6.12229 5.5661 5.34993 6.40615 4.87432C7.24573 4.39897 8.21264 4.2481 9.14319 4.44537C10.074 4.64269 10.9154 5.17725 11.5218 5.96272C12.0688 6.67132 12.3932 7.54378 12.4511 8.45685H10.5499C10.2923 8.45685 10.1549 8.77516 10.3316 8.96259L13.2789 12.0904C13.3923 12.2107 13.6022 12.2107 13.7156 12.0904L16.663 8.96259C16.8396 8.77516 16.7022 8.45685 16.4446 8.45685H14.5274C14.4656 7.05162 13.9779 5.70008 13.1304 4.60586C12.2225 3.4337 10.9568 2.62836 9.54689 2.33186C8.13664 2.03531 6.67383 2.26721 5.40876 2.98602C4.14416 3.70455 3.1573 4.86381 2.61229 6.26282C2.06734 7.66162 1.99644 9.21668 2.41115 10.6642C2.82591 12.1118 3.70189 13.3658 4.89405 14.2101Z"
      fill="currentColor"
    />
  </svg>
</template>
