<template>
  <component
    :is="tag"
    class="text-link-item"
    :class="classes"
    :href="src"
    :to="!isRegular ? src : undefined"
    :target="isRegular ? '_blank' : undefined"
    @click.stop
  >
    <template v-if="content">
      {{ content }}
    </template>
    <slot v-else />
  </component>
</template>

<script setup>
'use strict';

import { computed, defineProps } from 'vue';

const props = defineProps({
	src: String,
	tag: {
		type: String,
		default: undefined,
	},
	color: String,
	content: String,
	isRegular: Boolean,
	hoverable: { type: Boolean, default: true },
});

// eslint-disable-next-line no-nested-ternary
const tag = props.tag ? props.tag : props.isRegular ? 'a' : 'router-link';

const classes = computed(() => {
	const list = [];

	if (props.color) {
		if (props.color === 'violet') {
			list.push('text-link-item__violet');
		}

		if (props.color === 'gray') {
			list.push('text-link-item__gray');
		}
	}

	if (props.hoverable) {
		list.push('text-link-item__hoverable');
	}

	return list.join(' ');
});
</script>

<style
  scoped
  lang="scss"
>
.text-link-item {
  color: inherit;
  text-decoration: none;

  &__hoverable {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__violet {
    color: var(--color-accent2);
  }

  &__gray {
    color: var(--color-shade40);
  }
}
</style>
