import { formatAddress, formatDate } from '@/utility';

import TableActions from '@/pages/banned/components/TableActions';

import PreviewLink from '@/components/ui/PreviewLink';
import CopyButton from '@/components/ui/CopyButton.vue';
import VerificationLabel from '@/components/ui/VerificationLabel';

const nft = [
	{ name: 'Date', value: (data) => formatDate(data?.createdAt, 'EEE LLL d, p') },
	{
		name: 'Item',
		value: (data) => <PreviewLink
			preview={data?.previewUrl}
			label={data?.name || data?.id}
			withStringFormatting={!data?.name}
		/>,
	},
	{
		name: 'Collection name',
		value: (data) => <VerificationLabel name={data?.collection?.name} isVerified={data?.collection?.isVerified} />,
	},
	{ name: 'Token ID', value: (data) => data?.tokenId },
	{
		name: 'Actions',
		value: (data) => <TableActions
			status={data?.nftStatus}
			id={data?.id}
			tokenId={data?.tokenId}
			collection={data?.collection}
		/>,
		width: '168px',
	},
];

const collection = [
	{ name: 'Date', value: (data) => formatDate(data?.createdAt, 'EEE LLL d, p') },
	{
		name: 'Collection',
		value: (data) => <PreviewLink
			preview={data?.previewUrl}
		><VerificationLabel name={data?.name} isVerified={data?.isVerified} /></PreviewLink>,
	},
	{ name: 'Contract address', value: (data) => formatAddress(data?.contractAddress) },
	{
		name: 'Actions',
		value: (data) => <TableActions status={data?.collectionStatus} id={data?.id} eth-address={data?.slug || data?.contractAddress} />,
		width: '168px',
	},
];

const user = [
	{
		name: 'Date',
		value: (data) => formatDate(data?.createdAt || data?.registeredAt, 'EEE LLL d, p'),
	},
	{
		name: 'User',
		value: (data) => <PreviewLink
			preview={data?.avatarUrl}
			label={data?.username || data?.ethAddress}
			withStringFormatting={!data?.username}
		/>,
	},
	{
		name: 'Wallet address',
		value: (data) => <CopyButton label={formatAddress(data?.ethAddress || '')} value={data?.ethAddress} />,
	},
	{
		name: 'Actions',
		value: (data) => <TableActions
			id={data?.id}
			status={data?.userStatus}
			eth-address={data?.ethAddress }
		/>,
		width: '168px',
	},
];

export const columnsForBanned = {
	nft,
	collection,
	user,
};

export const marketplaceRoutes = {
	nft: ({ tokenId, collection }) => `collections/${collection?.contractAddress || collection?.slug}/${tokenId}`,
	collection: ({ ethAddress }) => `collections/${ethAddress}`,
	user: ({ ethAddress }) => ethAddress,
};
