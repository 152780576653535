import { addError } from '@/components/utility/notifications';
import { capitalize } from 'lodash';

export class ErrorApiHandler {
	showResponseError = error => {
		const message = this.serverCodeToNotificationText(error)
            || ((error.response?.data?.payload?.errors ? capitalize(error.response?.data?.payload.errors[0]) : error.response?.data.message) || 'Something went wrong');

		if (message !== 'hidden') {
			addError(message);
		}
	};

	serverCodeToNotificationText = error => {
		const code = error?.response?.data?.code;

		switch (code) {
			default:
				return '';
		}
	};
}

const errorApiHandler = new ErrorApiHandler();

export default errorApiHandler;
