<template>
  <List
    :list="list"
    :is-loading="isLoading"
    @deployed="onUpdateState"
    @trashed="onUpdateState"
  />
</template>

<script>
import { inject } from 'vue';

import List from '../components/list/index.vue';
import useDropsList from '../components/list/useDropsList';

export default {
	components: { List },
	setup () {
		const { isLoading, getList, list } = useDropsList('draft');

		const getCounters = inject('getCounters');

		const onUpdateState = async () => {
			await Promise.allSettled([
				getCounters?.(),
				getList(),
			]);
		};

		return { isLoading, onUpdateState, list };
	},
};
</script>
