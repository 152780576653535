<template>
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.84706 1.88235L8.47059 0H0V16H1.88235V9.41177H7.15294L7.52941 11.2941H14.1176V1.88235H8.84706Z"
      fill="currentColor"
    />
  </svg>
</template>
