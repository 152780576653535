<template>
  <ConfirmationModal
    :title="forModal.title"
    :accept-text="forModal.acceptBtnText"
    :cancel-text="forModal.declineBtnText"
    :accept-button-props="{ color: 'black' }"
    :cancel-button-props="{ color: 'black' }"
    :disable-btns="btnOnLoading"
    @on-accept="banNft"
  >
    <div class="ban-nft-content">
      <p>{{ forModal.text }}</p>
    </div>
  </ConfirmationModal>
</template>

<script>
import { ref, defineComponent } from 'vue';
import { ConfirmationModal } from '@/components/common/modal';
import { confirmModalContent } from '@/components/common/modal/data/confirmModalContent';
import { useStore } from 'vuex';

export default defineComponent({
	name: 'BanModal',
	components: { ConfirmationModal },
	setup (props, { attrs }) {
		const store = useStore();
		const btnOnLoading = ref(false);
		const propsModal = attrs.modalProps;
		const modalType = propsModal.type;
		const forModal = propsModal.isBanned ? confirmModalContent.nft.unban : confirmModalContent.nft.ban;

		const banNft = async () => {
			btnOnLoading.value = true;
			await store.dispatch('nfts/toggleBan', propsModal);
			store.dispatch('modal/closeModal');
			btnOnLoading.value = false;
		};

		return {
			forModal,
			banNft,
			btnOnLoading,
			modalType,
		};
	},
});
</script>

<style scoped lang="scss">
  .ban-nft-content {
    width: 100%;
    max-width: 360px;
    text-align: center;
    margin: 20px 25px
  }
</style>
