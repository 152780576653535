<template>
  <div class="token-container">
    <PaymentTokenImage
      class="token-container-logo-wrapper"
      :contract-address="token?.contractAddress"
    />

    <span class="token-container__symbol">{{ token.symbol }}</span>
    <span class="token-container__name">{{ token.name }}</span>
    <CrossIcon
      v-if="!token.default"
      class="token-container__close-button"
      @click="deleteToken"
    />
  </div>
</template>

<script>
'use strict';

import { CrossIcon } from '@/components/icons';
import PaymentTokenImage from '@/components/common/paymentToken/PaymentTokenImage.vue';

export default {
	components: {
		CrossIcon,
		PaymentTokenImage,
	},
	props: {
		token: Object,
	},
	emits: ['deleteToken'],
	setup (props, { emit }) {
		const deleteToken = (event) => {
			event.stopPropagation();
			event.preventDefault();
			emit('deleteToken');
		};

		return {
			deleteToken,
		};
	},
};
</script>

<style scoped lang="scss">
  .token-container {
    position: relative;
    height: 65px;
    max-width: 295px;
    border: 1px solid $shade30;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 12px;
    display: grid;
    grid-template-areas:
    'logo symbol'
    'logo   name';
    grid-template-columns: 40px auto;
    column-gap: 8px;

    &-logo-wrapper {
      grid-area: logo;
    }

    &__symbol {
      max-width: 180px;
      grid-area: symbol;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: $black;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__name {
      max-width: 180px;
      grid-area: name;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: $black;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__close-button {
      cursor: pointer;
      position: absolute;
      top: 14px;
      right: 14px;
    }
  }
</style>
