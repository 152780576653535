<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20pt"
    height="20pt"
    viewBox="0 0 20 20"
  >
    <path
      fill="currentColor"
      d="M 2.183594 18.570312 L 0 18.570312 L 6.699219 11.097656 C 6.914062 10.859375 6.914062 10.5 6.699219 10.269531 L 0.0507812 2.855469 L 2.152344 2.855469 L 8.839844 10.332031 C 9.007812 10.550781 9.039062 10.871094 8.855469 11.078125 C 7.105469 13.03125 2.183594 18.570312 2.183594 18.570312 Z M 19.949219 2.855469 L 17.851562 2.855469 L 9.390625 12.351562 C 9.171875 12.585938 9.164062 12.957031 9.378906 13.195312 L 9.605469 13.472656 L 14.144531 18.570312 L 16.335938 18.570312 L 11.425781 13.070312 C 11.210938 12.832031 11.21875 12.460938 11.4375 12.226562 Z M 12.988281 12.511719 C 12.9375 12.703125 12.988281 12.917969 13.125 13.074219 C 14.347656 14.449219 18.011719 18.570312 18.011719 18.570312 L 20 18.570312 L 13.75 11.601562 L 13.183594 12.203125 C 13.105469 12.285156 13.023438 12.402344 12.988281 12.511719 Z M 3.828125 2.855469 L 5.847656 2.855469 C 5.847656 2.855469 9.449219 7.007812 10.753906 8.296875 C 10.960938 8.496094 10.921875 8.761719 10.734375 8.972656 C 10.039062 9.757812 10.023438 9.773438 10.023438 9.773438 Z M 11.878906 7.679688 L 16.179688 2.855469 L 14.144531 2.855469 L 11.898438 5.382812 L 11.25 6.109375 C 11.027344 6.34375 11.023438 6.707031 11.238281 6.953125 C 11.402344 7.132812 11.878906 7.679688 11.878906 7.679688 Z M 3.847656 18.570312 L 8.144531 13.746094 C 8.144531 13.746094 8.625 14.292969 8.789062 14.476562 C 9.003906 14.722656 8.996094 15.085938 8.777344 15.316406 L 8.128906 16.046875 L 5.878906 18.570312 Z M 3.847656 18.570312 "
    />
  </svg>
</template>
