import { isEmpty } from 'lodash';

export default {
	openModal: (state, payload) => {
		if (typeof payload === 'object' && !isEmpty(payload)) {
			state.openedModals.push(payload);
		}
	},
	closeModal: state => {
		state.openedModals.pop();
	},
	closeAllModals: state => {
		state.openedModals = [];
	},
};
