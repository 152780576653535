export function getHistoryParams () {
	return window.location.search
		.replace('?', '')
		.split('&')
		.reduce((acc, param) => {
			const [key, value] = param.split('=');
			acc[key] = value;
			return acc;
		}, {});
}

export function serializeHistoryParams (params) {
	return Object.keys(params)
		.map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
		.join('&');
}
