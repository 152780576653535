import { datePattern12 } from '@/utility';

export * from './form';
export * from './requests';
export * from './formatters';

export const getPreviewValues = values => {
	const isValidDate = datePattern12.test(values?.startsAt || '');
	const startDate = isValidDate ? new Date(values.startsAt) : new Date();
	const issue = values?.issue || '';

	return {
		creator: values?.creatorName ? values.creatorName : 'creator',
		title: values?.title ? values.title : 'Drop Title Goes Here',
		imageUrl: values?.thumbnail || '',
		bannerUrl: values?.banner || '',
		startDate,
		description: values?.description || '',
		access: values?.access || '',
		issue,
		// eslint-disable-next-line no-nested-ternary
		maxAllocation: issue ? (issue === 'numbered' ? (values?.maxAllocation || '0') : 'Unlimited') : '',
	};
};
