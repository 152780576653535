<template>
  <div
    class="circular-progress"
    :style="{background: progress, width, height}"
  >
    <slot />
  </div>
</template>

<script>
export default {
	props: {
		width: {
			type: String,
			required: false,
			default: '100px',
		},
		height: {
			type: String,
			required: false,
			default: '100px',
		},
		total: {
			type: [Number, String],
			required: false,
			default: 0,
		},
		current: {
			type: [Number, String],
			required: false,
			default: 0,
		},
	},
	computed: {
		progress () {
			if (this.hasBothValues) {
				const totalValue = typeof this.total === 'string' ? parseFloat(this.total) : this.total;
				const currentValue = typeof this.current === 'string' ? parseFloat(this.current) : this.current;
				const value = Math.round((currentValue / totalValue) * 100);

				return `radial-gradient(white 50%, transparent 51%), conic-gradient(#7112ff 0% ${value}%, #CCCCCC ${value}% 100%)`;
			}

			return 'transparent';
		},
		hasBothValues () {
			return this.current && this.total;
		},
	},
};
</script>

<style scoped lang='scss'>
  .circular-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $violet;
    border-radius: 50%;
  }
</style>
