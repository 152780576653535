<template>
  <div>
    <LabeledContainer
      class="item"
      label="Type of trait"
      for-id="metaType"
    >
      <FormSelect
        name="metaType"
        :options="metaTypeOptions"
      />
    </LabeledContainer>

    <LabeledContainer
      class="item"
      label="Name"
      for-id="metaName"
    >
      <FormTextField
        id="metaName"
        name="metaName"
        placeholder="Enter a trait name"
      />
    </LabeledContainer>

    <LabeledContainer
      class="item"
      label="Value"
      for-id="metaValue"
    >
      <FormTextField
        id="metaValue"
        :type="values.metaType.value !== 'name' ? 'number' : undefined"
        name="metaValue"
        placeholder="Enter a value"
      />
    </LabeledContainer>

    <LabeledContainer
      v-if="values.metaType.value !== 'name'"
      class="item"
      label="Max Value (optional)"
      for-id="metaMaxValue"
    >
      <FormTextField
        id="metaMaxValue"
        :disabled="isNameType"
        type="number"
        name="metaMaxValue"
        placeholder="Enter a value"
      />
    </LabeledContainer>
  </div>
  <div class="actions-wrapper">
    <AcceptCancelButtons
      accept-text="Add Trait"
      reverse
      @on-accept="onSubmit"
      @on-cancel="$emit('onCancel')"
    />
  </div>
</template>

<script>
import { watch, computed } from 'vue';
import * as Yup from 'yup';
import { useForm } from 'vee-validate';

import LabeledContainer from '@/components/ui/LabeledContainer';
import AcceptCancelButtons from '@/components/ui/AcceptCancelButtons';
import FormSelect from '@/components/common/form/components/FormSelect.vue';
import FormTextField from '@/components/common/form/components/FormTextField.vue';
import {
	object,
	requiredOption,
	requiredString,
	numberSchema,
	minMaxString,
	trim,
	positiveNumberText,
	requiredNumber,
	leadingZeros,
} from '@/components/common/form';

import { genUniqueKey } from '@/utility';

const metaTypeOptions = [
	{ label: 'Name', value: 'name' },
	{ label: 'Ranking', value: 'ranking' },
	{ label: 'Stats', value: 'stats' },
	{ label: 'BoostNumber', value: 'boost_number' },
	{ label: 'BoostPercentage', value: 'boost_percentage' },
];

export default {
	components: {
		FormSelect,
		FormTextField,
		LabeledContainer,
		AcceptCancelButtons,
	},
	emits: ['onCancel', 'onAccept'],
	setup (_, { emit }) {
		const { handleSubmit, setFieldValue, values, errors } = useForm({
			initialValues: {
				metaType: metaTypeOptions[0],
				metaName: '',
				metaValue: '',
				metaMaxValue: '',
			},
			validationSchema: object({
				metaType: requiredOption(),
				metaName: minMaxString(1, 100).concat(requiredString()).concat(trim),
				metaValue: Yup.mixed().when('metaType', {
					is: option => option.value === 'name',
					then: requiredString().concat(trim),
					otherwise: requiredNumber()
						.moreThan(-1, positiveNumberText)
						.concat(leadingZeros(2))
						.test('more-than-max-value', 'Value can\'t be more Max value', (value, ctx) => {
							const maxValue = ctx?.parent?.metaMaxValue;

							const numberValue = Number(value);

							if ((!value || isNaN(numberValue)) || !maxValue) {
								return true;
							}

							return numberValue <= maxValue;
						}),
				}),
				metaMaxValue: numberSchema
					.moreThan(-1, positiveNumberText)
					.concat(leadingZeros(2))
					.test('less-than-value', 'Max value can\'t be less Value', (value, ctx) => {
						const metaValue = Number(ctx?.parent?.metaValue);

						if (!value || (!metaValue || isNaN(metaValue))) {
							return true;
						}

						return value >= metaValue;
					}),
			}),
		});

		const onSubmit = handleSubmit(values => {
			let value;
			let maxValue;

			if (values.metaType.value === 'name') {
				value = values.metaValue;
			} else {
				value = parseFloat(values.metaValue);
				maxValue = parseFloat(values.metaMaxValue) || undefined;
			}

			emit('onAccept', {
				id: genUniqueKey(),
				name: values.metaName,
				type: values.metaType.value,
				value,
				maxValue,
				ratio: 0,
			});
		});

		watch(() => values?.metaType, (value) => {
			if (value === 'name' && values?.metaMaxValue) {
				setFieldValue('metaMaxValue', '');
			}
		});

		const isNameType = computed(() => values?.metaType?.value === 'name');

		return {
			values,
			errors,
			onSubmit,
			isNameType,
		};
	},
	data () {
		return {
			metaTypeOptions,
		};
	},
};
</script>

<style scoped lang='scss'>
.item {
  margin-bottom: 20px;
}

.actions-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
</style>
