<template>
  <div
    class="blocks-slot"
    :class="{'blocks-slot__hoverable': transitionTo?.length}"
    @click="toLink"
  >
    <div class="blocks-slot-media-wrapper">
      <slot
        v-if="!mediaSlot"
        name="media"
      >
        <Media
          v-if="preview"
          fit="contain"
          :src="preview"
          :placeholder-src="placeholderPath"
          class="blocks-slot-media-wrapper__media"
        />
        <img
          v-else
          :src="placeholderPath"
          class="blocks-slot-media-wrapper__media"
        >
      </slot>
      <template v-else>
        <component :is="mediaSlot" />
      </template>
    </div>
    <p class="block-slot__username">
      {{ resultLabel }}
    </p>
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import { formatAddress } from '@/utility';

import Media from '@/components/common/Media';

export default defineComponent({
	name: 'PreviewLink',
	components: { Media },
	props: {
		preview: String,
		label: String,
		withStringFormatting: {
			type: Boolean,
			default: false,
		},
		transitionTo: String,
		mediaSlot: {
			type: Object,
			required: false,
			default: null,
		},
	},
	data () {
		return {
			placeholderPath: require('@/images/avatar-placeholder.png'),
		};
	},
	computed: {
		resultLabel () {
			return this.withStringFormatting ? formatAddress(this.label) : this.label;
		},
	},
	methods: {
		toLink () {
			if (this.transitionTo) {
				window.open(this.transitionTo);
			}
		},
	},
});
</script>

<style scoped lang="scss">
.blocks-slot-media-wrapper {
  width: 40px;
  height: 40px;
  margin-right: 20px;

  &__media {
    width: inherit;
    height: inherit;
  }
}

.blocks-slot {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &__hoverable {
    &:hover {
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
}
</style>
