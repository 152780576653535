'use strict';

export default {
	setDefaultCollections: (state, { collections, totalCount }) => {
		state.defaultCollections = collections;
		state.allTotal = totalCount;
	},
	setCustomCollections: (state, { containers }) => {
		state.customCollections = containers;
		state.customTotal = containers.length;
	},
	setMergeCollections: (state, collections) => {
		state.mergeTypeCollectionsAddresses = collections;
	},
	setLimit: (state, limit) => {
		state.limit = limit;
	},
	setOffset: (state, offset) => {
		state.offset = offset;
	},
	setSearch: (state, search) => {
		state.search = search;
	},
	setLoading: (state, loading) => {
		state.loading = loading;
	},
	setBanLoading: (state, loading) => {
		state.banLoading = loading;
	},
	setCollectionStatus: (state, collectionStatus) => {
		state.collectionStatus = collectionStatus;
	},
	setSort: (state, sort) => {
		state.sort = sort;
	},
	setCollection: (state, payload) => {
		state.collection = payload;
	},
	setCurrentTab: (state, tab) => {
		state.currentTab = tab;
	},
	addContainerContractAddressForMerge: (state, collection) => {
		state.customCollectionMergeContractAddresses.push(collection);
	},
	removeContainerContractAddressForMerge: (state, id) => {
		state.customCollectionMergeContractAddresses = state.customCollectionMergeContractAddresses.filter(item => item.id !== id);
	},
	clearContainerContractAddressForMerge: (state) => {
		state.customCollectionMergeContractAddresses = [];
	},
};
