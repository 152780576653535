<template>
  <div class="announcements">
    <LinkToEditPage :identifier="route.params.identifier" />
    <div class="announcements__header">
      <AnnouncementsManagingPageTitle
        v-if="!loading"
        :collection-name="collection?.name || collectionIdentifier"
        label="Announcements"
      />
      <Button
        v-if="announcements?.length && !loading"
        upper-case
        class="announcements__button_create"
        @click="handlerClickCreate"
      >
        create announcement
      </Button>
      <AnnouncementsListPlaceholder
        v-if="!announcements?.length && !loading"
        button-label="create announcement"
        class="announcements__placeholder"
        placeholder-text="Use announcements to tell followers of your collection about it’s activities, plans and more."
        @click-button="handlerClickCreate"
      />
    </div>
    <LoaderIcon
      v-if="loading"
      class="announcements__loader"
      size="64px"
    />
    <TabsManager
      :hide="loading || !announcements?.length"
      :is-sticky="false"
      :items="tabItems"
      class="announcements__tabs-manager"
      is-centered
      @create-action="handlerClickCreate"
    >
      <template #default="{activeTab}">
        <transition
          mode="out-in"
        >
          <TabComponent
            :identifier="collectionIdentifier"
            :status="activeTab"
            @trash-action="updateTabsWithCounters"
            @start-loading="startLoading"
            @stop-loading="stopLoading"
          />
        </transition>
      </template>
    </TabsManager>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import TabsManager from '@/components/ui/tabs/TabsManager.vue';
import TabComponent from '@/pages/collections/announcements/components/TabComponent.vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import AnnouncementsListPlaceholder
	from '@/components/pages/announcements_and_airdrops/announcements-list-placeholder/AnnouncementsListPlaceholder';
import Button from '@/components/ui/Button';
import AnnouncementsManagingPageTitle
	from '@/components/pages/announcements_and_airdrops/announcements-managing-page-title/AnnouncementsManagingPageTitle';
import { COLLECTION_ANNOUNCEMENT_STATUSES, COLLECTION_ANNOUNCEMENT_TYPES } from '@/pages/collections/announcements/data';

import { useUpdateTabsWithCounter } from '@/pages/collections/announcements/composables/useUpdateTabsWithCounter';
import { useLoading } from '@/composables/useLoading';
import LoaderIcon from '@/components/icons/LoaderIcon';
import LinkToEditPage from '@/pages/collections/announcements/components/components/LinkToEditPage';

const store = useStore();
const route = useRoute();
const router = useRouter();

const { loading, startLoading, stopLoading } = useLoading();

const collection = computed(() => store.state.editCollection.collection);
const collectionIdentifier = computed(() => collection.value?.slug || collection.value?.contractAddress || route.params.identifier);
const announcements = computed(() => store.state.announcements.currentList);

const tabItems = ref([
	{
		label: 'All',
		value: 'all',
		type: COLLECTION_ANNOUNCEMENT_TYPES.AD,
		showScheduled: true,
	},
	{
		label: 'Drafts',
		value: COLLECTION_ANNOUNCEMENT_STATUSES.DRAFT,
		status: COLLECTION_ANNOUNCEMENT_STATUSES.DRAFT,
		type: COLLECTION_ANNOUNCEMENT_TYPES.AD,
		showScheduled: true,
	},
	{
		label: 'Published',
		status: COLLECTION_ANNOUNCEMENT_STATUSES.PUBLISHED,
		value: COLLECTION_ANNOUNCEMENT_STATUSES.PUBLISHED,
		type: COLLECTION_ANNOUNCEMENT_TYPES.AD,
		showScheduled: true,
	},
	{
		label: 'Trash',
		value: COLLECTION_ANNOUNCEMENT_STATUSES.TRASH,
		status: COLLECTION_ANNOUNCEMENT_STATUSES.TRASH,
		type: COLLECTION_ANNOUNCEMENT_TYPES.AD,
		showScheduled: true,
	},
]);

const { updateTabsWithCounters } = useUpdateTabsWithCounter(tabItems, collection);

const handlerClickCreate = () => {
	router.push({ name: 'CollectionAnnouncementCreate' });
};

onMounted(() => {
	startLoading();
	store.dispatch('editCollection/getCollection', { id: route.params.identifier });
});
</script>

<style lang="scss" scoped>
.announcements {
  position: relative;

  &__placeholder {
    margin: 256px 0 402px 0;
    max-width: 337px;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__button {
    &_create {
      max-width: 30%;
      align-self: center;
    }
  }

  &__header {
    @include flex(column);
    gap: 16px 0;
    padding: 24px 16px 32px 16px;
  }

  &__title {
    text-align: center;

    &_overflow {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 800px;

      @include max-screen-sm() {
        -webkit-line-clamp: 2;
      }
    }
  }

  &__tabs-manager {
    :deep(.tabs-content) {
      padding-top: 0;
    }
  }

  &__text-notfing-found {
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    text-align: center;
    margin: 16px 0 24px 0;
    max-width: 337px;
  }
}
</style>
