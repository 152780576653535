<template>
  <svg
    height="14"
    viewBox="0 0 16 14"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6 0.1C14.6 0.1 16.1-0.5 16 0.9 16 1.5 15.6 3.5 15.3 5.7L14.3 12.2C14.3 12.2 14.2 13.1 13.5 13.3 12.7 13.4 11.6 12.7 11.4 12.5 11.3 12.4 8.3 10.6 7.3 9.7 7 9.4 6.7 8.9 7.4 8.3L11.7 4.2C12.2 3.7 12.7 2.6 10.6 4L4.9 7.9C4.9 7.9 4.2 8.3 3 7.9L0.3 7.1C0.3 7.1-0.7 6.5 1 5.9 5.1 3.9 10.2 2 14.6 0.1Z"
      fill="currentColor"
    />
  </svg>
</template>
