<template>
  <div class="add-collection-section__container">
    <div class="add-collection-section__select">
      <LabeledContainer
        label="Select a action"
        for-id="actionType"
      >
        <FormSelect
          :options="containerRenderTypeOptions"
          :model-value="activeActionType"
          name="actionType"
          @update:model-value="handleChange"
        />
      </LabeledContainer>
    </div>
    <div class="add-collection-section__select-content">
      <MergeCollections
        v-show="activeActionType.value === containerRenderTypeOptions[0].value"
      />
      <SplitCollections
        v-show="activeActionType.value === containerRenderTypeOptions[1].value"
        :handle-change-address="handleChangeAddress"
        @on-remove-element="removeElement"
      />
      <DraftCollections
        v-show="activeActionType.value === containerRenderTypeOptions[2].value"
        :on-change-draft-address="onChangeDraftAddress"
      />
    </div>
  </div>
</template>

<script setup>
import { defineEmits, ref } from 'vue';

import LabeledContainer from '@/components/ui/LabeledContainer';
import FormSelect from '@/components/common/form/components/FormSelect';
import { containerRenderTypeOptions } from '@/pages/collections/add-container/sections/helpers';
import MergeCollections from '@/pages/collections/add-container/sections/components/MergeCollections.vue';
import SplitCollections from '@/pages/collections/add-container/sections/components/SplitCollections.vue';
import DraftCollections from '@/pages/collections/add-container/sections/components/DraftCollections.vue';

defineProps({
	handleChangeAddress: {
		type: Function,
	},
	onChangeDraftAddress: {
		type: Function,
	},
});

const emit = defineEmits(['onRemoveElement']);

const removeElement = (collectionId) => emit('onRemoveElement', collectionId);

const activeActionType = ref(containerRenderTypeOptions[0]);

const handleChange = (option) => {
	activeActionType.value = option;
};
</script>

<style lang="scss" scoped>
.add-collection-section {
  &__container {
    max-width: 100%;
  }

  &__select {
    max-width: 186px;
    margin: 0 0 35px;

    &:deep(.select-button) {
      justify-content: space-between;
    }
  }
}
</style>
