import difference from 'lodash/difference';

/**
 * @param {string[]} textareaItems
 * @returns {{textareaItems: string}}
 */
export function initValues (textareaItems) {
	return { textareaItems: textareaItems.filter(item => !!item).join('\n') };
}

/**
 * @param {string[]} currentItems
 * @param {string} textareaValue
 */
export function getDataDifference (currentItems, textareaValue) {
	const updatedItems = textareaValue.split('\n').filter(item => !!item);

	const itemsToAdd = difference(updatedItems, currentItems);
	const itemsToRemove = difference(currentItems, updatedItems);

	return { itemsToAdd, itemsToRemove, updatedItems };
}
