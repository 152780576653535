<template>
  <div class="boost-item">
    <CircularProgress
      class="progress-item"
      :current="current"
      :total="total"
    >
      <RocketIcon />
    </CircularProgress>
    <div class="boost-info">
      <div class="boost-name">
        {{ name }}
      </div>
      <div>{{ value }}</div>
    </div>
  </div>
</template>

<script>
import { isString } from 'lodash';

import { RocketIcon } from '@/components/icons';

import CircularProgress from './CircularProgress.vue';

export default {
	components: {
		RocketIcon,
		CircularProgress,
	},
	props: {
		name: {
			type: String,
			required: true,
		},
		current: {
			type: [Number, String],
			required: false,
			default: 0,
		},
		total: {
			type: [Number, String],
			required: false,
			default: 0,
		},
		isPercentage: {
			type: Boolean,
			required: false,
		},
	},
	computed: {
		value () {
			const currentValue = isString(this.current) ? parseFloat(this.current) : this.current;
			const totalValue = isString(this.total) ? parseFloat(this.total) : this.total;

			let value = this.current || this.total;

			if (this.hasBothValues) {
				value = this.isPercentage ? Math.round((currentValue / totalValue) * 100) : value;
			}

			if (!isNaN(currentValue)) {
				value = `${this.isPercentage ? `${value}%` : `+${value}`} boost`;
			}

			return value;
		},
		hasBothValues () {
			return this.current && this.total;
		},
	},
};
</script>

<style scoped lang='scss'>
  .boost-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    word-break: break-word;

    .progress-item {
      margin-bottom: 10px;
    }

    .boost-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .boost-name {
        font-weight: 600;
      }
    }
  }
</style>
