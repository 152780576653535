/**
 * @desc format custom collections to same format
 * @param { Array<Record<string, any>> } collections
 */
export const customCollectionFormatter = ({ collections }) => collections?.map((item) => customCollectionItemFormatter({ item }));

/**
 * @desc format custom collections item to same format
 * @param { Record<string, any> } item
 */
const customCollectionItemFormatter = ({ item }) => ({
	...item,
	id: item.id,
	name: item.name,
	createdAt: item.createdAt,
	type: item.type,
	contractAddresses: item.contractAddresses,
	isVerified: item.verified,
});
