<template>
  <div class="edit-form">
    <span
      class="edit-form__header"
      @click="transitionTo"
    >{{ address }}</span>
    <div class="edit-form__registered">
      <span class="edit-form__registered__text__joined">{{ registeredDate ? 'Joined: ' : 'User not registered' }}</span>
      <span class="edit-form__registered__text">{{ registeredDate }}</span>
    </div>
    <BaseDivider />
    <div class="edit-form__form">
      <span class="form__title_normal">Avatar</span>
      <FormDropImage
        class="banner-banner"
        :address="profile?.ethAddress"
        name="avatarUrl"
        :disabled="notEditable"
        type="user/avatar"
        helper-text="Uploaded"
        :max-file-bytes="100*1024*1024"
        recommended-text="350 x 350 recommended."
      />
      <span class="form__title_normal">Banner</span>
      <FormDropImage
        name="bannerUrl"
        :address="profile?.ethAddress"
        :disabled="notEditable"
        type="user/banner"
        helper-text="Uploaded"
        :max-file-bytes="100*1024*1024"
        recommended-text="1600 x 300 recommended."
      />
      <span class="form__title_small">Username</span>
      <FormTextField
        name="username"
        variant="rounded"
      />

      <!--TODO Uncomment when will necessary-->
      <!--      <span class="form__title_small">Email</span>-->
      <!--      <FormTextField type="text"-->
      <!--                     name="email"-->
      <!--                     variant="rounded"/>-->

      <span class="form__title_small">Description</span>
      <FormTextarea
        name="description"
        variant="rounded"
        :rows="1"
        :max-height="512"
      />

      <span class="form__title_normal">Websites and Social Links</span>
      <BaseDivider />
      <div class="form__social-links">
        <FormWebsiteField
          name="websiteUrl"
          class="social-links__btn_web"
        />
        <FormSocialLinkField
          name="socialLinks"
          class="social-links__btn_social"
        />
      </div>
      <BaseDivider />

      <ConfigurationList
        :config="config"
        :disabled="isSubmitting"
        class="form__config"
      />

      <BaseDivider />

      <div class="form__action-btns">
        <Button
          type="submit"
          class="action_btns__btn_main"
          :disabled="updateLoading || !isChanged"
          @click="onSubmit"
        >
          Save Changes
        </Button>

        <Button
          class="action_btns__btn_secondary"
          :disabled="banLoading"
          :color="banBtnColor"
          variant="outlined"
          @click="changeBan"
        >
          {{ banBtnText }} User
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, provide, ref, shallowRef, watch } from 'vue';
import { useStore } from 'vuex';
import { useForm } from 'vee-validate';

import Button from '@/components/ui/Button';
import BaseDivider from '@/components/ui/BaseDivider';
import BanModal from '@/pages/users/components/BanModal';

import FormTextarea from '@/components/common/form/components/FormTextarea.vue';
import FormTextField from '@/components/common/form/components/FormTextField.vue';
import FormDropImage from '@/components/common/form/components/FormDropImage.vue';
import FormWebsiteField from '@/components/common/form/components/FormWebsiteField.vue';
import FormSocialLinkField from '@/components/common/form/components/FormSocialLinkField.vue';

import { formatAddress, formatDate } from '@/utility';
import { initValues, validationSchema, submitFormValues } from './helpers';
import { isEqual } from 'lodash';
import ConfigurationList from '@/components/pages/users/edit/componets/ConfigurationList';

export default {
	components: {
		ConfigurationList,
		Button,
		BaseDivider,
		FormTextField,
		FormTextarea,
		FormDropImage,
		FormWebsiteField,
		FormSocialLinkField,
	},

	props: {
		profile: {
			type: Object,
			required: true,
			default: null,
		},
	},

	setup (props) {
		const marketplaceUrl = process.env.VUE_APP_WEBSITE_BASE_URL;
		const store = useStore();
		const address = computed(() => formatAddress(props.profile.ethAddress));
		const registeredDate = computed(() => props.profile?.registeredAt && formatDate(props.profile?.registeredAt, 'EEE LLL d, p'));
		const banBtnText = computed(() => props.profile.userStatus === 'active' ? 'Ban' : 'Unban');
		const banBtnColor = computed(() => props.profile.userStatus === 'active' ? 'red' : 'grey');
		const isChanged = ref(false);
		const updateLoading = ref(false);
		const banLoading = ref(false);
		const isSubmitting = ref(false);
		const update = async payload => {
			updateLoading.value = true;
			await store.dispatch('editUser/updateProfile', { id: props.profile.id, updatedValues: payload })
				.finally(() => {
					updateLoading.value = false;
					isChanged.value = false;
				});
		};

		const changeBan = computed(() => banBtnText.value === 'Unban'
			? async () => {
				banLoading.value = true;
				await store.dispatch('editUser/toggleBan')
					.finally(() => { banLoading.value = false; });
			}
			: () => store.dispatch('modal/openModal', {
				content: shallowRef(BanModal),
				props: { ...props.profile, fromEditing: true },
			}));

		const { values, handleSubmit, setFieldValue } = useForm({
			initialValues: initValues(props.profile),
			validationSchema,
		});

		const onSubmit = handleSubmit(async values => {
			isSubmitting.value = true;

			try {
				await submitFormValues({ update }, values);
			} finally {
				isSubmitting.value = false;
			}
		});

		const config = [
			{
				label: 'Hide average sale amount from profile',
				name: 'hideAverageSaleAmount',
			},
			{
				label: 'Hide traded amount from profile',
				name: 'hideTradedAmount',
			},
		];

		const transitionTo = () => {
			window.open(`${marketplaceUrl}/${props.profile.ethAddress}`);
		};

		watch(
			values,
			(values) => {
				isChanged.value = !isEqual(values, initValues(store.state.editUser.initState));
			},
		);

		provide('formState', { setFieldValue, values });

		return {
			address,
			registeredDate,
			values,
			handleSubmit,
			setFieldValue,
			onSubmit,
			isSubmitting,
			updateLoading,
			changeBan,
			banLoading,
			banBtnText,
			banBtnColor,
			isChanged,
			config,
			transitionTo,
		};
	},
};
</script>

<style scoped lang="scss">
.edit-form {
  max-width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__header {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    line-height: 36px;
    cursor: pointer;
  }

  &__registered {
    display: flex;
    margin-bottom: 26px;

    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;

      &__joined {
        margin-right: 4px;
        color: #ACACAC;
      }
    }
  }

  &__form {
    width: 100%;
    min-width: 510px;
    margin-top: 26px;

    .form {

      &__title_normal {
        display: block;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        margin: 10px 0px 17px 0px;
      }

      &__title_small {
        display: block;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        margin: 18px 0px 12px 0px;
      }

      &__action-btns {
        display: flex;
        padding-top: 20px;

        button:first-child {
          min-width: 235px;
          margin-right: 13px;
        }
      }

      &__social-links {
        padding-top: 20px;
        padding-bottom: 20px;

        .social-links {
          &__btn_social {
            margin-top: 16px;
          }
        }
      }

      &__config {
        padding: 21px 0;
      }
    }
  }
}
</style>
