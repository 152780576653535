<template>
  <span>{{ label }}</span>
  <button
    class="copy-button"
    :class="{ 'copy-button_hover': !inline }"
    @click.stop="copy"
  >
    <Tooltip
      :value="tooltipText"
      @closed="tooltipClosed"
    >
      <slot
        v-if="hideIcon"
        name="text"
      />
      <CopyIcon v-else />
    </Tooltip>
  </button>
</template>

<script>
'use strict';

import { defineComponent } from 'vue';
import useClipboard from 'vue-clipboard3';
import Tooltip from '@/components/ui/Tooltip.vue';
import { CopyIcon } from '@/components/icons';

export default defineComponent({
	components: { Tooltip, CopyIcon },

	props: {
		label: {
			type: String,
			required: false,
		},
		value: {
			type: String,
			required: true,
		},
		inline: {
			type: Boolean,
			required: false,
			default: false,
		},
		hideIcon: {
			type: Boolean,
			required: false,
			default: false,
		},
	},

	data () {
		return {
			tooltipText: 'Click to Copy',
		};
	},

	methods: {
		tooltipClosed () {
			this.tooltipText = 'Click to Copy';
		},

		async copy () {
			const { toClipboard } = useClipboard();
			await toClipboard(this.value);

			this.tooltipText = 'Copied!';
		},
	},
});
</script>

<style lang="scss" scoped>
.copy-button {
  outline: none;
  background: transparent;
  border: 0;

  > svg {
    display: block;
  }
}

.copy-button_hover {
  padding: 13px;
  border-radius: 50%;
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--icon-background);
    cursor: pointer;
  }
}
</style>
