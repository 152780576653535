import axios from 'axios';
import store from '@/store';
import qs from 'qs';

import { addError } from '@/components/utility/notifications';

import errorApiHandler from './errorApiHandler.service';

const responseBody = response => response?.data;

const requestService = axios.create();

const trimStringValues = data => {
	const deepTrim = obj => {
		Object.keys(obj).forEach(key => {
			const value = obj[key];
			const type = typeof value;

			// eslint-disable-next-line no-prototype-builtins
			if (value !== null && (type === 'string' || type === 'object') && obj.hasOwnProperty(key)) {
				if (type === 'object') {
					deepTrim(obj[key]);
				} else {
					obj[key] = obj[key].trim();
				}
			}
		});
	};

	let requestData;

	if (Array.isArray(data)) {
		requestData = [...data];

		requestData.map(deepTrim);
	} else if (data instanceof FormData) {
		requestData = data;
	} else {
		requestData = { ...data };

		deepTrim(requestData);
	}

	return requestData;
};

const customParamsSerializer = params => qs.stringify(trimStringValues(params), { arrayFormat: 'repeat' });

requestService.interceptors.request.use(config => {
	const token = store.state?.auth?.tokens?.access;

	config.headers.Authorization = `Bearer ${token || ''}`;

	return config;
});

requestService.interceptors.response.use(undefined, async error => {
	if (!error?.response) {
		addError('Whoops! There seems to be a problem with a network connection. Please try again later.');
	} else {
		if (error.response.data.message === 'Unauthorized' && error.response.status === 401) {
			await store.dispatch('auth/refresh');

			const retryData = await requestService(error.config);

			return Promise.resolve(retryData);
		}
		errorApiHandler.showResponseError(error);

		return Promise.reject(error);
	}
});

const requests = {
	post: (url, data, config, returnWholeResponse = false) => {
		const requestData = trimStringValues(data);

		const response = requestService.post(url, requestData, config);

		return returnWholeResponse ? response : response.then(responseBody);
	},
	patch: (url, data) => requestService.patch(url, trimStringValues(data)).then(responseBody),
	delete: (url, data) => requestService.delete(url, trimStringValues(data)).then(responseBody),
	put: (url, data, config) => requestService.put(url, trimStringValues(data), config).then(responseBody),
	get: (url, config) => requestService
		.get(url, {
			...config,
			paramsSerializer: customParamsSerializer,
		})
		.then(responseBody),
};

export default requests;
