<template>
  <div class="edit-collection-section__container">
    <div class="edit-collection-section__select-content">
      <component
        :is="renderContentByActionType"
        :collections="collections"
        :container-id="containerId"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import { containerRenderTypes } from '@/pages/collections/edit-container/sections/helpers';
import DraftCollections from '@/pages/collections/edit-container/sections/components/DraftCollection.vue';
import MergeCollections from '@/pages/collections/edit-container/sections/components/MergedCollection.vue';
import SplitCollections from '@/pages/collections/edit-container/sections/components/SplittedCollection.vue';

const props = defineProps({
	collections: {
		type: Array,
		default: () => [],
	},
	containerId: {
		type: String,
		default: '',
	},
	actionType: {
		type: String,
		default: '',
	},
});

const renderContentByActionType = computed(() => {
	switch (props.actionType) {
		case containerRenderTypes[0].value:
			return MergeCollections;
		case containerRenderTypes[1].value:
			return SplitCollections;
		case containerRenderTypes[2].value:
			return DraftCollections;
		default:
			return null;
	}
});

</script>

<style lang="scss" scoped>
.edit-collection-section {
  &__container {
    &:deep(.container-content__header) {
      .label {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #000000;
        margin: 0;
      }
    }
  }
}
</style>
