<template>
  <div
    class="labeled-wrapper"
    :class="[{ 'has-label-content': hasLabelContent }, { 'big-label': isBigLabel }]"
  >
    <label
      class="label"
      :for="forId"
    >
      {{ label }}
      <slot
        v-if="hasLabelContent"
        name="labelContent"
      />
    </label>
    <slot />
  </div>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			required: false,
		},
		forId: {
			type: String,
			required: false,
		},
		isBigLabel: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		hasLabelContent () {
			return !!this.$slots.labelContent;
		},
	},
};
</script>

<style scoped lang="scss">
  .labeled-wrapper {
    &.has-label-content {
      .label {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
      }
    }

    &.big-label {
      .label {
        font-weight: 700 !important;
        font-size: 20px !important;
        margin: 24px 0 20px !important;
      }
    }

    .label {
      display: block;
      margin-bottom: 5px;
      font-weight: 400;
    }
  }
</style>
