<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="27"
    fill="none"
    viewBox="0 0 22 27"
  >
    <defs />
    <path
      fill="currentColor"
      d="M6.29 20.65v-9.53H0L11 0l11 11.12h-6.29v9.53H6.3zM0 27v-3.18h22V27H0z"
    />
  </svg>
</template>
