<script>
import { defineComponent } from 'vue';

import Cell from '../Cell.vue';

export default defineComponent({
	name: 'BodyCell',
	components: {
		Cell,
	},
	props: ['component'],
	render () {
		return <Cell class="body__cell">{this.component || this.$slots.default()}</Cell>;
	},
});
</script>
