<template>
  <div class="tooltip-wrapper">
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="!hideTooltip && isOpen"
        class="tooltip-item"
        :class="classes"
      >
        <span>{{ value }}</span>
      </div>
    </transition>
    <div
      class="content"
      @mouseenter="isOpen = true"
      @mouseleave="isOpen = false"
    >
      <slot />
    </div>
  </div>
</template>

<script>
'use strict';

export default {

	props: {
		value: {
			type: String,
		},
		position: {
			type: String,
			required: false,
		},
		hideTooltip: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	data () {
		return {
			isOpen: false,
		};
	},
	computed: {
		classes () {
			let classes = '';

			if (this.position) classes += this.position;

			return classes;
		},
	},
};
</script>

<style scoped lang='scss'>
.tooltip-wrapper {
  position: relative;
  width: max-content;
  height: max-content;
  display: inline-flex;

  .content {
    display: inline-flex;
    width: inherit;
    height: inherit;
  }
}

.tooltip-item {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  z-index: 999;
  padding: 10px;
  border-radius: 20px;
  background-color: $grey;
  color: $white;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  width: max-content;

  &.right {
    transform: translateX(0) translateY(-50%);
    left: 20px;
    top: 50%;
  }
}
</style>
