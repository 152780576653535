<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#fff"
      d="M1.6 7.2H0v1.6h1.6V7.2ZM3.2 7.2H1.6v1.6h1.6V7.2ZM3.2 8.8H1.6v1.6h1.6V8.8ZM4.8 8.8H3.2v1.6h1.6V8.8ZM4.8 10.4H3.2V12h1.6v-1.6Z"
    />
    <path
      fill="#fff"
      d="M6.4 10.4H4.8V12h1.6v-1.6ZM6.4 12H4.8v1.6h1.6V12ZM11.2 5.6h1.6V4h-1.6v1.6ZM11.2 7.2h1.6V5.6h-1.6v1.6ZM12.8 5.6h1.6V4h-1.6v1.6ZM9.6 7.2h1.6V5.6H9.6v1.6ZM9.6 8.8h1.6V7.2H9.6v1.6ZM8 8.8h1.6V7.2H8v1.6ZM8 10.4h1.6V8.8H8v1.6ZM6.4 10.4H8V8.8H6.4v1.6ZM6.4 12H8v-1.6H6.4V12ZM14.4 2.4h-1.6V4h1.6V2.4ZM16 2.4h-1.6V4H16V2.4Z"
    />
  </svg>
</template>
