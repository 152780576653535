import requests from './requests.service';

const baseUrl = process.env.VUE_APP_ADMIN_PANEL_BASE_URL;
const basePath = `${baseUrl}/admin/v1/users`;

export const usersService = {
	getList: params => requests.get(basePath, { params }),
	banUser: id => requests.post(`${basePath}/${id}/ban`),
	unbanUser: id => requests.delete(`${basePath}/${id}/ban`),
	getUser: id => requests.get(`${basePath}/${id}`),
	editUser: (id, payload) => requests.patch(`${basePath}/${id}`, payload),
	editRole: (id, role) => requests.post(`${basePath}/${id}/rolse`, { role }),
};
