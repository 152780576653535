import {
	maxString,
	maxStringMasked,
	object,
	requiredString,
	timeAfter,
	url,
} from '@/components/common/form/formValidation.js';

export const STATUS_OPTIONS = {
	draft: { label: 'Draft', value: 'draft' },
	published: { label: 'Published', value: 'published' },
};

export const VISIBILITY_OPTIONS = {
	public:	{ label: 'Public', value: 'public' },
	private: { label: 'Private', value: 'private' },
};

export const PUBLISH_OPTIONS = {
	immediately: { label: 'Immediately', value: 'immediately' },
	custom: { label: 'Custom', value: 'custom' },
};

export const AD_INITIAL_VALUES = {
	title: '',
	body: '',
	status: STATUS_OPTIONS.draft,
	visibility: VISIBILITY_OPTIONS.public,
	publish: PUBLISH_OPTIONS.immediately,
	publishedAt: '',
};

export const AIRDROP_INITIAL_VALUES = {
	startsAt: '',
	endsAt: '',
	claimURL: '',
	tweetURL: '',
	...AD_INITIAL_VALUES,
};

export const AD_SCHEMA = object({
	title: requiredString().concat(maxString(100)),
	body: maxString(8000),
	publishedAt: timeAfter({ indent: 15 }),
});

export const TWEET_URL_MASK = 'https://twitter.com/';

export const AIRDROP_SCHEMA = object({
	title: requiredString().concat(maxString(100)),
	body: maxString(8000),
	publishedAt: timeAfter({ indent: 15 }),
	endsAt: timeAfter({ errorMessage: 'End time must be after start time', fromKeys: ['startsAt'] }),
	claimURL: url.concat(requiredString()).concat(maxString(2083)),
	tweetURL: requiredString().concat(maxStringMasked(2083, TWEET_URL_MASK.length)),
});

export const THUMBNAIL_ACCEPT_OPTIONS = [
	{ value: 'image/png', label: 'png' },
	{ value: 'image/jpeg', label: 'jpeg' },
	{ value: 'image/jpg', label: 'jpg' },
	{ value: 'image/gif', label: 'gif' },
];
