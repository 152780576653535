import { COLLECTION_ERRORS } from '@/pages/collections/constants/index.js';

export const validateDraftAddressErrorsHandler = ({ err, setErrorFunc }) => {
	if (err.response?.status === 303) {
		setErrorFunc(`draftAddress`, COLLECTION_ERRORS.INCLUDED_IN_MERGE);

		return COLLECTION_ERRORS.INCLUDED_IN_MERGE;
	}

	return null;
};
