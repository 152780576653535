import { shallowRef } from 'vue';
import { isEmpty, map, omit, orderBy, sortBy } from 'lodash';

import { genUniqueKey } from '@/utility/helpers';
import { renderSocialIconByKey } from '@/components/icons';

const getIcon = value => shallowRef(renderSocialIconByKey(value));

export const twitterLinkType = { label: 'Twitter', value: 'twitter', icon: getIcon('twitter') };
export const instagramLinkType = { label: 'Instagram', value: 'instagram', icon: getIcon('instagram') };
export const facebookLinkType = { label: 'Facebook', value: 'facebook', icon: getIcon('facebook') };
export const youtubeLinkType = { label: 'YouTube', value: 'youtube', icon: getIcon('youtube') };
export const linkedinLinkType = { label: 'LinkedIn', value: 'linkedin', icon: getIcon('linkedin') };
export const emailLinkType = { label: 'Email', value: 'email', icon: getIcon('email') };
export const twitchLinkType = { label: 'Twitch', value: 'twitch', icon: getIcon('twitch') };
export const telegramLinkType = { label: 'Telegram', value: 'telegram', icon: getIcon('telegram') };
export const mediumLinkType = { label: 'Medium', value: 'medium', icon: getIcon('medium') };
export const discordLinkType = { label: 'Discord', value: 'discord', icon: getIcon('discord') };
export const snapchatLinkType = { label: 'SnapChat', value: 'snapchat', icon: getIcon('snapchat') };
export const githubLinkType = { label: 'GitHub', value: 'github', icon: getIcon('github') };

export const linkTypes = orderBy([
	discordLinkType,
	emailLinkType,
	facebookLinkType,
	githubLinkType,
	instagramLinkType,
	linkedinLinkType,
	mediumLinkType,
	snapchatLinkType,
	telegramLinkType,
	twitchLinkType,
	twitterLinkType,
	youtubeLinkType,
], 'value');

export const defaultLink = {
	id: '',
	link: '',
	type: twitchLinkType,
};

export const createLink = (fields) => {
	if (isEmpty(fields)) return null;

	const availableTypes = {};
	const values = map(fields.value, field => field.value);

	linkTypes.forEach(type => {
		availableTypes[type.value] = { ...type, count: 0 };
	});

	sortBy(map(values, 'type.value')).forEach(type => {
		availableTypes[type].count += 1;
		if (availableTypes[type].count >= 2) {
			delete availableTypes[type];
		}
	});

	const type = availableTypes[Object.keys(availableTypes)[0]];

	if (isEmpty(type)) return null;

	return {
		id: genUniqueKey(),
		link: '',
		type: omit(type, ['count', 'icon']),
	};
};
