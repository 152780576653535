<template>
  <component
    :is="renderComponent"
    :href="marketplaceCollectionLink"
    target="_blank"
    rel="noopener nofollow noreferrer"
    :class="['collection-cell', {link: isLink}]"
  >
    <Media
      v-if="props.collection.avatarUrl"
      :src="props.collection.avatarUrl"
      :placeholder-src="placeholderAvatarUrl"
      class="collection-cell__media"
    />
    <img
      v-else
      class="collection-cell__media"
      :src="placeholderAvatarUrl"
    >
    <VerificationLabel
      v-if="props.collection.name || !showAddress"
      :name="props.collection.name ? props.collection.name : 'Unnamed'"
      :is-verified="props.collection.isVerified || props.collection.verified"
    />
    <CopyButton
      v-else
      :value="props.collection.contractAddress"
      :label="formattedAddress"
    />
  </component>
</template>

<script setup>
import { computed, defineProps } from 'vue';

import { formatAddress } from '@/utility';
import Media from '@/components/common/Media.vue';
import CopyButton from '@/components/ui/CopyButton';
import VerificationLabel from '@/components/ui/VerificationLabel.vue';

const placeholderAvatarUrl = require('@/images/avatar-placeholder.png');

const props = defineProps({
	collection: {
		type: Object,
		required: true,
	},
	showAddress: {
		type: Boolean,
		default: false,
	},
	isLink: {
		type: Boolean,
		default: false,
	},
});
const marketplaceUrl = process.env.VUE_APP_WEBSITE_BASE_URL;
const renderComponent = computed(() => props.isLink ? 'a' : 'div');

const formattedAddress = computed(() => formatAddress(props.collection.contractAddress || ''));
const marketplaceCollectionLink = computed(() => `${marketplaceUrl}/collections/${props.collection.slug || props.collection.id}`);
</script>

<style lang="scss" scoped>
.collection-cell {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__media {
    width: 40px;
    height: 40px;
    margin-right: 10px;

    border-radius: 8px;

    :deep(img) {
      border-radius: 8px;
    }
  }
}

.link {
  width: fit-content;
  cursor: pointer;
}
</style>
