<template>
  <div
    class="tabs-menu"
    :class="{'is-sticky': isSticky}"
    :style="{top: stickyOffset}"
  >
    <slot />
  </div>
</template>

<script>
import { provide, computed, ref } from 'vue';

export default {
	name: 'Tabs',
	props: {
		modelValue: {
			type: [String, Number],
		},
		isSticky: {
			type: Boolean,
			required: false,
			default: true,
		},
		stickyOffset: {
			type: [String, Number],
			required: false,
			default: '60px',
		},
	},
	emits: ['update:modelValue'],
	setup (props, { emit }) {
		const active = computed(() => props.modelValue);
		const tabs = ref([]);

		const selectTab = tab => {
			emit('update:modelValue', tab);
		};

		provide('tabsState', {
			active,
			tabs,
			selectTab,
		});
	},
};
</script>

<style scoped lang='scss'>
  .tabs-menu {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    top: 0;
    background-color: $white;
    z-index: 100;
    overflow-x: auto;

    &.is-sticky {
      position: sticky;
    }
  }
</style>
