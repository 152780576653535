<template>
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.64999 3.04999C0.821567 3.04999 0.149994 2.37841 0.149994 1.54999C0.149994 0.721561 0.821567 0.0499878 1.64999 0.0499878C2.47842 0.0499878 3.14999 0.721561 3.14999 1.54999C3.14999 2.37841 2.47842 3.04999 1.64999 3.04999ZM8.04999 3.04999C7.22157 3.04999 6.54999 2.37841 6.54999 1.54999C6.54999 0.721561 7.22157 0.0499878 8.04999 0.0499878C8.87842 0.0499878 9.54999 0.721561 9.54999 1.54999C9.54999 2.37841 8.87842 3.04999 8.04999 3.04999ZM0.149994 7.94999C0.149994 8.77841 0.821567 9.44999 1.64999 9.44999C2.47842 9.44999 3.14999 8.77841 3.14999 7.94999C3.14999 7.12156 2.47842 6.44999 1.64999 6.44999C0.821567 6.44999 0.149994 7.12156 0.149994 7.94999ZM8.04999 9.44999C7.22157 9.44999 6.54999 8.77841 6.54999 7.94999C6.54999 7.12156 7.22157 6.44999 8.04999 6.44999C8.87842 6.44999 9.54999 7.12156 9.54999 7.94999C9.54999 8.77841 8.87842 9.44999 8.04999 9.44999ZM0.149994 14.35C0.149994 15.1784 0.821567 15.85 1.64999 15.85C2.47842 15.85 3.14999 15.1784 3.14999 14.35C3.14999 13.5216 2.47842 12.85 1.64999 12.85C0.821567 12.85 0.149994 13.5216 0.149994 14.35ZM8.04999 15.85C7.22157 15.85 6.54999 15.1784 6.54999 14.35C6.54999 13.5216 7.22157 12.85 8.04999 12.85C8.87842 12.85 9.54999 13.5216 9.54999 14.35C9.54999 15.1784 8.87842 15.85 8.04999 15.85Z"
      fill="currentColor"
    />
  </svg>
</template>
