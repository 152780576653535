<template>
  <div
    v-if="total"
    class="pagination"
  >
    <Button
      variant="outlined"
      :disabled="isLess"
      @click="decrease"
    >
      {{ buttonLabels.prev }}
    </Button>
    <span class="pagination__counters">{{ `${formattedOffset} / ${total}` }}</span>
    <Button
      variant="outlined"
      :disabled="isMore"
      @click="increase"
    >
      {{ buttonLabels.next }}
    </Button>
  </div>
</template>

<script setup>
import { computed, defineEmits, inject } from 'vue';
import Button from '@/components/ui/Button';

const emit = defineEmits(['offset:change']);

const limit = inject('limit');
const offset = inject('offset');
const total = inject('total');
const limitOfRecords = 10000;

const buttonLabels = {
	prev: '< prev',
	next: 'next >',
};

const isMore = computed(() => {
	const countThisPage = offset.value + limit.value;
	const countNextPage = countThisPage + limit.value;
	return countThisPage >= total.value || countNextPage > limitOfRecords;
});
const isLess = computed(() => offset.value <= 0);
const formattedOffset = computed(() => {
	const [start, end] = [offset.value + 1, Math.min(offset.value + limit.value, total.value)];
	return start >= end ? end : `${start} - ${end}`;
});

const increase = () => {
	emit('offset:change', offset.value + limit.value);
};

const decrease = () => {
	let value = offset.value - limit.value;
	if (value < 0) {
		value = 0;
	}
	emit('offset:change', value);
};
</script>

<style scoped lang='scss'>
.pagination {
  display: flex;

  &__counters {
    display: flex;
    align-items: center;
    padding: 0 11px;
  }
}
</style>
