<template>
  <TitledPage :title="`Users (${formatNumber(total, 0)})`">
    <WithEmptyState
      :value="userSearch"
      :count="usersList?.length"
    >
      <template #filters="{emptyState}">
        <Filters :is-empty-state="emptyState" />
      </template>

      <Table
        :data="usersList"
        :columns="columns"
        class="users-slot"
        :loading="loading"
        :pagination="{ limit, offset, total }"
        @offset:change="handleOffsetChange"
      />
    </WithEmptyState>
  </TitledPage>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { computed, onMounted } from 'vue';

import Table from '@/components/ui/table/Table';

import TitledPage from '@/layouts/components/TitledPage';
import WithEmptyState from '@/layouts/components/WithEmptyState';

import { columnsForTable } from '@/pages/users/helpers';
import Filters from '@/pages/users/components/Filters';

import { formatNumber } from '@/utility';

const store = useStore();
const route = useRoute();

const usersList = computed(() => store.state.users.users);
const total = computed(() => store.state.users.count);
const limit = computed(() => store.state.users.showLength);
const offset = computed(() => store.state.users.offset);
const loading = computed(() => store.state.users.loading);
const userSearch = computed(() => store.state.users.search);
const columns = computed(() => columnsForTable);

const handleOffsetChange = (value) => {
	store.dispatch('users/changeOffset', value);
};

onMounted(() => {
	if (route.query?.search) {
		store.dispatch('users/getUsers', { search: route.query?.search });
	}
});
</script>

<style scoped lang="scss"></style>
