import { markRaw } from 'vue';
import { useStore } from 'vuex';

// import { currentStore } from '@/store';

import AnnouncementModal from '../AnnouncementModal.vue';

export const useAnnouncementRemoveModal = () => {
	const store = useStore();

	const openAnnouncementModal = (props) => store.dispatch('modal/openModal', {
		content: markRaw(AnnouncementModal),
		props: {
			title: props?.title,
			body: props?.body,
			listItem: props?.listItem,
			onSubmit: props?.onSubmit,
		},
	});

	return {
		openAnnouncementModal,
	};
};
