<template>
  <div
    class="dropdown-content"
    :style="{width, maxHeight, height}"
  >
    <slot />
  </div>
</template>

<script>
export default {
	props: {
		width: {
			type: String,
			required: false,
			default: '209px',
		},
		height: {
			type: String,
			required: false,
		},
		maxHeight: {
			type: String,
			required: false,
		},
	},
};
</script>

<style scoped lang='scss'>
  .dropdown-content {
    padding: 10px;
    color: $grey;
    overflow-y: auto;
  }
</style>
