<template>
  <div
    class="reports-action-buttons"
    :style="{ 'width': width + 'px' }"
  >
    <Button
      v-for="button of buttons"
      v-show="button.show()"
      :key="button"
      :disabled="button.disabled()"
      :variant="button.variant"
      :round="button.round"
      :size="button.size"
      :class="button.class || ''"
      @click="button.clickFn"
    >
      <component :is="button.icon" />{{ button.label }}
    </Button>
  </div>
</template>

<script setup>
import { computed, defineProps, ref, shallowRef } from 'vue';
import { useStore } from 'vuex';
import Button from '@/components/ui/Button.vue';
import { CheckIcon, CrossIcon, PencilIcon, ShareIcon } from '@/components/icons';
import AcceptModal from './AcceptModal.vue';
import { acceptConstants, marketplaceUrls } from '../helpers';
import { refLoadingWrapper } from '@/utility';

const store = useStore();

const marketplaceUrl = process.env.VUE_APP_WEBSITE_BASE_URL;

const removeCheckbox = (item) => store.commit('reports/removeCheckbox', item);

const buttons = shallowRef([
	// TODO remove hardcoded false checks when unban will be implemented
	{
		variant: 'outlined',
		show: () => !resolved.value,
		disabled: () => loading.value,
		round: true,
		clickFn: () => onAcceptClick(),
		icon: CheckIcon,
	},
	{
		variant: 'outlined',
		show: () => !resolved.value,
		disabled: () => loading.value,
		round: true,
		clickFn: () => onDeclineClick(),
		icon: CrossIcon,
	},
	{
		variant: 'outlined',
		show: () => resolved.value && false,
		disabled: () => loading.value,
		clickFn: () => onRevertClick(),
		size: 'flex',
		label: 'Revert',
	},
	{
		variant: 'outlined',
		show: () => !props.hideEditButton,
		disabled: () => false,
		round: true,
		clickFn: () => onEditClick(),
		icon: PencilIcon,
	},
	{
		variant: 'outlined',
		show: () => true,
		disabled: () => false,
		round: true,
		clickFn: () => onLinkClick(),
		icon: ShareIcon,
		class: 'share',
	},
]);

const width = computed(() => props.hideEditButton ? 136 : 184);
const type = computed(() => store.state.reports.type);

const props = defineProps({
	item: { type: Object, required: true },
	hideEditButton: { type: Boolean, required: false, default: false },
});

const resolved = computed(() => props.item.status !== 'pending');

const loading = ref(false);

const { title, message } = acceptConstants.single;

const onAcceptClick = () => {
	store.dispatch('modal/openModal', {
		content: shallowRef(AcceptModal),
		props: {
			ids: [props.item.id],
			title,
			message: message.replace('{type}', type.value),
			callback: () => removeCheckbox(props.item),
		},
	});
};

const onDeclineClick = async () => {
	await refLoadingWrapper(
		async () => {
			console.log(props.item);
			await store.dispatch('reports/declineReports', [props.item.id]);
			removeCheckbox(props.item);
		},
		loading,
	);
};

const onRevertClick = async () => {
	await refLoadingWrapper(
		async () => {
			await store.dispatch('reports/revertReports', [props.item.id]);
			removeCheckbox(props.item);
		},
		loading,
	);
};

const onEditClick = () => {};

const onLinkClick = () => {
	window.open(`${marketplaceUrl}/${marketplaceUrls[type.value](props.item)}`);
};
</script>

<style lang="scss" scoped>
.reports-action-buttons {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  button.share svg {
    margin-left: 2px;
  }
}
</style>
