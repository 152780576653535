<template>
  <div class="drop-state">
    <LiveCountdown
      v-if="isLive"
      :distance="distance"
      @on-end="onLiveEnd"
      @on-update="onLiveUpdate"
    />

    <UpcomingCountdown
      v-if="isUpcoming || isDraft"
      :start-date="startDate"
      :date-value="distance"
      :is-light="isLight"
      :small-font="smallFont"
      @on-end="onUpcomingEnd"
      @on-update="onUpcomingUpdate"
    />

    <div
      v-if="isEnded || isTrashed"
      class="ended-state"
      :class="{'small-font': smallFont}"
    >
      <Tag
        :is-rounded="false"
        color="white"
      >
        {{ isEnded ? 'Ended' : 'Trashed' }}
      </Tag>
      <h2 class="ended-state-date">
        {{ dateString }}
      </h2>
    </div>
  </div>
</template>

<script>
import Tag from '@/components/ui/Tag';

import { formatWithTimezone } from '@/utility';

import LiveCountdown from './LiveCountdown.vue';
import UpcomingCountdown from './UpcomingCountdown.vue';

export default {
	components: {
		Tag,
		LiveCountdown,
		UpcomingCountdown,
	},
	props: {
		status: {
			type: String,
			required: false,
		},
		startDate: {
			type: [Date, String, Number],
			required: true,
		},
		endDate: {
			type: [Date, String, Number],
			required: true,
		},
		secondsRemaining: Number,
		isLight: {
			type: Boolean,
			default: false,
			required: false,
		},
		smallFont: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	emits: ['onUpdate', 'onUpcomingEnd', 'onLiveEnd', 'onDistanceUpdate'],
	data () {
		return {
			distance: 0,
			currentStatus: this.status,
		};
	},
	computed: {
		isDraft () {
			return this.currentStatus === 'draft';
		},
		isUpcoming () {
			return this.currentStatus === 'upcoming';
		},
		isLive () {
			return this.currentStatus === 'live';
		},
		isEnded () {
			return this.currentStatus === 'ended';
		},
		isTrashed () {
			return this.currentStatus === 'trash';
		},
		dateString () {
			return formatWithTimezone(this.endDate, 'MMMM D, YYYY, ha');
		},
	},
	watch: {
		currentStatus (newValue, prevValue) {
			if (newValue !== prevValue) {
				this.$emit('onUpdate', newValue);
			}
		},
	},
	created () {
		this.distance = this.getDistance();
	},
	methods: {
		getDistance () {
			if (!!this.secondsRemaining && this.secondsRemaining > 0) {
				return this.secondsRemaining * 1000;
			}

			if (this.isUpcoming || this.isDraft) {
				return this.startDate;
			}

			if (this.isLive) {
				return this.endDate;
			}

			return 0;
		},
		onLiveEnd () {
			this.currentStatus = 'ended';

			this.$emit('onLiveEnd');
		},
		onUpcomingEnd () {
			this.currentStatus = 'live';

			this.distance = null;

			this.$emit('onUpcomingEnd');
		},
		onUpcomingUpdate (distance) {
			this.$emit('onDistanceUpdate', distance);
		},
		onLiveUpdate (distance) {
			this.$emit('onDistanceUpdate', distance);
		},
	},
};
</script>

<style scoped lang='scss'>
  .ended-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-date {
      font-size: 30px;
      line-height: 1em;
      text-align: center;
      font-weight: normal;
      margin-top: 20px;

      @include media-max-width($mediumScreen) {
        font-size: 20px;
      }
    }

    &.small-font {
      .ended-state-date {
        font-size: 16px;
      }
    }
  }
</style>
