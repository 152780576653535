<template>
  <div class="app-container">
    <Sidebar />
    <Main>
      <Header />
      <ContentSection><router-view /></ContentSection>
    </Main>
  </div>
</template>

<script>
import Main from '../components/Main.vue';
import Sidebar from './components/Sidebar.vue';
import Header from '../components/header/index.vue';
import ContentSection from '../components/ContentSection.vue';

export default {
	components: {
		Main,
		Header,
		Sidebar,
		ContentSection,
	},
};
</script>

<style scoped lang='scss'>
  .app-container {
    display: flex;
    min-height: 100vh;
  }
</style>
