<template>
  <component
    :is="priceTag"
    class="price-amount"
  >
    {{ priceString }}
  </component>
</template>

<script>
import { getCurrencyByType } from './helpers';

export default {
	props: {
		value: {
			type: String,
			required: true,
			default: '0',
		},
		currency: {
			type: String,
			required: false,
		},
		priceTag: {
			type: String,
			required: false,
			default: 'span',
		},
	},
	computed: {
		priceString () {
			const currency = getCurrencyByType(this.currency);

			return `${this.value} ${currency || ''}`;
		},
	},
};
</script>

<style scoped lang='scss'>
.price-amount {
  font-size: inherit;
  line-height: inherit;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  word-break: break-word;
}
</style>
