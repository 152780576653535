<template>
  <ModalBody class="announcement-modal-body">
    <div class="announcement-modal-body__container">
      <div class="announcement-modal-body__head">
        <div class="announcement-modal-body__head-title text-subhead-bold">
          {{ props.title }}
        </div>
        <div
          v-if="publishedAt"
          class="announcement-modal-body__head-distance text-footnote"
        >
          {{ formatDistance(new Date(props.publishedAt)) }}
        </div>
      </div>
      <img
        v-if="props.thumbnail && !props.caughtLoadingImg && !props.imageLoadingError"
        :src="props.thumbnail"
        alt="announcement-image"
        class="announcement-modal-body__image"
        @error="handleCatchLoadingImageError"
        @load="handleLoadingSuccessful"
      >

      <AnnouncementModalBodyParser :announcement-body="props.body" />
    </div>
    <AirdropPanel
      v-if="type === COLLECTION_ANNOUNCEMENT_TYPES.AIRDROP"
      :claim-url="claimUrl"
      :ends-at="endsAt"
      :starts-at="startsAt"
      :tweet-url="tweetUrl"
      class="announcement-modal-body__airdrop-panel"
      without-border
    />
  </ModalBody>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue';

import { ModalBody } from '@/components/common/modal/index';

import AirdropPanel from '@/components/pages/collection/announcements/components/airdrop-panel/AirdropPanel.vue';
import AnnouncementModalBodyParser from '@/components/common/modal/announcementDetailsModal/components/AnnouncementModalBodyParser.vue';

import { COLLECTION_ANNOUNCEMENT_TYPES } from '@/components/pages/collection/announcements/data';

import { formatDistance } from '@/utility/datetime.js';

const props = defineProps({
	body: String,
	caughtLoadingImg: Boolean,
	type: String,
	claimUrl: String,
	endsAt: String,
	imageLoadingError: Boolean,
	publishedAt: String,
	startsAt: String,
	thumbnail: String,
	title: String,
	tweetUrl: String,
});

const emit = defineEmits(['loading-image-error', 'loading-image-successful']);

const handleCatchLoadingImageError = (event) => {
	emit('loading-image-error', event);
};

const handleLoadingSuccessful = (event) => {
	emit('loading-image-successful', event);
};
</script>

<style lang="scss">
$size: 424px;

.announcement-modal-body {
  @include max-screen-sm() {
		padding: 16px;
	}

  &__container {
    @include flex(column, flex-start, flex-start);
    row-gap: 16px;

    @include max-screen-sm() {
      max-width: 100%;
    }
  }

  &__head {
    @include flex(column, flex-start, flex-start);
		row-gap: 6px;

    &-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 23px;
      word-break: break-word;
    }

    &-distance {
      font-size: 13px;
      line-height: 15px;
      font-weight: 500;
      color: #878787;
    }
  }

  &__image {
    max-width: 100%;
		max-height: $size;
		align-self: center;
		border: 1px solid var(--color-shade70);

		@include max-screen-sm() {
			max-width: 100%;
			max-height: 100%;
		}

    &-placeholder {
      @include flex(row, center, center);
      width: 100%;
      min-height: 100px;
      background-color: var(--color-shade60);
    }
  }

  &__airdrop-panel {
		@include flex(column, flex-start, flex-start);
		gap: 16px;
		border: none;
	}
}
</style>
