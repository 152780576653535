<template>
  <div
    class="list-header list-row"
    :style="grid"
  >
    <div
      v-for="(column, index) in columns"
      :key="index"
      class="list-header-cell list-cell"
    >
      {{ column?.name }}
    </div>
  </div>
  <div class="list-body">
    <draggable
      :list="list"
      :animation="150"
      handle=".custom-handle"
    >
      <template #item="{element}">
        <div
          class="list-body-row list-row"
          :style="grid"
        >
          <div
            v-for="(column, index) in columns"
            :key="index"
            class="list-body-cell list-cell"
          >
            <template v-if="column.key === 'handle'">
              <div class="custom-handle">
                <HandleIcon />
              </div>
              <div class="media-container">
                <Media
                  :src="element.mediaUrl"
                  autoplay
                />
              </div>
            </template>
            <template v-if="column.key === 'name'">
              {{ element?.name }}
            </template>
            <template v-if="column.key === 'amountForSale'">
              {{ element?.amountForSale }}
            </template>
            <template v-if="column.key === 'maxQuantity'">
              {{ element?.maxQuantity }}
            </template>
            <template v-if="column.key === 'price'">
              <Price
                :value="element?.price?.value"
                :style="{fontWeight: 'normal'}"
                :currency="element?.price?.currency"
              />
            </template>
            <template v-if="column.key === 'actions'">
              <div class="actions">
                <Button
                  variant="outlined"
                  @click="openEdit(element)"
                >
                  {{ disabled ? 'View' : 'Edit' }}
                </Button>
                <Button
                  variant="outlined"
                  :disabled="disabled"
                  color="red"
                  @click="openDeleteNftModal({item: element, state: formState})"
                >
                  Remove
                </Button>
              </div>
            </template>
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

import { HandleIcon } from '@/components/icons';
import Button from '@/components/ui/Button';
import Media from '@/components/common/Media';
import Price from '@/components/common/price';

import { generateGrid } from '@/utility';

import { openDeleteNftModal } from '@/pages/drops/details/modals/deletionModal/actions';
import { openCreateEditNftModal } from '@/pages/drops/details/modals/createEditNftModal/actions';

export default {
	components: {
		Media,
		Price,
		Button,
		draggable,
		HandleIcon,
	},
	inject: ['formState', 'mainState'],
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	emits: ['onChange'],
	data () {
		return {
			openDeleteNftModal,
			columns: [
				{ name: '', width: '0.5fr', key: 'handle' },
				{ name: 'Name', width: '1fr', key: 'name' },
				{ name: 'Amount for Sale', key: 'amountForSale' },
				{ name: 'Max Issue', key: 'maxQuantity' },
				{ name: 'Price', key: 'price' },
				{ name: '', key: 'actions' },
			],
		};
	},
	computed: {
		grid () {
			return generateGrid(this.columns);
		},
	},
	watch: {
		list: {
			handler (value) {
				this.$emit('onChange', value);
			},
			deep: true,
		},
	},
	methods: {
		openEdit (item) {
			openCreateEditNftModal({ details: item, state: this.formState, mainState: this.mainState });
		},
	},
};
</script>

<style scoped lang='scss'>
  .list-header {
    border: 1px solid rgba(0, 0, 0, .2);
    border-left: none;
    border-right: none;
    font-weight: 500;
  }

  .list-cell {
    display: flex;
    align-items: center;
    word-break: break-word;
  }

  .list-row {
    padding: 20px 0;
  }

  .list-body-row {
    background-color: $white;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
  }

  .media-container {
    width: 40px;
    height: 40px;

    :deep(img, video) {
      object-fit: contain;
    }
  }

  .actions {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 10px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .custom-handle {
    display: inline-flex;
    cursor: grab;
    padding: 20px;
  }
</style>
