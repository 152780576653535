export default {
	getNFTs: (state, payload) => {
		state.nfts = payload.nfts;
		state.count = payload.totalCount;
	},
	startLoading (state, { reset = true }) {
		if (reset) {
			state.nfts = [];
		}
		state.loading = true;
	},
	finishLoading (state) {
		state.loading = false;
	},
	toggleBan: (state, id) => {
		const forToggle = state.nfts.find(nft => nft.id === id);
		forToggle.nftStatus = forToggle.nftStatus === 'banned' ? 'active' : 'banned';
	},
	setShowLength (state, payload) {
		state.showLength = payload;
	},
	setSearch (state, payload) {
		state.search = payload;
	},
	setOffset (state, payload) {
		state.offset = payload;
	},
};
