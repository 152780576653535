<template>
  <div
    class="text-overflow"
    :class="{'text-overflow--clamp': isLineClamp}"
    :style="cssVars"
  >
    <div
      v-if="content && isHtml"
      class="text-overflow_html"
      v-html="content"
    />
    <slot v-else />
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
	isLineClamp: {
		type: Boolean,
		default: false,
	},
	linesQty: {
		type: Number,
		default: 2,
	},
	content: {
		type: String,
	},
	isHtml: {
		type: Boolean,
		default: false,
	},
});

const cssVars = computed(() => props.isLineClamp && {
	'--lines-qty': props.linesQty,
});
</script>

<style lang="scss">
    .text-overflow {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &--clamp {
          display: -webkit-box;
          -webkit-line-clamp: var(--lines-qty);
          -webkit-box-orient: vertical;
          white-space: initial;
        }

        &_html {
          * {
            display: inline;
          }

          img {
            display: none;
          }
        }
    }
</style>
