/**
 *
 * @param {Record<any, any>} object
 */
export const isObjectEmpty = (object) => {
	let isEmpty = true;

	Object.keys(object).forEach(() => (isEmpty = false));

	return isEmpty;
};
