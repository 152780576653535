import { usersService } from '@/services';

export default {
	async getUsers ({ state, commit }, { reset, limit, offset, search }) {
		if (limit) commit('setShowLength', parseInt(limit, 10));
		if (offset) commit('setOffset', parseInt(offset, 10));
		if (search) commit('setSearch', search);

		commit('startLoading', { reset });

		const params = {
			limit: state.showLength,
			offset: state.offset,
			userStatus: state.status,
			sort: state.sort,
		};
		if (state.search.length) params.search = state.search;
		try {
			const response = await usersService.getList(params);
			commit('getUsers', { users: response.users, totalCount: response.totalCount });
		} finally {
			commit('finishLoading');
		}
	},
	async toggleBan ({ commit }, { id, isBanned }) {
		if (isBanned) {
			await usersService.unbanUser(id);
			commit('toggleBan', { id, action: 'unban' });
		} else {
			await usersService.banUser(id);
			commit('toggleBan', { id, action: 'ban' });
		}
	},
	async changeShowLength ({ dispatch, commit }, length) {
		commit('setShowLength', length);
		await dispatch('getUsers', { reset: true });
	},
	async changeSearch ({ dispatch, commit }, search) {
		commit('setSearch', search);
		await dispatch('getUsers', { reset: true });
	},
	async changeOffset ({ dispatch, commit }, offset) {
		commit('setOffset', offset);
		await dispatch('getUsers', { reset: true });
	},
	async changeSort ({ dispatch, commit }, sort) {
		commit('setSort', sort);
		await dispatch('getUsers', { reset: true });
	},
	resetPage ({ commit }) {
		commit('setSearch', '');
		commit('getUsers', { users: [], totalCount: 0 });
	},
};
