<template>
  <TextField
    :id="id"
    type="number"
    v-bind="$attrs"
    class="number-field"
    :disabled="disabled"
    :is-rounded="isRounded"
    :error-message="errorMessage"
    :is-error-message-visible="isErrorMessageVisible"
    @keypress="onKeyPress"
  />
</template>

<script>
import TextField from './TextField.vue';

export default {
	components: {
		TextField,
	},
	props: {
		id: {
			type: String,
			required: false,
		},
		errorMessage: {
			type: String,
			required: false,
		},
		isErrorMessageVisible: {
			type: Boolean,
			required: false,
			default: true,
		},
		isRounded: {
			type: Boolean,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
		isInteger: {
			type: Boolean,
			required: false,
		},
	},
	methods: {
		onKeyPress (e) {
			const invalidCharacters = ['-', 'e', '+', 'E', ','];

			if (this.isInteger) {
				invalidCharacters.push(...['.']);
			}

			if (invalidCharacters.includes(e.key)) {
				e.preventDefault();
			}
		},
	},
};
</script>

<style scoped lang='scss'>
  .number-field {
    :deep(input) {
      &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      -moz-appearance: textfield;
    }
  }
</style>
