import { tokensService } from '@/services';

export default {
	async getTokens ({ state, commit }, { reset, limit, offset, search }) {
		if (limit) commit('setShowLength', parseInt(limit, 10));
		if (offset) commit('setOffset', parseInt(offset, 10));
		if (search) commit('setSearch', search);

		commit('startLoading', { reset });

		const params = {
			limit: state.showLength,
			offset: state.offset,
			userStatus: state.status,
		};
		if (state.search.length) params.search = state.search;
		try {
			const response = await tokensService.getList(params);
			commit('getTokens', { tokens: response.tokens, totalCount: response.totalCount });
		} finally {
			commit('finishLoading');
		}
	},
	async changeShowLength ({ dispatch, commit }, length) {
		commit('setShowLength', length);
		await dispatch('getTokens', { reset: true });
	},
	async changeSearch ({ dispatch, commit }, search) {
		commit('setSearch', search);
		await dispatch('getTokens', { reset: true });
	},
	async changeOffset ({ dispatch, commit }, offset) {
		commit('setOffset', offset);
		await dispatch('getTokens', { reset: true });
	},
	async deleteToken ({ commit, dispatch }, { address }) {
		await tokensService.deleteToken(address);
		await dispatch('getTokens', { reset: true });
		commit('deleteToken', address);
	},
	async addToken ({ dispatch }, address) {
		await tokensService.addToken(address);
		await dispatch('getTokens', { reset: true });
	},
};
