<template>
  <router-link :to="routes.main">
    <LogoIcon class="logo" />
  </router-link>
</template>

<script>
import routes from '@/router/routes';

import { LogoIcon } from '@/components/icons';

export default {
	components: { LogoIcon },
	data () {
		return {
			routes,
		};
	},
};
</script>

<style scoped lang='scss'>
  .logo {
    max-width: 190px;
  }
</style>
