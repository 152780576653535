<template>
  <div>
    <slot
      :emptyState="isEmptyState"
      name="filters"
    />

    <slot
      v-if="isEmptyState"
      name="empty-state"
    >
      <EmptyStateList :placeholder-text="`Enter ${ MIN_LIMIT_CHARACTERS_FOR_SEARCH } characters to search`" />
    </slot>

    <slot
      v-else
      :emptyState="isEmptyState"
    />
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';

import EmptyStateList from '@/components/ui/EmptyStateList';

import { MIN_LIMIT_CHARACTERS_FOR_SEARCH } from '@/constants/filters';

const props = defineProps({
	value: {
		type: String,
		default: '',
	},
	count: {
		type: Number,
		default: 0,
	},
});

const isEmptyState = computed(() => props?.value?.trim().length < MIN_LIMIT_CHARACTERS_FOR_SEARCH);
</script>
