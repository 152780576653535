<template>
  <ModalContentContainer class="content">
    <ModalHeader class="content__header">
      <ModalTitle
        class="header__title"
        value="Add a contract address"
      />
    </ModalHeader>
    <ModalBody class="content__body">
      <FormTextField
        placeholder="Enter a contract address"
        name="address"
      />
      <div
        v-if="unavailable"
        class="body__error"
      >
        <InfoIcon class="error__icon" />
        <p>{{ errorMessage }}</p>
      </div>
    </ModalBody>
    <ModalFooter class="content__footer">
      <Button
        class="footer__button"
        :disabled="isLoading"
        @click="onSubmit"
      >
        Add
      </Button>
    </ModalFooter>
  </ModalContentContainer>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useForm } from 'vee-validate';

import {
	ModalContentContainer,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalTitle,
} from '@/components/common/modal/index';
import Button from '@/components/ui/Button';
import { isCombinedCollection } from '@/utility';
import collectionsService from '@/services/collections.service';
import { object, ethereumAddress } from '@/components/common/form';
import FormTextField from '@/components/common/form/components/FormTextField.vue';
import { COLLECTION_STATUS, COLLECTION_ERRORS } from '@/pages/collections/constants';

const store = useStore();

const unavailable = ref(false);
const errorMessage = ref('');
const isLoading = ref(false);

const { handleSubmit, setFieldError } = useForm({
	initialValues: { address: '' },
	validationSchema: object({
		address: ethereumAddress('Wrong format'),
	}),
});

const onSubmit = handleSubmit(async (values) => {
	try {
		if (store.state.collections.customCollectionMergeContractAddresses.find(item => item.contractAddress === values.address)) {
			setFieldError('address', COLLECTION_ERRORS.ALREADY_IN_LIST);
			return;
		}

		isLoading.value = true;
		const collectionInfo = (await collectionsService.getCollections({ search: values.address })).collections?.[0];

		if (!collectionInfo) {
			setFieldError('address', COLLECTION_ERRORS.NOT_FOUND);
			isLoading.value = false;
			return;
		}

		if (isCombinedCollection(collectionInfo.id)) {
			setFieldError('address', COLLECTION_ERRORS.INCLUDED_IN_CONTAINER);
			isLoading.value = false;
			return;
		}

		if (collectionInfo.collectionStatus === COLLECTION_STATUS.BANNED) {
			setFieldError('address', COLLECTION_ERRORS.IS_BANNED);
			isLoading.value = false;
			return;
		}

		await store.dispatch(
			'collections/addContainerContractAddressForMerge',
			{ ...collectionInfo },
		);
		await store.dispatch('modal/closeModal');
		isLoading.value = false;
	} catch (e) {
		throw new Error(e);
	}
});
</script>

<style lang="scss" scoped>
.content {
  &__footer {
    .footer__button {
      width: 100%;
    }
  }
}
</style>
