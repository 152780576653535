<template>
  <ModalContentContainer class="announcement-modal">
    <ModalHeader>
      <div class="header-content">
        <h3 class="text-subhead">
          {{ modalProps?.title }}
        </h3>
      </div>
    </ModalHeader>
    <ModalBody class="body-container">
      <p>{{ modalProps?.body }}</p>
    </ModalBody>
    <ModalFooter>
      <div class="actions">
        <Button
          upper-case
          :disabled="submitLoading || isEmpty"
          @click="handleSubmit"
        >
          Remove
        </Button>
      </div>
    </ModalFooter>
  </ModalContentContainer>
</template>

<script setup>
import { useAttrs } from 'vue';

import { ModalContentContainer, ModalHeader, ModalBody, ModalFooter } from '@/components/common/modal';

import Button from '@/components/ui/Button';

const attrs = useAttrs();
const modalProps = attrs?.modalProps;

const handleSubmit = () => {
	modalProps?.onSubmit();
};

</script>

<style lang="scss">
.announcement-modal {
    max-width: 456px;
}

.header-content {
    width: 100%;
    text-align: center;
}

.header-content__close {
    position: absolute;
    right: 16px;
    border-radius: 50% !important;
}

.actions {
    @include flex();
}

.body-container {
    text-align: center;
}
</style>
