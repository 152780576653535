<template>
  <div class="configuration__panel">
    <FormCheckbox
      v-for="option in config"
      :key="option.name"
      :disabled="disabled"
      :name="option.name"
      hide-unchecked-icon
      rectangle-form
      size="24px"
      value
    >
      {{ option.label }}
    </FormCheckbox>
  </div>
</template>

<script>
import FormCheckbox from '@/components/common/form/components/FormCheckbox.vue';

export default {
	name: 'ConfigurationList',
	components: {
		FormCheckbox,
	},
	props: {
		config: { type: Array, required: false, default: () => [] },
		disabled: { type: Boolean, required: false, default: false },
	},
};
</script>

<style lang="scss" scoped>
.configuration {
	&__panel {
    display: flex;
    flex-direction: column;
		gap: 16px;

	}
}
</style>
