import { markRaw } from 'vue';
import TextLink from '@/components/ui/TextLink.vue';
import TextOverflow from '@/components/ui/TextOverflow.vue';
import AnnouncementBodyFormatter from '@/components/pages/announcements_and_airdrops/announcement-body-formatter/AnnouncementBodyFormatter.vue';

export function tableTextLink (src, text, moreProps) {
	return {
		component: markRaw(TextLink),
		props: { src, content: text, color: 'violet', settingsPosition: { name: 'relative', index: 1 }, isOverflow: true, ...moreProps },
	};
}

export function tableTextOverflow (props) {
	return {
		props,
		component: markRaw(TextOverflow),
	};
}

export function tableAnnouncementBodyFormatter (props) {
	return {
		props,
		component: markRaw(AnnouncementBodyFormatter),
	};
}
