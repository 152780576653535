<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 0H14C15.103 0 16 0.897 16 2V14C16 15.103 15.103 16 14 16H2C0.897 16 0 15.103 0 14V2C0 0.897 0.897 0 2 0ZM2 2V14H14.002L14 2H2ZM5 9L6 10L9 6L13 12H3L5 9Z"
      fill="currentColor"
    />
  </svg>
</template>
