<template>
  <div class="modal-content">
    <slot />
  </div>
</template>

<style scoped lang='scss'>
  .modal-content {
    background-color: $white;
    border-radius: 24px;
  }
</style>
