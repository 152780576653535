<template>
  <div class="body">
    <slot />
  </div>
</template>

<style scoped lang='scss'>
  .body {
    height: 100%;
    padding: 20px;
    flex: 1;
    overflow-y: auto;
  }
</style>
