<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M15.5 14.5L19.6 18.6C19.9 18.9 19.9 19.4 19.6 19.8 19.5 19.9 19.2 20 19 20 18.8 20 18.6 19.9 18.4 19.8L14.3 15.7C12.8 16.9 10.9 17.6 8.8 17.6 4 17.6 0 13.7 0 8.8 0 4 4 0 8.8 0 13.7 0 17.6 4 17.6 8.8 17.6 11 16.8 13 15.5 14.5ZM1.7 8.8C1.7 12.7 4.9 15.9 8.8 15.9 12.7 15.9 15.9 12.7 15.9 8.8 15.9 4.9 12.7 1.7 8.8 1.7 4.9 1.7 1.7 4.9 1.7 8.8Z"
      fill="currentColor"
    />
  </svg>
</template>
