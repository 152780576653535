<template>
  <div class="users-block">
    <div class="users-block__filters">
      <div class="users-block__search">
        <SearchField
          :model-value="search"
          placeholder="Search users"
          to-query-param
          @enter:model-value="changeSearchString"
        />
      </div>

      <LabeledContainer
        class="users-block__filter"
        label="Show"
        for-id="show"
      >
        <Select
          :options="showOptions"
          :model-value="getShowOptionByValue(showLength)"
          name="show"
          :is-disabled-dropdown="isEmptyState"
          @update:model-value="changeLength"
        >
          <template #icon>
            <ChevronDownIcon />
          </template>
        </Select>
      </LabeledContainer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { Select } from '@/components/ui/select';
import { ChevronDownIcon } from '@/components/icons';
import { SearchField } from '@/components/ui/text-fields';
import LabeledContainer from '@/components/ui/LabeledContainer';

import { showOptions, MIN_LIMIT_CHARACTERS_FOR_SEARCH } from '@/constants/filters';

export default {
	components: {
		LabeledContainer,
		ChevronDownIcon,
		SearchField,
		Select,
	},
	props: {
		isEmptyState: {
			type: Boolean,
			default: false,
		},
	},
	data () {
		return {
			showOptions,
			statusOptions: [
				{ label: 'Active', value: 'active' },
				{ label: 'Banned', value: 'banned' },
				{ label: '-', value: '' },
			],
			defaultStatusOption: { label: '-', value: '' },
			roleOptions: [
				{ label: 'All', value: '' },
				{ label: 'User', value: 'user' },
				{ label: 'Editor', value: 'editor' },
				{ label: 'Admin', value: 'admin' },
			],
			sortOptions: [
				{ label: 'Most Recent', value: 'most_recent' },
				{ label: 'Most Relevant', value: 'most_relevant' },
			],
		};
	},
	computed: {
		...mapState({
			showLength: state => state.users.showLength,
			search: state => state.users.search,
			sort: state => state.users.sort,
		}),
		getShowOptionByValue () {
			return value => this.showOptions.find(opt => opt.value === value);
		},
	},
	methods: {
		...mapActions('users', ['changeShowLength', 'changeSearch', 'changeSort', 'resetPage']),
		changeLength (option) {
			this.changeShowLength(option.value);
		},
		changeSearchString (search) {
			if (search?.trim().length >= MIN_LIMIT_CHARACTERS_FOR_SEARCH) {
				this.changeSearch(search);
			} else {
				this.resetPage();
			}
		},
	},
};
</script>

<style
  scoped
  lang="scss"
>
.users-block {
  display: flex;
  justify-content: space-between;

  &__filters {
    display: flex;
    margin-bottom: 24px;
  }

  &__sort {
    display: flex;
    align-items: center;
    width: 160px;

    &_list:deep(.dropdown__list) {
      width: 160px;
    }
  }

  &__filter {
    display: flex;
    align-items: center;
    width: 115px;
    margin-right: 20px;

    &_status:deep(.dropdown__list) {
      width: 115px;
    }

    &:deep(.label) {
      margin: 0;
      padding-right: 4px;
    }
  }

  &__action {
    width: 135px;
  }

  &__search {
    width: 415px;
    margin-right: 20px;
  }
}
</style>
