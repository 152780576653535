import { collectionsService } from '@/services';

function sliceIntoChunks (pool, chunkSize) {
	const chunks = [];

	for (let i = 0; i < pool.length; i += chunkSize) {
		const chunk = pool.slice(i, i + chunkSize);
		chunks.push(chunk);
	}

	return chunks;
}

/**
 * @param {string[]} ids
 * @param {boolean} isFromDetailPage
 * @returns {Promise<Object<string, object>>[]}
 */
export function getNftsContext (ids, isFromDetailPage) {
	return sliceIntoChunks(ids, 30).map(chunk => collectionsService.getContext({
		ids: chunk,
		// @TODO: This flag's name is likely to change on backend
		isFromDetailPage,
	}));
}

/**
 * @param {string[]} ids
 * @returns {(function(): Promise<{context: Object<string, object>, ids: string[]}>)[]}
 */
export function getCollectionsContext (ids) {
	return sliceIntoChunks(ids, 30).map(chunk => async () => ({
		ids: chunk,
		context: await collectionsService.getContext({ ids: chunk }),
	}));
}

/**
 * @param {string[]} ids
 * @returns {(function(): Promise<{context: Object<string, object>, ids: string[]}>)[]}
 */
export function getUsersContext (ids) {
	return sliceIntoChunks(ids, 30).map(chunk => async () => ({
		ids: chunk,
		context: await collectionsService.getContext({ ids: chunk }),
	}));
}
