<template>
  <div
    :class="{
      'thumbnail': isThumbnail,
      'rounded-angle': roundedAngle,
      'with-content': hasContent
    }"
    class="avatar-wrapper"
  >
    <div
      v-if="!hideNoIcon || (hideNoIcon && src)"
      :style="{width: size, height: size, flex: `0 0 ${size}`}"
      class="avatar"
      :class="{ 'avatar_list-item': isListItem }"
    >
      <component
        :is="redirectLink ? 'router-link' : 'span'"
        v-if="src && srcIsValid && !isVideo"
        :to="redirectLink"
      >
        <PictureLoader
          :picture-base-url="src"
          :is-picture-retina3x="isRetina3x"
          picture-alt-text="avatar-image"
        />
      </component>
      <video
        v-if="src && srcIsValid && isVideo"
        :height="size.split('px')[0]"
        :src="src"
        :autoplay="autoplay"
        :width="size.split('px')[0]"
        loop
        muted
        @error="handleError"
      />
      <div
        v-if="!src"
        class="avatar-placeholder"
      >
        <slot
          v-if="!hidePlaceholderIcon"
          name="placeholderIcon"
        >
          <UserIcon :size="placeholderIconSize || `${sizeNumber / 4 - 10}px`" />
        </slot>
      </div>
    </div>
    <div
      v-if="hasContent"
      class="content"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { UserIcon } from '@/components/icons';
import PictureLoader from '@/components/common/media/PictureLoader.vue';

export default {
	components: { PictureLoader, UserIcon },
	props: {
		src: {
			type: [String, null],
			required: true,
		},
		size: {
			type: String,
			required: false,
			default: '40px',
		},
		autoplay: {
			type: Boolean,
			default: true,
		},
		isRetina3x: {
			type: Boolean,
			default: false,
		},
		isThumbnail: Boolean,
		isListItem: {
			type: Boolean,
			default: false,
		},
		roundedAngle: Boolean,
		urlToRedirect: String,
		placeholderIconSize: String,
		hidePlaceholderIcon: Boolean,
		hideNoIcon: {
			type: Boolean,
			default: false,
		},
	},
	data () {
		return {
			srcIsValid: true,
		};
	},
	computed: {
		hasContent () {
			return !!this.$slots.default;
		},
		sizeNumber () {
			return this.size?.replaceAll?.(/\D/g, '');
		},
		type () {
			const srcSplit = this.src.split('.');
			return srcSplit[srcSplit.length - 1];
		},
		isVideo () {
			return ['mp4', 'avi', 'webm'].includes(this.type);
		},
		redirectLink () {
			return this.urlToRedirect ?? '';
		},
	},
	methods: {
		handleError () {
			this.srcIsValid = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.avatar-wrapper {
  display: flex;
  align-items: center;

  .avatar {
    position: relative;
    border-radius: 50%;
    overflow: hidden;

    &_list-item {
      border: 1px solid var(--color-shade60);

      & > * {
        @include flex(initial);
        flex: 0 0 64px;
        height: inherit;
        width: inherit;

        & > :deep(*) {
          width: inherit;
          height: inherit;
        }
      }
    }

    picture {
      &:deep(img) {
        display: block;
        object-fit: cover;
        border-radius: inherit;
        width: 100%;
        height: 100%;
      }
    }
  }

  .avatar-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-shade60);
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  &.thumbnail {
    .avatar {
      border: 1px solid var(--color-shade70);
      border-radius: 2px;
    }
  }

  &.rounded-angle {
    .avatar {
      border: 1px solid var(--color-shade70);
      border-radius: 8px;
    }
  }

  &.with-content {
    gap: 8px;
  }

  .content {
    word-break: break-word;
    max-width: 100%;
  }
}
</style>
