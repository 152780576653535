<template>
  <div class="bar-wrapper">
    <div
      class="bar-inner"
      :style="{width: `${progress}%`}"
    />
  </div>
</template>

<script>
export default {
	props: {
		current: {
			type: [Number, String],
			required: true,
			default: 0,
		},
		total: {
			type: [Number, String],
			required: true,
			default: 0,
		},
	},
	computed: {
		progress () {
			const totalValue = typeof this.total === 'string' ? parseFloat(this.total) : this.total;
			const currentValue = typeof this.current === 'string' ? parseFloat(this.current) : this.current;

			return Math.round((currentValue / totalValue) * 100);
		},
	},
};
</script>

<style scoped lang='scss'>
  .bar-wrapper {
    display: flex;
    width: 100%;
    height: 20px;
    border-radius: 10px;
    align-items: center;
    overflow: hidden;
    background-color: rgba(0, 0, 0, .2);

    .bar-inner {
      height: inherit;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      background-color: $violet;
    }
  }
</style>
