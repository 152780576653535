<template>
  <div
    class="titled-text"
    :class="{'reversed': reverse}"
  >
    <div
      v-if="title"
      class="title"
    >
      {{ title }}
    </div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: false,
		},
		reverse: {
			type: Boolean,
		},
	},
};
</script>

<style scoped lang='scss'>
  .titled-text {
    display: flex;
    flex-direction: column;

    &.reversed {
      .title {
        opacity: 1;
        font-weight: 600;
      }

      .content {
        font-weight: normal;
        opacity: .5;
      }
    }
  }

  .title {
    opacity: .5;
  }

  .content {
    font-weight: 600;
  }
</style>
