<template>
  <div class="access-list-wrapper">
    <LabeledContainer
      :label="textByType?.label || ''"
      for-id="access-list"
    >
      <FormLinedTextarea
        id="access-list"
        name="accessList"
        :disabled="disabled"
        :placeholder="textByType?.placeholder"
      />
      <div class="bottom-row">
        <HelperText>One per line</HelperText>
      </div>
    </LabeledContainer>
    <ErrorList />
    <div
      v-if="type === 'whitelist' && isIMX"
      class="two columns-row access-list-start-end"
    >
      <LabeledContainer
        label="Whitelist Start Time"
        for-id="whitelist-start-time"
      >
        <FormDatetime
          id="whitelist-start-time"
          name="accessStartsAt"
          :disabled="notEditable"
          format="MM-dd-yyyy hh:mm a"
          placeholder="MM-DD-YYYY HH:mm a"
          :clearable="false"
        />
      </LabeledContainer>
      <LabeledContainer
        label="Whitelist End Time"
        for-id="whitelist-end-time"
      >
        <FormDatetime
          id="whitelist-end-time"
          name="accessEndsAt"
          :disabled="notEditable"
          format="MM-dd-yyyy hh:mm a"
          placeholder="MM-DD-YYYY HH:mm a"
          :clearable="false"
        />
      </LabeledContainer>
    </div>
  </div>
</template>

<script>
import HelperText from '@/components/ui/HelperText';
import LabeledContainer from '@/components/ui/LabeledContainer';
import FormDatetime from '@/components/common/form/components/FormDatetime.vue';
import FormLinedTextarea from '@/components/common/form/components/FormLinedTextarea.vue';

import ErrorList from './ErrorList.vue';
import { providerTypes } from '../../../helpers';

export default {
	components: {
		ErrorList,
		HelperText,
		LabeledContainer,
		FormLinedTextarea,
		FormDatetime,
	},

	inject: ['mainState'],
	props: {
		type: [String],
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		isIMX () {
			return this.mainState.provider.value === providerTypes.IMX;
		},

		textByType () {
			switch (this.type) {
				case 'whitelist':
					return {
						label: 'Whitelisted Addresses',
						placeholder: 'Enter an address',
					};
				case 'nft':
					return {
						label: 'NFT Access Addresses',
						placeholder: 'Enter a contract address',
					};
				default:
					return null;
			}
		},
		notEditable () {
			return this.mainState?.notEditable?.value;
		},
	},
};
</script>

<style scoped lang='scss'>
  .state-trigger {
    color: $grey;
    cursor: pointer;
    justify-content: flex-end;
    display: flex;
    font-weight: normal;
  }

  .bottom-row {
    margin-top: 5px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }

  .columns-row {
    width: 100%;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;

    &.two {
      grid-template-columns: repeat(2, 1fr);
    }

    &.three {
      grid-template-columns: repeat(3, 1fr);
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .access-list-start-end {
    margin-top: 20px;
  }
</style>
