<template>
  <div
    v-if="total"
    class="pagination"
  >
    <Button
      class="pagination__button"
      :class="{'disabled': isLess}"
      :disabled="isLess"
      :is-secondary="true"
      @click="decrease"
    >
      <ChevronLeftIcon size="24px" />
      <span class="text-body-16">{{ prevLabel }}</span>
    </Button>

    <Button
      v-if="!isMore"
      class="pagination__button"
      :class="{'disabled': isMore}"
      :is-secondary="true"
      @click="increase"
    >
      <span class="text-body-16">{{ nextLabel }}</span>
      <ChevronRightIcon size="24px" />
    </Button>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, defineEmits, inject } from 'vue';

import { ChevronLeftIcon, ChevronRightIcon } from '@/components/icons';

const Button = defineAsyncComponent(() => import('@/components/ui/Button.vue'));

const emit = defineEmits(['offset:change']);

const limit = inject('limit');
const offset = inject('offset');
const total = inject('total');

const isActivePage = computed(() => Math.floor(offset.value / limit.value) + 1);

const isMore = computed(() => (offset.value + limit.value) >= total.value);
const isLess = computed(() => offset.value <= 0);

const prevLabel = computed(() => isActivePage.value === 1
	? `${offset.value + 1}–${offset.value + limit.value}`
	: `${limit.value * (isActivePage.value - 2) + 1}–${limit.value * (isActivePage.value - 1)}`);
const nextLabel = computed(() => `${limit.value * isActivePage.value + 1}–${limit.value * isActivePage.value + limit.value}`);

const increase = () => {
	emit('offset:change', offset.value + limit.value);
};

const decrease = () => {
	let value = offset.value - limit.value;
	if (value < 0) {
		value = 0;
	}
	emit('offset:change', value);
};
</script>

<style
  scoped
  lang="scss"
>
.pagination {
  display: flex;

  & > :first-child {
    margin-right: 24px;
  }

  &__button {
    color: var(--color-accent2);

    & > :first-child {
      margin-right: 8px;
    }

    &.disabled {
      color: var(--color-shade40);
    }
  }

  &__counter {
    &-active {
      color: var(--color-base1);
      background-color: var(--color-shade60);
    }
  }
}
</style>
