<template>
  <BodyRow
    :class="{'secondary-color': withDifferentRow && (source % 2 === 0), 'hover-effect': rowHover}"
    :clickable="!!rowClick"
    :hoverable="rowHover"
    :row-url="rowUrl"
    :style="gridStyles"
    class="table-row"
    @click="rowClick?.(source, $event)"
    @mousedown.middle="rowClick?.(source, $event, { isMiddleClick: true })"
  >
    <BodyCell
      v-for="(column, columnKey) in columns"
      :key="`${column.id ? column.id : columnKey}-${dataKey}`"
      :cell-padding="cellPadding"
      :is-hide="column?.isHide"
      :justify-content="column?.justifyContent"
    >
      <template v-if="withBulkActions && columnKey === 0">
        <BaseCheckbox
          :checked="selectedCheckbox(source)"
          :value="selectedCheckbox(source)"
          class="checkbox"
          hide-unchecked-icon
          name="table_body_checkbox"
          rectangle-form
          size="24px"
          @click.prevent.stop="$emit('checkboxClick', source)"
        />
      </template>
      <template v-if="column?.value && isColumnFunction(column?.value)">
        <component
          :is="column.value(source).component"
          v-if="hasComponent(column.value(source))"
          :column-key="columnKey"
          :row-key="dataKey"
          v-bind="column.value(source)?.props"
        />
        <template v-else>
          {{ column.value(source) }}
        </template>
      </template>
    </BodyCell>
  </BodyRow>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import BodyCell from '@/components/ui/table/components/body/BodyCell.vue';
import BodyRow from '@/components/ui/table/components/body/BodyRow.vue';

const props = defineProps({
	columns: {
		type: Array,
	},
	source: {
		type: Object,
	},
	selectedCheckboxes: {
		type: Set,
	},
	gridStyles: {
		type: Object,
	},
	loading: Boolean,
	withBulkActions: Boolean,
	rowHover: Boolean,
	rowClick: { type: Function, default: undefined },
	withDifferentRow: { type: Boolean, default: false },
	cellPadding: String,
	rowAnimationName: String,
	dataKey: Number,
	rowUrl: { type: String, default: null },
});

defineEmits(['checkboxClick']);

function selectedCheckbox (dataItem) {
	return props.selectedCheckboxes.has(dataItem?.id);
}

function isColumnFunction (value) {
	return value && typeof value === 'function';
}

function hasComponent (value) {
	return !!value?.component;
}

</script>

<style lang="scss">
.table-row:not:first-child {
	min-height: 81px;
}

.hover-effect {
  &:hover {
    cursor: pointer;
  }
}
</style>
