export default {
	openModal ({ commit }, payload) {
		commit('openModal', payload);
	},
	closeModal ({ commit }) {
		commit('closeModal');
	},
	closeAllModals ({ commit }) {
		commit('closeAllModals');
	},
};
