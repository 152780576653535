<template>
  <div class="nft-actions-block">
    <div class="nft-actions-block__search">
      <SearchField
        :model-value="search"
        placeholder="Search NFT"
        to-query-param
        @enter:model-value="changeSearchString"
      />
    </div>
    <div class="nft-actions-block__filters">
      <LabeledContainer
        class="nft-actions-block__filter"
        label="Show"
        for-id="show"
      >
        <Select
          :options="showOptions"
          :model-value="getShowOptionByValue(showLength)"
          :is-disabled-dropdown="isEmptyState"
          name="show"
          @update:model-value="changeLength"
        >
          <template #icon>
            <ChevronDownIcon />
          </template>
        </Select>
      </LabeledContainer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { Select } from '@/components/ui/select';
import { ChevronDownIcon } from '@/components/icons';
import { SearchField } from '@/components/ui/text-fields';
import LabeledContainer from '@/components/ui/LabeledContainer';

import { showOptions, MIN_LIMIT_CHARACTERS_FOR_SEARCH } from '@/constants/filters';

export default {
	components: {
		LabeledContainer,
		ChevronDownIcon,
		SearchField,
		Select,
	},
	props: {
		isEmptyState: {
			type: Boolean,
			default: false,
		},
	},
	data () {
		return {
			showOptions,
		};
	},
	computed: {
		...mapState({
			showLength: state => state.nfts.showLength,
			search: state => state.nfts.search,
		}),
		getShowOptionByValue () {
			return value => this.showOptions.find(opt => opt.value === value);
		},
	},
	methods: {
		...mapActions('nfts', ['changeShowLength', 'changeSearch', 'resetPage']),
		changeLength (option) {
			this.changeShowLength(option.value);
		},
		changeSearchString (search) {
			if (search?.trim().length >= MIN_LIMIT_CHARACTERS_FOR_SEARCH) {
				this.changeSearch(search);
			} else {
				this.resetPage();
			}
		},
	},
};
</script>

<style
  scoped
  lang="scss"
>
.nft-actions-block {
  display: flex;
  justify-content: flex-start;

  &__filters {
    display: flex;
    margin-bottom: 24px;
  }

  &__filter {
    display: flex;
    align-items: center;
    width: 115px;
    margin-right: 20px;

    &_status:deep(.dropdown__list) {
      width: 115px;
    }

    &:deep(.label) {
      margin: 0;
      padding-right: 4px;
    }
  }

  &__action {
    width: 135px;
  }

  &__search {
    width: 415px;
    margin-right: 20px;
  }
}
</style>
