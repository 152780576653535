<template>
  <Tabs
    v-show="!hide"
    class="tabs-wrapper"
    :model-value="activeTab"
    :is-sticky="isSticky"
    :sticky-offset="stickyOffset"
    :is-centered="isCentered"
    @update:model-value="changeActiveTab"
  >
    <Tab
      v-for="(item, index) in items"
      :key="index"
      :color="item?.color"
      :description="item?.description"
      :rounded="rounded"
      :title="item?.label"
      :value="item?.value"
    />
  </Tabs>
  <div
    v-show="!hide"
    :class="{ 'no-padding': noPadding }"
    class="tabs-content"
  >
    <slot :activeTab="activeTab" />
  </div>
</template>

<script>
import { inject } from 'vue';
import { getHistoryParams, serializeHistoryParams } from '@/utility/history';
import Tab from './Tab.vue';
import Tabs from './Tabs.vue';

const emitEvents = {
	CHANGE_ACTIVE_TAB: 'change-active-tab',
};

export default {
	components: { Tabs, Tab },
	provide () {
		return {
			activeTab: this.activeTab,
		};
	},
	props: {
		items: {
			type: Array,
			required: true,
			default: () => [],
		},
		isSticky: {
			type: Boolean,
			required: false,
			default: true,
		},
		stickyOffset: {
			type: [String, Number],
			required: false,
		},
		isCentered: {
			type: Boolean,
			default: true,
		},
		isOnlyConnectWallet: {
			type: Boolean,
			default: false,
		},
		noPadding: { type: Boolean, required: false, default: false },
		withProfile: Boolean,
		rounded: {
			type: Boolean,
			default: true,
		},
		isBlockRedirect: {
			type: Boolean,
			default: false,
		},
		hide: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	emits: [emitEvents.CHANGE_ACTIVE_TAB],
	setup () {
		const navigationMode = inject('navigationMode', 'router');
		const authProvider = inject('authProviderState');

		return {
			navigationMode,
			authProvider,
		};
	},
	data () {
		const current = this.items.find(item => item?.value === this.$route.query?.tab);

		return {
			activeTab: current?.value,
		};
	},
	watch: {
		$route () {
			this.setActiveTab();
		},
		items () {
			// Tabs were added programmatically, so you need to track changes in the list of tabs
			// For example tabs Offers(Profile)
			this.setActiveTab();
		},
	},
	mounted () {
		this.setActiveTab();
	},
	methods: {
		async changeActiveTab (value) {
			if (value !== this.activeTab) {
				if (this.navigationMode === 'history') {
					const query = { ...getHistoryParams(), tab: value };
					const queryString = serializeHistoryParams(query);
					history.pushState(history.state, null, `${window.location.pathname}?${queryString}`);
					this.setActiveTab();
				} else {
					await this.$router.push({
						path: this.$route.path,
						query: { ...this.$route.query, tab: value },
					});
				}
				this.$emit(emitEvents.CHANGE_ACTIVE_TAB);
			}
		},
		setActiveTab () {
			const query = this.navigationMode === 'history' ? getHistoryParams() : this.$route.query || {};
			const foundItem = this.items.find(item => item?.value === query.tab);
			this.activeTab = foundItem?.value || (this.items[0]?.value || '');
		},
	},
};
</script>

<style lang="scss" scoped>
.tabs-wrapper {
  width: 100%;
}

.tabs-content {
  padding-top: 20px;

	&.no-padding {
		padding-top: initial;
	}
}
</style>
