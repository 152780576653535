import { reactive } from 'vue';

import commonService from './common.service';

async function sendFile (image, type) {
	if (!image) return null;

	let url = null;
	const progressData = reactive({ uploaded: 0, fileSize: 0 });

	const changeProgress = (val) => {
		progressData.uploaded = val;
	};

	const uploader = await commonService.largeFile.getChunks({
		uploadType: type,
		uploadSize: image.size,
		mimeType: image.type,
	});

	if (uploader) {
		const { uploadId, parts } = uploader;
		const uploadedPartsInfo = await commonService.largeFile.uploadParts(image, parts, parts[0].size, changeProgress);

		if (uploadedPartsInfo) {
			const linkForUploadedFile = await commonService.largeFile.completeMultiUpload(uploadId, uploadedPartsInfo);
			url = linkForUploadedFile.url;
		}
	}

	return url;
}

export const mediaHubService = {
	sendFile,
};
