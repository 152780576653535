<template>
  <div
    class="icon-text-wrapper"
    :class="{'with-content': hasContent, reversed}"
  >
    <div
      v-if="hasIcon"
      class="icon-text-icon-wrapper"
    >
      <slot />
    </div>
    <div
      v-if="hasContent"
      class="icon-text-content"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
	props: {
		reversed: Boolean,
	},

	computed: {
		hasIcon () {
			return !!this.$slots?.default;
		},
		hasContent () {
			return !!this.$slots?.content;
		},
	},
};
</script>

<style scoped lang='scss'>
  .icon-text-wrapper {
    display: flex;
    align-items: center;

    .icon-text-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }

    &.with-content {
      .icon-text-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }

      &.reversed {
        flex-direction: row-reverse;
        justify-content: flex-end;

        .icon-text-icon-wrapper {
          margin-right: 0;
          margin-left: 10px;
        }

        .icon-text-content {
          flex: unset;
        }
      }
    }
  }

  .icon-text-content {
    flex: 1;
    word-break: break-word;
  }
</style>
