import { formatToTimeZone } from 'date-fns-timezone';
import * as fns from 'date-fns';
import * as timeago from 'timeago.js';
import enUS from 'date-fns/locale/en-US';

const shortLocale = (number, index) => [
	['just now', 'right now'],
	['%s sago', 'in %ss'],
	['1min ago', 'in 1min'],
	['%smin ago', 'in %smin'],
	['1h ago', 'in 1h'],
	['%sh ago', 'in %sh'],
	['1d ago', 'in 1d'],
	['%sd ago', 'in %sd'],
	['1w ago', 'in 1w'],
	['%sw ago', 'in %sw'],
	['1mo ago', 'in 1mo'],
	['%smo ago', 'in %smo'],
	['1y ago', 'in 1y'],
	['%sy ago', 'in %sy'],
][index];

timeago.register('short-locale', shortLocale);

export const formatRelative = (date, short) => timeago.format(
	typeof date === 'string' ? new Date(date) : date,
	short ? 'short-locale' : undefined,
);

const WRONG_DATE_FORMAT = 'Wrong date format';

export const DEFAULT_DATE_FORMAT = 'MM-dd-yyyy hh:mm a';

const getDate = date => {
	if (date instanceof Date) {
		return date;
	}

	if (typeof date === 'string') {
		return new Date(date);
	}

	return null;
};

export const formatDate = (date, pattern) => {
	const dateValue = getDate(date);

	if (!dateValue) return WRONG_DATE_FORMAT;

	return fns.format(dateValue, pattern, { locale: enUS });
};

export const formatWithTimezone = (date, pattern) => {
	const dateValue = getDate(date);

	if (!dateValue) return WRONG_DATE_FORMAT;

	return formatToTimeZone(
		dateValue,
		`${pattern} z`,
		// eslint-disable-next-line new-cap
		{ timeZone: Intl.DateTimeFormat().resolvedOptions()?.timeZone },
	);
};

const distanceLocale = {
	lessThanXSeconds: '{{count}}s',
	xSeconds: '{{count}}s',
	halfAMinute: '30s',
	lessThanXMinutes: '{{count}}m',
	xMinutes: '{{count}}m',
	aboutXHours: '{{count}}h',
	xHours: '{{count}}h',
	xDays: '{{count}}d',
	aboutXWeeks: '{{count}}w',
	xWeeks: '{{count}}w',
	aboutXMonths: '{{count}}mon',
	xMonths: '{{count}}mon',
	aboutXYears: '{{count}}y',
	xYears: '{{count}}y',
	overXYears: '{{count}}y',
	almostXYears: '{{count}}y',
};

const formatDistanceLocale = (token, count, options) => {
	const modifiedOptions = options || {};

	const result = distanceLocale[token].replace('{{count}}', count);

	if (modifiedOptions.addSuffix) {
		if (modifiedOptions.comparison > 0) {
			return `in ${result}`;
		}

		return `${result} ago`;
	}

	return result;
};

export const formatDistance = (date, addSuffix = true) => fns.formatDistance(new Date(date), new Date(), {
	addSuffix,
	locale: {
		...enUS,
		formatDistance: formatDistanceLocale,
	},
});
