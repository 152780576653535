import { collectionsService, nftsService, usersService } from '@/services';
import { find } from 'lodash';

export default {
	async fetchBannedList ({ commit, state }, { reset, limit, offset, search }) {
		if (limit) commit('setShowLength', parseInt(limit, 10));
		if (offset) commit('setOffset', parseInt(offset, 10));
		if (search) commit('setSearch', search);

		commit('startLoading', { reset });
		const params = {
			limit: state.showLength,
			offset: state.offset,
		};
		if (state.search.length) params.search = state.search;
		try {
			let response = {};
			switch (state.type) {
				case 'nft':
					params.nftStatus = 'banned';
					response = await nftsService.getList(params);
					state.list = response.nfts;
					state.totalCount = response.totalCount;
					break;
				case 'user':
					params.userStatus = 'banned';
					response = await usersService.getList(params);
					state.list = response.users;
					state.totalCount = response.totalCount;
					break;
				case 'collection':
					params.collectionStatus = 'banned';
					response = await collectionsService.getCollections(params);
					state.list = response.collections;
					state.totalCount = response.totalCount;
					break;
			}
		} finally {
			commit('finishLoading');
		}
	},
	async unban ({ state }, id) {
		try {
			switch (state.type) {
				case 'nft':
					await nftsService.unbanNft(id);
					break;
				case 'user':
					await usersService.unbanUser(id);
					break;
				case 'collection':
					await collectionsService.unbanCollection(id);
					break;
			}
			const item = find(state.list, { id });
			if (state.type !== 'user') item.isBanned = false;
			else item.userStatus = 'active';
		} catch (e) {
			console.log(e);
		}
	},
	async ban ({ state }, id) {
		try {
			switch (state.type) {
				case 'nft':
					await nftsService.banNft(id);
					break;
				case 'user':
					await usersService.banUser(id);
					break;
				case 'collection':
					await collectionsService.banCollection(id);
					break;
			}
			const item = find(state.list, { id });
			if (state.type !== 'user') item.isBanned = true;
			else item.userStatus = 'banned';
		} catch (e) {
			console.log(e);
		}
	},
	async changeActiveTab ({ dispatch, commit }, type) {
		commit('setActiveTab', type);
		commit('setShowLength', 10);
		commit('setOffset', 0);
		commit('setSearch', '');
		await dispatch('fetchBannedList', { reset: true });
	},
	async changeShowLength ({ dispatch, commit }, length) {
		commit('setShowLength', length);
		await dispatch('fetchBannedList', { reset: true });
	},
	async changeOffset ({ dispatch, commit }, offset) {
		commit('setOffset', offset);
		await dispatch('fetchBannedList', { reset: true });
	},
	async changeSearch ({ dispatch, commit }, search) {
		commit('setSearch', search);
		commit('setOffset', 0);
		await dispatch('fetchBannedList', { reset: true });
	},
};
