<template>
  <div>
    <LabeledContainer
      class="item"
      label="NFT file"
    >
      <FormBanner
        :disabled="disabled"
        type="drop/nft/media"
        name="mediaUrl"
      />
    </LabeledContainer>

    <LabeledContainer
      class="item"
      label="NFT preview"
    >
      <FormBanner
        :disabled="disabled"
        type="drop/nft/preview"
        name="previewUrl"
      />
    </LabeledContainer>

    <LabeledContainer
      class="item"
      label="NFT Name"
      for-id="nft-name"
    >
      <FormTextField
        id="nft-name"
        :disabled="disabled"
        name="name"
        placeholder="Enter a name"
      />
    </LabeledContainer>

    <LabeledContainer
      class="item"
      label="Description"
      for-id="nft-description"
    >
      <FormTextarea
        id="nft-description"
        :disabled="disabled"
        name="description"
        placeholder="Enter a description"
      />
    </LabeledContainer>

    <LabeledContainer
      class="item"
      label="Amount for sale"
      for-id="nft-amountForSale"
    >
      <FormTextField
        id="nft-amountForSale"
        :disabled="disabled"
        type="number"
        is-integer
        name="amountForSale"
        placeholder="Enter a number"
      />
    </LabeledContainer>

    <LabeledContainer
      class="item"
      label="Max Issue"
      for-id="nft-maxIssue"
    >
      <FormTextField
        id="nft-maxIssue"
        :disabled="disabled"
        type="number"
        is-integer
        name="maxQuantity"
        placeholder="Enter a number"
      />
    </LabeledContainer>

    <LabeledContainer
      class="item"
      label="Edition"
      for-id="edition"
    >
      <FormNativeSelect
        id="edition"
        :disabled="disabled"
        name="edition"
      >
        <option value="open">
          Open Edition
        </option>
        <option value="limited">
          Limited Edition
        </option>
      </FormNativeSelect>
    </LabeledContainer>

    <div class="checkbox-price">
      <LabeledContainer
        label="Ethereum Price"
        for-id="nft-price"
      >
        <div class="price-row">
          <FormTextField
            id="nft-price"
            name="price"
            placeholder="Enter a price"
          />
          <span class="price-row-currency">ETH</span>
        </div>
      </LabeledContainer>
    </div>

    <!-- todo: use this -->
    <!--    <div class='checkbox-price'>-->
    <!--      <FormCheckbox :disabled='true' class='checkbox-item' name='acceptEth' :value='true'>-->
    <!--        Accept Ethereum-->
    <!--      </FormCheckbox>-->
    <!--      <div v-if='acceptEth'>-->
    <!--        <LabeledContainer label='Ethereum Price' forId='nft-price'>-->
    <!--          <div class='price-row'>-->
    <!--            <FormTextField :disabled='true' id='nft-price' name='price' placeholder='Enter a price'/>-->
    <!--            <span class='price-row-currency'>ETH</span>-->
    <!--          </div>-->
    <!--        </LabeledContainer>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import LabeledContainer from '@/components/ui/LabeledContainer';
import FormBanner from '@/components/common/form/components/FormBanner.vue';
import FormTextarea from '@/components/common/form/components/FormTextarea.vue';
import FormTextField from '@/components/common/form/components/FormTextField.vue';
import FormNativeSelect from '@/components/common/form/components/FormNativeSelect.vue';

export default {
	components: {
		FormBanner,
		// FormCheckbox,
		FormTextarea,
		FormTextField,
		LabeledContainer,
		FormNativeSelect,
	},
	inject: ['state'],
	computed: {
		acceptEth () {
			return this.state?.formValues?.acceptEth;
		},
		disabled () {
			return this.state.notEditable;
		},
	},
};
</script>

<style scoped lang='scss'>
  .item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .checkbox-price {
    .checkbox-item {
      margin-bottom: 10px;
    }
  }

  .price-row {
    display: flex;
    align-items: center;

    .price-row-currency {
      margin-left: 10px;
    }
  }

  .select {
    width: 100%;
  }

  .select-button {
    border-radius: 8px;
    width: 100%;
    justify-content: flex-start;
  }
</style>
