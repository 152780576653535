<template>
  <div class="image-dropzone">
    <BaseMediahubDropzone
      :name="name"
      :type="type"
      :max-file-size="maxFileBytes"
      :external-error="error"
      title="Choose an image or drag it here"
      :accept="acceptedMimeTypes"
      :address="address"
      :with-icon="false"
      @on-drop="$emit('onUploadingComplete', $event)"
    />
    <HelperText class="helper-text">
      PNG, JPG, JPEG or GIF only<br/>
      <template v-if="maxFileBytes && maxFileBytes !== 0">
        {{ recommendedText }} Max file size {{ maxFileSize }}
      </template>
    </HelperText>
  </div>
</template>

<script>
import HelperText from '@/components/ui/HelperText';
import BaseMediahubDropzone from '@/components/common/files/dropZone/BaseMediahubDropzone';

import { formatBytes } from '@/utility';

export default {
	components: {
		BaseMediahubDropzone,
		HelperText,
	},
	props: {
		maxFileBytes: {
			type: Number,
			required: false,
			default: () => 250 * 1024 * 1024,
		},
		name: {
			type: String,
			required: false,
		},
		error: {
			type: String,
			required: false,
		},
		type: {
			type: String,
			required: false,
		},
		recommendedText: {
			type: String,
			required: false,
		},
		address: {
			type: String,
			required: false,
		},
	},
	emits: ['onUploadingComplete'],
	computed: {
		maxFileSize () {
			return formatBytes(this.maxFileBytes);
		},
		acceptedMimeTypes () {
			const baseMimeTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'image/webp'];
			let mimeTypes;
			switch (this.type) {
				case 'banner':
					mimeTypes = [...baseMimeTypes];
					break;
				case 'avatar':
					mimeTypes = [...baseMimeTypes];
					break;
				case 'thumbnail':
					mimeTypes = [...baseMimeTypes];
					break;
			}
			return mimeTypes;
		},
	},
};
</script>

<style scoped lang='scss'>
.helper-text {
  margin-top: 5px;
}

.avatar {
  :deep(.dropzone-wrapper) {
    width: 140px;
    height: 140px;
    color: $black;
    border-color: $shade30;
    background-color: $shade10;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    border-radius: 100%;
  }
}

.banner {
  :deep(.dropzone-wrapper) {
    color: $black;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    border-color: $shade30;
    background-color: $shade10;
  }
}
</style>
