import Axios from 'axios';

import requests from './requests.service';

const baseUrl = process.env.VUE_APP_DROPS_BASE_URL;

const baseMedihubUrl = process.env.VUE_APP_MEDIAHUB_BASE_URL;

const basePath = `${baseUrl}/admin/v1`;

const filesPath = `${basePath}/files`;

const files = {
	upload: (data, params) => requests.post(`${filesPath}`, data, { params }),
};

const largeFile = {
	getChunks: (data) => requests.post(`${baseMedihubUrl}/v1/uploads`, data),

	uploadParts: async (file, urls, chunkSize, changeProgress) => {
		const axios = Axios.create();
		delete axios.defaults.headers.put['Content-Type'];

		let uploaded = 0;
		let restSize = file.size;
		const keys = Object.keys(urls);
		const promises = [];

		for (const indexStr of keys) {
			const index = parseInt(indexStr, 10);
			const start = index * chunkSize;
			const end = (index + 1) * chunkSize;
			const blob = index < keys.length
				? file.slice(start, end)
				: file.slice(start);
			promises.push(axios.put(urls[index].url, blob)
				// eslint-disable-next-line no-loop-func
				.then(result => {
					if (restSize > chunkSize) {
						uploaded += chunkSize;
						restSize -= chunkSize;
						changeProgress(uploaded);
					} else {
						uploaded = uploaded + restSize;
						changeProgress(uploaded);
					}
					return result;
				}));
		}
		const resultsParts = await Promise.all(promises);

		return resultsParts.map((part, index) => ({
			ETag: part.headers.etag,
			PartNumber: index + 1,
		}));
	},

	completeMultiUpload: async (uploadId, parts) => requests.post(`${baseMedihubUrl}/v1/uploads/${uploadId}/complete`, parts),
};

export default {
	files,
	largeFile,
};
