'use strict';

// Export state mutation functions.
export default {
	// Handle requests by the page to initialize the Ethereum provider.
	initializeRequest (state) {
		state.initializing = true;
	},

	// Handle a failure to initialize the Ethereum provider.
	initializeFailure (state) {
		state.initializing = false;
	},

	// Handle the successful initialization of a provider. This can
	// occur immediately upon loading the page when the default provider is
	// initialized, or it can occur when the user disconnects a signing account
	// from the application. When that happens, we replace the user's
	// provider with an Infura provider for sustained read-only access.
	// This also intializes the user's write-enabled provider upon successfully
	// connecting to the local Ethereum provider.
	initializeSuccess (
		state,
		{ canSign, provider, address, balance, networkId },
	) {
		state.initializing = false;
		state.canSign = canSign;
		state.provider = provider;
		state.address = address;
		state.balance = balance;
		state.networkId = networkId;
		state.connected = true;
	},

	// This function is called whenever we update the status of the provider's
	// polling activity.
	pausedChanged (state, pauseStatus) {
		state.paused = pauseStatus;
	},

	// This function is called whenever we detect a new active Ethereum address.
	addressChanged (state, address) {
		state.address = address;
	},

	// This function is called whenever we detect a new Ethereum network.
	networkChanged (state, chainId) {
		state.networkId = chainId;
	},

	// This function is called whenever we detect a new Ethereum block number.
	blockNumberChanged (state, blockNumber) {
		state.blockNumber = blockNumber;
	},

	disconnect (state) {
		state.connected = false;
	},

	hasLocalProvider (state, value) {
		state.hasLocalProvider = value;
	},
};
