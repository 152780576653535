<template>
  <div
    v-click-outside="{handler: closeDropdown}"
    class="profile"
  >
    <div
      :style="showDropdown ? 'background-color: rgba(113, 18, 255, 0.2)' : ''"
      class="profile__button"
      @click.stop="toggleDropdown"
    >
      <p>{{ formattedAddress }}</p>

      <div class="dropdown-icon">
        <ChevronDownIcon />
      </div>

      <!-- Handle the dropdown for a connected signing address menu. -->
      <!-- Process the link to the 'My Items' page. -->
      <div
        v-if="showDropdown"
        class="dropdown"
      >
        <!-- Process the link to the wallet disconnection page. -->
        <div
          class="dropdown__item"
          @click="disconnect"
        >
          <PowerIcon />
          <p>Disconnect</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { formatAddress } from '@/utility';
import { ChevronDownIcon, PowerIcon } from '@/components/icons';
import clickOutside from '@/utility/directives/click-outside';

export default {
	directives: { clickOutside },
	components: { ChevronDownIcon, PowerIcon },
	data () {
		return {
			showDropdown: false,
		};
	},
	computed: {
		...mapState({
			address: state => state.auth.address,
		}),
		formattedAddress () {
			return formatAddress(this.address || '');
		},
	},
	methods: {
		...mapActions('auth', ['logout']),
		toggleDropdown () {
			this.showDropdown = !this.showDropdown;
		},
		closeDropdown () {
			this.showDropdown = false;
		},
		async disconnect () {
			await this.logout();
		},
	},
};
</script>

<style scoped lang='scss'>
.profile {
  margin-left: 20px;
}

.profile__button {
  cursor: pointer;
  display: flex;
  border-radius: 50px;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.profile__button:hover {
  cursor: pointer;
  background-color: rgba(113, 18, 255, 0.1);
  color: $violet;
}

.profile__button:active {
  background-color: rgba(113, 18, 255, 0.2);
}

.profile__button > p {
  color: rgb(5, 5, 5);
  font-size: 14px;
}

.dropdown {
  position: absolute;
  top: 41px;
  right: 0;
  width: 160px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(113, 18, 255, 0.2);
  border-radius: 34px;
  background-color: white;
  font-size: 14px;
  z-index: 10;
}

.dropdown-icon {
  margin-top: -5px;
  margin-left: 10px;
}

.dropdown__item {
  color: rgb(5, 5, 5);
  padding: 10px 10px 10px 15px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  transition: background-color 0.2s ease;
  text-decoration: none;
}

.dropdown__item:hover {
  cursor: pointer;
  background-color: rgba(113, 18, 255, 0.1);
  color: $violet;
}

.dropdown__item > p {
  margin-left: 10px;
  margin-bottom: 3px;
}
</style>
