<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4626_2426)">
      <path
        d="M8 0C3.582 0 0 3.63809 0 8.12527C0 11.9326 2.58133 15.1184 6.06133 16C6.024 15.8903 6 15.763 6 15.6052V14.2165C5.67533 14.2165 5.13133 14.2165 4.99467 14.2165C4.44733 14.2165 3.96067 13.9775 3.72467 13.5333C3.46267 13.0397 3.41733 12.2847 2.768 11.8229C2.57533 11.6692 2.722 11.4939 2.944 11.5176C3.354 11.6354 3.694 11.9211 4.014 12.345C4.33267 12.7695 4.48267 12.8657 5.078 12.8657C5.36667 12.8657 5.79867 12.8488 6.20533 12.7837C6.424 12.2197 6.802 11.7004 7.264 11.4553C4.6 11.177 3.32867 9.83089 3.32867 8.00339C3.32867 7.21659 3.65867 6.45552 4.21933 5.8143C4.03533 5.17782 3.804 3.87981 4.29 3.38553C5.48867 3.38553 6.21333 4.17503 6.38733 4.38832C6.98467 4.18045 7.64067 4.06263 8.33 4.06263C9.02067 4.06263 9.67933 4.18045 10.278 4.38967C10.45 4.17774 11.1753 3.38553 12.3767 3.38553C12.8647 3.88049 12.6307 5.18392 12.4447 5.81904C13.002 6.45891 13.33 7.21794 13.33 8.00339C13.33 9.82954 12.0607 11.1749 9.40067 11.4546C10.1327 11.8426 10.6667 12.9327 10.6667 13.754V15.6052C10.6667 15.6757 10.6513 15.7265 10.6433 15.7867C13.7607 14.6769 16 11.6706 16 8.12527C16 3.63809 12.418 0 8 0Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4626_2426">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
