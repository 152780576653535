import { formatAddress } from '@/utility';
import CopyButton from '@/components/ui/CopyButton.vue';
import { CONTAINER_OPTIONS } from '@/pages/collections/constants';
import { CollectionCell, TableActions } from '@/pages/collections/components';

export const containerCollectionTableColumns = [
	{
		name: 'Collection',
		value: (data) => <CollectionCell collection={data} show-address={true} />,
	},
	{
		name: 'Contract Address',
		value: (data) => <CopyButton value={data?.contractAddress} label={formatAddress(data?.contractAddress || '')} />,
	},
	{
		name: '',
		value: (data) => <TableActions collection={data} hideUnbanButton={true} hideBanButton={true} hideShareButton={false} hideRemoveButton={true} justifyContent="flex-end" />,
	},
];

export const containerRenderTypeOptions = [
	{ label: 'Merge', value: CONTAINER_OPTIONS.MERGE },
	{ label: 'Split', value: CONTAINER_OPTIONS.SPLIT },
	{ label: 'Draft', value: CONTAINER_OPTIONS.DRAFT },
];
