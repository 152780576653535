import actions from './actions';
import mutations from './mutations';

const state = {
	tokens: [],
	count: 0,
	offset: 0,
	showLength: 10,
	search: '',
	loading: false,
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
