<template>
  <label class="form-text-field">
    <span
      v-if="label"
      class="form-text-field__label"
    >
      {{ label }}
    </span>
    <component
      :is="renderComponent"
      :id="id"
      :is-rounded="isRounded"
      :type="type"
      :name="name"
      :value="inputValue"
      :disabled="disabled"
      :is-integer="isInteger"
      :error-message="errorMsg"
      :placeholder="placeholder"
      :is-error-message-visible="isHasErrorMessage"
      @input="handleChange"
      @on:update="$emit('on:update', $event)"
      @blur="handleBlur"
    >
      <template #beforeInput>
        <slot name="beforeInput" />
      </template>
    </component>
  </label>
</template>

<script>
import { useField } from 'vee-validate';

import { TextField, NumberField } from '@/components/ui/text-fields';

export default {
	components: { TextField },
	props: {
		name: {
			type: String,
			required: true,
		},
		id: {
			type: String,
			required: false,
		},
		type: {
			type: String,
			required: false,
			default: 'text',
		},
		value: {
			type: String,
			required: false,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			required: false,
		},
		isInteger: {
			type: Boolean,
			required: false,
		},
		isRounded: {
			type: Boolean,
			required: false,
			default: false,
		},
		label: {
			type: String,
			required: false,
		},
		customError: {
			type: String,
			default: '',
		},
	},
	emits: ['on:update'],
	setup (props) {
		const {
			value: inputValue,
			errorMessage,
			handleBlur,
			handleChange,
		} = useField(props?.name || '', undefined, {
			initialValue: props?.value,
		});

		return {
			inputValue,
			handleBlur,
			handleChange,
			errorMessage,
		};
	},
	computed: {
		errorMsg () {
			return this.customError || this?.errorMessage || '';
		},
		isHasErrorMessage () {
			return !!this.errorMsg;
		},
		renderComponent () {
			return this.type === 'number' ? NumberField : TextField;
		},
	},
};
</script>

<style lang=scss scoped>
.form-text-field {
  display: flex;
  flex-direction: column;

  &__label {
    margin-bottom: 4px;

    font-weight: 600;
    font-size: 14px;
  }
}
</style>
