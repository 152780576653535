<template>
  <section class="info-wrapper">
    <div class="info-wrapper-inner">
      <slot />
    </div>
  </section>
  <slot name="preview" />
</template>

<style scoped lang='scss'>
  .info-wrapper {
    width: 100%;
    height: 100%;
    padding: 20px;

    .info-wrapper-inner {
      display: grid;
      grid-auto-columns: 1fr;
      gap: 60px;
      grid-template-columns: 1fr 450px;
      width: 100%;
      margin: 0 auto;
      height: 100%;
      max-width: 1600px;
    }
  }
</style>
