<template>
  <div class="table__placeholder">
    <div class="table__placeholder_box">
      List is empty
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({ name: 'Placeholder' });
</script>

<style scoped lang="scss">
.table__placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  &_box {
    text-align: center;
    margin-top: 50px;
    font-size: 24px;
  }
}
</style>
