import { markRaw } from 'vue';
import { useStore } from 'vuex';

import SaveAsDraftConfirmationModal from '../SaveAsDraftConfirmationModal.vue';

function useSaveAsDraftConfirmationModal () {
	const store = useStore();

	async function openSaveAsDraftConfirmationModal (onConfirm) {
		await store.dispatch('modal/openModal', {
			content: markRaw(SaveAsDraftConfirmationModal),
			props: { onConfirm },
		});
	}

	return { openSaveAsDraftConfirmationModal };
}

export default useSaveAsDraftConfirmationModal;
