import unformattedStyles from '@/assets/style/_variablesjs.scss';

// function jsonIze (str) {
// 	return str
// 	// wrap keys without quote with valid double quote
// 		.replace(/([$\w]+)\s*:/g, (_, $1) => `"${$1}":`)
// 		.replace(/: (.*),/g, (_, $1) => `: "${$1}",`);
// }
//
// let formattedStyles = unformattedStyles.slice(7).replace(/;/g, ',');
// formattedStyles = jsonIze(formattedStyles);
// const styles = JSON.parse(formattedStyles.slice(0, -3) + formattedStyles.slice(-2));
const styles = unformattedStyles;

export default styles;
