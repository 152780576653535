<template>
  <div class="action-btns">
    <Button
      class="share"
      variant="outlined"
      :disabled="isDisabled"
      round
      @click="deleteModal"
    >
      <CrossIcon />
    </Button>
  </div>
</template>

<script>
import { defineComponent, shallowRef } from 'vue';

import Button from '@/components/ui/Button';
import DeleteModal from '@/components/common/modal/DeleteModal';
import { CrossIcon } from '@/components/icons';
import { useStore } from 'vuex';

export default defineComponent({
	name: 'TableActions',
	components: { Button, CrossIcon },
	props: {
		isDisabled: Boolean,
		id: String,
		address: String,
	},
	setup (props) {
		const store = useStore();

		const deleteToken = async () => {
			await store.dispatch('tokens/deleteToken', props);
		};

		const deleteModal = () => store.dispatch('modal/openModal', {
			content: shallowRef(DeleteModal),
			props: deleteToken,
		});

		return {
			deleteModal,
		};
	},
});
</script>

<style scoped lang="scss">
.action-btns {
  display: flex;

  button:not(:first-child) {
    margin-left: 8px;
  }

  &__ban-unban-btn {
    width: 88px;
  }

  button.share svg {
    margin-left: 2px;
  }
}
</style>
