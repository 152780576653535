import { watch } from 'vue';
import { merge } from 'lodash';

import { announcementsService } from '@/services';

export const useUpdateTabsWithCounter = (tabsRef, collectionComputedRef) => {
	const fetchCounter = async (query) => {
		const urnType = 'collection';
		let response;

		try {
			response = await announcementsService.getAnnouncementsCounters(urnType, collectionComputedRef.value?.slug || collectionComputedRef.value?.contractAddress, query);
		} catch (e) {
			throw new Error(e);
		}

		return response;
	};

	const updateTabsWithCounters = async () => {
		let promisesArr;
		try {
			promisesArr = await Promise.all(tabsRef.value?.map(({
				type,
				status,
				showScheduled,
			}) => fetchCounter({
				...(status ? { status } : {}),
				...(type ? { type } : {}),
				...(showScheduled ? { showScheduled } : {}),
			})))
			;
		} catch (e) {
			throw new Error(e);
		} finally {
			tabsRef.value = merge(tabsRef.value, promisesArr?.map(({ totalCount }) => ({ description: totalCount })));
		}
	};

	watch(() => collectionComputedRef.value, updateTabsWithCounters);

	return {
		updateTabsWithCounters,
	};
};
