<template>
  <svg
    fill="none"
    height="16"
    viewbox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.8 15.9V10.3H4.7V8H6.8V6.2C6.8 4.2 7.9 3.1 9.8 3.1 10.6 3.1 11.6 3.3 11.6 3.3V5.3H10.6C9.6 5.3 9.2 5.9 9.2 6.5V8H11.5L11.1 10.3H9.2V15.9C13.1 15.3 16 12 16 8 16 3.6 12.4 0 8 0 3.6 0 0 3.6 0 8 0 12 2.9 15.3 6.8 15.9ZM8.3 16C8.5 16 8.8 16 9 15.9 8.8 16 8.5 16 8.3 16Z"
      fill="currentColor"
    />
  </svg>
</template>
