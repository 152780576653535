<template>
  <div class="collaborators">
    <div class="collaborators__header">
      <span class="header__title">Collaborator</span>
      <p class="header__block-text">
        Collaborators can modify collection settings, receive payments for items they created, and change the collection's royalty payout address. The maximum number of collaborators is 20.
      </p>
      <Button
        class="header__button"
        variant="outlined"
        :disabled="count >= 20"
        @click="openAddModal"
      >
        + Add a collaborator
      </Button>
    </div>
    <Table
      :columns="tableColumns"
      :data="collaborators.collaborators"
      class="collaborators__table"
    />
  </div>
</template>

<script>
import { computed, shallowRef } from 'vue';
import { useStore } from 'vuex';
import Table from '@/components/ui/table/Table';
import Button from '@/components/ui/Button';
import { columns } from './helpers';
import AddCollaboratorModal from '@/pages/collections/edit/components/AddCollaboratorModal';

export default {
	components: { Table, Button },
	props: {
		collaborators: {
			type: Object,
			required: true,
			default: null,
		},
	},
	setup () {
		const store = useStore();
		const tableColumns = computed(() => columns);
		const count = computed(() => store.state.editCollection.collaborators.collaborators.length);

		const openAddModal = () => {
			store.dispatch('modal/openModal', {
				content: shallowRef(AddCollaboratorModal),
			});
		};

		return {
			tableColumns,
			openAddModal,
			count,
		};
	},
};
</script>

<style scoped lang="scss">
  .collaborators {
    padding-top: 32px;

    &__header {

      .header {
        &__title {
          display: block;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 28px;
        }

        &__block-text {
          display: block;
          width: 600px;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
        }

        &__button {
          margin: 20px 0px;
        }
      }
    }

    &__table {
      max-width: 965px;
    }
  }
</style>
