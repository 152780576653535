import TableActions from '@/pages/users/components/TableActions';
import Status from '@/components/ui/Status';
import { formatAddress, formatDate } from '@/utility';
import PreviewLink from '@/components/ui/PreviewLink';

const clientBaseUrl = process.env.VUE_APP_WEBSITE_BASE_URL;
export const columnsForTable = [
	{
		name: 'Date',
		value: (data) => data?.registeredAt ? formatDate(data?.registeredAt, 'EEE LLL d, p') : 'Not registered',
	},
	{
		name: 'User',
		value: (data) => <PreviewLink preview={data.avatarUrl}
			label={data.username || data.ethAddress}
			withStringFormatting={!data.username}
			transitionTo={`${clientBaseUrl}/${data.ethAddress}`}
		/> },
	{
		name: 'Wallet address',
		value: (data) => formatAddress(data?.ethAddress || ''),
	},
	{
		name: 'Status',
		value: (data) => <Status type={data?.userStatus === 'banned' ? 'banned' : 'active'}>{data?.userStatus === 'banned' ? 'Banned' : 'Active'}</Status>,
	},
	{
		name: 'Actions',
		value: (data) => <TableActions id={data?.id} isBanned={data?.userStatus !== 'active'} eth-address={data?.ethAddress}></TableActions>,
		width: '216px',
	},
];
