<template>
  <div class="select-content__merge">
    <div class="select-content__wrap">
      <div class="select-content__header">
        <LabeledContainer label="Contract addresses">
          <p class="select-content__subtitle">
            {{ `You can merge from ${COLLECTIONS_CONSTANTS.MIN_COUNT_FOR_MERGE_COLLECTIONS} to ${COLLECTIONS_CONSTANTS.MAX_COUNT_FOR_MERGE_COLLECTIONS} collections` }}
          </p>
        </LabeledContainer>
      </div>
      <div
        v-if="mergeCollectionsAddresses.length !== 0"
        class="select-content__table"
      >
        <Table
          :columns="containerCollectionTableColumns"
          :data="mergeCollectionsAddresses"
          class="collections-slot"
        />
      </div>
      <div class="select-content__button">
        <Tooltip
          value="You have added the maximum number of collection address"
          :hide-tooltip="!isAddButtonDisabled"
        >
          <Button
            class="actions-block__add-button"
            variant="outlined"
            width="132"
            :disabled="isAddButtonDisabled"
            @click="openModalAddAddress"
          >
            + Add address
          </Button>
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, markRaw } from 'vue';

import Button from '@/components/ui/Button';
import Table from '@/components/ui/table/Table';
import Tooltip from '@/components/ui/Tooltip.vue';
import LabeledContainer from '@/components/ui/LabeledContainer';
import { COLLECTIONS_CONSTANTS } from '@/pages/collections/constants';
import AddContractAddressModal from '@/pages/collections/components/AddContractAddressModal.vue';
import { containerCollectionTableColumns } from '@/pages/collections/add-container/sections/helpers';

const store = useStore();

const mergeCollectionsAddresses = computed(() => store.state.collections.customCollectionMergeContractAddresses);

const isAddButtonDisabled = computed(() => mergeCollectionsAddresses.value.length >= COLLECTIONS_CONSTANTS.MAX_COUNT_FOR_MERGE_COLLECTIONS);

const openModalAddAddress = () => {
	store.dispatch('modal/openModal', {
		content: markRaw(AddContractAddressModal),
	});
};
</script>

<style lang="scss" scoped>
.select-content {
  &__merge {
    max-width: 726px;
    position: relative;
  }

  &__wrap {
    display: grid;
    row-gap: 24px;
  }

  &__header {
    &:deep(.labeled-wrapper) {
      display: grid;
      row-gap: 8px;

      label {
        margin-bottom: 0 !important;
      }
    }
  }

  &__table {
    max-height: 600px;
    overflow-y: auto;
  }
}
</style>
