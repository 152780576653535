<template>
  <svg
    width="17"
    height="17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#a)">
      <path
        d="m16.04 7.03-1.79-.53a6.7 6.7 0 0 0-.5-1.24l.87-1.64a.33.33 0 0 0-.06-.38l-1.28-1.28a.33.33 0 0 0-.38-.06l-1.63.87a6.2 6.2 0 0 0-1.26-.54L9.48.47a.33.33 0 0 0-.32-.22h-1.8a.33.33 0 0 0-.31.23l-.53 1.76c-.45.13-.87.3-1.27.53l-1.6-.86a.33.33 0 0 0-.4.06L1.97 3.23a.33.33 0 0 0-.06.39l.87 1.6c-.23.4-.4.82-.54 1.26L.47 7a.33.33 0 0 0-.23.31v1.8a.33.33 0 0 0 .23.31l1.77.54c.14.43.32.85.54 1.24l-.88 1.67a.33.33 0 0 0 .06.39l1.28 1.27a.33.33 0 0 0 .38.06l1.65-.88c.4.21.8.38 1.23.5l.53 1.8a.33.33 0 0 0 .31.23h1.8a.33.33 0 0 0 .32-.23l.53-1.8c.42-.13.83-.3 1.22-.5l1.66.88a.33.33 0 0 0 .38-.06l1.28-1.27a.32.32 0 0 0 .06-.39l-.89-1.65c.21-.39.38-.8.5-1.22l1.8-.53a.33.33 0 0 0 .24-.31V7.35a.32.32 0 0 0-.2-.32zm-7.78 4.15a2.94 2.94 0 1 1-.343-5.87 2.94 2.94 0 0 1 .343 5.87z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          d="M0 0h17v17H0z"
        />
      </clipPath>
    </defs>
  </svg>
</template>
