<template>
  <div class="action-btns">
    <Button
      v-if="isBanned"
      :disabled="btnOnLoading"
      variant="outlined"
      size="flex"
      class="action-btns__ban-unban-btn"
      @click="openBanModal"
    >
      Unban
    </Button>
    <Button
      v-else-if="!isBanned"
      :disabled="btnOnLoading"
      variant="outlined"
      size="flex"
      class="action-btns__ban-unban-btn"
      @click="openBanModal"
    >
      Ban
    </Button>
    <Button
      :disabled="btnRefreshOnLoading"
      class="share"
      variant="outlined"
      round
      @click="refreshMetadata"
    >
      <RefreshIcon />
    </Button>
    <Button
      class="share"
      variant="outlined"
      round
      @click="transitionToMarketplace"
    >
      <ShareIcon />
    </Button>
  </div>
</template>

<script>
import { defineComponent, ref, shallowRef } from 'vue';

import Button from '@/components/ui/Button';
import BanModal from '@/pages/nfts/components/BanModal';
import { ShareIcon, RefreshIcon } from '@/components/icons';
import { useStore } from 'vuex';
import { nftsService } from '@/services';
import { addSuccess } from '@/components/utility/notifications';

export default defineComponent({
	name: 'TableActions',
	components: { Button, ShareIcon, RefreshIcon },
	props: {
		isBanned: Boolean,
		id: String,
		tokenId: String,
		collection: Object,
	},
	setup (props) {
		const store = useStore();
		const baseMarketplaceUrl = process.env.VUE_APP_WEBSITE_BASE_URL;
		const btnOnLoading = ref(false);
		const btnRefreshOnLoading = ref(false);
		const openBanModal = () => store.dispatch('modal/openModal', {
			content: shallowRef(BanModal),
			props,
		});
		const refreshMetadata = async () => {
			btnRefreshOnLoading.value = true;
			await nftsService.refreshMetadata(props.id).finally(() => { btnRefreshOnLoading.value = false; });
			addSuccess('We are updating the metadata, refresh the page in a couple of minutes');
		};
		const transitionToMarketplace = () => {
			window.open(`${baseMarketplaceUrl}/collections/${props.collection?.contractAddress || props.collection?.slug}/${props.tokenId}`);
		};

		return {
			openBanModal,
			btnOnLoading,
			btnRefreshOnLoading,
			refreshMetadata,
			transitionToMarketplace,
		};
	},
});
</script>

<style scoped lang="scss">
.action-btns {
  display: flex;

  button:not(:first-child) {
    margin-left: 8px;
  }

  &__ban-unban-btn {
    width: 88px;
  }
  button.share svg {
    margin-left: 2px;
  }
}
</style>
