<template>
  <div class="link-fields-component-container">
    <div class="link-fields-collection">
      <div
        v-for="(_, idx) in fields"
        :key="idx"
        class="link-fields-collection-item"
      >
        <h4 class="link-fields-title">
          Social Link
        </h4>

        <div class="link-fields-group">
          <div>
            <form-text-field
              is-rounded
              :name="`socialLinks[${idx}].link`"
            />
          </div>

          <FormLinkSelect
            :name="`socialLinks[${idx}].type`"
            :value="formValues.socialLinks[idx].type"
            :social-links="formValues.socialLinks"
            :options="linkTypes"
          />
          <div class="link-fields-button-wrapper">
            <Button
              type="button"
              class="link-fields-button"
              is-text
              @click="removeLink(idx)"
            >
              <CrossIcon />
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Button
        variant="outlined"
        class="link-fields-main-button"
        :disabled="disabled"
        :width="170"
        @click="addLink()"
      >
        + Add a social link
      </Button>
    </div>
  </div>
</template>

<script>
'use strict';

import { isEmpty } from 'lodash';
import { useFieldArray } from 'vee-validate';

import Button from '@/components/ui/Button.vue';
import { CrossIcon } from '@/components/icons';
import { createLink, linkTypes } from '@/pages/users/edit/components/helpers';
import FormTextField from '@/components/common/form/components/FormTextField.vue';

import FormLinkSelect from './FormLinkSelect.vue';

export default {
	components: { Button, CrossIcon, FormTextField, FormLinkSelect },
	inject: ['formState'],
	props: {
		name: { type: String, required: true },
	},
	setup (props) {
		const { fields, remove, push } = useFieldArray(props.name);

		const addLink = () => {
			const link = createLink(fields);

			if (isEmpty(link)) return;

			push(link);
		};

		const removeLink = value => remove(value);

		return { fields, removeLink, addLink };
	},
	data () {
		return {
			linkTypes,
		};
	},
	computed: {
		formValues () {
			return this.formState?.values || null;
		},
		disabled () {
			return this.formState?.values.links?.length === this.linkTypes?.length * 2 || false;
		},
	},
};
</script>

<style scoped lang="scss">
.link-fields {
  &-main-button {
    margin-top: 20px;
  }

  &-component-container {
    width: 100%;
  }

  &-collection {
    display: grid;
    grid-template-columns: 100%;
    gap: 16px;

    &-item {
      display: grid;
      grid-template-columns: 100%;
      gap: 5px;

      :deep(.select-button) {
        width: 100%;
        color: $violet;
      }

      &:first-child {
        margin-top: 24px;
      }

      .link-fields-title {
        font-weight: 400;
      }
    }
  }

  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  &-group {
    display: grid;
    grid-template-columns: auto minmax(127px, auto) 40px;
    gap: 10px;
    align-items: flex-start;
  }

  &-button {
    width: 40px;
    height: 40px;
  }

  &-button-wrapper {
    :deep(.text) {
      color: $violet;
    }
  }
}
</style>
