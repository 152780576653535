<template>
  <div class="table__body">
    <BodyRow
      v-for="(dataItem, dataKey) in data"
      :key="dataKey"
      :style="gridStyles"
      @click="$emit('rowClick', dataItem)"
    >
      <BodyCell v-if="withCheckbox">
        <BaseCheckbox
          :value="checkboxValue(dataItem)"
          :checked="checkboxValue(dataItem)"
          @click.prevent="handleCheckbox(dataItem)"
        />
      </BodyCell>

      <BodyCell
        v-for="(col, colKey) in columns"
        :key="colKey"
        :component="col?.value(dataItem, dataKey)"
      />
    </BodyRow>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import BaseCheckbox from '@/components/ui/BaseCheckbox';
import BodyRow from './BodyRow.vue';
import BodyCell from './BodyCell.vue';

export default defineComponent({
	name: 'Body',
	components: {
		BodyCell,
		BodyRow,
		BaseCheckbox,
	},
	inject: ['data', 'columns', 'gridStyles', 'withCheckbox', 'checkboxValue'],
	emits: ['rowClick', 'checkboxClick'],
	methods: {
		handleCheckbox (dataItem) {
			this.$emit('checkboxClick', dataItem);
		},
	},
});
</script>
