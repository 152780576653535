<template>
  <div class="sidebar">
    <div class="sidebar-header">
      <Logo />
    </div>
    <div class="sidebar-body">
      <NavLink :to="routes.user">
        <template #icon>
          <PersonIcon />
        </template>
        Users
      </NavLink>
      <NavLink :to="routes.nfts">
        <template #icon>
          <ThumbnailIcon />
        </template>
        NFTs
      </NavLink>
      <NavLink :to="routes.collections">
        <template #icon>
          <ThumbnailsCollectionIcon />
        </template>
        Collections
      </NavLink>
      <NavLink :to="routes.drops">
        <template #icon>
          <DropIcon />
        </template>
        Drops
      </NavLink>
      <NavLink :to="routes.reports">
        <template #icon>
          <FlagIcon />
        </template>
        Reports
      </NavLink>
      <NavLink :to="routes.banned">
        <template #icon>
          <BlockedIcon />
        </template>
        Banned
      </NavLink>
      <NavLink :to="routes.defaultTokens">
        <template #icon>
          <TokensIcon />
        </template>
        Default tokens
      </NavLink>
      <NavLink :to="routes.settings">
        <template #icon>
          <SettingsIcon />
        </template>
        Settings
      </NavLink>
    </div>
  </div>
</template>

<script>
import routes from '@/router/routes';

import {
	PersonIcon,
	ThumbnailIcon,
	ThumbnailsCollectionIcon,
	DropIcon,
	FlagIcon,
	BlockedIcon,
	TokensIcon,
	SettingsIcon,
} from '@/components/icons';

import NavLink from './NavLink.vue';
import Logo from '../../components/Logo.vue';

export default {
	components: {
		Logo,
		NavLink,
		FlagIcon,
		DropIcon,
		PersonIcon,
		BlockedIcon,
		SettingsIcon,
		ThumbnailIcon,
		TokensIcon,
		ThumbnailsCollectionIcon,
	},
	data () {
		return { routes };
	},
};
</script>

<style scoped lang='scss'>
  .sidebar {
    border-right: 1px solid rgba(113, 18, 255, 0.2);
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: $mainSidebarWidth;
    overflow-y: auto;
    position: sticky;
    top: 0;
    background-color: $white;

    &-header {
      height: 60px;
      padding: 0 20px 0 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        display: flex;
        align-items: center;
      }
    }

    &-body {
      padding: 20px;
    }
  }
</style>
