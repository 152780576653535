<template>
  <div class="form-thumbnail">
    <ThumbnailDropzone
      v-if="!inputValue"
      :name="name"
      :error="errorMsg"
      @on-uploading-complete="onUploadingComplete"
    />
    <MediaPreview
      v-else
      class="media-preview-wrapper"
      :src="inputValue"
      :disabled="disabled"
      helper-text="For the background of the drop card."
      @on-delete="onDelete"
    />
  </div>
</template>

<script>
import { useField } from 'vee-validate';

import MediaPreview from '@/components/common/files/MediaPreview.vue';
import ThumbnailDropzone from '@/components/common/files/dropZone/ThumbnailDropzone.vue';

export default {
	components: {
		MediaPreview,
		ThumbnailDropzone,
	},
	props: {
		name: {
			type: String,
			required: true,
		},
		value: {
			type: String,
			required: false,
			default: '',
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	setup (props) {
		const {
			value: inputValue,
			errorMessage,
			handleChange,
		} = useField(props?.name || '', undefined, {
			initialValue: props?.value,
		});

		return {
			inputValue,
			handleChange,
			errorMessage,
		};
	},
	computed: {
		errorMsg () {
			return this?.errorMessage || '';
		},
		isHasErrorMessage () {
			return !!this.errorMsg;
		},
	},
	methods: {
		onUploadingComplete (file) {
			this.handleChange(file?.url || '');
		},
		onDelete () {
			this.handleChange('');
		},
	},
};
</script>

<style scoped lang='scss'>
  .media-preview-wrapper {
    :deep(.media-preview) {
      width: fit-content;
      height: 110px;
    }
  }
</style>
