import requests from './requests.service';
import localStorage from './local-storage.service';
import { ethersService } from './';

const storageKey = '__sf_auth';
const basePath = process.env.VUE_APP_AUTH_BASE_URL;

const authPath = `${basePath}/v1/auth`;

const login = async address => {
	const provider = await ethersService.getProvider();
	const [signer, network] = await Promise.all([provider.getSigner(), provider.getNetwork()]);
	const netId = network.chainId;

	const loginResponse = await requests.post(`${authPath}/login/ethereum`, {
		address,
		netId,
		audience: 'admin',
	});

	const message = 'Sign in to Gigamart!\n\n\n'
        + 'Click ‘Sign’ to sign in. No password is needed!\n\n'
        + 'By signing in you accept the Gigamart Terms of Service: https://gigamart.com/terms\n\n'
        + `Wallet Address: ${address}\n\n`
        + `Nonce: ${loginResponse?.otp}`;
	const code = await signer.signMessage(message);

	const verifyResponse = await requests.post(`${authPath}/otp/verify`, {
		code,
		sessionId: loginResponse?.sessionId,
	});

	const credentials = { access: verifyResponse.accessToken, refresh: verifyResponse.refreshToken, address };
	saveAuth(credentials);

	return credentials;
};

const refreshToken = async (address) => {
	const current = getCurrentAuth();

	if (current) {
		if (current.address !== address) {
			return false;
		}

		const response = await requests.post(`${authPath}/token/refresh`, { refreshToken: current.refresh });

		const credentials = { access: response.accessToken, refresh: response.refreshToken, address };
		saveAuth(credentials);

		return credentials;
	}
};

const getCurrentAuth = () => {
	const data = localStorage.get(storageKey);

	if (!data) {
		return null;
	}

	try {
		return JSON.parse(data);
	} catch {
		return null;
	}
};

const saveAuth = data => {
	localStorage.set(storageKey, JSON.stringify(data));
};

const clearAuth = () => {
	localStorage.remove(storageKey);
};

export const authService = {
	login,
	saveAuth,
	clearAuth,
	refreshToken,
	getCurrentAuth,
};
