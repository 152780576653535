<template>
  <ErrorMessage
    v-if="!isNew && !isLoading && !hasData"
    class="box"
  />
  <template v-if="!isLoading && (isNew || hasData)">
    <component :is="renderFormByProvider" />
  </template>
  <div
    v-if="isLoading"
    class="box"
  >
    <LoadingSpinnerIcon class="spinner" />
  </div>
</template>

<script>
import { computed, provide } from 'vue';

import LoadingSpinnerIcon from '@/components/icons/LoadingSpinnerIcon';

import { providerTypes } from '../helpers';
import { useDetails } from './helpers/index';
import ErrorMessage from '../components/ErrorMessage.vue';
import ImxContainer from './components/imx-container/index.vue';
import PreviewContent from './components/preview-content/index.vue';
import EthereumContainer from './components/eth-container/index.vue';

export default {
	components: {
		ErrorMessage,
		PreviewContent,
		LoadingSpinnerIcon,
	},
	provide () {
		return {
			toPreview: this.toPreview,
			setPreviewInstance: this.setPreviewInstance,
		};
	},
	props: {
		drop: { type: String, required: true },
	},
	setup (props) {
		const data = useDetails(props.drop);

		provide('mainState', {
			data,
			status: computed(() => data.status.value),
			isNew: computed(() => data.isNew.value),
			provider: computed(() => data.provider.value),
			notEditable: computed(() => data.notEditable.value),
			isLoading: computed(() => data?.isLoading?.value || false),
			isSubmitLoading: computed(() => data.isSubmitLoading.value),
		});

		return {
			hasData: data.hasData,
			provider: data.provider,
			isNew: data.isNew.value,
			isLoading: data?.isLoading || false,
		};
	},
	data () {
		return {
			previewInstance: null,
		};
	},
	computed: {
		renderFormByProvider () {
			switch (this.provider) {
				case providerTypes.IMX:
					return ImxContainer;
				case providerTypes.ETHEREUM:
					return EthereumContainer;
				default:
					return null;
			}
		},
	},
	methods: {
		setPreviewInstance (instance) {
			this.previewInstance = instance;
		},
		toPreview () {
			this.previewInstance?.toPreview();
		},
	},
};
</script>

<style scoped lang='scss'>
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: calc(100vh - 60px);

    .spinner {
      width: 100px;
      height: 100px;
    }
  }
</style>
