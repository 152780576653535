export default {
	login (state, { access, refresh, address }) {
		state.address = address;
		state.tokens.access = access;
		state.tokens.refresh = refresh;
	},
	logout (state) {
		state.address = undefined;
		state.tokens.access = undefined;
		state.tokens.refresh = undefined;
	},
	setLoading (state, payload) {
		state.isLoading = payload;
	},
};
