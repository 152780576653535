<template>
  <div class="airdrops">
    <LinkToEditPage :identifier="route.params.identifier" />
    <div class="airdrops__header">
      <AnnouncementsManagingPageTitle
        v-if="!loading"
        :collection-name="collection?.name || collectionIdentifier"
        label="Airdrops"
      />
      <Button
        v-if="airdrops?.length && !loading"
        upper-case
        @click="handlerClickCreate"
      >
        create airdrop
      </Button>
      <AnnouncementsListPlaceholder
        v-if="!airdrops?.length && !loading"
        button-label="create airdrop"
        class="airdrops__placeholder"
        placeholder-text="Use airdrops to tell followers of your collection about it’s activities, plans and more."
        @click-button="handlerClickCreate"
      />
    </div>
    <LoaderIcon
      v-if="loading"
      class="airdrops__loader"
      size="64px"
    />
    <TabsManager
      :hide="loading || !airdrops?.length"
      :is-sticky="false"
      :items="tabItems"
      class="airdrops__tabs-manager"
      is-centered
    >
      <template #default="{activeTab}">
        <transition
          mode="out-in"
        >
          <TabComponent
            :identifier="collectionIdentifier"
            :status="activeTab"
            @trash-action="updateTabsWithCounters"
            @start-loading="startLoading"
            @stop-loading="stopLoading"
          />
        </transition>
      </template>
    </TabsManager>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import TabsManager from '@/components/ui/tabs/TabsManager.vue';
import TabComponent from '@/pages/collections/airdrops/components/TabComponent';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import AnnouncementsListPlaceholder
	from '@/components/pages/announcements_and_airdrops/announcements-list-placeholder/AnnouncementsListPlaceholder';
import Button from '@/components/ui/Button';
import AnnouncementsManagingPageTitle
	from '@/components/pages/announcements_and_airdrops/announcements-managing-page-title/AnnouncementsManagingPageTitle';
import { COLLECTION_ANNOUNCEMENT_STATUSES, COLLECTION_ANNOUNCEMENT_TYPES } from '@/pages/collections/announcements/data';
import { useUpdateTabsWithCounter } from '@/pages/collections/announcements/composables/useUpdateTabsWithCounter';

import { useLoading } from '@/composables/useLoading';
import LoaderIcon from '@/components/icons/LoaderIcon';
import LinkToEditPage from '@/pages/collections/announcements/components/components/LinkToEditPage';

const store = useStore();
const route = useRoute();
const router = useRouter();

const { loading, startLoading, stopLoading } = useLoading();

const collection = computed(() => store.state.editCollection.collection);
const collectionIdentifier = computed(() => collection.value?.slug || collection.value?.contractAddress || route.params.identifier);
const airdrops = computed(() => store.state.airdrops.currentList);

const tabItems = ref([
	{
		label: 'All',
		value: 'all',
		type: COLLECTION_ANNOUNCEMENT_TYPES.AIRDROP,
		showScheduled: true,
	},
	{
		label: 'Drafts',
		value: COLLECTION_ANNOUNCEMENT_STATUSES.DRAFT,
		status: COLLECTION_ANNOUNCEMENT_STATUSES.DRAFT,
		type: COLLECTION_ANNOUNCEMENT_TYPES.AIRDROP,
		showScheduled: true,
	},
	{
		label: 'Published',
		value: COLLECTION_ANNOUNCEMENT_STATUSES.PUBLISHED,
		status: COLLECTION_ANNOUNCEMENT_STATUSES.PUBLISHED,
		type: COLLECTION_ANNOUNCEMENT_TYPES.AIRDROP,
		showScheduled: true,
	},
	{
		label: 'Trash',
		value: COLLECTION_ANNOUNCEMENT_STATUSES.TRASH,
		status: COLLECTION_ANNOUNCEMENT_STATUSES.TRASH,
		type: COLLECTION_ANNOUNCEMENT_TYPES.AIRDROP,
		showScheduled: true,
	},
]);

const handlerClickCreate = () => {
	router.push({ name: 'CollectionAirdropCreate' });
};

const { updateTabsWithCounters } = useUpdateTabsWithCounter(tabItems, collection);

onMounted(async () => {
	startLoading();
	store.dispatch('editCollection/getCollection', { id: route.params.identifier });
});
</script>

<style lang="scss" scoped>
.airdrops {
  position: relative;

	&__placeholder {
		margin: 256px 0 402px 0;
		max-width: 337px;
	}

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

	&__header {
		@include flex(column);
		gap: 16px 0;
		padding: 24px 16px 32px 16px;
	}

	&__title {
		text-align: center;
	}

	&__tabs-manager {

		:deep(.tabs-content) {
			padding-top: 0;
		}
	}
}
</style>
