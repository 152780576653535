<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.7354 0.506165C14.5448 0.506165 15.3061 0.821498 15.8781 1.39416C16.4508 1.96616 16.7661 2.72683 16.7661 3.53683C16.7661 4.34616 16.4508 5.1075 15.8781 5.6795L14.3701 7.1875L10.0848 2.90216L11.5928 1.39416C12.1648 0.821498 12.9261 0.506165 13.7354 0.506165ZM0.766113 16.5062L2.45011 10.5368L9.14011 3.84683L13.4254 8.13216L6.73545 14.8222L0.766113 16.5062Z"
      fill="currentColor"
    />
  </svg>
</template>
