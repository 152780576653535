<template>
  <Skeletor
    class="skeleton-item"
    v-bind="$attrs"
  />
</template>

<script>
import { Skeletor } from 'vue-skeletor';

export default {
	components: { Skeletor },
};
</script>

<style scoped lang='scss'>
  .skeleton-item {
    background-color: #D8D8D8;
  }
</style>
