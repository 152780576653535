<template>
  <span class="helper-text"><slot /></span>
</template>

<style scoped lang='scss'>
  .helper-text {
    display: block;
    opacity: .5;
  }
</style>
