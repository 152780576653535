<template>
  <BaseNftCard
    :title="title"
    :media-url="mediaUrl"
    :collection-name="collectionName"
    @mouseenter="isToggleMenuVisible = true"
    @mouseleave="isToggleMenuVisible = false"
  >
    <template
      v-if="hideActions && isToggleMenuVisible"
      #mediaContainerInner
    >
      <div class="drop-action-wrapper">
        <DropMenu v-slot="{close}">
          <DropdownContent width="100%">
            <ListItem
              color="violet"
              @click="$emit('onClickListItem', {type: 'edit', close})"
            >
              Edit
            </ListItem>
            <ListItem
              color="violet"
              @click="$emit('onClickListItem', {type: 'remove', close})"
            >
              Remove
            </ListItem>
          </DropdownContent>
        </DropMenu>
      </div>
    </template>
    <template #infoBottom>
      <DropInfo
        :sold="sold"
        :price="price"
        :status="status"
        :starts-at="startsAt"
        :provider="provider"
        :is-eligible="isEligible"
        :amount-for-sale="amountForSale"
      />
    </template>
  </BaseNftCard>
</template>

<script>
import { ListItem } from '@/components/ui/list';
import { DropdownContent } from '@/components/ui/baseDropdown';

import BaseNftCard from './BaseNftCard.vue';
import DropInfo from './components/DropInfo.vue';
import DropMenu from './components/DropMenu.vue';

export default {
	components: {
		DropInfo,
		DropMenu,
		ListItem,
		BaseNftCard,
		DropdownContent,
	},
	props: {
		title: String,
		sold: Number,
		price: Object,
		status: String,
		mediaUrl: String,
		provider: String,
		startsAt: [String, Number, Date],
		isEligible: {
			type: Boolean,
			required: false,
			default: true,
		},
		amountForSale: Number,
		collectionName: String,
		hideActions: Boolean,
	},
	emits: ['onClickListItem'],
	data () {
		return {
			isToggleMenuVisible: false,
		};
	},
};
</script>

<style scoped lang='scss'>
  .drop-action-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
  }
</style>
