<template>
  <div class="stat-item">
    <h3 class="stat-item-value">
      {{ value }}
    </h3>
    <TitledText
      :title="name"
      reverse
    >
      <template v-if="maxValue">
        Out of {{ maxValue }}
      </template>
    </TitledText>
  </div>
</template>

<script>
import TitledText from '@/components/ui/TitledText';

export default {
	components: {
		TitledText,
	},
	props: {
		value: {
			type: [String, Number],
			required: false,
			default: 0,
		},
		maxValue: {
			type: [String, Number],
			required: false,
			default: 0,
		},
		name: {
			type: String,
			required: false,
		},
	},
};
</script>

<style scoped lang='scss'>
  .stat-item {
    display: flex;
    align-items: center;
    word-break: break-word;

    &-value {
      margin-right: 20px;
      color: $violet;
      font-size: 40px;
      font-weight: 700;
    }
  }
</style>
