<template>
  <LoadingSpinnerIcon v-if="loading" />
  <EditProfile
    v-if="!loading && profile"
    :profile="profile"
  />
</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import EditProfile from '@/pages/users/edit/components/EditProfile';
import LoadingSpinnerIcon from '@/components/icons/LoadingSpinnerIcon';

export default {
	components: {
		EditProfile,
		LoadingSpinnerIcon,
	},
	props: {
		user: { type: String, required: true },
	},
	setup (props) {
		const store = useStore();

		const profile = computed(() => store.state.editUser.profile);
		const loading = computed(() => store.state.editUser.loading);

		onMounted(() => {
			store.dispatch('editUser/getProfile', { id: props.user });
		});

		return {
			profile,
			loading,
		};
	},
};
</script>
