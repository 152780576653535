<template>
  <div
    class="header"
    :class="classes"
  >
    <div class="header-content">
      <slot />
    </div>
    <div class="close-action-wrapper">
      <CloseButton class="close-action" />
    </div>
  </div>
</template>

<script>
import CloseButton from './CloseButton.vue';

export default {
	components: { CloseButton },
	props: {
		isCentered: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	computed: {
		classes () {
			let classes = '';

			if (this.isCentered) classes += 'is-centered ';

			return classes;
		},
	},
};
</script>

<style scoped lang='scss'>
  $gap: 16px;
  $padding: 20px;

  .header {
    padding: $padding;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 40px;
    gap: $gap;
    border-bottom: 1px solid rgba(0, 0, 0, .2);

    &-content {
      display: flex;
      align-items: center;
    }

    &.is-centered {
      .header-content {
        padding-left: calc(#{$padding} + #{$gap});
        justify-content: center;
        text-align: center;
      }
    }

    .close-action-wrapper {
      width: 100%;
      height: 100%;
      position: relative;

      .close-action {
        position: absolute;
        right: 0;
        top: -6px;
      }
    }
  }
</style>
