<template>
  <div :class="['field-wrapper', !!errorMessage ? 'error' : undefined, isRounded ? 'is-rounded' : undefined]">
    <div :class="{'field-inner': true, 'with-icon': hasIcon}">
      <div
        v-if="hasIcon"
        class="icon"
      >
        <slot name="inputIcon" />
      </div>
      <slot name="beforeInput" />
      <input
        v-bind="$attrs"
        :id="id"
        class="field"
        :type="type"
        :disabled="disabled"
        @input="$emit('on:update', $event.target.value)"
        @keyup.enter="onKeyupEnter"
      >
    </div>
    <ErrorMessage v-if="isErrorMessageVisible && !!errorMessage">
      {{ errorMessage }}
    </ErrorMessage>
  </div>
</template>

<script>
import ErrorMessage from './ErrorMessage.vue';

export default {
	components: { ErrorMessage },
	props: {
		type: {
			type: String,
			default: 'text',
			required: false,
		},
		id: {
			type: String,
			required: false,
		},
		errorMessage: {
			type: String,
			required: false,
		},
		isErrorMessageVisible: {
			type: Boolean,
			required: false,
			default: true,
		},
		isRounded: {
			type: Boolean,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
		},
	},
	emits: ['keyupEnter', 'on:update'],
	computed: {
		hasIcon () {
			return !!this.$slots.inputIcon;
		},
	},
	methods: {
		onKeyupEnter (event) {
			this.$emit('keyupEnter', event);
		},
	},
};
</script>

<style scoped lang='scss'>
  .field-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    &.error {
      .field {
        box-shadow: 0 0 0 1px rgba(241, 40, 72, .20);

        &:not(:disabled) {
          &:hover {
            box-shadow: 0 0 0 1px rgba(241, 40, 72, .50);
          }

          &:focus {
            box-shadow: 0 0 0 2px $red;
          }
        }
      }
    }

    .field {
      &:disabled {
        opacity: .5;
        cursor: default;
      }

      &:not(:disabled) {
        &:hover {
          box-shadow: 0 0 0 1px rgba(113, 18, 255, .50);
        }

        &:focus {
          box-shadow: 0 0 0 2px $violet;
        }
      }
    }

    &.is-rounded {
      .field {
        border-radius: 24px;
      }
    }
  }

  .field-inner {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    &.with-icon {
      .field {
        padding-left: 36px;
        padding-right: 15px;
      }

      .icon {
        position: absolute;
        left: 10px;
        display: flex;
      }
    }
  }

  .field {
    outline: none;
    padding: 8px 12px;
    border: 1px none transparent;
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    border-radius: 10px;
    background-color: $white;
    color: $grey;
    transition: 200ms cubic-bezier(.215, .61, .355, 1);
    box-shadow: 0 0 0 1px rgba(113, 18, 255, .20);
    width: 100%;
  }
</style>
