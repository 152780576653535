<template>
  <div class="edit-announcement__input-wrapper">
    <div
      v-if="props.label"
      class="text-body-bold text-body-16"
    >
      {{ props.label }}
    </div>
    <div class="edit-announcement__input-wrapper-input">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
	label: String,
});
</script>

<style lang="scss">
.edit-announcement__input-wrapper {
  width: 100%;
  @include flex(column, flex-start, flex-start);
  row-gap: 8px;

  &-input {
    width: 100%;
  }
}
</style>
