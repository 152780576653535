<template>
  <div class="custom-collection-cell">
    <Media
      v-if="props.collection.avatarUrl"
      :src="props.collection.avatarUrl"
      :placeholder-src="placeholderAvatarUrl"
      class="custom-collection-cell__media"
    />
    <img
      v-else
      class="custom-collection-cell__media custom-collection-cell__media-placeholder"
      :src="placeholderAvatarUrl"
    >
    <div class="custom-collection-cell__wrapper">
      <VerificationLabel
        :name="props.collection.name || 'Unnamed'"
        :is-verified="props.collection.isVerified"
      />
      <CollectionsRow
        v-if="props.collection.contractAddresses?.length && !props.collection.name"
        :container-id="props.collection.id"
        :collections="props.collection.contractAddresses"
        class="custom-collection-cell__collection-row"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';

import Media from '@/components/common/Media.vue';
import VerificationLabel from '@/components/ui/VerificationLabel.vue';
import CollectionsRow from '@/pages/collections/components/CollectionsRow';

const placeholderAvatarUrl = require('@/images/avatar-placeholder.png');

const props = defineProps({
	collection: {
		type: Object,
		required: true,
	},
});
</script>

<style lang="scss" scoped>
.custom-collection-cell {
  display: flex;
  flex-direction: row;
  column-gap: 10px;

  &__media {
    width: 40px;
    height: 40px;

    border-radius: 8px;

    :deep(img) {
      border-radius: 8px;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 8px;
  }
}
</style>
