<template>
  <div
    v-if="visible || checked"
    :class="{'check-button': true, 'checked': checked, 'with-position': withPosition}"
    @click="handleCheck"
  >
    <CheckPixelIcon v-if="isIcon" />
  </div>
</template>

<script>
import { CheckPixelIcon } from '@/components/icons';

export default {
	components: {
		CheckPixelIcon,
	},

	props: {
		visible: {
			type: Boolean,
			default: true,
			require: false,
		},
		withPosition: {
			type: Boolean,
			default: false,
		},
		checked: {
			type: Boolean,
			default: false,
		},
		isIcon: {
			type: Boolean,
			default: true,
		},
	},

	emits: ['changeCheck'],

	methods: {
		handleCheck (e) {
			e.preventDefault();
			e.stopPropagation();

			this.$emit('changeCheck');
		},
	},
};
</script>

<style scoped lang="scss">
.check-button {
  width: 40px;
  height: 40px;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $violet;
  //box-shadow: var(--value-shadow-base1) var(--color-shadow-base0);
  @include defaultTransition(all);
  cursor: pointer;
  z-index: 1;

  @media (any-hover: hover) {
    &:hover {
      background-color: #7112ff0f;
    }
  }

  &:active {
    background-color: var(--color-shade60);
  }

  &.checked {
    background-color: $violet;
    border-color: var(--color-accent2);
  }
}

.with-position {
  position: absolute;
  left: 8px;
  top: 8px;
}
</style>
