<template>
  <router-link
    v-slot="{isExactActive}"
    class="nav-link"
  >
    <ListItem
      class="nav-link-item"
      color="violet"
      :is-active="isExactActive"
    >
      <IconText>
        <slot name="icon" />
        <template #content>
          <slot />
        </template>
      </IconText>
    </ListItem>
  </router-link>
</template>

<script>
import IconText from '@/components/ui/IconText';
import { ListItem } from '@/components/ui/list';

export default {
	components: {
		ListItem,
		IconText,
	},
};
</script>

<style scoped lang='scss'>
  .nav-link {
    display: block;

    &-item {
      padding: 15px 20px;
      font-weight: 500;
      background-color: transparent !important;

      &.active {
        font-weight: 600;
      }
    }
  }
</style>
