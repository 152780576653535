<template>
  <header
    class="header"
    :class="{'is-full-width': isFullWidth}"
  >
    <Logo
      v-if="isFullWidth"
      class="logo"
    />
    <Profile />
  </header>
</template>

<script>
import Logo from '../../components/Logo.vue';
import Profile from './components/Profile.vue';

export default {
	components: {
		Logo,
		Profile,
	},
	props: {
		isFullWidth: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
};
</script>

<style scoped lang='scss'>
  .header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 60px;
    padding: 0 15px;
    box-shadow: inset 0 -1px 0 0 rgba(113, 18, 255, .20);
    position: fixed;
    top: 0;
    left: 270px;
    right: 0;
    background-color: $white;
    z-index: 1000;

    &.is-full-width {
      left: 0;
      width: 100%;
    }

    .logo {
      margin-right: 20px;
    }
  }
</style>
