<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="4"
    viewBox="0 0 18 4"
    fill="none"
  >
    <path
      d="M10.9 2C10.9 3 10 3.9 9 3.9 8 3.9 7.1 3 7.1 2 7.1 1 8 0.1 9 0.1 10 0.1 10.9 1 10.9 2ZM15.6 0.1C14.5 0.1 13.7 1 13.7 2 13.7 3 14.5 3.9 15.6 3.9 16.6 3.9 17.4 3 17.4 2 17.4 1 16.6 0.1 15.6 0.1ZM2.4 0.1C1.4 0.1 0.6 1 0.6 2 0.6 3 1.4 3.9 2.4 3.9 3.5 3.9 4.3 3 4.3 2 4.3 1 3.5 0.1 2.4 0.1Z"
      fill="currentColor"
    />
  </svg>
</template>
