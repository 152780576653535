import requests from './requests.service';

const baseUrl = process.env.VUE_APP_ADMIN_PANEL_BASE_URL;
const basePath = `${baseUrl}/admin/v1/nfts`;

export const nftsService = {
	getList: params => requests.get(basePath, { params }),
	banNft: id => requests.post(`${basePath}/${id}/ban`),
	unbanNft: id => requests.delete(`${basePath}/${id}/ban`),
	refreshMetadata: id => requests.post(`${basePath}/${id}/refresh`),
};
