<template>
  <div
    class="drop-card"
    :class="{'hoverable': hoverable}"
  >
    <img
      v-if="imageUrl"
      :src="imageUrl"
      class="drop-card-background"
    >
    <div class="content-container">
      <BlockchainIdentifier
        v-if="provider"
        class="blockchain-identifier"
        is-light
        :provider="provider"
      />

      <div class="creator-name">
        <span>{{ username }}</span>
      </div>
      <h3 class="title">
        {{ title }}
      </h3>
      <div
        v-if="isWithDropState"
        class="drop-state-wrapper"
      >
        <DropState
          v-if="currentStatus"
          small-font
          :status="currentStatus"
          :end-date="endDate"
          :start-date="startDate"
          :seconds-remaining="secondsRemaining"
          @on-update="onStatusUpdate"
          @on-distance-update="$emit('onTimerUpdate', $event)"
        />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import BlockchainIdentifier from '@/components/ui/BlockchainIdentifier.vue';

import DropState from '../../../components/DropState.vue';

export default {
	components: {
		DropState,
		BlockchainIdentifier,
	},
	props: {
		username: { type: String, required: true },
		title: { type: String, required: true },
		status: { type: String, required: true },
		startDate: { type: [Date, String, Number], required: false },
		endDate: { type: [Date, String, Number], required: false },
		secondsRemaining: { type: Number, required: false },
		imageUrl: String,
		provider: String,
		isWithDropState: {
			type: Boolean,
			required: false,
			default: true,
		},
		hoverable: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	emits: ['onTimerUpdate'],
	data () {
		return {
			currentStatus: this?.status,
		};
	},
	methods: {
		onStatusUpdate (status) {
			if (this.isWithDropState) {
				this.currentStatus = status;
			}
		},
	},
};
</script>

<style scoped lang='scss'>
  .drop-card {
    min-height: 385px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
    border-radius: 30px;
    position: relative;

    &-background {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: inherit;
    }

    &.hoverable {
      .content-container {
        &:hover {
          background-color: rgba(0, 0, 0, .25);
        }
      }
    }

    .content-container {
      width: 100%;
      height: 100%;
      padding: 60px 20px;
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, .5);
      transition: background-color 200ms cubic-bezier(.215, .61, .355, 1);

      .blockchain-identifier {
        padding: 10px;
      }

      .creator-name {
        margin-bottom: 10px;
        opacity: 0.5;
        font-size: 16px;
        line-height: 1em;
        word-break: break-word;
      }

      .title {
        margin-bottom: 20px;
        font-size: 30px;
        line-height: 1.1;
        font-weight: 600;
        word-break: break-word;
      }

      .drop-state-wrapper {
        margin-bottom: 20px;
      }
    }
  }
</style>
