<template>
  <Header is-full-width />
  <Main class="main">
    <router-view />
  </Main>
</template>

<script>
import Main from '../components/Main.vue';
import Header from '../components/header/index.vue';

export default {
	components: {
		Main,
		Header,
	},
};
</script>

<style scoped lang='scss'>
  .main {
    overflow: unset;
    min-height: 100vh;
  }
</style>
