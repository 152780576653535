<template>
  <div>
    {{ labelDate }}
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import {
	formatDate,
} from '@/utility/datetime.js';

const props = defineProps({
	date: String,
});

const labelDate = computed(() => props.date && formatDate(props.date, 'MMM d, hh:mm aa'));
</script>
