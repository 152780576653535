<template>
  <TitledPage :title="`Banned (${formatNumber(counter, 0)})`">
    <TabsQueryRouter
      v-slot="{ activeTab }"
      :items="tabs"
      :is-sticky="false"
    >
      <TabsContentContainer class="report-tabs__container">
        <component :is="renderTabContentByType(activeTab)" />
      </TabsContentContainer>
    </TabsQueryRouter>
  </TitledPage>
</template>

<script>
import { has } from 'lodash';
import { mapActions, mapState } from 'vuex';

import { TabsQueryRouter, TabsContentContainer } from '@/components/common/tabs';

import TitledPage from '@/layouts/components/TitledPage';

import { formatNumber } from '@/utility';

import BannedList from './sections/BannedList.vue';

export default {
	components: {
		TitledPage,
		TabsQueryRouter,
		TabsContentContainer,
	},
	data () {
		return {
			activeTab: 'nft',
			tabs: [
				{ label: 'Users', value: 'user' },
				{ label: 'NFTs', value: 'nft' },
				{ label: 'Collections', value: 'collection' },
			],
			formatNumber,
		};
	},
	computed: {
		...mapState({
			counter: state => state.banned.totalCount,
		}),
		renderTabContentByType () {
			return type => {
				this.activeTab = type;
				return BannedList;
			};
		},
	},
	watch: {
		activeTab (value) {
			const query = { ...this.$route.query };
			if (has(query, 'search')) delete query.search;
			this.$router.replace({ query });
			this.changeActiveTab(value);
		},
	},
	methods: {
		...mapActions('banned', ['changeActiveTab']),
	},
};
</script>

<style scoped lang="scss">
.report-tabs__container {
  padding-top: 24px;
}
</style>
