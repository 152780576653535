<template>
  <div
    ref="tabsMenu"
    class="tabs-menu"
    :class="{'is-sticky': isSticky, 'centered': isCentered, 'is-pinned': isPinned}"
    :style="{top: stickyOffset}"
  >
    <slot />
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, provide, ref } from 'vue';

import usePositionStickyDetection from '@/composables/usePositionStickyDetection.js';

const props = defineProps({
	modelValue: {
		type: [String, Number],
	},
	isSticky: {
		type: Boolean,
		required: false,
		default: true,
	},
	stickyOffset: {
		type: [String, Number],
		required: false,
		default: '70px',
	},
	isCentered: Boolean,
});

const emit = defineEmits(['update:modelValue']);

const active = computed(() => props.modelValue);
const tabs = ref([]);

const selectTab = tab => {
	emit('update:modelValue', tab);
};

provide('tabsState', {
	active,
	tabs,
	selectTab,
});

const tabsMenu = ref(null);
const isPinned = usePositionStickyDetection({
	element: tabsMenu,
	topOffset: parseInt(props.stickyOffset, 10),
});
</script>

<style scoped lang="scss">
.tabs-menu {
	display: flex;
	border-bottom: 1px solid var(--color-shade70);
	top: 0;
	z-index: 13;
	overflow-x: auto;

  //&.is-pinned {
  //  @supports ((-webkit-backdrop-filter: blur(12px)) or (backdrop-filter: blur(12px))) {
  //    background-color: rgba(23, 23, 23, 0.7);
  //    -webkit-backdrop-filter: blur(12px);
  //    backdrop-filter: blur(12px);
  //    border-bottom: none;
  //  }
  //}

	&.centered {
		@include screen-md() {
			justify-content: center;
		}
	}

  &.is-sticky {
    position: sticky;

    &.is-pinned {
      @supports ((-webkit-backdrop-filter: blur(64px)) or (backdrop-filter: blur(64px))) {
        background-color: rgba(23, 23, 23, 0.7);
        -webkit-backdrop-filter: blur(64px);
        backdrop-filter: blur(64px);
        border-bottom: none;
      }
    }
  }
}
</style>
