import { isAfter, isBefore } from 'date-fns';

import { requiredString, stringSchema } from '@/components/common/form';
import { datePattern12, ethereumAddressPattern } from '@/utility';

import { formatAddressListToArray, hasDuplicates } from './formatters';

export const baseAccessListValidation = stringSchema.test(
	'is-address-invalid',
	'',
	(value, ctx) => {
		const list = formatAddressListToArray(value);

		if (!list.every(item => ethereumAddressPattern.test(item?.line))) {
			return ctx.createError({
				message: 'Some of the addresses are not valid',
			});
		}

		if (hasDuplicates(list, 'line')) {
			return ctx.createError({
				message: 'There are duplicate addresses in the list',
			});
		}

		return true;
	},
);

export const dateValidation = requiredString().matches(datePattern12, {
	message: 'Must be a MM-DD-YYYY HH:mm a',
	excludeEmptyString: true,
});

export const baseEndsAtValidation = dateValidation.when('startsAt', (startDate, schema) => {
	const isValidStart = datePattern12.test(startDate);

	if (isValidStart) {
		return schema.test(
			'later-than-start',
			'End date cannot be later than start date',
			value => {
				const isValidEnd = datePattern12.test(value);

				if (isValidEnd) {
					const start = new Date(startDate);
					const end = new Date(value);

					return !isAfter(start, end);
				}

				return true;
			},
		);
	}

	return schema;
});

export const baseAccessEndsAtValidation = stringSchema.when('accessStartsAt', (startDate, schema) => {
	const isValidStart = datePattern12.test(startDate);

	if (isValidStart) {
		return schema.test(
			'later-than-start',
			'End date cannot be erlier than start date',
			value => {
				const isValidEnd = datePattern12.test(value);

				if (isValidEnd) {
					const start = new Date(startDate);
					const end = new Date(value);

					return !isAfter(start, end);
				}

				return true;
			},
		);
	}

	return schema;
});

export const accessStartValidation = stringSchema.when('startsAt', (startDate, schema) => {
	const isValidStart = datePattern12.test(startDate);

	if (isValidStart) {
		return schema.test(
			'sooner-than-start',
			'Start Time cannot be sooner than start date',
			value => {
				const isValidAccessStart = datePattern12.test(value);

				if (isValidAccessStart) {
					const start = new Date(startDate);
					const accessStart = new Date(value);

					return !isAfter(start, accessStart);
				}

				return true;
			},
		);
	}
	return schema.test(
		'later-than-start',
		'Required start date field',
		(value) => !value,
	);
});

export const accessEndValidation = baseAccessEndsAtValidation.when('endsAt', (endDate, schema) => {
	const isValidEnd = datePattern12.test(endDate);

	if (isValidEnd) {
		return schema.test(
			'later-than-end',
			'End Time cannot be later than end date',
			value => {
				const isValidAccessEnd = datePattern12.test(value);

				if (isValidAccessEnd) {
					const end = new Date(endDate);
					const accessEnd = new Date(value);

					return !isBefore(end, accessEnd);
				}

				return true;
			},
		);
	}
	return schema.test(
		'later-than-start',
		'Required end date field',
		(value) => !value,
	);
});
