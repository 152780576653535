import mutations from './mutations';

const state = {
	isAllChecked: false,
	isForcedReset: false,
	listToTrash: [],
	currentList: undefined,
	isNotDataAll: true,
	collection: null,
	context: null,
};

export default {
	namespaced: true,
	state,
	mutations,
};
