<template>
  <LoadingSpinnerIcon v-if="loading" />
  <EditCollection
    v-if="!loading && collection"
    :collection="collection"
    :identifier="identifier"
  />
  <Collaborators
    v-if="!loading && collaborators"
    :collaborators="collaborators"
  />
</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

import EditCollection from '@/pages/collections/edit/components/EditCollection';
import Collaborators from '@/pages/collections/edit/components/Collaborators';
import LoadingSpinnerIcon from '@/components/icons/LoadingSpinnerIcon';

export default {
	components: {
		LoadingSpinnerIcon,
		EditCollection,
		Collaborators,
	},
	props: {
		identifier: { type: String, required: true },
	},
	setup (props) {
		const store = useStore();

		const collection = computed(() => store.state.editCollection.collection);
		const collaborators = computed(() => store.state.editCollection.collaborators);
		const loading = computed(() => store.state.editCollection.loading);

		onMounted(() => {
			store.dispatch('editCollection/getCollection', { id: props.identifier });
		});
		return {
			collection,
			collaborators,
			loading,
		};
	},
};
</script>
