<template>
  <Form
    :loading="isLoading"
    :textarea-items="items"
    :is-button-disabled="isSaving"
    placeholder="Enter an address"
    @submit="onSubmit"
  />
</template>

<script setup>
import { settingsService } from '@/services';

import Form from '../components/Form.vue';
import { useSettingsList } from '../hooks/useSettingsList';

const { items, isLoading, isSaving, onSubmit } = useSettingsList({
	getList: settingsService.getNotableCollections,
	updateList: settingsService.updateNotableCollections,
});
</script>
