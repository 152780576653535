import { ref, onMounted } from 'vue';

import { dropsService } from '@/services';

const useDropsList = status => {
	const isLoading = ref(false);
	const list = ref([]);
	const page = ref(null);

	const addToList = data => {
		list.value = [...list.value, ...data];
	};

	const getResponseList = data => data?.drops || [];

	const getList = async data => {
		const isInit = data?.isInit != null ? data.isInit : true;

		isLoading.value = true;

		if (isInit && list.value.length !== 0) {
			list.value = [];

			if (page.value) {
				page.value = null;
			}
		}

		try {
			if (status === 'ended' || status === 'trash') {
				const params = {
					limit: 20,
					cursor: page.value === null ? undefined : page.value,
				};

				const res = status === 'ended' ? await dropsService.getEnded(params) : await dropsService.getTrash(params);

				if (res) {
					const resValue = getResponseList(res);

					if (isInit) {
						list.value = resValue;
					} else {
						addToList(resValue);
					}

					page.value = res?.cursorNext || null;
				}
			} else {
				const res = await dropsService.getList({ status });

				if (res) {
					list.value = getResponseList(res);
				}
			}
		} finally {
			isLoading.value = false;
		}
	};

	onMounted(async () => {
		await getList();
	});

	return {
		page,
		list,
		getList,
		isLoading,
	};
};

export default useDropsList;
