<template>
  <div class="wrapper">
    <LogoIcon class="logo" />
    <Button
      :disabled="isLoading"
      @click="handleConnect"
    >
      {{ connectWalletText }}
    </Button>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { LogoIcon } from '@/components/icons';
import Button from '@/components/ui/Button';

export default {
	components: {
		Button,
		LogoIcon,
	},
	computed: {
		...mapState({
			ethereum: state => state.ethers,
			isLoading: state => state.auth.isLoading,
		}),
		connectWalletText () {
			return this.ethereum.hasLocalProvider ? 'Connect to your Wallet' : 'Install a new Wallet';
		},
	},
	methods: {
		...mapActions('ethers', ['connectWallet']),
		...mapActions('auth', ['login']),
		async handleConnect () {
			if (this.ethereum.hasLocalProvider) {
				await this.connectWallet({
					onConnected: this.onConnected,
				});
			} else {
				window.open('https://www.metamask.io', '_blank');
			}
		},

		async onConnected () {
			await this.login({ address: this.ethereum.address });
		},
	},
};
</script>

<style scoped lang='scss'>
.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logo {
    margin-bottom: 20px;
  }
}
</style>
