import * as yup from 'yup';
import uniq from 'lodash/uniq';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';
import {
	url,
	trim,
	range,
	email,
	object,
	maxString,
	stringSchema,
	ethereumAddress,
	nullableEthereumAddress,
} from '@/components/common/form/formValidation';
import { ethereumAddressPattern } from '@/utility';
import { FORM_FIELD_ERRORS } from '@/constants/errors';
import { CONTAINER_OPTIONS } from '@/pages/collections/constants';
import { containerRenderTypeOptions } from '@/pages/collections/add-container/sections/helpers';

export const defaultValues = {
	socialLinks: [],
	websiteUrl: '',
	name: '',
	avatarUrl: '',
	bannerUrl: '',
	description: '',
	isVerified: false,
	slug: '',
	actionType: containerRenderTypeOptions[0],
	splitAddresses: [''],
	ranges: [''],
	draftAddress: '',
};

function initLinks (values) {
	const links = [];

	if (!isEmpty(values)) {
		for (const key in values) {
			forEach(uniq(values[key]), link => links.push({
				link,
				type: { label: startCase(key), value: lowerCase(key) },
			}));
		}
	}

	return links;
}

export const initValues = (dataset = null) => {
	if (isEmpty(dataset)) {
		return defaultValues;
	}
	return {
		socialLinks: initLinks(dataset.socialLinks),
		websiteUrl: dataset.websiteUrl || defaultValues.websiteUrl,
		name: dataset.name || defaultValues.name,
		avatarUrl: dataset.avatarUrl || defaultValues.avatarUrl,
		bannerUrl: dataset.bannerUrl || defaultValues.bannerUrl,
		description: dataset.description || defaultValues.description,
		isVerified: dataset.isVerified || dataset.verified || defaultValues.isVerified,
		slug: dataset?.slug || defaultValues.slug,
		actionType: defaultValues.actionType,
		splitAddresses: dataset.collections?.map(item => item.contractAddress) || defaultValues.splitAddresses,
		ranges: dataset.collections?.map(item => item.ranges) || defaultValues.ranges,
		draftAddress: dataset.draftAddress || defaultValues.draftAddress,
	};
};

export const getValidationSchema = ({ isEdit }) => object({
	websiteUrl: url,
	description: stringSchema.concat(maxString(1000)),
	name: stringSchema.required('Required field').concat(trim).concat(maxString(256)),
	socialLinks: yup.array().of(yup.object({
		link: stringSchema
			.when('type.value', value => value === 'email' ? email() : url),
	})),
	slug: stringSchema.required('Required field').concat(maxString(256)).concat(trim).test({
		name: 'testSlug',
		test (value) {
			if (ethereumAddressPattern.test(value)) {
				return this.createError({
					path: 'slug',
					message: 'Wrong format. Must not contain Ethereum address',
				});
			}
			if (!value?.match(/^[0-9a-z-]*$/)) {
				return this.createError({
					path: 'slug',
					message: FORM_FIELD_ERRORS.FORM_SLUG_FIELD_WRONG_FORMAT,
				});
			}
			return true;
		},
	}),
	actionType: yup.object(),
	ranges: yup.array()
		.when(
			'actionType.value',
			(value, field) => value === CONTAINER_OPTIONS.SPLIT
				? field.of(stringSchema.required('Fill in the input field').concat(range('Wrong format. Example: 10;26;35;100-456')))
				: field,
		),
	splitAddresses: yup.array()
		.when(
			'actionType.value',
			(value, field) => value === CONTAINER_OPTIONS.SPLIT
				? field.of(stringSchema.required('Fill in the input field').concat(ethereumAddress('Wrong format')))
				: field,
		),
	draftAddress: isEdit
		? stringSchema.concat(nullableEthereumAddress('Wrong format'))
		: stringSchema
			.when(
				'actionType.value',
				(value, field) => value === CONTAINER_OPTIONS.DRAFT
					? field.concat(nullableEthereumAddress('Wrong format'))
					: field,
			),
});

function getSocialLinks (values) {
	let socialLinks = {};

	if (!isEmpty(values)) {
		socialLinks = values.reduce((acc, val) => {
			if (!acc[val.type.value]) acc[val.type.value] = [];

			if (val.link.length > 0) acc[val.type.value].push(val.link);

			return acc;
		}, {});
	}

	return socialLinks;
}

export const submitFormValues = async (data, values) => {
	if (!data?.update && !values) return;

	const { update, actionType } = data;

	const dataset = {
		name: isEmpty(values.name) ? null : values.name,
		description: isEmpty(values.description) ? null : values.description,
		avatarUrl: values.avatarUrl || null,
		bannerUrl: values.bannerUrl || null,
		slug: values?.slug || null,
		websiteUrl: isEmpty(values.websiteUrl) ? null : values.websiteUrl,
		socialLinks: getSocialLinks(values.socialLinks),
		verified: values.isVerified,
	};

	switch (actionType) {
		case CONTAINER_OPTIONS.MERGE:
			if (data.mergeCollections) {
				dataset.contractAddresses = data.mergeCollections.map(item => item.contractAddress);
			}
			break;
		case CONTAINER_OPTIONS.SPLIT:
			dataset.ranges = Object.values(values.ranges);
			dataset.contractAddresses = Object.values(values.splitAddresses);
			break;
		case CONTAINER_OPTIONS.DRAFT:
			dataset.replacementContractAddress = values.draftAddress || null;
	}

	await update(dataset);
};
