<template>
  <ConfirmationModal
    :title="title"
    :accept-button-props="{ color: 'black' }"
    :cancel-button-props="{ color: 'black' }"
    :disable-btns="loading"
    accept-text="Accept"
    @on-accept="onAccept"
  >
    <div class="accept-message">
      {{ message }}
    </div>
  </ConfirmationModal>
</template>

<script setup>
import { ref, useAttrs } from 'vue';
import { useStore } from 'vuex';
import { ConfirmationModal } from '@/components/common/modal';
import { refLoadingWrapper } from '@/utility';

const store = useStore();

const attrs = useAttrs();

const { ids, title, message, callback } = attrs.modalProps;

const loading = ref(false);

const onAccept = async () => {
	await refLoadingWrapper(
		async () => {
			await store.dispatch('reports/acceptReports', ids);
		},
		loading,
	);
	store.dispatch('modal/closeModal');
	callback?.();
};
</script>

<style lang="scss" scoped>
.accept-message {
  max-width: 360px;
  padding: 20px 25px;
}
</style>
