<template>
  <BaseDropdown
    is-disable-hover-active
    centered
  >
    <template #default="{opened}">
      <IconButton
        class="action"
        :class="{'active': opened}"
      >
        <DotsRowIcon />
      </IconButton>
    </template>
    <template #dropdown="{close}">
      <slot :close="close" />
    </template>
  </BaseDropdown>
</template>

<script>
import IconButton from '@/components/ui/IconButton';
import { DotsRowIcon } from '@/components/icons';
import { BaseDropdown } from '@/components/ui/baseDropdown';

export default {
	components: {
		IconButton,
		DotsRowIcon,
		BaseDropdown,
	},
};
</script>

<style scoped lang='scss'>
  .action {
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 12%);
    background-color: $white;
    border-radius: 40px;
    z-index: 100;

    &:hover, &.active {
      background-color: $white;
      box-shadow: 0 14px 40px 0 rgb(0 0 0 / 25%);
    }

    &:active {
      transform: scale(.95);
    }
  }
</style>
