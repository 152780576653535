export const genUniqueKey = () => `_${Math.random().toString(36).substr(2, 9)}`;

export const generateGrid = (columns, gap = '20px') => {
	const foundColumn = columns.find(({ width }) => !!width);

	let columnString = '';

	if (!foundColumn) {
		columnString = `repeat(${columns.length}, minmax(150px, 1fr))`;
	} else {
		columnString = columns.map(({ width }) => {
			if (!width) {
				return 'minmax(150px, 1fr)';
			}

			return width;
		}).join(' ');
	}

	return {
		gap,
		display: 'grid',
		gridTemplateColumns: columnString,
	};
};

export const detectChanges = (initState, formFields) => {
	const updated = {};
	for (const key of Object.keys(formFields)) {
		if (initState[key] !== formFields[key]) {
			updated[key] = formFields[key];
		}
	}

	return updated;
};

export const isCombinedCollection = (address) => address?.split(':')?.[0] === 'cn';

export const isSplitContainer = (address) => address?.split(':')?.[1] === 's';
