<template>
  <div class="edit-form__top-actions">
    <RefreshButton
      :width="268"
      label="Get collection metadata from OS"
      :disabled="isRefreshingCollectionMetadata"
      @click="refreshCollectionMetadata"
    />
    <RefreshButton
      :width="228"
      label="Refresh all NFTs metadata"
      :disabled="isRefreshingNFTsMetadata"
      @click="refreshNFTsMetadata"
    />
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue';

import { collectionsService } from '@/services';
import { addSuccess } from '@/components/utility/notifications';
import RefreshButton from '@/components/common/button/RefreshButton';

const props = defineProps({
	collectionId: { type: String, required: true },
	collectionAddress: { type: String, required: true },
});

const isRefreshingNFTsMetadata = ref(false);

const refreshNFTsMetadata = async () => {
	isRefreshingNFTsMetadata.value = true;

	try {
		const response = await collectionsService.refreshNFTsMetadata(props.collectionId);
		if (response?.status === 202) {
			addSuccess('We have started to update the NFTs metadata. This may take a while, please check the results a bit later.');
		}
	} catch (error) {
		console.error(error);
	}

	isRefreshingNFTsMetadata.value = false;
};

const isRefreshingCollectionMetadata = ref(false);

const refreshCollectionMetadata = async () => {
	isRefreshingCollectionMetadata.value = true;

	try {
		const response = await collectionsService.refreshMetadataFromOpenSea([props.collectionAddress]);
		if (response?.status === 202) {
			addSuccess('We have started to update the Collection metadata. This may take a while, please check the results a bit later.');
		}
	} catch (error) {
		console.error(error);
	}

	isRefreshingCollectionMetadata.value = false;
};
</script>

<style lang="scss" scoped>
.edit-form__top-actions {
  display: flex;
  gap: 15px;
}
</style>
