import { isEmpty, isString } from 'lodash';

import {
	minMaxString,
	object,
	requiredString,
	requiredMixed,
	leadingZeros,
	positiveNumber,
	trim,
	stringSchema,
	maxNumber,
	integerNumber,
	requiredArray,
} from '@/components/common/form';
import { formatDate } from '@/utility';
import { providerTypes } from '@/pages/drops/helpers';

import {
	formatAddressListToString,
	formatAddressListToArray,
	baseAccessListValidation,
	dateValidation,
	baseEndsAtValidation,
	formatNftListToRequest,
} from '../../../helpers';

import { convertDateFormat } from '@/pages/drops/details/components/eth-container/helpers/convertDateFormat';

export const defaultValues = {
	collectionName: '',
	creatorName: '',
	title: '',
	startsAt: '',
	endsAt: '',
	description: '',
	access: '',
	issue: 'numbered',
	maxAllocation: '',
	thumbnail: '',
	banner: '',
	accessList: '',
	type: null,
	nfts: [],
	removeNFTs: [],
	acceptWhitelist: false,
};

export const validationSchema = object({
	thumbnail: requiredString(),
	banner: requiredString(),
	collectionName: requiredString().concat(minMaxString(1, 100)).concat(trim),
	creatorName: requiredString().concat(minMaxString(1, 50)).concat(trim),
	title: requiredString().concat(minMaxString(1, 100)).concat(trim),
	description: requiredString().concat(minMaxString(1, 500)).concat(trim),
	access: requiredMixed().oneOf(['public'], 'No value selected'),
	issue: requiredMixed().oneOf(['numbered', 'unnumbered'], 'No value selected'),
	maxAllocation: positiveNumber
		.concat(integerNumber)
		.concat(leadingZeros())
		.concat(maxNumber(99999)),
	startsAt: dateValidation,
	endsAt: baseEndsAtValidation,
	accessList: stringSchema.when('acceptWhitelist', {
		is: value => value,
		then: requiredString().concat(baseAccessListValidation),
	}),
	// eslint-disable-next-line no-template-curly-in-string
	nfts: requiredArray().min(1, 'Must have at least ${min} items'),
});

export const getInitialValues = details => {
	if (details && !isEmpty(details)) {
		const dateToString = date => formatDate(date, 'MM-dd-yyyy HH:mm');

		const accessList = details?.accessList || [];

		return {
			...defaultValues,
			accessList: formatAddressListToString(accessList),
			acceptWhitelist: accessList.length !== 0,
			creatorName: details?.creator?.username || '',
			collectionName: details?.collection?.name || '',
			title: details?.title || '',
			description: details?.description || '',
			issue: details?.issueType || defaultValues.issue,
			maxAllocation: details?.maxAllocation || '',
			access: details?.access || '',
			thumbnail: details?.thumbnailUrl || '',
			banner: details?.bannerUrl || '',
			startsAt: details?.startsAt ? dateToString(details.startsAt) : '',
			endsAt: details?.endsAt ? dateToString(details.endsAt) : '',
			nfts: details?.nfts || [],
		};
	}

	return defaultValues;
};

export const mapFormToRequestData = (data, isNew) => {
	const maxAllocation = isString(data.maxAllocation) ? parseInt(data.maxAllocation, 10) || 0 : data.maxAllocation;

	return {
		deploy: data?.type === 'deploy',
		drop: {
			provider: providerTypes.ETHEREUM,
			creatorUsername: data?.creatorName,
			title: data?.title,
			description: data?.description,
			access: data?.access,
			issueType: data?.issue,
			maxAllocation,
			thumbnailUrl: data?.thumbnail,
			bannerUrl: data?.banner,
			startsAt: data?.startsAt ? convertDateFormat(data.startsAt) : undefined,
			endsAt: data?.endsAt ? convertDateFormat(data.endsAt) : undefined,
			collectionName: data?.collectionName,
			accessList: data?.acceptWhitelist ? formatAddressListToArray(data?.accessList).map(item => item.line) : undefined,
		},
		nfts: formatNftListToRequest(data?.nfts),
		removeNFTIds: !isNew ? (data?.removeNFTs || []) : undefined,
	};
};
