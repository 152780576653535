import { createToast, clearToasts } from 'mosha-vue-toastify';

const createNotification = (message, variant, timeout = 5000) => createToast(message, {
	timeout,
	type: variant,
	transition: 'slide',
	hideProgressBar: true,
	position: 'bottom-left',
});

export const addError = (message, timeout) => createNotification(message, 'danger', timeout);

export const addSuccess = (message, timeout) => createNotification(message, 'success', timeout);

export const addWarning = (message, timeout) => createNotification(message, 'warning', timeout);

export const addDefault = (message, timeout) => createNotification(message, 'default', timeout);

export const clearAllNotifications = () => clearToasts();
