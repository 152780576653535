import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { isEmpty } from 'lodash';

import { dropsService } from '@/services';
import routes from '@/router/routes';

import { providerList, providerTypes } from '../../helpers';

export const useDetails = id => {
	const hasData = ref(false);
	const initialValues = ref(null);
	const status = ref(null);
	const isLoading = ref(false);
	const isSubmitLoading = ref(false);
	const isNew = ref(providerList.includes(id));
	const provider = ref(isNew.value ? id : null);
	const notEditable = ref(false);
	const drop = ref(null);

	const { replace } = useRouter();

	const getDetails = async (showLoading = true) => {
		if (showLoading) {
			isLoading.value = true;
		}

		try {
			if (!isNew.value) {
				const [detailsResponse, nftsListResponse] = await Promise.all([
					dropsService.getDetails(id),
					dropsService.getDetailsNfts(id),
				]);

				if (detailsResponse) {
					drop.value = detailsResponse;
					hasData.value = detailsResponse && !isEmpty(detailsResponse);

					status.value = detailsResponse?.status;
					provider.value = detailsResponse?.provider;
					notEditable.value = status.value != null && status.value !== 'draft';

					initialValues.value = {
						...detailsResponse,
						nfts: nftsListResponse?.nfts || [],
					};
				}
			}
		} finally {
			if (showLoading) {
				isLoading.value = false;
			}
		}
	};

	const setDraftState = async dropId => {
		// eslint-disable-next-line no-param-reassign
		id = dropId;

		isNew.value = false;

		await getDetails(false);

		await replace(`${routes.drops}/${dropId}`);
	};

	const createDrop = async values => {
		if (values.deploy && provider.value === providerTypes.ETHEREUM) {
			values.deploy = false;

			const response = await dropsService.create(values);

			if (response && response?.id) {
				await setDraftState(response?.id);

				const contractData = await dropsService.createContract({ ...values, dropId: response.id });

				if (contractData) {
					await dropsService.deploy(response.id, {
						contractAddress: contractData.mintShop1155,
						contractAddressSuper: contractData.super1155,
					});
				}
			}

			return;
		}

		await dropsService.create(values);
	};

	const submit = async values => {
		isSubmitLoading.value = true;

		try {
			if (isNew.value) {
				await createDrop(values);
			} else {
				if (status.value === 'draft' && provider.value === providerTypes.ETHEREUM && values.deploy) {
					const contractData = await dropsService.createContract({ ...values, dropId: id });

					if (contractData) {
						values.drop.contractAddress = contractData.mintShop1155;
						values.drop.contractAddressSuper = contractData.super1155;
					}
				}

				await dropsService.update(id, values);
			}

			await replace(`${routes.drops}${status.value != null ? `?tab=${status.value}` : ''}`);
		} finally {
			isSubmitLoading.value = false;
		}
	};

	onMounted(async () => {
		await getDetails();
	});

	return {
		drop,
		isNew,
		submit,
		status,
		hasData,
		provider,
		isLoading,
		notEditable,
		initialValues,
		isSubmitLoading,
	};
};
