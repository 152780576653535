<template>
  <ModalContentContainer class="content">
    <ModalHeader class="content__header">
      <ModalTitle
        class="header__title"
        value="Add a collaborator"
      />
    </ModalHeader>
    <ModalBody class="content__body">
      <FormTextField
        placeholder="Enter a wallet address"
        name="address"
      />
    </ModalBody>
    <ModalFooter class="content__footer">
      <Button
        class="footer__button"
        size="flex"
        :disabled="btnLoading"
        @click="onSubmit"
      >
        Add
      </Button>
    </ModalFooter>
  </ModalContentContainer>
</template>

<script>
import { provide, ref } from 'vue';
import { useStore } from 'vuex';
import { useForm } from 'vee-validate';

import { ethersService } from '@/services';

import {
	ModalContentContainer,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalTitle,
} from '@/components/common/modal';
import Button from '@/components/ui/Button';
import {
	ethereumAddress,
	object,
} from '@/components/common/form';
import FormTextField from '@/components/common/form/components/FormTextField.vue';

export default {
	components: {
		ModalContentContainer,
		ModalHeader,
		ModalBody,
		ModalFooter,
		ModalTitle,
		Button,
		FormTextField,
	},

	setup () {
		const store = useStore();
		const btnLoading = ref(false);

		const validationSchema = object({
			address: ethereumAddress(),
		});

		const { values, handleSubmit, setFieldValue } = useForm({
			initialValues: { address: '' },
			validationSchema,
		});

		const update = async (payload) => {
			await store.dispatch('editCollection/addCollaborator', payload);
		};

		const submitFormValues = async (data, values, signature, signatureTime) => {
			if (!data && !values) return;

			const { update } = data;
			const dataset = {
				walletAddress: values.address.toLowerCase(),
				signature,
				signatureTime,
			};
			await update(dataset);
		};

		const onSubmit = handleSubmit(async values => {
			btnLoading.value = true;

			try {
				const signerAddress = store.state.ethers?.address?.toLowerCase();
				const collectionAddress = store.state.editCollection?.collection?.contractAddress?.toLowerCase();
				const provider = await ethersService.getProvider();
				const signer = await provider.getSigner();
				const signatureTime = Date.now();
				const message = `I, ${signerAddress}, want to add the address ${values.address?.toLowerCase()} to the collection ${collectionAddress}.\n`
            + `Current time: ${`${new Date(signatureTime).toLocaleString('en-US', { timeZone: 'UTC' })} (UTC)`}`;
				const signature = await signer.signMessage(message);

				await submitFormValues({ update }, values, signature, signatureTime);
			} finally {
				btnLoading.value = false;
			}
			store.dispatch('modal/closeModal');
		});

		provide('formState', { setFieldValue, values });

		return {
			onSubmit,
			btnLoading,
		};
	},
};
</script>

<style scoped lang="scss">
  .content {
    width: 410px;
    &__footer {
      display: flex;
      justify-content: center;
    }
  }
</style>
