<template>
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 0L0 5H10L5 0ZM0 7L5 12L10 7H0Z"
      fill="currentColor"
    />
  </svg>
</template>
