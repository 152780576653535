import { ethers } from 'ethers';

// Format a number with place delimiters.
export const formatNumber = function (value, decimals = 6) {
	// eslint-disable-next-line no-param-reassign
	value = value.toString().split('.');
	value[0] = value[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + ',');

	if (value[1]) {
		value[1] = value[1].substring(0, decimals);
		value[1] = value[1].padEnd(decimals, '0');
	} else {
		value[1] = ''.padEnd(decimals, '0');
	}
	// eslint-disable-next-line no-param-reassign
	value = value.join('.');
	if (decimals === 0) {
		// eslint-disable-next-line no-param-reassign
		value = value.substring(0, value.length - 1);
	}

	return value;
};

// Format an address to have an ellipsis in the middle
export const formatAddress = function (address) {
	const length = address.length;

	if (length > 0) {
		return `${address.substring(0, 6)}...${address.substring(length - 4)}`;
	}

	return '';
};

export const formatString = (string, stringLengthLimit = 24, leftHalfSize = 6, rightHalfSize = 4) => {
	if (!string || typeof string !== 'string' || !string.length) return '';
	if (string.length < stringLengthLimit) return string;

	return `${string.substring(0, leftHalfSize)}...${string.substring(string.length - rightHalfSize)}`;
};

export function formatAddressName (name, address, ensName) {
	if (name) {
		return !ethers.utils.isAddress(name) ? name : formatAddress(name || '');
	}
	if (ensName) {
		return formatString(ensName, 24, 20, 3);
	}
	if (address) {
		return formatAddress(address || '');
	}

	return '';
}

export const abbreviateNumber = function (value) {
	if (value < 1000) {
		return value;
	}

	const si = [
		{ v: 1e3, s: 'K' },
		{ v: 1e6, s: 'M' },
		{ v: 1e9, s: 'B' },
		{ v: 1e12, s: 'T' },
		{ v: 1e15, s: 'P' },
		{ v: 1e18, s: 'E' },
	];

	let i;

	// eslint-disable-next-line no-plusplus
	for (i = si.length - 1; i > 0; i--) {
		if (value >= si[i].v) {
			break;
		}
	}

	return (
		(value / si[i].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1')
        + si[i].s
	);
};

export const formatUrl = rawUrl => rawUrl?.replace(/(^\w+:|^|)(\/)/g, '') || '';

export const formatBytes = (bytes, decimals = 0) => {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	if (bytes === 0) return '0';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const capitalizeFirstLetter = (string) => `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
