<template>
  <div :class="['textarea-wrapper', !!errorMessage ? 'error' : undefined]">
    <textarea
      v-bind="$attrs"
      :id="id"
      class="textarea"
    />
    <ErrorMessage v-if="isErrorMessageVisible && !!errorMessage">
      {{ errorMessage }}
    </ErrorMessage>
  </div>
</template>

<script>
import ErrorMessage from './ErrorMessage.vue';

export default {
	components: { ErrorMessage },
	props: {
		id: {
			type: String,
			required: false,
		},
		errorMessage: {
			type: String,
			required: false,
		},
		isErrorMessageVisible: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
};
</script>

<style scoped lang='scss'>
  .textarea-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    &.error {
      .textarea {
        box-shadow: 0 0 0 1px rgba(241, 40, 72, .20);

        &:not(:disabled) {
          &:hover {
            box-shadow: 0 0 0 1px rgba(241, 40, 72, .50);
          }

          &:focus {
            box-shadow: 0 0 0 2px $red;
          }
        }
      }
    }
  }

  .textarea {
    outline: none;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 18px;
    border: 1px solid transparent;
    min-height: 40px;
    background-color: $white;
    color: $grey;
    transition: 200ms cubic-bezier(.215, .61, .355, 1);
    box-shadow: 0 0 0 1px rgba(113, 18, 255, .20);
    width: 100%;
    border-radius: 10px;
    resize: vertical;

    &:disabled {
      opacity: .5;
      cursor: default;
    }

    &:not(:disabled) {
      &:hover {
        box-shadow: 0 0 0 1px rgba(113, 18, 255, .50);
      }

      &:focus {
        box-shadow: 0 0 0 2px $violet;
      }
    }
  }
</style>
