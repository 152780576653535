import { ref, onMounted } from 'vue';

import { addSuccess } from '@/components/utility/notifications';

export function useSettingsList ({ getList, updateList }) {
	const items = ref([]);
	const isSaving = ref(false);
	const isLoading = ref(false);

	/**
     * @param {Object} data
     * @param {string[]} data.itemsToAdd
     * @param {string[]} data.itemsToRemove
     * @param {string[]} data.updatedItems
     * @returns {Promise<void>}
     */
	async function onSubmit (data) {
		try {
			isSaving.value = true;

			await updateList({ add: data.itemsToAdd, remove: data.itemsToRemove });
			addSuccess('Changes successfully saved');

			items.value = data.updatedItems;
		} finally {
			isSaving.value = false;
		}
	}

	onMounted(async () => {
		try {
			isLoading.value = true;
			items.value = (await getList())?.values || [];
		} catch {
			items.value = [];
		} finally {
			isLoading.value = false;
		}
	});

	return {
		items,
		isSaving,
		onSubmit,
		isLoading,
	};
}
