<template>
  <div class="split-collection__table">
    <div class="table__header">
      <p>Collection address</p>
      <p>Range</p>
      <p />
    </div>
    <ul class="table__content">
      <SplitCollectionTableRow
        v-for="(collection, idx) in collections"
        :key="idx"
        :collection-address="collection.contractAddress"
        :collection-range="collection.ranges"
        :collection-id="idx"
        :is-last-item-in-array="collections?.length === COLLECTIONS_CONSTANTS.MIN_COUNT_FOR_SPLIT_COLLECTION"
        :is-edit-mode="isEditMode"
        :handle-change-address="handleChangeAddress"
        @on-remove-element="removeElement"
      />
    </ul>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue';

import { COLLECTIONS_CONSTANTS } from '@/pages/collections/constants/index.js';

import SplitCollectionTableRow
	from '@/components/common/tables/SplitCollectionTable/SplitCollectionTableRow/SplitCollectionTableRow.vue';

defineProps({
	collections: {
		type: Array,
		required: true,
	},
	isEditMode: {
		type: Boolean,
		default: false,
	},
	handleChangeAddress: {
		type: Function,
	},
});

const emit = defineEmits(['onRemoveElement']);

const removeElement = (collectionId) => emit('onRemoveElement', collectionId);
</script>

<style lang=scss scoped>
.split-collection__table {
  max-width: 1204px;
  width: 100%;
  display: flex;
  flex-direction: column;

  border: 1px solid $shade30;

  .table__header {
    display: flex;
    align-items: center;
    padding: 20px;

    border-bottom: 1px solid $shade30;

    p {
      width: 45%;

      font-size: 14px;
      font-weight: 500;

      &:last-child {
        width: 10%;
      }
    }
  }

  .table__content {
    display: flex;
    flex-direction: column;

    .table__row {
      &:not(:last-child) {
        border-bottom: 1px solid $shade30;
      }
    }
  }
}
</style>
